import {useMemo} from "react"
import {LabelModel} from "src/models/label"
import {SettingConfig} from "src/models/setting-config"
import {WorkshiftModel} from "src/services/workshift.service"
import toDateTime from "src/utils/date-time"
import {toPrice} from "src/utils/price"

interface WorkshiftReceiptConfig {
  settings: SettingConfig
  paymentTypes: LabelModel[]
  workshift: WorkshiftModel
  type?: "download" | "print"
  className?: string
  paddingWidth?: number
}

function WorkshiftReceiptRow({name, value}: {name: string; value: JSX.Element | string | number}) {
  return (
    <div className="flex justify-between gap-1 text-xs font-light">
      <span>{name}:</span>
      <span className="font-semibold text-nowrap">{value}</span>
    </div>
  )
}

export function WorkshiftReceipt({
  settings,
  paymentTypes,
  workshift,
  type = "print",
  className = "",
  paddingWidth = 4
}: WorkshiftReceiptConfig) {
  const padding = type === "print" ? 0 : paddingWidth
  const paddingBottom = type === "print" ? 64 : paddingWidth

  const paymentMap = workshift.payments.reduce((p, c) => ({...p, [c.type]: c}), {})

  const customFields: LabelModel[] = useMemo(() => {
    if (!settings) return []
    if (!settings.custom_fields) return []
    if (!settings.custom_fields.order) return []
    if (!workshift) return []
    if (!workshift.extra) return []
    return settings.custom_fields.order
      .filter((field) => Object.keys(workshift.extra).includes(field.name))
      .map((field) => ({id: field.name, label: field.label}))
  }, [workshift, settings])

  return (
    <div
      id="capture"
      className={className}
      style={{
        fontFamily: "Arial, Helvetica, Sans-Serif",
        width: 288,
        padding,
        paddingBottom
      }}>
      <div className="flex gap-2 flex-col pb-3">
        <div className="text-center w-full font-semibold text-nowrap">Общий отчет</div>
        <WorkshiftReceiptRow name="Начало смены" value={workshift.start_at && toDateTime(workshift.start_at)} />
        <WorkshiftReceiptRow name="Конец смены" value={workshift.end_at && toDateTime(workshift.end_at)} />
        <WorkshiftReceiptRow name="Кассир" value={workshift.profile} />
        <WorkshiftReceiptRow name="Пункт проката" value={workshift.point} />
        <hr className="my-0" />
        <WorkshiftReceiptRow name="Общее количество аренд" value={workshift.order_count} />
        <WorkshiftReceiptRow name="Сумма аренд" value={toPrice(workshift.order_amount)} />
        {customFields.map((field) => (
          <WorkshiftReceiptRow key={field.id} name={field.label} value={workshift.extra[field.id]} />
        ))}
        <WorkshiftReceiptRow name="Количество штрафов" value={workshift.order_penalty_count} />
        <WorkshiftReceiptRow name="Сумма штрафов" value={toPrice(workshift.order_penalty_amount)} />
        <hr className="my-0" />

        {workshift.services.length > 0 &&
          workshift.services.map((service, i) => (
            <WorkshiftReceiptRow
              key={i}
              name={service.name || "Не выбрано"}
              value={toPrice(+service.price, "", ` (${service.count})`)}
            />
          ))}
        {workshift.services.length > 0 && <hr className="my-0" />}
        <WorkshiftReceiptRow
          name="Сумма оплат"
          value={toPrice(workshift.payment_amount, "", ` (${workshift.payment_count || 0})`)}
        />
        {paymentTypes.map((paymentTypes) => {
          const payment = paymentMap[paymentTypes.id]
          return (
            <WorkshiftReceiptRow
              key={paymentTypes.id}
              name={paymentTypes.label || "Не выбрано"}
              value={toPrice(payment?.amount || 0, "", ` (${payment?.count || 0})`)}
            />
          )
        })}
        {paymentMap["null"] && (
          <WorkshiftReceiptRow
            name="Другое"
            value={toPrice(paymentMap["null"]?.amount || 0, "", ` (${paymentMap["null"]?.count || 0})`)}
          />
        )}
      </div>
      <div className="text-center text-xs font-light pb-6">yume.cloud</div>
    </div>
  )
}
