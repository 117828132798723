import React, {ReactNode} from "react"
import {ImageField} from "src/components/shared/image-loader/image"
import {IntegrationModel} from "src/models/integration"
import modalService from "src/components/modal/global/modal.service"
import {useNavigate} from "react-router"
import integrationService from "src/services/integration"
import {useTranslation} from "react-i18next"
import {FormProvider, useForm} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import {WazzupGuideModal} from "./wazzup/guide"
import "./styles.scss"

export function IntegrationConnection({
  integration,
  instruction,
  additional
}: {
  integration: IntegrationModel
  instruction?: string | ReactNode
  additional?: ReactNode
}) {
  const {t} = useTranslation()
  const form = useForm({defaultValues: {}})
  const navigate = useNavigate()
  const fields = integration.extra ? integration.extra.fields : []
  const fieldsMap = integration.extra ? integration.extra.specs.reduce((p, c) => ({...p, [c.name]: c}), {}) : {}

  const onInstall = form.handleSubmit(async (payload) => {
    await integrationService.connect(integration.code, payload)
    const link = integration.extra?.link
    navigate(link || `/integrations/${integration.code}`)
    modalService.closeAllModal()
  })
  const canInstall = integration.extra && "manual" in integration.extra ? integration.extra.manual : true

  const onCancel = () => modalService.closeModal()

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-3">
        <h4 className="text-xl font-bold mb-3">{t("integration.connect_integration")}</h4>

        <div className="mb-4 flex flex-row gap-2 justify-between items-center">
          <div className="flex items-center gap-4 w-full">
            <ImageField
              draggable={false}
              src={integration.icon}
              className="w-14 h-14 border p-1 rounded-lg overflow-hidden object-contain"
            />

            <div className="flex gap-2 flex-col flex-1">
              <div className="text-lg font-bold">{integration.name}</div>
              {integration.comment && <div className="text-gray-400">{integration.comment}</div>}
            </div>
          </div>

          {integration.extra && "is_yume_cloud" in integration.extra && integration.extra["is_yume_cloud"] && (
            <span className="color-primary font-semibold text-nowrap">{t("integration.from_yume")}</span>
          )}
        </div>

        {fields.map((key) => (
          <FormControl
            className="form-control"
            key={key}
            name={`extra.${key}`}
            placeholder={fieldsMap[key].label}
            params={{required: true, ...fieldsMap[key].params}}
          />
        ))}

        {instruction && (
          <div className={`${typeof instruction === "string" && "text-primary text-[13px]"}`}>{instruction}</div>
        )}

        {canInstall && (
          <div className="flex gap-2 justify-end">
            {additional ? (
              additional
            ) : (
              <button className="btn btn-card" onClick={onCancel}>
                {t("common.cancel")}
              </button>
            )}
            <button className="btn btn-primary btn-color-white" onClick={onInstall} disabled={!form.formState.isValid}>
              {t("common.install")}
            </button>
          </div>
        )}
      </div>
    </FormProvider>
  )
}
