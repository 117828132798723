import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import KanbanBoardComponent from "./list"
import {FormProvider, useForm} from "react-hook-form"
import CRMFunnel from "src/components/shared/components/select/crm-funnels"
import {useContext, useState} from "react"
import {CRMContext} from "./list/context"
import {toPrice} from "../../utils/price"

const CrmComponent = () => {
  const {funnelForm, leads} = useContext(CRMContext)
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }
  const closeDrawer = () => {
    setOpen(false)
  }

  return (
    <FormProvider {...funnelForm}>
      <HeaderContent>
        <div className="flex justify-between items-center py-2 mx-4 border-b border-gray-100">
          <div className="font-semibold text-[22px] text-black">Заявки</div>

          <div className="flex gap-4 items-center">
            <div className="text-sm">
              {leads.count} сделка – {toPrice(leads.price)}
            </div>

            <CRMFunnel
              name="funnel"
              placeholder="Выберите воронку"
              params={{required: true}}
              isSearchable={false}
              isClearable={true}
            />
            <button className="btn btn-primary btn-color-white text-base font-medium leading-5" onClick={showDrawer}>
              <span className="flex items-center gap-2">
                <Icon icon="add" /> New Deal
              </span>
            </button>
          </div>
        </div>
      </HeaderContent>
      <KanbanBoardComponent closeDrawer={closeDrawer} open={open} />
    </FormProvider>
  )
}

export default CrmComponent
