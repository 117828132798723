import {createContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {InventorySetModel} from "src/models/manager/inventory/inventory-set.model"
import inventorySetService from "src/services/inventory/inventory-set.service"

interface ProductSetContextInterface {
  id: number
  set: InventorySetModel
}

export const ProductSetContext = createContext<ProductSetContextInterface>(null)

export const ProductSetProvider = ({children}) => {
  const {id} = useParams()
  const [setInfo, setSetInfo] = useState<InventorySetModel>(null)

  useEffect(() => {
    if (!id) return
    inventorySetService.get(+id).then(setSetInfo)
  }, [id])

  return <ProductSetContext.Provider value={{set: setInfo, id: +id}}>{setInfo && children}</ProductSetContext.Provider>
}
