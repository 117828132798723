import clsx from "clsx"
import {CSSProperties} from "react"
import {RegisterOptions} from "react-hook-form"

interface FormOptionConfig<T> {
  value: T
  onChange: (val: T) => void
  params?: RegisterOptions<any, any>
  className?: string
  style?: CSSProperties
  options: Array<{
    label: string
    value: T
    className?: string
    style?: CSSProperties
    hint?: string
  }>
  textClassName?: string
  hintClassName?: string
}

const selected = {
  active: "border-primary",
  default: "border-gray-200",
  toggle: {
    active: "min-w-5 w-5 h-5 rounded-full border-[6px] border-primary",
    default: "min-w-5 w-5 h-5 rounded-full border border-gray-200"
  }
}

export function OptionChoose<T = number>(config: FormOptionConfig<T>) {
  const {onChange, options, value, textClassName, hintClassName} = config
  return (
    <div className="flex flex-col gap-3">
      {options.map((option, index) => (
        <div
          key={index}
          className={clsx(
            "flex gap-2 border-none cursor-pointer",
            option.value === value ? selected.active : selected.default
          )}
          onClick={() => onChange(option.value)}>
          <span className={option.value === value ? selected.toggle.active : selected.toggle.default} />
          <span>
            <span className={clsx("text-nowrap mr-2", textClassName)}>{option.label}</span>
            <span className={clsx(hintClassName)}>{option.hint}</span>
          </span>
        </div>
      ))}
    </div>
  )
}
