import {useContext} from "react"
import {OrderContext} from "../context/order-context"
import {useTranslation} from "react-i18next"
import {OrderStatus} from "../../constants"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "src/app"
import clsx from "clsx"

export default function OrderInventoriesHeader(config: {header: string; onAdd: () => void; service?: boolean}) {
  const {order} = useContext(OrderContext)
  const {t} = useTranslation()
  const {isMobile} = useContext(ConfigContext)
  // const form = useForm<{unique_id: string}>()
 
  // const onCheckoutSearch = (unique_id: string) => {
  //   const inventoryRequest = inventories.find(
  //     (obj) => obj.inventory.unique_id.toLowerCase() === unique_id.toLowerCase()
  //   )
  //   if (inventoryRequest !== undefined) {
  //     addCheckout(inventoryRequest.id)
  //   } else {
  //     modalService.open({
  //       component: <MessageModal message={t("orders.main.not_found_by_article")} />
  //     })
  //   }
  // }

  // const checkoutFormSubmit = form.handleSubmit((payload) => {
  //   onCheckoutSearch(payload.unique_id)
  //   form.setValue("unique_id", "")
  // })

  // const onScan = () => {
  //   modalService.open({
  //     component: (
  //       <div className="flex items-center gap-3">
  //         <Icon className="text-5xl" icon="barcode_scanner" />
  //         <span className="text-lg wieght-600">{t("orders.main.scan_message")}</span>
  //       </div>
  //     )
  //   })
  // }

  return (
    <div className="flex gap-3 flex-col px-2 py-1 md:px-3 md:py-4">
      <div className="flex gap-2 justify-between items-center min-h-6">
        <div className="text-lg font-bold md:text-xl">{config.header}</div>
        <div className="flex gap-2">
          {/* {!config.service && (
            <button className="btn border border-black hover:text-black hover:border-black gap-2 px-4 py-3 hidden md:flex" onClick={onScan}>
              <Icon icon="barcode_scanner" />
              <span>{t("orders.main.scan")}</span>
            </button>
          )} */}

          {[OrderStatus.REQUEST, OrderStatus.RESERVED, OrderStatus.INRENT].includes(order.status) && (
            <button
              id="step3"
              className={clsx("btn gap-1 px-4 py-3", isMobile ? "btn-primary-20 h-9 w-9" : "btn-black")}
              onClick={config.onAdd}>
              <Icon icon="add" className="md:text-white text-primary" />
              {!isMobile && t("common.add")}
            </button>
          )}

          {/* {!config.service && (
            <FormProvider {...form}>
              <form onSubmit={checkoutFormSubmit} className="hidden md:block">
                <FormControl
                  icon="search"
                  name="unique_id"
                  className="form-control"
                  placeholder={t("common.input.search")}
                  params={{required: true}}
                />
              </form>
            </FormProvider>
          )} */}
        </div>
      </div>
    </div>
  )
}
