export default function YoutubePlayer({src, className}: {src: string, className?: string}) {
    return (
        <iframe
            width="100%"
            height="400"
            className={className}
            frameBorder="0"
            src={src}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        ></iframe>
    )
}