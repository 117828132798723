import React, {useEffect, useMemo, useRef} from "react"
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import Icon from "src/components/shared/components/material-icon"
import {isNil} from "src/utils/isNil"
import clsx from "clsx"
import {use100vh} from "react-div-100vh"

const ModalComponent = ({
  id,
  size = "sm",
  removable = true,
  index,
  current,
  ...config
}: ModalConfig & {show: boolean; index?: string; current?: string}) => {
  const ref = useRef(null)
  const height = use100vh()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (index !== current) return
      if (config.backdropClick) return
      if (event.key === "Escape" && config.show) handleModalToggle()
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [config.show, index, current])

  const component = useMemo(() => {
    if (isNil(index)) return config.component
    if (Array.isArray(config.component)) return config.component
    return React.cloneElement(config.component, {id: index})
  }, [config.component, index])

  const handleModalToggle = () => {
    if (!config.disableClose) {
      modalService.closeModal(index)
      if (config.onHide !== undefined) config.onHide()
    }
  }

  useEffect(() => {
    if (config.onOpen) config.onOpen()
  }, [component])

  return (
    config.show && (
      <div
        style={{minHeight: height}}
        className={clsx(
          "relative w-auto pointer-events-none m-0 md:m-4 md:ml-auto md:mr-auto",
          {"max-w-screen md:max-w-[400px]": size === "xs"},
          {"max-w-screen md:max-w-[500px]": size === "sm"},
          {"max-w-screen md:max-w-[640px]": size === "md"},
          {"max-w-screen md:max-w-[800px]": size === "lg"},
          {"max-w-screen md:max-w-[min(calc(100vw_-_48px),_1280px)]": size === "xl"},
          true && "flex items-center md:min-h-[calc(100%_-_32px)]"
        )}>
        <div
          id={id}
          ref={ref}
          onClick={(e) => e.stopPropagation()}
          className={clsx(
            "fixed bottom-0 md:relative md:bottom-auto flex flex-col w-full pointer-events-auto outline-none bg-white text-black",
            "border-0 border-transparent rounded-t-xl rounded-b-none md:rounded-t-xl md:rounded-b-xl bg-clip-padding shadow-lg"
          )}>
          <div
            className={clsx(
              "modal-content text-sm px-3 md:px-6 flex-1 min-h-40 md:min-h-[inherit] max-h-[calc(100vh_-_48px)] md:max-h-[inherit] overflow-y-auto md:overflow-y-visible",
              removable ? "pb-16 pt-12 md:pb-8 md:pt-8" : "py-4 pb-16 md:pb-6 md:py-6",
              config.dialogClassName
            )}>
            {removable && (
              <div
                className={clsx(
                  "absolute top-1.5 right-1.5 text-xs cursor-pointer rounded-lg w-9 h-9 md:rounded-full md:w-7 md:h-7",
                  "flex items-center justify-center bg-white border border-gray-100 shadow-md text-black z-[1056]",
                  "hover:bg-white hover:border hover:border-gray-100 hover:text-black hover:shadow"
                )}
                onClick={handleModalToggle}>
                <Icon icon="close" />
              </div>
            )}
            {component}
          </div>
        </div>
      </div>
    )
  )
}

export default ModalComponent
