import {useContext, useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Outlet} from "react-router"
import {ConfigContext} from "src/app"
import HeaderContent from "src/components/header/header-content"
import {SidebarMenu, SidebarWrapper} from "src/components/shared/components/side-wrapper"
import {setChildStyle, setDefaultChildStyle} from "src/main"
import {ProductType, SettingConfig} from "src/models/setting-config"
import {TenantType} from "src/services/tenant.service"

type MenuTab = "main"
  | "rent"
  | "order-status"
  | "inventory-state"
  | "bonus"
  | "managers"
  | "custom-fields"
  | "wazzup"


export default function SettingsComponent() {
  const form = useForm<SettingConfig>({
    defaultValues: {
      rent_product_types: [ProductType.GROUP, ProductType.INVENTORTY, ProductType.SALE]
    }
  })
  const {settings, integrationMap, isMobile, headerHeight, tenant} = useContext(ConfigContext)
  const {t} = useTranslation()

  const menuTabMap: Record<MenuTab, SidebarMenu> = {
    "main": {link: "/settings/main", label: t("settings.sections.main")},
    "rent": {link: "/settings/rent", label: t("settings.sections.rent")},
    "order-status": {link: "/settings/order-status", label: t("settings.sections.order-status")},
    "inventory-state": {link: "/settings/inventory-state", label: t("settings.sections.inventory-state")},
    "bonus": {link: "/settings/bonus", label: t("settings.sections.bonuses")},
    "managers": {link: "/settings/managers", label: t("settings.sections.managers")},
    "custom-fields": {link: "/settings/custom-fields", label: t("settings.sections.custom-fields")},
    "wazzup": {link: "/settings/wazzup", label: "Wazzup"}
  }

  useEffect(() => {
    if (!settings) return
    form.reset({
      ...settings,
      language: (localStorage.getItem("language") as any) || settings.language
    })
    return () => { }
  }, [form, settings])

  const menus = useMemo(() => {
    if (!tenant) return []
    let list = ["main", "rent", "order-status", "inventory-state", "bonus", "managers", "custom-fields", "wazzup"]

    if (!tenant.bonus_enabled) {
      list = list.filter(k => k !== "bonus")
    }
    if (tenant.type_code === TenantType.TRANSFER) {
      list = list.filter(k => !["rent", "order-status", "inventory-state", "bonus", "managers", "custom-fields"].includes(k))
    }
    if (!(integrationMap["wazzup"] && integrationMap["wazzup"].connected && !integrationMap["wazzup"].disabled)) {
      list = list.filter(k => k !== "wazzup")
    }

    return list.map(key => menuTabMap[key])
  }, [tenant, integrationMap])

  useEffect(() => {
    setChildStyle({paddingLeft: 0, paddingRight: 0, paddingTop: isMobile ? headerHeight : 0, paddingBottom: 0})
    return setDefaultChildStyle
  }, [isMobile, headerHeight])

  return (
    <FormProvider {...form}>
      <HeaderContent>
        <div className="text-2xl font-semibold header-content">{t("settings.header")}</div>
      </HeaderContent>

      <SidebarWrapper menus={menus}>
        <Outlet />
      </SidebarWrapper>
    </FormProvider>
  )
}
