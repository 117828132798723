import {useContext, useEffect, useState} from "react"
import {toPrice} from "src/utils/price"
import {EMPTY_LIST, ListModel, ListParams} from "src/models/common"
import servicesService from "src/services/services"
import {ServiceModel} from "src/models/manager/order/order-request-service-new"
import Table from "src/components/shared/antd-custom/table"
import type {ColumnsType} from "antd/es/table"
import toDateTime from "src/utils/date-time"
import {useTranslation} from "react-i18next"
import {OrderContext} from "../context/order-context"
import {ConfigContext} from "src/app"
import {FormProvider, useForm, useFormContext} from "react-hook-form"
import {FormControlEntity} from "src/components/shared/inputs/form-control-entity"
import {useNavigate} from "react-router"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"

function ServiceAddCell({
  service,
  onSelect
}: {
  service: ServiceModel
  onSelect: (service: ServiceModel, count?: number) => void
}) {
  const form = useForm({defaultValues: {count: 1}})
  const {t} = useTranslation()
  const disabled = form.watch("count") === 0
  const onSubmit = form.handleSubmit((payload) => onSelect(service, payload.count))

  return (
    <FormProvider {...form}>
      <div className="flex gap-2 align-center justify-end">
        <FormControlEntity name="count" maxcount={100} rootclassname="flex justify-center items-center" />
        <button className="btn btn-primary text-white" disabled={disabled} onClick={onSubmit}>
          {t("common.add")}
        </button>
      </div>
    </FormProvider>
  )
}

export default function OrderServicesAddModal({onHide}: {onHide?: () => void}) {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {watch} = useFormContext()
  const page = +watch("page") || 1
  const pageSize = +watch("pageSize") || 10
  const [list, setList] = useState<ListModel<ServiceModel>>(EMPTY_LIST)
  const {createServicesForm, orderForm} = useContext(OrderContext) || {}
  const {isMobile} = useContext(ConfigContext)

  const addService = (service: ServiceModel, count: number) => {
    for (let i = 0; i < count; i++) {
      createServicesForm.append({
        object_id: null,
        service,
        price: 0,
        price_discount: 0,
        start_at: orderForm.watch("rent_start"),
        end_at: orderForm.watch("rent_end")
      })
    }

    if (onHide) {
      onHide()
    } else {
      navigate(-1)
    }
  }
  useEffect(() => {
    servicesService.list({page, pageSize}).then(setList)
  }, [page, pageSize])

  const mobileColumns: ColumnsType<ServiceModel> = [
    {
      width: "auto",
      title: t("groups.table.name"),
      dataIndex: "name",
      key: "name",
      render: (_, set) => {
        const {id, name, tarifs, created_at} = set

        return (
          <div className="flex flex-col gap-2">
            <p className="font-medium text-[15px] text-black">{name}</p>
            <div className={"flex gap-1"}>
              <p className="font-normal text-[13px] text-gray-370">№{id}</p>
              {tarifs.length !== 0 ? (
                <>
                  <p className="font-normal text-[13px] text-gray-370">•</p>
                  <p className="font-normal text-[13px] text-gray-370">
                    {tarifs.map((tarif) => toPrice(tarif.price, "", ""))}
                  </p>
                </>
              ) : (
                <>
                  <p className="font-normal text-[13px] text-gray-370">•</p>
                  <p className="font-normal text-[13px] text-gray-370">Нет тарифов</p>
                </>
              )}
            </div>
          </div>
        )
      }
    }
  ]

  const columns: ColumnsType<ServiceModel> = [
    {
      width: 48,
      title: "№",
      key: "id",
      dataIndex: "id",
      render: (id) => id
    },
    {
      width: 240,
      title: t("orders.service.table.name"),
      key: "name",
      dataIndex: "name",
      render: (name) => name
    },
    {
      width: 180,
      title: t("orders.service.table.tarifs"),
      key: "tarifs",
      dataIndex: "tarifs",
      render: (tarifs) => (
        <div className="flex gap-1 flex-wrap">
          {tarifs.map((tarif, index) => (
            <p>{toPrice(tarif.price, "", tarifs.length <= 1 || index === tarifs.length - 1 ? "" : ", ")}</p>
          ))}
        </div>
      )
    },
    {
      width: 160,
      title: t("orders.service.table.created_at"),
      key: "created_at",
      dataIndex: "created_at",
      render: (created_at) => toDateTime(created_at)
    },
    {
      width: 80,
      title: "",
      key: "action",
      render: (service) => <ServiceAddCell service={service} onSelect={addService} />
    }
  ]

  return (
    <>
      <Table
        showHeader={!isMobile}
        className="rounded-none border-0"
        rowKey={(obj) => obj.id}
        // onRow={(service) => ({
        //   onClick: () => {
        //     createServicesForm.append({
        //       object_id: null,
        //       service,
        //       price: 0,
        //       price_discount: 0,
        //       start_at: orderForm.watch("rent_start"),
        //       end_at: orderForm.watch("rent_end")
        //     })
        //
        //     modalService.closeModal()
        //   }
        // })}
        rootClassName="w-full"
        dataSource={list.results}
        columns={isMobile ? mobileColumns : columns}
        autoScroll={true}
      />
      <PaginationFormComponent className="mt-4" count={list.count} />
    </>
  )
}
