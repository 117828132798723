import {createContext, useEffect, useState} from "react"
import {useParams} from "react-router"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import inventoriesService from "../../services/inventory/inventory.service"
import {InventoryModel} from "../../models/manager/inventory/inventory.model"

export interface CarContextType {
  car: any
  carId: string
  activeRoute: string
  getCar: () => void
}

export const CarContext = createContext<CarContextType>(undefined)

const CarProvider = ({children}) => {
  const {carId} = useParams<{carId: string}>()

  const [car, setCar] = useState<InventoryModel>()
  const activeRoute = useCurrentRoute()
  function getCar() {
    inventoriesService.get(+carId).then(setCar)
  }

  useEffect(() => {
    getCar()

    return () => setCar(undefined)
  }, [carId])

  return <CarContext.Provider value={{car, carId, activeRoute, getCar}}>{car && children}</CarContext.Provider>
}
export default CarProvider
