import axios from "axios"
import {CrudInterface} from "../interfaces/crud.interface"
import {ListModel} from "../models/common"
import {ApiAbstract} from "../abstract/api.abstract"

export default class GenericEntityService<T> extends ApiAbstract implements CrudInterface<T> {
  constructor(protected uri: string) {
    super(uri)
  }

  public async fetch(params?: any): Promise<T[]> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async list(params?: any): Promise<ListModel<T>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async get(id: number): Promise<T> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async post(payload: any): Promise<T> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: any): Promise<T> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async delete(id: number): Promise<T> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
}
