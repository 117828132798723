import {useEffect, useState} from "react"

export const useContainerDimensions = (myRef, prop) => {
  const [dimensions, setDimensions] = useState({width: 0, height: 0})

  useEffect(() => {
    if (!myRef.current) return

    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    const handleResize = () => setDimensions(getDimensions())
    if (myRef.current) setDimensions(getDimensions())
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [myRef, prop])

  return dimensions
}
