import {useTranslation} from "react-i18next"
import {ImageField} from "src/components/shared/image-loader/image"
import {IntegrationModel} from "src/models/integration"
import {IntegrationConnection} from "../connection.modal"
import modalService from "src/components/modal/global/modal.service"
import {Tooltip} from "antd"
import Icon from "src/components/shared/components/material-icon"
import integrationService from "src/services/integration"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import clsx from "clsx"

interface IntegrationLandingParams {
  integration: IntegrationModel
  children: JSX.Element | JSX.Element[]
}

export function IntegrationLandingWrapper({integration, children}: IntegrationLandingParams) {
  const {t} = useTranslation()

  const onConnect = () => {
    modalService.open({
      component: <IntegrationConnection integration={integration} />
    })
  }

  const onDisconnect = () => {
    const onConfirm = () => integrationService.disconnect(integration.code).then(() => {})

    modalService.open({
      component: <ConfirmModal message={t("common.disconnect")} onConfirm={onConfirm} delete={true} />
    })
  }

  return (
    integration && (
      <div className={clsx("flex flex-col gap-4 bg-white p-6 rounded-lg mb-6 max-w-[720px] m-auto")}>
        <div className="flex justify-normal flex-col gap-4 sm:flex-row sm:justify-between sm:items-center w-full">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex justify-center items-center w-12 h-12 bg-gray-70 rounded">
              <ImageField
                draggable={false}
                src={integration.icon}
                className="w-7 h-7 rounded-lg overflow-hidden object-contain"
              />
            </div>
            <div className="font-bold text-2xl">{integration.name}</div>
          </div>
          {integration.connected ? (
            <div className="btn-group">
              <div className="btn btn-green-8 gap-1 text-[13px] btn-color-green font-semibold w-full h-11">
                <Icon icon="check" />
                {t("common.connected")}
              </div>
              <Tooltip placement="top" title="Отключить">
                <button className="btn btn-red-8 btn-color-red h-11" onClick={onDisconnect}>
                  <Icon icon="link_off" />
                </button>
              </Tooltip>
            </div>
          ) : (
            <button
              onClick={onConnect}
              disabled={integration.connected}
              className="btn items-center btn-primary btn-color-white font-semibold h-11">
              {t("common.connect")}
            </button>
          )}
        </div>
        <div className="flex gap-4 flex-col flex-1">{children}</div>
      </div>
    )
  )
}
