import {OptionChoose} from "../option-choose"

export default function DoNowOrPlanSelector({
  value,
  onChange,
  options
}: {
  value: boolean
  onChange: (value: boolean) => void
  options: Array<{label: string; value: boolean}>
}) {
  return (
    <OptionChoose<boolean>
      onChange={onChange}
      options={options}
      value={value}
      textClassName="font-medium text-black"
      hintClassName="font-normal text-sm text-gray-350"
    />
  )
}
