import {useTranslation} from "react-i18next"
import {useContext, useEffect, useMemo, useState} from "react"
import {Segmented} from "src/components/shared/antd-custom"
import tenantService, {Subscription, SubscriptionPeriod} from "src/services/tenant.service"
import moment from "moment"
import {ConfigContext} from "src/app"
import clsx from "clsx"
import {priceBeautify} from "src/utils/price"
import {TariffPermissionList} from "./profile/tariff-permission-list"
import modalService from "../../components/modal/global/modal.service"
import {SubscriptionPhoneForm} from "./phone"
import {useLocation, useNavigate} from "react-router"
import subscriptionModalService from "src/services/subscription.service"
import {Tooltip} from "antd"
import Icon from "src/components/shared/components/material-icon"

type CURRENCY = "USD" | "KZT"

const currencyFormatter: Record<CURRENCY, string> = {USD: "$", KZT: "₸"}
const defaultMonth = 30

const hierarhy: Record<string, number> = {
  "Start": 0,
  "Lite": 1,
  "Pro": 2,
  "Enterpise": 3
}

function TenantSubscriptionTarif({
  subscription,
  currency,
  option
}: {
  subscription: Subscription
  currency: CURRENCY
  option: number
}) {
  const {tenant} = useContext(ConfigContext)
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const tarifMap: Record<number, SubscriptionPeriod> = Object.fromEntries(
    subscription.periods.filter((period) => period.currency === currency).map((period) => [period.days, period])
  )

  const onSubscriptionPayment = async () => {
    const onConfirm = async ({phone}: {phone?: string} = {}) => {
      const invoice = await tenantService.createInvoice({
        subscription: subscription.id,
        period: tarifMap[option].id,
        success_url: window.location.href,
        phone
      })

      modalService.closeModal()
      subscriptionModalService.setShow({show: false, type: null})

      navigate(`/payment/${invoice.id}?path=${location.pathname}`)

      // if (invoice.link && +invoice.status !== 3) {
      //   navigate(`/payment/${invoice.id}`)
      // } else if (+invoice.status === 3) {
      //   window.location.replace("/profile/subscription")
      // }
    }

    if (tenant && !tenant.card_phone) {
      modalService.open({component: <SubscriptionPhoneForm phone={tenant.card_phone} onConfirm={onConfirm} />})
    } else {
      onConfirm({phone: tenant.card_phone})
    }
  }

  const initialPrice = priceBeautify(tarifMap[defaultMonth].price * Math.round(tarifMap[option].days / 30), 0)
  const currentPrice = priceBeautify(tarifMap[option].price, 0)

  const connectBtn = useMemo(() => {
    if (!tenant) return <></>
    if (!subscription) return <></>
    if (!tarifMap) return <></>

    if (subscription.name === "Enterprise") return (
      <a href="https://wa.me/+7779479990" className="btn bg-primary text-white font-medium">
        Связаться с нами
      </a>
    )

    const defaultBtn = (
      <button className="btn bg-primary text-white font-medium" onClick={onSubscriptionPayment}>
        Подключить
      </button>
    )

    if (!tenant.period) return defaultBtn
    if (!tarifMap[option]) return defaultBtn
    console.log(hierarhy[subscription.name], hierarhy[tenant.subscription.name], hierarhy[subscription.name] > hierarhy[tenant.subscription.name])

    if (hierarhy[subscription.name] < hierarhy[tenant.subscription.name]) {
      return (
        <div className="btn bg-gray-70 text-gray-350 font-medium cursor-default gap-2">
          <span>Лимит превышен</span>
          <Tooltip title="В данный момент у вас активировано больше пунктов проката, аккаунтов сотрудников и других функций, чем доступно в этом тарифе"><Icon icon="info" /></Tooltip>
        </div>
      )
    }

    if (subscription.id === tenant.subscription.id && +tarifMap[option].days > +tenant.period.days) {
      return (
        <button className="btn bg-primary text-white font-medium" onClick={onSubscriptionPayment}>
          Улучшить тариф
        </button>
      )
    }

    if (subscription.id === tenant.subscription.id && tenant.period.id === tarifMap[option].id && moment(tenant.end_at) >= moment()) {
      return (
        <div className="btn bg-primary text-primary font-medium bg-opacity-20">
          Текущий тариф
        </div>
      )
    }

    return defaultBtn
    // return defaultBtn
  }, [tenant, subscription, tarifMap, onSubscriptionPayment])

  if (!tarifMap[option] || !tarifMap[defaultMonth]) return <></>
  return (
    <div
      className={clsx(
        "min-w-64 flex flex-col flex-1 gap-6 px-4 md:px-6 py-3 md:py-5 shadow rounded-lg",
        subscription.name === "Pro" ? "border-2 border-primary" : "border border-gray-200"
      )}>
      <div className="flex flex-col gap-[6px] min-h-24">
        <div className="text-2xl text-black font-bold flex gap-2 items-center">
          {subscription.name}
          {subscription.name === "Pro" && (
            <span className="text-primary text-xs font-medium bg-primary bg-opacity-20 rounded-md py-1 px-2">
              Популярно
            </span>
          )}
        </div>

        {subscription.name === "Enterprise" && <div className="text-sm text-black font-medium">Договорная оплата</div>}

        {subscription.name !== "Enterprise" && (
          <div className="text-base text-black font-medium gap-2">
            {option !== defaultMonth && (
              <span className="text-alert line-through pr-1">
                {currencyFormatter[currency]}
                {initialPrice}
              </span>
            )}
            <span>
              {currentPrice} {currencyFormatter[currency]} / {moment.duration(tarifMap[option].days, "days").humanize()}
            </span>
          </div>
        )}

        {tenant && tenant.subscription && tarifMap[option] && tenant.period.id === tarifMap[option].id && (
          <span className="text-gray-350 font-normal text-[13px]">
            Действительно до {moment(tenant.end_at).format("DD.MM.YYYY")}
          </span>
        )}
      </div>

      {connectBtn}

      <div className="flex flex-col gap-1 flex-1">
        <TariffPermissionList {...subscription} />
      </div>
    </div>
  )
}

export function TenantSubscriptionModal() {
  const {t} = useTranslation()
  const [option, setOption] = useState<number>(180)
  const [subs, setSubs] = useState<Subscription[]>([])
  const [currency, _] = useState<CURRENCY>("KZT")

  const options = [
    {label: "месяц", className: "w-full py-1", value: 30},
    {
      value: 180,
      className: "w-full py-1 px-0",
      label: (
        <div className="flex gap-1 items-center">
          <span>6 мес.</span>
          <span className="flex items-center justify-center bg-alert bg-opacity-20 text-alert py-1 px-2 rounded-md h-6">
            -15%
          </span>
        </div>
      )
    },
    {
      value: 365,
      label: (
        <div className="flex gap-1 items-center">
          <span>12 мес.</span>
          <span className="flex items-center justify-center bg-alert bg-opacity-20 text-alert py-1 px-2 rounded-md h-6">
            -20%
          </span>
        </div>
      ),
      className: "w-full py-1 px-0"
    }
  ]

  useEffect(() => {
    const sub = tenantService.subscriptions$.subscribe(setSubs)
    return () => sub.unsubscribe()
  }, [])

  return (
    option && (
      <div className="flex flex-col gap-3 md:gap-6 w-full">
        <h3 className="text-2xl font-bold">{t("profile.subscription.title")}</h3>

        <Segmented<number>
          value={option}
          className="w-max bg-gray-70 p-1 rounded-md"
          onChange={setOption}
          options={options}
        />

        <div className="flex flex-col sm:flex-row overflow-x-scroll gap-3 md:gap-6">
          {subs.map((sub) => (
            <TenantSubscriptionTarif key={sub.id} subscription={sub} option={option} currency={currency} />
          ))}
        </div>
      </div>
    )
  )
}
