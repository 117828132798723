import {Tooltip} from "antd"
import {useContext, useEffect, useMemo, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Link, useNavigate, useParams} from "react-router"
import Icon from "src/components/shared/components/material-icon"
import {FormOption} from "src/components/shared/inputs/form-option"
import tenantService, {InvoiceDetailModel, Subscription, SubscriptionPeriod} from "src/services/tenant.service"
import {priceModifier} from "src/utils/price"
import toDate from "src/utils/date"
import useQueryParams from "src/hooks/useQuertParams"
import {ConfigContext} from "src/app"
import moment from "moment"

export function SubscriptionInvoice() {
    const form = useForm()
    const {id} = useParams()
    const {searchParams} = useQueryParams()
    const navigate = useNavigate()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [invoice, setInvoice] = useState<InvoiceDetailModel>()
    const [subscriptionMap, setSubscriptionMap] = useState<Record<number, Subscription>>(null)
    const [subscriptionPeriodMap, setSubscriptionPeriodMap] = useState<Record<number, SubscriptionPeriod>>(null)
    const {tenant} = useContext(ConfigContext)
    const onBack = () => navigate(searchParams.path)

    const onPeriodChange = form.handleSubmit(async (payload) => {
        const invoice = await tenantService.createInvoice({...payload, success_url: window.location.origin + (searchParams.path ?? "")})
        navigate(`/payment/${invoice.id}?path=${searchParams.path}`)
    })

    const spreadAmount = useMemo(() => {
        if (!invoice) return 0
        if (!subscriptionPeriodMap) return 0
        return (
            +invoice.subscription.price_paid +
            +subscriptionPeriodMap[invoice.subscription.period].price +
            +invoice.subscription.addon_rental_points_price +
            +invoice.subscription.addon_users_price -
            +invoice.subscription.price
        )
    }, [invoice, subscriptionPeriodMap])

    const month = useMemo(() => {
        if (!invoice) return 0
        if (!subscriptionPeriodMap) return 0
        return Math.round(subscriptionPeriodMap[invoice.subscription.period].days / 30)
    }, [invoice, subscriptionPeriodMap])

    const tariffPrice = useMemo(() => {
        if (!invoice) return 0
        if (!subscriptionMap) return 0
        const monthPeriod = subscriptionMap[invoice.subscription.subscription].periods.find(p => p.days === 30)
        if (!monthPeriod) return 0
        return +monthPeriod.price * month
    }, [invoice, subscriptionMap, month])

    const price = useMemo(() => {
        if (!invoice) return 0

        return (
            +tariffPrice +
            +invoice.subscription.addon_rental_points_price +
            +invoice.subscription.addon_users_price
        )
    }, [invoice, tariffPrice])

    const discount = useMemo(() => {
        if (!invoice) return 0
        if (!subscriptionPeriodMap) return 0
        return tariffPrice - subscriptionPeriodMap[invoice.subscription.period].price
    }, [invoice, subscriptionPeriodMap, tariffPrice])

    const discountPercent = useMemo(() => {
        if (!invoice) return 0
        return 100 * discount / tariffPrice
    }, [invoice, discount, tariffPrice])

    const addonUserPrice = useMemo(() => {
        if (!invoice) return 0
        return +invoice.subscription.addon_users_price + +invoice.addon_users * month * 7000
    }, [invoice, month])

    const addonRentalPointPrice = useMemo(() => {
        if (!invoice) return 0
        return +invoice.subscription.addon_rental_points_price + +invoice.addon_rental_points * month * 8000
    }, [invoice, month])

    useEffect(() => {
        tenantService.getInvoice(+id).then((_invoice) => {
            setInvoice(_invoice)
            form.reset({
                subscription: _invoice.subscription.subscription,
                period: _invoice.subscription.period,
            })
        })
    }, [id])

    useEffect(() => {
        const sub = tenantService.subscriptions$.subscribe((subs) => {
            setSubscriptionMap(
                subs.reduce((acc, sub) => {
                    acc[sub.id] = sub
                    return acc
                }, {})
            )
            setSubscriptionPeriodMap(
                subs.reduce((acc, sub) => {
                    sub.periods.forEach((period) => {
                        acc[period.id] = period
                    })
                    return acc
                }, {})
            )
            setLoaded(true)
        })
        return () => sub.unsubscribe()
    }, [])

    return <FormProvider {...form}>
        <div className="bg-gray-70 relative min-h-svh">
            <div className="flex items-center sticky top-0 left-0 right-0 w-full h-20 shadow bg-white p-3 px-8">
                <div onClick={onBack} className="flex items-center gap-3 text-2xl font-semibold cursor-pointer">
                    <Icon icon="chevron_left" className="text-2xl" /> Оплата
                </div>
            </div>
            <div className="flex items-center justify-center p-4 pt-24">
                {tenant && loaded && invoice && (
                    <div className="flex flex-col border border-gray-100 rounded-lg shadow-md bg-white w-full max-w-[720px] p-5 gap-6">
                        <div className="text-2xl font-bold">Сменить тариф на {subscriptionMap[invoice.subscription.subscription].name}</div>
                        <FormOption<any>
                            size="lg"
                            className="gap-3"
                            value={+form.watch("period")}
                            onChange={(val: any) => {
                                form.setValue("period", val)
                                onPeriodChange()
                            }}
                            options={
                                subscriptionMap[invoice.subscription.subscription].periods
                                    .filter((p) => {
                                        if (!tenant.period) return true
                                        if (!tenant.subscription) return true
                                        if (moment(tenant.end_at) < moment()) return true
                                        return subscriptionPeriodMap[tenant.period.id].days < p.days
                                    })
                                    .map((p) => ({...p, month: Math.round(p.days / 30)}))
                                    .map((p) => ({
                                        value: +p.id,
                                        className: "p-2 md:p-4 gap-3",
                                        label: (
                                            <div className="flex flex-col">
                                                <div className="text-lg font-semibold">Период {p.month} мес.</div>
                                                <div className="text-sm font-medium bg-opacity-80">{priceModifier(p.price / p.month)} / мес</div>
                                            </div>
                                        )
                                    }))
                            }
                        />

                        <hr className="text-gray-400" />

                        <div className="flex gap-6 justify-between text-black items-start">
                            <div className="flex flex-col">
                                <div className="text-lg font-semibold">{subscriptionMap[invoice.subscription.subscription].name}</div>
                                <div className="text-gray-370">Ваш план будет автоматически продлен {toDate(invoice.subscription.end_at)}</div>
                            </div>
                            <div className="font-semibold text-[15px]">{priceModifier(tariffPrice)}</div>
                        </div>

                        <hr className="text-gray-400" />

                        <div className="flex flex-col gap-3">
                            {+addonRentalPointPrice > 0 && (
                                <div className="flex justify-between items-center gap-3 text-[15px] font-semibold">
                                    <span>Доп. точки проката</span>
                                    <span>{priceModifier(addonRentalPointPrice)}</span>
                                </div>
                            )}
                            {+addonUserPrice > 0 && (
                                <div className="flex justify-between items-center gap-3 text-[15px] font-semibold">
                                    <span>Доп. пользователи</span>
                                    <span>{priceModifier(addonUserPrice)}</span>
                                </div>
                            )}
                            <div className="flex justify-between items-center gap-3 text-[15px] font-semibold">
                                <span>Сумма без скидки</span>
                                <span>{priceModifier(price)}</span>
                            </div>
                            {discount > 0 && (
                                <div className="flex justify-between items-center gap-3 text-[15px] font-semibold">
                                    <span>Cкидка (-{discountPercent}%)</span>
                                    <span className="text-gray-370">{priceModifier(-discount)}</span>
                                </div>
                            )}
                            {spreadAmount > 0 && (
                                <div className="flex justify-between items-center gap-3 text-[15px] font-semibold">
                                    <div className="flex gap-2 items-center">
                                        <span>Распределение</span>
                                        <Tooltip title="Мы вычитаем уплаченную сумму по текущему тарифу и стоимость неиспользованного периода с начала расчетного цикла">
                                            <Icon icon="info" className="text-gray-370 text-sm cursor-pointer" />
                                        </Tooltip>
                                    </div>
                                    <span className="text-gray-370">-{priceModifier(spreadAmount)}</span>
                                </div>
                            )}
                            <div className="flex justify-between items-center gap-3">
                                <span className="text-[15px] font-semibold">Итого</span>
                                <span className="text-lg font-bold">{priceModifier(invoice.amount)}</span>
                            </div>
                        </div>

                        <Link to={invoice.link} className="btn bg-primary text-white font-medium self-end min-w-48">Оплатить</Link>
                    </div>
                )}
            </div>
        </div>
    </FormProvider>
}
