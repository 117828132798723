import {useContext, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {useLocation, useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import HeaderContent from "../../../../components/header/header-content"
import Icon from "../../../../components/shared/components/material-icon"
import StateDataComponent from "./state-data"
import {useParams} from "react-router"
import {OrderImageModel, OrderMediaType} from "../../../../models/manager/order/order-image.model"
import orderImageService from "../../../../services/order-requests/order-images.service"

export default function StateCheckComponent() {
  const {id} = useParams()
  const {isMobile} = useContext(ConfigContext)
  const navigate = useNavigate()
  const location = useLocation()
  const type = location.state?.type || OrderMediaType.BEFORE

  const [medias, setMedias] = useState<OrderImageModel[]>([])

  const loadImages = () => orderImageService.fetch(id).then((res) => setMedias(res))

  const handleDelete = (fileId: number, mediaId?: number) => {
    orderImageService.deleteFile(+id, mediaId, fileId).then(() => {
      setMedias((prevMedias) =>
        prevMedias
          .map((media) => ({
            ...media,
            resources: media.resources.filter((resource) => resource.id !== fileId)
          }))
          .filter((media) => media.resources.length > 0)
      )
    })
  }

  useEffect(() => {
    loadImages()
  }, [])

  return (
    <div className="-m-4 md:0 md:hidden">
      <HeaderContent>
        <div className="gap-1 py-5 header-content border-0">
          <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />
          <p className="font-bold text-xl text-black">Учет состояния</p>
        </div>
      </HeaderContent>
      <StateDataComponent medias={medias} type={type} onDelete={handleDelete} />
    </div>
  )
}
