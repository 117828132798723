import {ImageField} from "src/components/shared/image-loader/image"
import introImage from "src/assets/images/onboarding-intro.png"
import modalService from "src/components/modal/global/modal.service"
import {useContext} from "react"
import {useTranslation} from "react-i18next"
import {useTour} from "@reactour/tour"
import {ConfigContext} from "../../../app"

export default function OnboardingIntroModal({onClick}: {onClick?: () => void}) {
  const {t} = useTranslation()
  const {tenant} = useContext(ConfigContext)

  const {setIsOpen} = useTour()

  const onStart = () => {
    if (!tenant) return
    if (tenant.demo) setIsOpen(true)
    onClick()
    modalService.closeModal()
  }

  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <ImageField
        src={introImage}
        draggable={false}
        style={{
          objectFit: "cover",
          width: "100%",
          height: 160,
          borderRadius: 8
        }}
      />
      <div className="flex flex-col gap-4 justify-center items-center">
        <h4 className="text-2xl font-semibold ">{t("onboarding.intro.header")}</h4>

        <div className="text-center text-[15px] font-normal" style={{lineHeight: "22px"}}>
          {t("onboarding.intro.message")}
        </div>

        <p className="font-normal text-[15px] text-black text-center">{t("onboarding.intro.sub_message")}</p>
      </div>

      <button className="btn btn-primary btn-color-white font-semibold px-5 text-center" onClick={onStart}>
        {t("onboarding.intro.start")}
      </button>
    </div>
  )
}
