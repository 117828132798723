import * as amplitude from "@amplitude/analytics-browser"
import {sessionReplayPlugin} from "@amplitude/plugin-session-replay-browser"

const AMPLITUDE_PROJECT_ID = "b3a1629d8a8a866b986124fcfc0ccfd2"

export const initAmplitude = (tenant: any) => {
  amplitude.init(AMPLITUDE_PROJECT_ID, {
    defaultTracking: {pageViews: false}
  })
  amplitude.setUserId(tenant)
  const sessionReplay = sessionReplayPlugin({
    sampleRate: 1,
    privacyConfig: {
      blockSelector: [".sensitive"],
      maskSelector: []
    }
  })

  amplitude.add(sessionReplay)
}

export const trackAmplitudeData = (
  eventType: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, unknown>
) => {
  amplitude.track(eventType, eventProperties)
}
