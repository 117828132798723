import {useContext, useEffect} from "react"
import {useTour} from "@reactour/tour"
import {useLocation} from "react-router-dom"
import tourSteps from "../mock/tour-steps"
import {ConfigContext} from "../app"

export default function TourHandler() {
  const {setIsOpen, currentStep} = useTour()
  const location = useLocation()
  const {currentTour, profile} = useContext(ConfigContext)

  useEffect(() => {
    localStorage.setItem("tour-step", currentStep.toString())
  }, [currentStep])

  useEffect(() => {
    if (!currentTour) return
    const onboardingState = JSON.parse(localStorage.getItem(`onboarding_${profile?.id}`))

    if (!onboardingState?.intro) return
    let intervalId: NodeJS.Timeout

    const startTour = () => {
      const stepSelector = tourSteps[currentStep]?.selector
      if (!stepSelector || typeof stepSelector !== "string") return

      intervalId = setInterval(() => {
        const element = document.querySelector(stepSelector)
        if (element) {
          clearInterval(intervalId)
          setIsOpen(true)
        }
      }, 200)
    }

    setIsOpen(false)
    setTimeout(startTour, 300)

    return () => clearInterval(intervalId)
  }, [location.pathname, currentStep, currentTour, setIsOpen])

  return null
}
