import React, {useEffect, useRef, useState} from "react"
import {BehaviorSubject} from "rxjs"
import Icon from "src/components/shared/components/material-icon"

const currentAudio = new BehaviorSubject<string | null>(null)

const AudioPlayer: React.FC<{src: string; ms: number}> = ({src, ms = 1}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(ms)
  const [loading, setLoading] = useState(false)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const handlePlayPause = async () => {
    if (!audioRef.current) {
      const audio = new Audio(src)
      audioRef.current = audio

      audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration)
        setLoading(false)
      })

      audio.addEventListener("timeupdate", () => {
        setProgress(audio.currentTime)
      })

      audio.addEventListener("ended", () => {
        setIsPlaying(false)
        setProgress(0)
        currentAudio.next(null)
      })

      currentAudio.next(src)

      setLoading(true)
    }

    if (isPlaying) {
      pauseAudio()
    } else {
      currentAudio.next(src)

      if (audioRef.current) {
        await audioRef.current.play()
        setIsPlaying(true)
      }
    }

    setIsPlaying(!isPlaying)
  }

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(e.target.value)
    if (audioRef.current) {
      audioRef.current.currentTime = newTime
      setProgress(newTime)
    }
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }

  useEffect(() => {
    const sub = currentAudio.subscribe((currentSrc) => {
      if (currentSrc !== src && isPlaying) pauseAudio()
    })

    return () => sub.unsubscribe()
  }, [isPlaying, src])

  return (
    <div className="flex gap-2 items-center w-full">
      <div className="flex flex-col flex-1">
        <input
          type="range"
          value={progress}
          max={duration}
          onChange={handleSeek}
          className="w-full h-2 bg-gray-200 rounded-lg text-black appearance-none cursor-pointer"
        />
        <div className="flex justify-between text-sm mt-1 text-gray-600">
          <span>{formatTime(progress)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
      <button className="btn btn-card p-2">
        {loading ? (
          <div className="w-5 h-5 border-[4px] border-gray-200 border-solid border-t-transparent rounded-full animate-spin" />
        ) : (
          <Icon onClick={handlePlayPause} icon={isPlaying ? "pause" : "play_arrow"} className="text-xl" />
        )}
      </button>
    </div>
  )
}

export default AudioPlayer
