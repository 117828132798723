import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import {ProtectedRoute} from "./guard"
import CustomSuspense from "src/components/custom-suspense"
import componentLoader from "src/components/custom-suspense/loader"

const ClientProvider = lazy(() => componentLoader(() => import("src/pages/clients/detail/client-context")))
const ClientDetailComponent = lazy(() => componentLoader(() => import("src/pages/clients/detail/client-detail")))
const ClientDocumentListComponent = lazy(() =>
  componentLoader(() => import("src/pages/clients/detail/client-document"))
)
const ClientFileListComponent = lazy(() => componentLoader(() => import("src/pages/clients/detail/client-files")))
const ClientInfoDetailComponent = lazy(() => componentLoader(() => import("src/pages/clients/detail/client-info")))
const ClientRentsDetailComponent = lazy(() => componentLoader(() => import("src/pages/clients/detail/client-rents")))
const ClientBonusListComponent = lazy(() => componentLoader(() => import("src/pages/clients/detail/client-bonuses")))
const ClientEditComponent = lazy(() => componentLoader(() => import("src/pages/clients/edit/clients-edit")))
const ClientComponent = lazy(() => componentLoader(() => import("src/pages/clients/list/clients")))

export const clientRoutes: RouteObject[] = [
  {
    path: "clients",
    element: (
      <ProtectedRoute accessKey="clients">
        <CustomSuspense>
          <ClientComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "clients/add",
    element: (
      <ProtectedRoute accessKey="clients">
        <CustomSuspense>
          <ClientEditComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "clients/:clientId/edit",
    element: (
      <ProtectedRoute accessKey="clients">
        <CustomSuspense>
          <ClientEditComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "clients/:clientId",
    element: (
      <ProtectedRoute accessKey="clients">
        <CustomSuspense>
          <ClientProvider>
            <ClientDetailComponent />
          </ClientProvider>
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="info" relative="route" replace={true} />
      },
      {
        path: "info",
        element: (
          <CustomSuspense>
            <ClientInfoDetailComponent />
          </CustomSuspense>
        )
      },
      {
        path: "files",
        element: (
          <CustomSuspense>
            <ClientFileListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "documents",
        element: (
          <CustomSuspense>
            <ClientDocumentListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "rents",
        element: (
          <CustomSuspense>
            <ClientRentsDetailComponent />
          </CustomSuspense>
        )
      },
      {
        path: "bonuses",
        element: (
          <CustomSuspense>
            <ClientBonusListComponent />
          </CustomSuspense>
        )
      }
    ]
  }
]
