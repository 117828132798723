import React from "react"
import {useTranslation} from "react-i18next"
import {FormProvider} from "react-hook-form"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"
import TransferAgent from "../../../components/shared/components/select/transfer-agent"
import TransferDriver from "../../../components/shared/components/select/transfer-driver"

const RideMetricsModal = ({form}: {form: any}) => {
  const {t} = useTranslation()

  return (
    <FormProvider {...form}>
      <div className=" flex gap-6 flex-col">
        <TransferAgent
          className="col"
          placeholder={t("transfers.form.customer")}
          name="transfer__agent"
          isClearable={true}
          isSearchable={false}
          required
        />

        <TransferDriver
          className="col"
          label={t("transfers.main.driver")}
          placeholder={t("transfers.form.choose_driver")}
          name="driver__user"
          isClearable={true}
          isSearchable={true}
        />

        <FormControlDaterange className="col form-control" name_start="start_at" name_end="end_at" />
      </div>
    </FormProvider>
  )
}

export default RideMetricsModal
