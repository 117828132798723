import {useContext} from "react"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "src/app"
import {useNavigate, useParams} from "react-router"
import {useTranslation} from "react-i18next"

const TransferHeader = ({onSubmit}) => {
  const {isMobile} = useContext(ConfigContext)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {id} = useParams()

  return (
    <div className="flex header-content p-0">
      <div className="flex items-center justify-normal md:justify-between gap-2 w-full px-4 py-3">
        {isMobile && <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />}
        <div className="text-lg font-semibold md:text-2xl">
          {t("transfers.main.transfer")} № {id}
        </div>

        {!isMobile && (
          <button className="btn btn-primary btn-color-white text-base font-medium leading-5 px-4" onClick={onSubmit}>
            {t("common.save")}
          </button>
        )}
      </div>
    </div>
  )
}

export default TransferHeader
