import {Navigate, Outlet, useNavigate} from "react-router"
import {TabConfig, TabPanel, TabPanelConfig} from "src/components/shared/tabs/tabs"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import HeaderContent from "src/components/header/header-content"
import {useCallback, useContext, useMemo} from "react"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import {useLocation} from "react-router-dom"
import hasAccess from "../../utils/hasAccess"
import clsx from "clsx"

function TabRender({route, label, disabled = false}: {route: string; label: string; disabled?: boolean}) {
  const {isMobile} = useContext(ConfigContext)
  const location = useLocation()
  const active = location.pathname.includes(route)
  return isMobile ? (
    <span
      className={clsx(
        "min-h-10 flex items-center justify-center gap-2 rounded-[32px] p-2 px-3 cursor-pointer",
        active ? "bg-black" : "bg-gray-70",
        disabled && "!bg-gray-400"
      )}>
      <span className={clsx("font-medium text-[13px] text-nowrap", active ? "text-white" : "text-black")}>{label}</span>
    </span>
  ) : (
    <span
      className={clsx("tab-nav-link text-nowrap font-medium text-[13px]", {
        active,
        "bg-[#f8f9fd]": disabled
      })}>
      {label}
    </span>
  )
}

export default function ReportComponent() {
  const {t} = useTranslation()
  const {profile, tenantConfigs, isMobile, tenant, tenantConfigs: {accesses}} = useContext(ConfigContext)
  const activeRoute = useCurrentRoute(3)
  const navigate = useNavigate()
  const location = useLocation()

  const hasPerm = useCallback(
    (key: string) => {
      if (!profile) return false
      if (profile.is_superuser) return true
      return profile.perms.some((perm) => perm.includes(key) || perm.includes("metricsmodel"))
    },
    [profile]
  )

  const tabs: TabPanelConfig[] = useMemo(() => {
    if (!tenant) return []
    if (!profile) return []

    return [
      {
        key: "sales",
        hidden: !accesses.reports.sales,
        label: () => (
          <TabRender
            route="sales"
            label={t("metrics.pages.sales")}
            disabled={!hasPerm("metricsfinance")}
          />
        )
      },
      {
        key: "transfers",
        hidden: !accesses.reports.transfers,
        label: () => (
          <TabRender
            route="transfers"
            label={t("metrics.pages.sales")}
          />
        )
      },
      {
        key: "expenses",
        hidden: !accesses.reports.expenses,
        label: () => (
          <TabRender
            route="expenses"
            label={t("metrics.pages.expenses")}
            disabled={!hasPerm("metricsexpenses")}
          />
        )
      },
      {
        key: "clients",
        hidden: !accesses.reports.clients,
        label: () => (
          <TabRender
            route="clients"
            label={t("metrics.pages.clients")}
            disabled={!hasPerm("metricsclient")}
          />
        )
      },
      {
        key: "drivers",
        hidden: !accesses.reports.drivers,
        label: () => (
          <TabRender
            route="drivers"
            label={t("transfers.main.driver")}
          />
        )
      },
      {
        key: "rides",
        hidden: !accesses.reports.rides,
        label: () => (
          <TabRender
            route="rides"
            label={t("transfers.main.rides")}
          />
        )
      },
      {
        key: "cars",
        hidden: !accesses.reports.drivers,
        label: () => (
          <TabRender
            route="cars"
            label={"Car park"}
          />
        )
      },
      {
        key: "managers",
        hidden: !accesses.reports.managers,
        label: () => (
          <TabRender
            route="managers"
            label={t("metrics.pages.managers")}
            disabled={!hasPerm("metricsmanager")}
          />
        )
      },
      {
        key: "services",
        hidden: !accesses.reports.services,
        label: () => (
          <TabRender
            route="services"
            label={t("metrics.pages.services")}
            disabled={!hasPerm("metricsservice")}
          />
        )
      },
      {
        key: "discounts",
        hidden: !accesses.reports.discounts,
        label: () => (
          <TabRender
            route="discount"
            label="Скидки"
            disabled={false}
          />
        )
      },
      {
        key: "sublease",
        hidden: !accesses.reports.sublease,
        label: () => (
          <TabRender
            route="sublease"
            label={t("metrics.pages.sublease")}
            disabled={!hasPerm("metricssublease")}
          />
        )
      },
      {
        key: "inventory",
        hidden: !accesses.reports.inventory,
        label: () => (
          <TabRender
            route="inventory"
            label={t("metrics.pages.inventory")}
            disabled={!hasPerm("metricsinventory")}
          />
        )
      },
      {
        key: "inventorization",
        hidden: !accesses.reports.inventorization,
        disabled: true,
        label: ({disabled}: TabPanelConfig) => (
          <TabRender route="inventorization"
            label={t("metrics.pages.inventorization")}
            disabled={disabled}
          />
        )
      },
      {
        key: "bonuses",
        hidden: !accesses.reports.bonuses,
        label: ({disabled}: TabPanelConfig) => (
          <TabRender
            route="bonuses"
            label={t("metrics.pages.bonuses")}
            disabled={disabled || !hasPerm("metricsbonus")}
          />
        )
      }
    ].filter(({key}) => hasAccess(accesses, `reports.${key}`)).map((tab) => {
      if (tab.key === "bonuses" && !tenant.bonus_enabled) return {...tab, disabled: true}
      return tab
    })
  }, [tenant, profile, accesses])

  const tabsConfig: TabConfig = {
    items: tabs,
    onClick: (config: TabPanelConfig) => !config.disabled && navigate(`/reports/${config.key}`, {relative: "path"}),
    activeTab: activeRoute,
    style: {padding: "0 16px", paddingTop: 8},
    isMobile: isMobile
  }

  if (location.pathname === "/reports" || location.pathname === "/reports/")
    return <Navigate to={`/reports/${Object.keys(tenantConfigs.accesses.reports)[0]}`} replace={false} />

  return (
    <>
      <HeaderContent>
        <div className="flex py-2 mx-4 font-semibold text-[22px] text-black border-b border-gray-100">
          {t("aside.menu.metrics")}
        </div>
        <TabPanel
          tabClassNames="px-0 md:px-6"
          className="py-2 md:py-0 border-b border-gray-200"
          {...tabsConfig}
        />
      </HeaderContent>
      <Outlet />
    </>
  )
}
