import {useContext, useEffect, useState} from "react"
import HeaderContent from "src/components/header/header-content"
import TransferStepper from "./transfer-stepper"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import TransferCity from "src/components/shared/components/select/transfer-city"
import TransferDispatchPoint from "src/components/shared/components/select/transfer-dispatchPoint"
import transportationService from "src/services/transportation.service"
import {useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import {useParams} from "react-router"
import {FormControlTextarea} from "src/components/shared/inputs/form-control-textarea"
import {TransferAddress, TransferRideModel} from "src/models/manager/transfer/transfer"
import {FormControlDatetime} from "src/components/shared/inputs/form-control-datetime"
import {GroupType} from "src/services/auth.service"
import DriverSelection from "../../../details/select/driver-selection"
import TransferHeader from "../../../details/ui/headers/transfer-header"
import {SharedInventoryGroupSelect} from "src/components/shared/components/select/inventory-group"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import {FormControlSwitch} from "src/components/shared/inputs/form-control-switch"
import {Tooltip} from "antd"
import Icon from "src/components/shared/components/material-icon"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import {LabelModel} from "src/models/label"

const TransferForm = () => {
  const form = useForm<any>({defaultValues: {start_extra: {tour: false}}})
  const [initialGroup, setInitialGroup] = useState<InventoryGroupModel | null>(null)
  const {t} = useTranslation()
  const {id, rideId} = useParams()
  const {watch, reset, setValue, handleSubmit} = form
  const {settings, profile} = useContext(ConfigContext)
  const navigate = useNavigate()
  const isAdmin = profile && profile.group_type === GroupType.ADMIN
  const isManager = profile && profile.group_type === GroupType.MANAGER
  const [ride, setRide] = useState(null)
  const values = watch()
  const startCityId = values.start_city
  const endCityId = values.end_city
  const startAddress = values.start_address
  const endAddress = values.end_address
  const car = values.inventory_group

  const {hideMobileMenu, showMobileMenu, isMobile} = useContext(ConfigContext)
  const [startAddressDetails, setStartAddressDetails] = useState<TransferAddress>(undefined)
  const [endAddressDetails, setEndAddressDetails] = useState<TransferAddress>(undefined)

  const onSubmit = handleSubmit(async (payload: any) => {
    if (profile.is_superuser || isManager) {
      payload.initial_inventory_group = payload.inventory_group
    } else {
      delete payload.initial_inventory_group
    }
    if (payload.start_extra && payload.start_extra.tour === false) {
      delete payload.start_extra.tour
    }
    if (rideId) await transportationService.putRides(+id, +rideId, payload)
    if (!rideId) await transportationService.postRides(+id, payload)

    navigate(-1)
  })

  useEffect(() => {
    if (rideId) {
      transportationService.getRide(+id, +rideId).then((data) => {
        setRide(data)
        reset(data)
      })
    }
  }, [rideId, id, reset])

  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  useEffect(() => {
    setStartAddressDetails(null)
    if (startAddress) {
      transportationService.getAddress(+startAddress).then((res) => {
        setStartAddressDetails(res)
      })
    } else {
      setStartAddressDetails(null)
    }
  }, [startAddress])

  useEffect(() => {
    setEndAddressDetails(null)
    if (endAddress) {
      transportationService.getAddress(+endAddress).then((res) => {
        setEndAddressDetails(res)
      })
    } else {
      setEndAddressDetails(null)
    }
  }, [endAddress])

  useEffect(() => {
    if (ride?.initial_inventory_group) {
      inventoryGroupService.get(ride.initial_inventory_group).then((data) => {
        setInitialGroup(data)
      })
    } else {
      setInitialGroup(null)
    }
  }, [ride?.initial_inventory_group])

  useEffect(() => {
    if (startCityId && endCityId) {
      const payload = {
        city_from: startCityId,
        city_to: endCityId,
        address_from: startAddress ? startAddress : undefined,
        address_to: endAddress ? endAddress : undefined,
        inventory_group: car ? car : undefined
      }

      transportationService.getPrices(payload).then((res) => {
        const priceItem = res?.results?.[0]
        if (priceItem?.price) form.setValue("price", +priceItem.price, {shouldValidate: true, shouldDirty: true})
      })
    }
  }, [car, startCityId, endCityId, startAddress, endAddress])

  const renderAddressDetails = (details: TransferAddress, field: keyof TransferRideModel) => {
    const {extra} = details || {extra: {}}
    return (
      <div className="flex gap-3 w-full">
        {Object.keys(extra).map((key) => (
          <FormControl
            key={key}
            name={`${field}.${key}`}
            rootclassname="flex-1"
            className="form-control"
            label={extra[key]}
            placeholder={extra[key]}
            disabled={isAdmin}
          />
        ))}
      </div>
    )
  }

  return (
    <FormProvider {...form}>
      <HeaderContent>
        <TransferHeader onSubmit={onSubmit} />
      </HeaderContent>

      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0 flex flex-col gap-0.5">
        <div className="w-full max-w-[768px] m-auto mb-0 md:mb-3">
          <div className="bg-white rounded-none mb-0 md:mb-3 md:rounded-lg md:shadow flex flex-col gap-5 p-4">
            <h3 className="text-xl font-bold">{t("transfers.action.choose_car_type")}</h3>
            {initialGroup && (
              <div>
                <p className="overflow line-clamp-1 text-[13px] text-gray-400 flex gap-2 mb-2">
                  {t("transfers.car.title")}:
                </p>
                <div className="flex items-center justify-between gap-2">
                  <p className="w-full rounded-none p-4 bg-white md:rounded-lg border border-gray-100">
                    {initialGroup?.name}
                  </p>
                </div>
              </div>
            )}
            <SharedInventoryGroupSelect
              className="col"
              name="inventory_group"
              label={t("transfers.form.car_type")}
              placeholder={t("transfers.action.choose_car_type")}
              listOptions={(params) => inventoryGroupService.list({...params, type: 0})}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>
        <div className="w-full max-w-[768px] m-auto mb-0 md:mb-3">
          <DriverSelection className="mb-0 md:mb-3" />
          <TransferStepper className="rounded-none mb-0 md:mb-3 md:rounded-lg border-none md:border-gray-200" />
          <div className="rounded-none p-4 bg-white mb-0 md:mb-3 md:rounded-lg border-none md:border-gray-200">
            <div className="text-xl font-bold">{t("transfers.car.comment_for_drivers")}</div>
            <FormControlTextarea
              name="driver_comment"
              rootclassname="col"
              className="form-control"
              label={t("transfers.form.comment_optional")}
              placeholder={t("transfers.form.enter_a_comment")}
            />
          </div>
        </div>
        <div className="w-full max-w-[768px] m-auto bg-white rounded-none md:rounded-lg md:shadow flex flex-col gap-5 p-4">
          <div className="flex flex-col gap-3 flex-1">
            <h3 className="text-xl font-bold">{t("transfers.form.general_information")}</h3>
            <div className="flex gap-3">
              {profile && profile.is_superuser && (
                <FormControl
                  name="price"
                  type="number"
                  label={t("transfers.form.transfer_price")}
                  rootclassname="flex-1"
                  className="form-control form-control-number"
                  number_only={+true}
                  number_decimal={+false}
                  suffix={settings && settings.currency}
                  placeholder={t("transfers.form.enter_price")}
                  required
                  disabled={isAdmin}
                  params={{required: t("transfers.form.enter_price")}}
                />
              )}
              <FormControlDatetime
                name="start_at"
                className="form-control"
                rootclassname="flex-1"
                label={t("transfers.form.departure_date_and_time")}
                params={{required: true}}
                disabled={isAdmin}
                datetimeConfig={{
                  minuteStep: 1,
                  showTime: {format: "HH:mm"},
                  allowClear: false
                }}
              />
            </div>
          </div>

          <div className="flex flex-col gap-3 flex-1">
            <div className="text-xl font-bold">{t("transfers.form.transfer_details")}</div>
            <div className="flex flex-col gap-3 md:flex-row">
              <TransferCity
                className="flex-1"
                label={t("transfers.form.from_city")}
                placeholder={t("transfers.form.from_city")}
                name="start_city"
                isClearable={true}
                isSearchable={false}
                onChange={(val: LabelModel) => {
                  if (val?.id !== +startCityId) setValue("start_address", null, {shouldValidate: true, shouldDirty: true})
                }}
                addOption
                required
                disabled={isAdmin}
                params={{required: t("transfers.form.enter_departure_city")}}
              />
              <TransferDispatchPoint
                key={startCityId}
                className="flex-1"
                label={t("transfers.form.from_address")}
                placeholder={t("transfers.form.from_address")}
                name="start_address"
                isClearable={true}
                isSearchable={false}
                addOption
                disabled={!startCityId || isAdmin}
                cityId={startCityId}
              // params={{required: t("transfers.form.enter_departure_address")}}
              // required
              />
            </div>

            {renderAddressDetails(startAddressDetails, "start_extra")}
            {startAddressDetails && (
              <FormControlSwitch
                name="start_extra.tour"
                className="my-2"
                onChange={() => {
                  const checked = watch("start_extra.tour")
                  if (checked) {
                    const startCity = watch("start_city")
                    const startAddress = watch("start_address")
                    const startExtra = watch("start_extra")

                    reset({
                      ...watch(),
                      end_city: startCity,
                      end_address: startAddress,
                      end_extra: startExtra
                    })
                  } else {
                    reset({
                      ...watch(),
                      end_city: null,
                      end_address: null,
                      end_extra: null
                    })
                  }
                }}
                style={{backgroundColor: watch("start_extra.tour") ? "var(--color-primary)" : undefined}}
                label={
                  <span className="overflow line-clamp-1 text-sm text-gray-400 font-medium flex gap-2 items-center">
                    {t("transfers.main.excursion")}
                    <Tooltip title="Включите эту опцию, если маршрут начинается и заканчивается в одной точке. Данные отправления будут автоматически применены для пункта прибытия.">
                      <button className="btn btn-card gap-2 font-semibold text-nowrap p-1">
                        <Icon icon="help" />
                      </button>
                    </Tooltip>
                  </span>
                }
              />
            )}

            <div className="border-b border-gray-100" />

            <div className="flex flex-col gap-3 md:flex-row">
              <TransferCity
                className="flex-1"
                label={t("transfers.form.to_city")}
                placeholder={t("transfers.form.to_city")}
                name="end_city"
                isClearable={true}
                isSearchable={false}
                addOption
                required
                onChange={(val: LabelModel) => {
                  if (val?.id !== +endCityId) setValue("end_address", null, {shouldValidate: true, shouldDirty: true})
                }}
                disabled={isAdmin}
                params={{required: t("transfers.form.enter_arrival_city")}}
              />
              <TransferDispatchPoint
                key={endCityId}
                className="flex-1"
                label={t("transfers.form.to_address")}
                placeholder={t("transfers.form.to_address")}
                name="end_address"
                isClearable={true}
                isSearchable={false}
                disabled={!endCityId || isAdmin}
                cityId={endCityId}
                addOption
              // params={{required: t("transfers.form.enter_arrival_address")}}
              // required
              />
            </div>
            {renderAddressDetails(endAddressDetails, "end_extra")}
          </div>

          <div className="flex flex-col gap-3 flex-1">
            <div className="text-xl font-bold">{t("transfers.form.additionally")}</div>
            <FormControlTextarea
              name="comment"
              rootclassname="col"
              className="form-control"
              label={t("transfers.form.comment_optional")}
              placeholder={t("transfers.form.enter_a_comment")}
              disabled={isAdmin}
            />
          </div>
        </div>
      </div>

      {isMobile && (
        <div className="fixed left-0 bottom-0 right-0 flex items-center w-full shadow-menu bg-white min-h-[72px] z-[100]">
          <div className="flex gap-1 flex-col w-full px-4 py-2 md:px-0 md:py-0">
            <button className="btn btn-primary btn-color-white text-base font-medium leading-5 py-4" onClick={onSubmit}>
              {t("common.save")}
            </button>
          </div>
        </div>
      )}
    </FormProvider>
  )
}

export default TransferForm
