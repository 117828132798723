import React from "react"

const CustomCircularProgress = ({
  percent,
  size = 120,
  strokeWidth = 10,
  gap = 5,
  completedColor = "#1890ff",
  remainingColor = "#f5f5f5",
  gapColor = "#ffffff",
  textSize = 12
}) => {
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius

  const progressLength = (percent / 100) * circumference
  const completedArc = progressLength > 2 * gap ? progressLength - 2 * gap : 0
  const remainingArc = circumference - (completedArc + 2 * gap)

  const segments = [
    {length: gap, offset: 0, color: gapColor},
    {length: completedArc, offset: gap, color: completedColor},
    {length: gap, offset: gap + completedArc, color: gapColor},
    {length: remainingArc, offset: gap + completedArc + gap, color: remainingColor}
  ]

  return (
    <svg width={size} height={size}>
      {segments.map((seg, idx) => (
        <circle
          key={idx}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={seg.color}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={`${seg.length} ${circumference - seg.length}`}
          strokeDashoffset={-seg.offset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeLinecap="butt"
        />
      ))}
      <text
        x="50%"
        y="50%"
        dominantBaseline="central"
        textAnchor="middle"
        style={{
          fontSize: textSize,
          fill: "#a165fd",
          fontWeight: 600
        }}>
        {`${percent}%`}
      </text>
    </svg>
  )
}

export default CustomCircularProgress
