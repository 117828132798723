import {lazy, useContext} from "react"
import {ConfigContext} from "src/app"
import CustomSuspense from "../../../components/custom-suspense"
import componentLoader from "../../../components/custom-suspense/loader"
import {OrderDeliveryListComponent} from "./delivery"

const OrderDetailGroupList = lazy(() => componentLoader(() => import("src/pages/orders/details/inventories/inventory-list/group-list")))
const OrderDetailSetList = lazy(() => componentLoader(() => import("src/pages/orders/details/inventories/inventory-set-list/list")))
const OrderDetailServiceList = lazy(() => componentLoader(() => import("src/pages/orders/details/services/services")))

export function AllWrapper() {
  const {settings} = useContext(ConfigContext)
  const ordering_products = settings?.order_layout_left?.ordering_products || ["inventories", "sets", "services", "deliveries"]
  const orderComponents = {
    inventories: {
      setting: true,
      component: <OrderDetailGroupList />
    },
    sets: {
      setting: settings.feature_set,
      component: <OrderDetailSetList />
    },
    services: {
      setting: settings.order_service,
      component: <OrderDetailServiceList />
    },
    deliveries: {
      setting: settings.order_delivery,
      component: <OrderDeliveryListComponent />
    }
  }

  return (
    <>
      {ordering_products.map((key) => {
        const conf = orderComponents[key]

        if (!conf) return null
        const {setting = true, component} = conf
        if (!setting) return null

        return <CustomSuspense key={key}>{component}</CustomSuspense>
      })}
    </>
  )
}
