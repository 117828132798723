import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"
import {LabelModel} from "src/models/label"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import FormSelectAsyncPagination from "../../inputs/form-select-async.pagination"
import {Direction, TransferAddress} from "../../../../models/manager/transfer/transfer"
import transportationService from "../../../../services/transportation.service"
import {ConfigAddOrEdit} from "../../../../pages/config/config-add-or-edit"
import {FormControl} from "../../inputs/form-control"
import settingService from "src/services/settings.service"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "options" | "modalProps"

interface TransferCityConfig extends Omit<FormSelectConfig<LabelModel>, RemovalKeys> {
  addOption?: boolean
  cityId: number
}

const TransferDispatchPoint: React.FC<TransferCityConfig> = (config) => {
  const {t} = useTranslation()
  const [selectedOption, setSelectedOption] = useState<Direction | null>(null)
  const [refreshKey, setRefreshKey] = useState(0)

  const onCreate = async (payload: Partial<LabelModel>) => {
    try {
      await transportationService.postAddress({...payload, city: config.cityId})
      settingService.loadConstants()
      modalService.closeModal()
      setRefreshKey((prevKey) => prevKey + 1)
    } catch (error) {
      console.error("Error creating address:", error)
    }
  }

  const modalProps: ExtendedModalConfig = {
    text: "Новый пункт приезда",
    size: "lg",
    backdropClick: false,
    component: (
      <ConfigAddOrEdit<LabelModel>
        onSubmit={(data) => onCreate(data).then(() => modalService.closeModal())}
        buttonTitle={t("common.add")}
        title="New direction"
        mode="add"
        buttonsContainer="w-full flex-col"
        cancelBtn={true}>
        <FormSelect<Direction>
          name="type"
          label={"Category of direction"}
          options={[
            {id: 1, value: "airport", name: "Airport"},
            {id: 2, value: "train_station", name: "Train station"},
            {id: 3, value: "hotel", name: "Hotel"}
          ]}
          getOptionValue={(option: Direction | null) => option?.value}
          getOptionLabel={(option: Direction | null) => option?.name}
          onChange={(newValue: Direction | null) => setSelectedOption(newValue)}
          value={selectedOption}
        />

        <FormControl
          name="name"
          type="text"
          label={"Name of the direction"}
          rootclassname="flex-1"
          className="form-control form-control-number"
          required
          params={{required: true}}
        />
        <FormControl
          name="link"
          type="text"
          label={"Link to the map"}
          rootclassname="flex-1"
          className="form-control form-control-number"
        />
      </ConfigAddOrEdit>
    )
  }

  return (
    <FormSelectAsyncPagination<TransferAddress>
      key={refreshKey}
      {...config}
      name={config.name}
      modalProps={config.addOption && modalProps}
      listOptions={(params) =>
        transportationService.listAddress({
          ...params,
          city: config.cityId
        })
      }
      getValue={(id) => transportationService.getAddress(+id)}
      getOptionLabel={(option: TransferAddress) => (option ? String(option.name) : null)}
      getOptionValue={(option: TransferAddress) => (option ? String(option.id) : null)}
    />
  )
}

export default TransferDispatchPoint
