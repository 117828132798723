import clsx from "clsx"
import {CSSProperties} from "react"
import {RegisterOptions} from "react-hook-form"

interface Option<T> {
  label: string | React.ReactNode
  value: T
  className?: string
}

interface FormOptionConfig<T> {
  value: T
  size?: 'md' | 'lg'
  onChange: (val: T) => void
  params?: RegisterOptions<any, any>
  className?: string
  style?: CSSProperties
  options: Option<T>[]
  error?: string
}

const selected = {
  active: "border-primary text-primary",
  default: "border-gray-200 text-black",
  error: "border-alert",
  toggle: {
    active: "rounded-full border-[6px] border-primary",
    default: "rounded-full border border-gray-200"
  }
}

const toggleSize = {
  "md": "w-5 h-5",
  "lg": "w-6 h-6"
}

export function FormOption<T = number>({size = 'md', ...config}: FormOptionConfig<T>) {
  return (
    <div className={clsx("flex flex-row gap-3", config.className)} style={config.style}>
      {config.options.map((option, index) => (
        <div
          key={index}
          onClick={() => config.onChange(option.value)}
          className={clsx(
            "btn btn-card flex-1 gap-2 p-2 md:p-3",
            option.className,
            config.error ? selected.error : option.value === config.value ? selected.active : selected.default
          )}>
          {option.value === config.value ? (
            <span className={clsx(toggleSize[size], selected.toggle.active)}></span>
          ) : (
            <span className={clsx(toggleSize[size], selected.toggle.default)}></span>
          )}
          <span className="flex-1 text-start">{option.label}</span>
        </div>
      ))}
      {config.error && <span className="text-red-500 text-sm">{config.error}</span>}
    </div>
  )
}
