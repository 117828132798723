import {Suspense} from "react"

const SuspenseFallback = () => {
  return (
    <div className="w-full flex items-center justify-center p-1">
      <div className="spinner-border text-gray-300"></div>
    </div>
  )
}

const CustomSuspense = ({children}) => {
  return <Suspense fallback={<SuspenseFallback />}>{children}</Suspense>
}

export default CustomSuspense
