import React, {useContext, useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import CustomSelect from "src/components/shared/inputs/select/select"
import {useTranslation} from "react-i18next"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormControlMask} from "src/components/shared/inputs/form-control-mask"
import {ConfigContext} from "src/app"
import {stageStore} from "../store/stage.store"
import {SharedProfileSelect} from "src/components/shared/components/select/profile"
import {SharedClientAttractionSelect} from "src/components/shared/components/select/client-attraction"
import leadsService from "src/services/crm/leads.service"
import {CRMContext} from "../list/context"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {Stage} from "src/services/crm/funnel.service"
import {firstValueFrom, map} from "rxjs"

const LeadCreateComponent = ({onSuccess}: {onSuccess?: () => void}) => {
  const form = useForm({
    defaultValues: {
      manager: null,
      stage: null,
      price: "",
      client_name: "",
      client_phone: "",
      source: null,
      name: ""
    }
  })
  const {handleSubmit, reset} = form
  const {settings} = useContext(ConfigContext)
  const {t} = useTranslation()
  const {setLeads, selectedFunnelId, funnelForm} = useContext(CRMContext)
  const values = funnelForm.getValues()
  const onSubmit = handleSubmit(async (payload: any) => {
    const data = {
      ...payload,
      funnel: selectedFunnelId
    }
    await leadsService.post(data)
    const response = await leadsService.list(values)
    setLeads(response)
    reset()
    if (onSuccess) onSuccess()
  })

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-5">
        <SharedProfileSelect
          label="Имя менеджера"
          name="manager"
          className="flex-1"
          placeholder="Введите менеджера"
          listParams={{is_staff: true}}
          isClearable={true}
          isSearchable={false}
        />
        <FormSelectAsync<Stage>
          className="col"
          label="Статус заявки"
          name="stage"
          placeholder="Выберите статус"
          listOptions={() => stageStore.stages$}
          getValue={(id: number) =>
            firstValueFrom(stageStore.stages$.pipe(map((list) => list.find((item) => item.id === +id))))
          }
          getOptionLabel={(option: Stage) => option?.name ?? ""}
          getOptionValue={(option: Stage) => String(option?.id)}
          isClearable={false}
          isSearchable={false}
        />

        <FormControl
          rootclassname="col"
          className="form-control"
          label="Сумма заявки"
          placeholder={t("common.input.placeholder.name")}
          name="price"
          params={{required: true}}
        />
        <FormControl
          rootclassname="col"
          className="form-control"
          label="Имя клиента"
          placeholder="Имя клиента"
          name="client_name"
          params={{required: true}}
        />
        <FormControlMask
          label={t("clients.form.phone")}
          placeholder={t("common.input.placeholder.phone_number")}
          rootclassname="flex-1"
          name="client_phone"
          mask={settings && settings.phone_format}
          className="form-control"
          required
        />
        <CustomSelect
          label="Вид бизнеса"
          className="flex-1"
          rootclassname="w-full"
          isClearable={false}
          isSearchable={false}
        />
        <SharedClientAttractionSelect
          className="col"
          label="Источник заявки"
          placeholder="Источник заявки"
          name="source"
          isClearable={true}
          isSearchable={false}
        />
        <FormControl
          rootclassname="col"
          className="form-control"
          label="Дополнительно"
          placeholder="Дополнительно"
          name="name"
          params={{required: true}}
        />
      </div>
      <div className="flex justify-end mt-10">
        <button className="btn btn-primary btn-color-white text-base font-medium leading-5 py-4" onClick={onSubmit}>
          {t("common.save")}
        </button>
      </div>
    </FormProvider>
  )
}

export default LeadCreateComponent
