import {
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  MutableRefObject
} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import modalService from "./components/modal/global/modal.service"
import {TenantSubscriptionModal} from "./pages/subscription"
import {CustomModal} from "./components/modal/global/customModal"
import {SettingConfig} from "./models/setting-config"
import {ManagerConstantMapModel, ManagerConstantModel, RentalPointModel} from "./models/manager/constants"
import {initAmplitude, trackAmplitudeData} from "./services/amplitude"
import subscriptionModalService, {SubscriptionModalConfig} from "./services/subscription.service"
import integrationService from "./services/integration"
import settingService from "./services/settings.service"
import moment from "moment-timezone"
import i18n from "./i18n"
import {IntegrationModel} from "./models/integration"
import tenantService, {TenantModel, TenantType} from "./services/tenant.service"
import {connect, disconnect} from "./services/cent"
import {headerHeight$} from "./components/header"
import authService, {ProfileModel} from "./services/auth.service"
import {DiscountModel} from "./models/manager/discount/discount.model"
import discountService from "./services/discount/discount.service"
import {isProd} from "./utils/isProd"
import * as Sentry from "@sentry/react"
import {DocumentModel} from "./models/manager/document"
import documentTemplateService from "./services/document-template.service"
import tenantsFunctionality, {TenantTypeConfigs} from "./mock/functionality-access"
import useQueryParams from "./hooks/useQuertParams"
import {tourChecks} from "./mock/tour-checklist"
import TourWrapper from "src/components/onboarding"
import {SplashScreen} from "src/splash"
import {Spinner} from "src/components/shared/spinner/spinner"
import loadingService from "src/services/loading.service"
import onboardingService, {OnboardingModel} from "src/services/onboarding"
import {access} from "fs"

const tenantTypes = [TenantType.AUTO, TenantType.SPORT, TenantType.TRANSFER, TenantType.DEFAULT]

interface ClientContextModel {
  logged: boolean
  tenant: TenantModel
  settings: SettingConfig
  constants: ManagerConstantModel
  constantsMap: ManagerConstantMapModel
  templates: DocumentModel[]
  templateMap: Record<number, DocumentModel>
  profile: ProfileModel
  point: RentalPointModel | undefined
  pointId: number | undefined
  discounts: DiscountModel[]
  discountsMap: Record<number, DiscountModel>
  integrationMap: Record<string, IntegrationModel>
  isMobile: boolean
  headerHeight: number
  mobileMenu: boolean
  hideMobileMenu: () => void
  showMobileMenu: () => void
  tenantConfigs: TenantTypeConfigs
  daysLeft: number
  currentTour: string
  setCurrentTour: Dispatch<SetStateAction<string>>
  onboardingData: OnboardingModel
  headerWrapper: MutableRefObject<HTMLDivElement>
  setHeaderWrapper: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>
}

export const ConfigContext = createContext<ClientContextModel>(null)

export function Application({children}) {
  const location = useLocation()
  const {searchParams} = useQueryParams()
  const [headerWrapper, setHeaderWrapper] = useState<MutableRefObject<HTMLDivElement>>(null)
  const [logged, setLogged] = useState<boolean | null>(null)
  const [profile, setProfile] = useState<ProfileModel | null>(undefined)
  const [tenant, setTenant] = useState<TenantModel>(undefined)
  const [settings, setSettings] = useState<SettingConfig>(undefined)
  const [constants, setConstants] = useState<ManagerConstantModel>(undefined)
  const [constantsMap, setConstantsMap] = useState<ManagerConstantMapModel>(undefined)
  const [discounts, setDiscounts] = useState<DiscountModel[]>([])
  const [integrationMap, setIntegrationMap] = useState<Record<string, IntegrationModel>>({})
  const [subs, setSubs] = useState<SubscriptionModalConfig>({show: false, type: null})
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [mobileMenu, setMobileMenu] = useState<boolean>(true)
  const [templates, setTemplates] = useState<DocumentModel[]>([])
  const [templateMap, setTemplateMap] = useState<Record<number, DocumentModel>>({})
  const [tenantConfigs, settenantConfigs] = useState<TenantTypeConfigs>(undefined)
  const [loading, setLoading] = useState<string[]>([])
  const [currentTour, setCurrentTour] = useState<string>("")
  const [onboardingData, setOnboardingData] = useState<OnboardingModel>(null)
  const navigate = useNavigate()

  const splash = useMemo(() => {
    if (logged === false) return false
    if (profile === null) return false
    if (!profile) return true
    if (!tenant) return true
    if (!settings) return true
    if (!constants) return true
    if (!constantsMap) return true
    if (!tenantConfigs) return true
    return false
  }, [logged, profile, tenant, settings, constants, constantsMap, tenantConfigs])

  const point: RentalPointModel | undefined = useMemo(() => {
    if (!profile) return undefined
    if (!constantsMap) return undefined
    return constantsMap.RENTAL_POINTS[profile.point]
  }, [profile, constantsMap])

  const pointId: number | undefined = useMemo(() => {
    if (!profile) return undefined
    return profile.point
  }, [profile])

  const daysLeft: number | undefined = useMemo(() => {
    return tenant && Math.ceil(moment(tenant.end_at).diff(moment(), "days", true))
  }, [tenant])

  const hideMobileMenu = useCallback(() => setMobileMenu(false), [])
  const showMobileMenu = useCallback(() => setMobileMenu(true), [])

  useEffect(() => {
    if (!location) return
    if (!location.pathname) return
    const hostname = window.location.href.replace(/^https?:\/\//, "")
    const companyName = hostname.split(".")[0]
    if (isProd) {
      trackAmplitudeData(`${companyName} | ${hostname.replace(/\/\d+(?=\/|$)/g, "[id]")}`, {
        pathname: location.pathname
      })
    }
    modalService.closeModal()
  }, [location.pathname])

  useEffect(() => {
    const sub = subscriptionModalService.show$.subscribe(setSubs)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = tenantService.tenant$.subscribe(setTenant)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    if (!tenant) {
      settenantConfigs(tenantsFunctionality[TenantType.DEFAULT])
      return
    }
    const code = tenantTypes.includes(tenant.type_code) ? tenant.type_code : TenantType.DEFAULT
    settenantConfigs(tenantsFunctionality[code])
  }, [tenant])

  useEffect(() => {
    const sub = settingService.config$.subscribe(setSettings)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    if (!tenant) return
    if (!tenantConfigs) return
    if (!settings) return
    if (tenantConfigs.accesses.maintenance === true && settings.feature_maintenance === false) {
      settingService.save({feature_maintenance: true})
    }
  }, [tenantConfigs, tenant, settings])

  useEffect(() => {
    const sub = discountService.discounts$.subscribe(setDiscounts)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = settingService.constants$.subscribe((c) => {
      setConstants(c)
      setConstantsMap({
        CLIENT_TICKS: Object.fromEntries(c.CLIENT_TICKS.map((o) => [o.id, o])),
        CLIENT_ATTRACTION_METHOD: Object.fromEntries(c.CLIENT_ATTRACTION_METHOD.map((o) => [o.id, o])),
        CLIENT_TYPE: Object.fromEntries(c.CLIENT_TYPE.map((o) => [o.id, o])),
        SERVICE_TYPE: Object.fromEntries(c.SERVICE_TYPE.map((o) => [o.id, o])),
        INVENTORY_GROUP_TYPE: Object.fromEntries(c.INVENTORY_GROUP_TYPE.map((o) => [o.id, o])),
        INVENTORY_CATEGORIES: Object.fromEntries(c.INVENTORY_CATEGORIES.map((o) => [o.id, o])),
        INVENTORY_STATUS: Object.fromEntries(c.INVENTORY_STATUS.map((o) => [o.id, o])),
        INVENTORY_STATE_STATUS: Object.fromEntries(c.INVENTORY_STATE_STATUS.map((o) => [o.id, o])),
        TARIF_TIME_PERIODS: Object.fromEntries(c.TARIF_TIME_PERIODS.map((o) => [o.id, o])),
        ORDER_REQUEST_STATUS: Object.fromEntries(c.ORDER_REQUEST_STATUS.map((o) => [o.id, o])),
        ORDER_PAYMENT_STATUS: Object.fromEntries(c.ORDER_PAYMENT_STATUS.map((o) => [o.id, o])),
        PAYMENT_TYPES: Object.fromEntries(c.PAYMENT_TYPES.map((o) => [o.id, o])),
        RENTAL_POINTS: Object.fromEntries(c.RENTAL_POINTS.map((o) => [o.id, o])),
        PASSPORT_ISSUERS: Object.fromEntries(c.PASSPORT_ISSUERS.map((o) => [o.id, o])),
        DISCOUNT_TYPE: Object.fromEntries(c.DISCOUNT_TYPE.map((o) => [o.id, o])),
        DISCOUNT_CALCULATION_TYPE: Object.fromEntries(c.DISCOUNT_CALCULATION_TYPE.map((o) => [o.id, o])),
        EXPENSE_CATEGORY: Object.fromEntries(c.EXPENSE_CATEGORY.map((o) => [o.id, o])),
        EXPENSE_COUNTERPARTY: Object.fromEntries(c.EXPENSE_COUNTERPARTY.map((o) => [o.id, o]))
      })
    })
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = integrationService.integrations$.subscribe((integrations) => {
      setIntegrationMap(integrations.reduce((p, c) => ({...p, [c.code]: c}), {}))
    })
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = documentTemplateService.documents$.subscribe((templates) => {
      setTemplates(templates)
      setTemplateMap(templates.reduce((p, c) => ({...p, [c.id]: c}), {}))
    })
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const systemLang = localStorage.getItem("language")
    if (systemLang) {
      moment.locale(systemLang)
      i18n.changeLanguage(systemLang)
      return
    }
    if (settings && i18n.language !== settings.language) {
      moment.locale(settings.language)
      i18n.changeLanguage(settings.language)
    }
  }, [settings])

  useEffect(() => {
    if (tenant) initAmplitude(tenant.name)
  }, [tenant])

  useEffect(() => {
    connect()
    return () => disconnect()
  }, [])

  useEffect(() => {
    const sub = headerHeight$.subscribe(setHeaderHeight)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = authService.authorized$.subscribe(setLogged)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = authService.profile$.subscribe((profile) => {
      setProfile(profile)
      if (profile) {
        Sentry.setUser({
          fullName: `${profile.first_name} ${profile.last_name}`,
          email: profile.email
        })
      }
    })
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    if ("state" in searchParams) localStorage.setItem("wazzup_state", searchParams["state"])
    if (tenant && profile && localStorage.getItem("wazzup_state")) {
      integrationService.connect("wazzup", {state: localStorage.getItem("wazzup_state")}).then(() => {
        localStorage.removeItem("wazzup_state")
        navigate("/wazzup")
      })
    }
  }, [tenant, profile, searchParams])

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const loadingSub = loadingService.loading$.subscribe(setLoading)
    return () => loadingSub.unsubscribe()
  }, [])

  useEffect(() => {
    if (!tenant) return
    if (tenant.demo && location.pathname.startsWith("/orders")) setCurrentTour("rental_control")
  }, [tenant, location])

  useEffect(() => {
    if (!localStorage.getItem("tour-checks")) {
      localStorage.setItem("tour-checks", JSON.stringify(tourChecks))
    }
  }, [])

  useEffect(() => {
    const sub = onboardingService.onboarding$.subscribe(setOnboardingData)
    return () => sub.unsubscribe()
  }, [])

  if (splash) return <SplashScreen />

  return (
    <ConfigContext.Provider
      value={{
        logged,
        tenant,
        settings,
        constants,
        constantsMap,
        templates,
        templateMap,
        profile,
        point,
        pointId,
        integrationMap,
        isMobile,
        mobileMenu,
        hideMobileMenu,
        showMobileMenu,
        headerHeight,
        discounts,
        discountsMap: discounts.reduce((p, c) => ({...p, [c.id]: c}), {}),
        tenantConfigs,
        daysLeft,
        currentTour,
        setCurrentTour,
        onboardingData,
        headerWrapper,
        setHeaderWrapper
      }}>
      {tenantConfigs && <TourWrapper>{children}</TourWrapper>}
      <CustomModal
        size="xl"
        backdropClick={true}
        show={subs.show}
        onHide={() => setSubs({show: false, type: null})}
        removable={daysLeft > 0}>
        <TenantSubscriptionModal />
      </CustomModal>
      {loading.length > 0 && <Spinner className="fixed w-screen h-screen z-[100000] top-0 left-0" />}
    </ConfigContext.Provider>
  )
}
