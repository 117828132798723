import {ReactNode, useContext} from "react"
import {OrderContext} from "../context/order-context"
import Icon from "src/components/shared/components/material-icon"
import modalService from "src/components/modal/global/modal.service"
import {OrderRequestDeliveryAddComponent} from "./add.modal"
import {useTranslation} from "react-i18next"
import {
  DeliveryStatus,
  DeliveryType,
  OrderRequestDeliveryModel
} from "src/models/manager/order/order-request-delivery.model"
import orderInventoryService from "src/services/order-requests/order-inventory.service"
import toDateTime from "src/utils/date-time"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import React from "react"
import OrderInventoriesHeader from "../common/content-header"
import {ConfigContext} from "src/app"

const urlRegex = /\b(?:https?:\/\/|www\.)\S+\b/g

const deliveryStatusLabel: Record<DeliveryStatus, ReactNode> = {
  [DeliveryStatus.DONE]: <div className="text-accept font-medium">Доставлен</div>,
  [DeliveryStatus.IN_PROGRESS]: <div className="text-warn font-medium">В пути ...</div>,
  [DeliveryStatus.PENDING]: <div className="text-gray-400 font-medium">В ожидании ...</div>
}

function DeliveryLink({
  delivery,
  place = "issue_place"
}: {
  delivery: OrderRequestDeliveryModel
  place: "issue_place" | "delivery_place"
}) {
  if (!delivery[place]) return <></>

  const name = delivery[place].name
  const lng = delivery[place].longitude
  const lat = delivery[place].latitude
  const links = name.match(urlRegex)
  const link = +lat === 0 && +lng === 0 ? `https://2gis.kz/search/${name}` : `https://2gis.kz/geo/${lng},${lat}`

  if (links && links.length > 0) {
    return (
      <div className="flex gap-2">
        {links.map((href) => (
          <a href={href} className="text-primary font-medium flex gap-1 items-center" target="_blank" rel="noreferrer">
            <Icon icon="open_in_new" />
            <span className="underline">{href}</span>
          </a>
        ))}
      </div>
    )
  }
  return (
    <a href={link} className="text-primary font-medium flex gap-1 items-center" target="_blank" rel="noreferrer">
      <Icon icon="open_in_new" />
      <span className="underline">{name}</span>
    </a>
  )
}

export function OrderDeliveryListComponent() {
  const {t} = useTranslation()
  const {settings} = useContext(ConfigContext)
  const {order, deliveries, getDeliveries} = useContext(OrderContext)

  const onAdd = (type: DeliveryType = DeliveryType.DELIVERY) => {
    modalService.open({
      component: (
        <OrderRequestDeliveryAddComponent
          defaultValues={{type}}
          onSubmit={(payload) =>
            orderInventoryService
              .createDelivery(order.id, payload)
              .then(() => modalService.closeModal())
              .then(() => getDeliveries())
          }
        />
      )
    })
  }

  const onDelete = (deliveryId: number) => {
    const onConfirm = () =>
      orderInventoryService
        .deleteDelivery(order.id, deliveryId)
        .then(() => modalService.closeModal())
        .then(() => getDeliveries())

    modalService.open({
      component: (
        <ConfirmModal
          message={t("common.confirm.delete")}
          confirm_text={t("common.delete")}
          onConfirm={onConfirm}
          delete={true}
        />
      )
    })
  }

  if (!settings.order_delivery) return <></>

  return (
    <>
      <OrderInventoriesHeader header={t("aside.menu.delivery")} onAdd={onAdd} />
      {deliveries.length > 0 && (
        <div className="p-2 md:p-3">
          {deliveries.map((delivery) => (
            <div key={delivery.id} className="relative card-wrapper">
              <button
                className="absolute top-2 right-2 btn btn-red-8 btn-color-red p-1 md:p-2"
                onClick={() => onDelete(delivery.id)}>
                <Icon icon="delete" />
              </button>
              <div className="flex gap-2 items-center">
                <span className="text-gray-400 text-nowrap">Статус:</span>
                {deliveryStatusLabel[delivery.status]}
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-gray-400 text-nowrap">{t("orders.delivery.issue_address")}:</span>
                <DeliveryLink delivery={delivery} place="issue_place" />
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-gray-400 text-nowrap">{t("orders.delivery.delivery_address")}:</span>
                <DeliveryLink delivery={delivery} place="delivery_place" />
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-gray-400 text-nowrap">{t("orders.delivery.delivery_date")}:</span>
                <span className="font-medium">
                  {toDateTime(delivery.delivered_at ? delivery.delivered_at : delivery.pickup_date)}
                </span>
              </div>
              {delivery.worker_detail && (
                <div className="flex gap-2 items-center">
                  <span className="text-gray-400 text-nowrap">Исполнитель:</span>
                  <span className="font-medium">
                    {delivery.worker_detail.first_name} {delivery.worker_detail.last_name}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  )
}
