import React, {CSSProperties, useContext, useEffect, useState} from "react"
import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable"
import {CSS} from "@dnd-kit/utilities"
import {hexToRgba} from "src/utils/color/color-twenty-percent"
import Icon from "src/components/shared/components/material-icon"
import {Dropdown, MenuProps} from "antd"
import {useTranslation} from "react-i18next"
import {CRMContext} from "./context"
import {useDebounce} from "src/hooks/useDebounce"
import modalService from "../../../components/modal/global/modal.service"
import CreateStageModal from "../detail/modal/create-stage-modal"
import {Stage} from "src/services/crm/funnel.service"
import {stageStore} from "../store/stage.store"
import {LeadList} from "../../../services/crm/leads.service"
import CRMCard from "./lead"
import {useDndContext} from "@dnd-kit/core"

interface Props {
  column: Stage
  leads: LeadList[]
  isOverlay?: boolean
}

const CRMColumn: React.FC<Props> = ({column, leads, isOverlay}) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: String(column.id),
    data: {type: "column"}
  })

  const {t} = useTranslation()
  const {selectedFunnelId} = useContext(CRMContext)
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(column.name)
  const debouncedValue = useDebounce(inputValue, 2000)

  const {active} = useDndContext()
  const isDragging = active?.id === String(column.id)
  const shouldDim = isDragging && !isOverlay

  useEffect(() => {
    if (debouncedValue !== column.name) {
      stageStore.updateStage(column.id, {name: debouncedValue})
    }
  }, [debouncedValue])

  const handleEditClick = () => setIsEditing(true)
  const handleDelete = () => {
    stageStore.deleteStage(column.id)
  }

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: shouldDim ? 0.3 : 1,
    pointerEvents: shouldDim ? "none" : "auto",
    borderBottom: `4px solid ${column.color}`,
    background: hexToRgba(column.color, 0.2),
    userSelect: "none",
    textAlign: "center",
    width: 320
  }

  const items: MenuProps["items"] = [
    {
      key: "edit",
      onClick: handleEditClick,
      label: (
        <button className="flex items-center gap-3">
          <Icon icon="edit" />
          <span className="font-normal text-sm text-black md:block">Редактировать название</span>
        </button>
      )
    },
    {
      type: "divider"
    },
    {
      key: "color-edit",
      onClick: () => {
        modalService.open({
          size: "sm",
          component: <CreateStageModal funnelId={selectedFunnelId} initialData={column} />
        })
      },
      label: (
        <button className="flex items-center gap-3">
          <Icon icon="colorize" />
          <span className="font-normal text-sm text-black md:block">Редактировать цвет</span>
        </button>
      )
    },
    {
      type: "divider"
    },
    {
      key: "delete",
      onClick: handleDelete,
      label: (
        <button className="flex items-center gap-3">
          <Icon icon="delete" className="text-alert" />
          <span className="font-normal text-sm text-alert md:block">{t("common.delete")}</span>
        </button>
      )
    }
  ]

  const placeholderId = `placeholder-${column.id}`

  return (
    <div id={String(column.id)} className="flex flex-col" ref={setNodeRef}>
      <div className="flex justify-between items-center p-2" style={style}>
        <div className="flex items-center gap-2" {...attributes} {...listeners}>
          <Icon icon="drag_indicator" className="text-gray-400 cursor-move" />
          {isEditing ? (
            <input
              className="bg-transparent outline-none font-bold text-lg caret-primary py-1"
              style={
                {
                  boxShadow: "0 2px 0 0 var(--tw-shadow-color)",
                  "--tw-shadow-color": "#a165fd"
                } as React.CSSProperties
              }
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={() => setIsEditing(false)}
              autoFocus
            />
          ) : (
            <span onClick={handleEditClick} className="font-bold text-lg cursor-text">
              {inputValue}
            </span>
          )}
        </div>
        <Dropdown menu={{items}} placement="bottomLeft" trigger={["click"]} arrow={false}>
          <Icon icon="more_horiz" className="cursor-pointer" />
        </Dropdown>
      </div>

      <div className="flex flex-col gap-2 mt-2">
        <SortableContext
          items={[...leads.map((lead) => String(lead.id)), placeholderId]}
          strategy={verticalListSortingStrategy}>
          {leads.map((lead) => (
            <CRMCard key={lead.id} lead={lead} />
          ))}

          {leads.length === 0 && (
            <div id={placeholderId} className="h-screen rounded-lg  flex items-center justify-center "></div>
          )}
        </SortableContext>
      </div>
    </div>
  )
}

export default CRMColumn
