import {FormProvider, useForm} from "react-hook-form"
import {FormControlMask} from "src/components/shared/inputs/form-control-mask"

export function SubscriptionPhoneForm({
  phone,
  onConfirm
}: {
  phone: string
  onConfirm: ({phone}: {phone: string}) => void
}) {
  const form = useForm<{phone: string}>({defaultValues: {phone}})
  const {formState, handleSubmit} = form
  const onSubmit = handleSubmit(onConfirm)

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-2">
        <FormControlMask
          label="Телефон"
          rootclassname="col"
          name="phone"
          mask="+9-999-999-99-99"
          className="form-control"
          params={{required: true}}
          required
        />
        <div className="flex items-center justify-end gap-2">
          <button
            className="btn btn-primary btn-color-white font-medium gap-2 flex-1 md:flex-none"
            disabled={!formState.isValid}
            onClick={onSubmit}>
            Сохранить номер телефона
          </button>
        </div>
      </div>
    </FormProvider>
  )
}
