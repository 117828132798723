import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import ReportClientBonusWrapperComponent from "src/pages/reports/bonuses"
import ReportPayback from "src/pages/reports/inventory"
import InventoryPaybackWrapper from "src/pages/reports/inventory/payback"
import ReportComponent from "src/pages/reports/reports"
import ReportSellsLayout from "src/pages/reports/sales"
import ReportManagerLayout from "src/pages/reports/managers"
import {ProtectedRoute} from "./guard"
import ReportRidesComponent from "../pages/reports/rides"
import ReportCarParkComponent from "../pages/reports/cars"
import componentLoader from "src/components/custom-suspense/loader"

const ReportClientsComponent = lazy(() => componentLoader(() => import("src/pages/reports/client")))
const InventoryPaybackComponent = lazy(() => componentLoader(() => import("src/pages/reports/inventory/payback/payback-inventories")))
const ReportSalesComponent = lazy(() => componentLoader(() => import("src/pages/reports/sales/finance")))
const ReportPenaltiesList = lazy(() => componentLoader(() => import("src/pages/reports/sales/penalties")))
const ReportUserSubleaseEarnings = lazy(() => componentLoader(() => import("src/pages/reports/sublease")))
const ReportInventorization = lazy(() => componentLoader(() => import("src/pages/reports/inventorization")))
const ReportUserServiceEarnings = lazy(() => componentLoader(() => import("src/pages/reports/services")))
const ReportExpencesComponent = lazy(() => componentLoader(() => import("src/pages/reports/expenses")))
const ReportDiscountListComponent = lazy(() => componentLoader(() => import("src/pages/reports/discount")))
const ReportUserSalaryComponent = lazy(() => componentLoader(() => import("src/pages/reports/managers/salary")))
const ReportUserDriverComponent = lazy(() => componentLoader(() => import("src/pages/reports/managers/driver")))
const InventoryGroupPaybackComponent = lazy(() => componentLoader(() => import("src/pages/reports/inventory/payback/payback-groups")))
const InventoryEfficencyComponent = lazy(() => componentLoader(() => import("src/pages/reports/inventory/efficency")))
const ReportClientBonusHistoryComponent = lazy(() => componentLoader(() => import("src/pages/reports/bonuses/history")))
const ReportClientBonusBalanceComponent = lazy(() => componentLoader(() => import("src/pages/reports/bonuses/balance")))
const ReportWorkshiftList = lazy(() => componentLoader(() => import("src/pages/reports/managers/workshift")))
const ReportTransportationComponent = lazy(() => componentLoader(() => import("src/pages/reports/sales/transfers")))

export const reportRoutes: RouteObject[] = [
  {
    path: "reports",
    element: <ProtectedRoute accessKey="reports"><ReportComponent /></ProtectedRoute>,
    children: [
      {path: "", element: <></>},
      {
        path: "inventory",
        element: <ProtectedRoute accessKey="reports.inventory"><ReportPayback /></ProtectedRoute>,
        children: [
          {
            path: "",
            element: <Navigate to="efficency" replace={false} />
          },
          {
            path: "efficency",
            element: (
              <CustomSuspense>
                <InventoryEfficencyComponent />
              </CustomSuspense>
            )
          },
          {
            path: "payback",
            element: <InventoryPaybackWrapper />,
            children: [
              {
                path: "",
                element: <Navigate to="group" replace={false} />
              },
              {
                path: "group",
                element: (
                  <CustomSuspense>
                    <InventoryGroupPaybackComponent />
                  </CustomSuspense>
                )
              },
              {
                path: "inventory",
                element: (
                  <CustomSuspense>
                    <InventoryPaybackComponent />
                  </CustomSuspense>
                )
              }
            ]
          }
        ]
      },
      {
        path: "sales",
        element: <ProtectedRoute accessKey="reports.sales"><ReportSellsLayout /></ProtectedRoute>,
        children: [
          {
            path: "",
            element: <Navigate to="main" replace={false} />
          },
          {
            path: "main",
            element: (
              <CustomSuspense>
                <ReportSalesComponent />
              </CustomSuspense>
            )
          },
          {
            path: "penalties",
            element: (
              <CustomSuspense>
                <ReportPenaltiesList />
              </CustomSuspense>
            )
          }
        ]
      },
      {
        path: "transfers",
        element: (
          <CustomSuspense>
            <ReportTransportationComponent />
          </CustomSuspense>
        )
      },
      {
        path: "rides",
        element: (
          <CustomSuspense>
            <ReportRidesComponent />
          </CustomSuspense>
        )
      },
      {
        path: "drivers",
        element: (
          <CustomSuspense>
            <ReportUserDriverComponent />
          </CustomSuspense>
        )
      },
      {
        path: "cars",
        element: (
          <CustomSuspense>
            <ReportCarParkComponent />
          </CustomSuspense>
        )
      },
      {
        path: "expenses",
        element: (
          <ProtectedRoute accessKey="reports.expenses">
            <CustomSuspense>
              <ReportExpencesComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "managers",
        element: <ProtectedRoute accessKey="reports.managers"><ReportManagerLayout /></ProtectedRoute>,
        children: [
          {
            path: "",
            element: <Navigate to="salary" replace={false} />
          },
          {
            path: "salary",
            element: (
              <CustomSuspense>
                <ReportUserSalaryComponent />
              </CustomSuspense>
            )
          },
          {
            path: "workshifts",
            element: (
              <CustomSuspense>
                <ReportWorkshiftList />
              </CustomSuspense>
            )
          }
        ]
      },
      {
        path: "bonuses",
        element: <ProtectedRoute accessKey="reports.bonuses"><ReportClientBonusWrapperComponent /></ProtectedRoute>,
        children: [
          {
            path: "",
            element: <Navigate to="balance" replace={false} />
          },
          {
            path: "balance",
            element: (
              <CustomSuspense>
                <ReportClientBonusBalanceComponent />
              </CustomSuspense>
            )
          },
          {
            path: "history",
            element: (
              <CustomSuspense>
                <ReportClientBonusHistoryComponent />
              </CustomSuspense>
            )
          }
        ]
      },
      {
        path: "services",
        element: (
          <ProtectedRoute accessKey="reports.services">
            <CustomSuspense>
              <ReportUserServiceEarnings />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "sublease",
        element: (
          <ProtectedRoute accessKey="reports.sublease">
            <CustomSuspense>
              <ReportUserSubleaseEarnings />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "inventorization",
        element: (
          <ProtectedRoute accessKey="reports.inventorization">
            <CustomSuspense>
              <ReportInventorization />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "clients",
        element: (
          <ProtectedRoute accessKey="reports.clients">
            <CustomSuspense>
              <ReportClientsComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "discounts",
        element: (
          <CustomSuspense>
            <ReportDiscountListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "*",
        element: <ProtectedRoute accessKey="reports"><Navigate to="/reports" replace={false} /></ProtectedRoute>
      }
    ]
  }
]
