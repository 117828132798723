import {useContext} from "react"
import {TourProvider} from "@reactour/tour"
import {ConfigContext} from "../../app"
import {ConfirmModal} from "../modal/global/confirmModal"
import modalService from "../modal/global/modal.service"
import onboardingService from "src/services/onboarding"
import tenantService from "../../services/tenant.service"
import tourSteps from "../../mock/tour-steps"

const TourWrapper = ({children}) => {
  const {currentTour, setCurrentTour} = useContext(ConfigContext)

  const opositeSide = {
    top: "bottom",
    bottom: "top",
    right: "left",
    left: "right"
  }

  function doArrow(position: string, verticalAlign: string, horizontalAlign: string | number, helperRect: DOMRect) {
    if (!position || position === "custom") return {}

    const width = 16
    const height = 12
    const color = "rgba(161, 101, 253, 1)"
    const isVertical = position === "top" || position === "bottom"

    return {
      [`--rtp-arrow-${isVertical ? horizontalAlign : verticalAlign}`]: `${isVertical ? helperRect.width / 2 - width / 2 : helperRect.height / 2 - height / 2}px`,
      [`--rtp-arrow-${opositeSide[position]}`]: -height + 2 + "px",
      [`--rtp-arrow-border-${isVertical ? "left" : "top"}`]: `${width / 2}px solid transparent`,
      [`--rtp-arrow-border-${isVertical ? "right" : "bottom"}`]: `${width / 2}px solid transparent`,
      [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`
    }
  }

  return (
    <TourProvider
      onClickClose={(clickProps) => {
        clickProps.setIsOpen(false)
        modalService.open({
          size: "xs",
          onHide: () => clickProps.setIsOpen(true),
          component: (
            <ConfirmModal
              delete={true}
              message="Вы точно хотите пропустить обучение и удалить демо-данные?"
              messageClassNames="font-normal text-[15px] text-black"
              confirm_text="Пропустить и удалить демо-данные"
              onConfirm={() => {
                onboardingService
                  .patch({
                    block_name: currentTour,
                    step_name: clickProps.currentStep,
                    passed: true
                  })
                  .then(() => {
                    clickProps.setIsOpen(false)
                    setCurrentTour("")
                  })

                tenantService.moveToProduction()
              }}
              onCancel={() => clickProps.setIsOpen(true)}
            />
          )
        })
      }}
      steps={tourSteps}
      onClickMask={() => { }}
      position="right"
      showBadge={false}
      showNavigation={false}
      disableFocusLock={true}
      highlightedMaskClassName="overflow-hidden"
      scrollSmooth={true}
      disableKeyboardNavigation={true}
      ContentComponent={(props) => {
        if (!props) return <></>
        if (props.transition) return <></>

        const currentStepData = props.steps[props.currentStep]
        if (!currentStepData) return null
        const content = typeof currentStepData.content === "function" ? currentStepData.content(props) : currentStepData.content
        return <>{content}</>
      }}
      styles={{
        popover: (base, state) => {
          const {helperRect, targetRect, position, verticalAlign, horizontalAlign} = state
          let transformX = 0
          let transformY = 0

          if (position === "right") {
            transformX = targetRect.right + 24
            transformY = targetRect.top - helperRect.height / 2 + targetRect.height / 2
          }
          if (position === "left") {
            transformX = targetRect.left - helperRect.width - 24
            transformY = targetRect.top - helperRect.height / 2 + targetRect.height / 2
          }
          if (position === "top") {
            transformX = targetRect.right - targetRect.width / 2 - helperRect.width / 2
            transformY = targetRect.top - helperRect.height - 24
          }
          if (position === "bottom") {
            transformX = targetRect.right - targetRect.width / 2 - helperRect.width / 2
            transformY = targetRect.bottom + 24
          }

          transformX = Math.min(Math.max(8, transformX), targetRect.windowWidth - 8 - helperRect.width)
          transformY = Math.min(Math.max(8, transformY), targetRect.windowHeight - 8 - helperRect.height)

          const currentStepData = tourSteps[localStorage.getItem("tour-step")]
          const contentIsNull =
            currentStepData && typeof currentStepData.content === "function" && currentStepData.content() === null

          return {
            ...base,
            padding: 0,
            opacity: helperRect.top === 0 && helperRect.left === 0 ? 0 : 1,
            borderRadius: 10,
            transition: "none",
            animation: "none",
            transform: `translateX(${transformX}px) translateY(${transformY}px)`,
            ...(!contentIsNull && doArrow(position, verticalAlign, horizontalAlign, helperRect))
          }
        },
        close: (base) => {
          const currentStepData = tourSteps[localStorage.getItem("tour-step")]
          const contentIsNull =
            currentStepData && typeof currentStepData.content === "function" && currentStepData.content() === null
          return {
            ...base,
            display: contentIsNull ? "none" : "block",
            color: "rgba(255, 255, 255, 0.5)",
            top: 8,
            right: 8,
            height: 8,
            width: 8
          }
        },
        maskArea: (base) => ({...base, rx: 6}),
        maskWrapper: (base) => ({...base, color: "var(--color-black)"})
      }}>
      {children}
    </TourProvider>
  )
}

export default TourWrapper
