import axios from "axios"
import {useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useNavigate} from "react-router"
import modalService from "src/components/modal/global/modal.service"
import {FormControl} from "src/components/shared/inputs/form-control"

interface TelegramSettings {
  payment: boolean
  penalty: boolean
  maintenance: boolean

  stat: boolean
  stat_period: string
}

interface TelegramModel {
  username: string
  setting: TelegramSettings
}

export default function ConfigTelegramComponent() {
  const navigate = useNavigate()
  const [telegram, setTelegram] = useState<TelegramModel>()
  const submitForm = useForm<{code: string}>()

  const getTelegram = async () => {
    return axios
      .get<TelegramModel>("/v1/crm/communication/telegram/", {params: {skip_error_handling: true}})
      .then(({data}) => setTelegram(data))
      .catch(createProfile)
  }

  const onConfirm = submitForm.handleSubmit(async (payload: any) => {
    return axios
      .post("/v1/crm/communication/telegram/", payload)
      .then(() => getTelegram())
      .then(() => modalService.closeModal())
  })

  const onDisconnect = async () => {
    if (!telegram) return
    return axios.post("/v1/crm/communication/telegram/disconnect/").then(() => setTelegram(undefined))
  }

  const createProfile = async () => {
    modalService.open({
      onHide: () => navigate("/profile"),
      component: (
        <FormProvider {...submitForm}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="font-bold text-xl">Подключение Telegram уведомлении</div>
              <div className="font-medium text-[14px]">
                Для подключения бота перейдите по{" "}
                <a className="text-blue-700" href="https://t.me/yume_cloud_bot" rel="noreferrer" target="_blank">
                  ссылке
                </a>{" "}
                и введите код
              </div>
            </div>
            <FormControl
              name="code"
              placeholder="Код"
              className="form-control"
              rootclassname="flex-1"
              params={{required: true}}
            />
            <div
              onClick={onConfirm}
              className="cursor-pointer p-3 rounded-lg text-center font-medium bg-[#24A1DE] text-[#fff]">
              Подключить
            </div>
          </div>
        </FormProvider>
      )
    })
  }

  useEffect(() => {
    getTelegram()
    return () => setTelegram(undefined)
  }, [])

  if (!telegram) return

  return (
    <div className="card-wrapper w-full">
      <div className="text-lg font-medium mb-4">
        Ваш профиль привязан с телеграм аккаунтом -{" "}
        <a className="text-blue-700" href={`https://t.me/${telegram.username}`} rel="noreferrer" target="_blank">
          @{telegram.username}
        </a>
      </div>
      <button onClick={onDisconnect} className="btn btn-red-8 btn-color-red">
        Отключить Telegram уведомления
      </button>
    </div>
  )
}
