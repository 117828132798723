import usersService from "../services/users"

export interface TourSection {
  title: string
  icon: {
    name: string
    color: string
  }
  steps: TourStep
}

export interface TourStep {
  [key: string]: {
    checked?: boolean
    title: string
    subTitle?: string
    duration: string
    link: string
    checkShowing?: {
      limitProperty: string
      getCount?: () => Promise<number>
      constantProperty?: string
    }
  }
}

export interface TourChecklist {
  [key: string]: TourSection
}

export type TourStepItem = TourStep[keyof TourStep]

export const tourChecks = {
  control_inventory: {
    add_rental_point: false,
    setting_periods: false,
    add_product: false,
    learn_inventorization: false
  },
  client_base_and_docs: {
    add_client: false,
    create_docs: false
  },
  final: {
    learn_integration: false,
    invite_employee: false
  }
}

const tourChecklist: TourChecklist = {
  control_inventory: {
    title: "Управление инвентарем",
    icon: {
      name: "inventory",
      color: "#F759AB"
    },
    steps: {
      add_rental_point: {
        title: "Добавьте пункты проката",
        duration: "2 минуты",
        link: "/config/rental-points",
        checkShowing: {
          limitProperty: "rental_point_count",
          constantProperty: "RENTAL_POINTS"
        }
      },
      setting_periods: {
        title: "Настройте периоды",
        subTitle: "Отправьте приглашение по эл.почте",
        duration: "3 минуты",
        link: "/config/lease-terms"
      },
      add_product: {
        title: "Добавьте продукты",
        duration: "1 минуты",
        link: "/groups/add"
      },
      learn_inventorization: {
        title: "Ознакомьтесь с инвентаризацией",
        duration: "1 минуты",
        link: "/inventorization/tasklist"
      }
    }
  },
  client_base_and_docs: {
    title: "Клиентская база и документооборот",
    icon: {
      name: "group",
      color: "#597EF7"
    },
    steps: {
      add_client: {
        title: "Добавьте клиента",
        duration: "2 минуты",
        link: "/clients/add"
      },
      create_docs: {
        title: "Создайте документ из шаблона",
        duration: "2 минуты",
        link: "/config/documents"
      }
    }
  },
  final: {
    title: "Финальный этап",
    icon: {
      name: "flag",
      color: "#A165FD"
    },
    steps: {
      learn_integration: {
        title: "Ознакомьтесь с интеграциями",
        duration: "2 минуты",
        link: "/integrations"
      },
      invite_employee: {
        title: "Пригласите сотрудников",
        duration: "2 минуты",
        link: "/users/main",
        checkShowing: {
          limitProperty: "staff_count",
          getCount: async () => {
            const res = await usersService.list({is_staff: true})
            return res.count
          }
        }
      }
    }
  }
}

export default tourChecklist
