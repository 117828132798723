import clsx from "clsx"

interface IconConfig extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  icon: string
  filled?: boolean
}

export default function Icon({filled = true, ...config}: IconConfig) {
  return (
    <span
      {...config}
      className={clsx(
        "leading-none user-select-none",
        config.className,
        filled ? "material-symbols-rounded-filled" : "material-symbols-rounded-empty"
      )}>
      {config.icon}
    </span>
  )
}
