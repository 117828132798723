import React, {useEffect, useMemo} from "react"
import Icon from "src/components/shared/components/material-icon"
import clsx from "clsx"
import {Link} from "react-router-dom"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {Tooltip} from "antd"

type LinkWrapperProps = {
  route?: string
  icon: string
  custom_icon?: JSX.Element
  text: string
  collapse?: boolean
  subRoutes?: {route: string; icon: string; text: string}[]
  isSection?: boolean
  onClick?: () => void
  id?: string
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({
  route,
  icon,
  custom_icon,
  text,
  subRoutes,
  isSection = false,
  collapse,
  onClick,
  id
}) => {
  const currentRoute = useCurrentRoute(2)

  const open = useMemo(() => {
    if (!subRoutes) return false
    return subRoutes.filter(s => currentRoute.startsWith(s.route)).length > 0 || currentRoute.startsWith(route)
  }, [subRoutes, currentRoute])

  return (
    <>
      {isSection ? (
        <Tooltip placement="right" title={text} open={!collapse ? false : undefined}>
          <Link
            to={`/${route}`}
            onClick={() => onClick && onClick()}
            className={clsx("menu-item mb-2", open && "bg-primary bg-opacity-20 text-primary")}
          >
            {custom_icon ? custom_icon : <Icon className="menu-item-icon" icon={icon} />}
            <div className="menu-item-name">{text}</div>

            {isSection && (
              <Icon
                className={clsx("menu-item-arrow transition-all duration-300 ease-in-out", open && "text-primary rotate-180")}
                icon="keyboard_arrow_down"
              />
            )}
          </Link>
        </Tooltip>
      ) : route ? (
        <Tooltip placement="right" title={text} open={!collapse ? false : undefined}>
          <Link
            onClick={onClick && onClick}
            to={`/${route}`}
            className={clsx("menu-item mb-2", currentRoute.startsWith(route) && "active")}
            id={id}>
            {custom_icon ? custom_icon : <Icon className="menu-item-icon" icon={icon} />}
            <div className="menu-item-name">{text}</div>
          </Link>
        </Tooltip>
      ) : (
        <Tooltip placement="right" title={text} open={!collapse ? false : undefined}>
          <div onClick={onClick && onClick} className={clsx("menu-item mb-2", isSection && open && "active")}>
            {custom_icon ? custom_icon : <Icon className="menu-item-icon" icon={icon} />}
            <div className="menu-item-name">{text}</div>
          </div>
        </Tooltip>
      )}

      {isSection &&
        open &&
        subRoutes &&
        subRoutes.map((sub, index) => (
          <Tooltip key={index} placement="right" title={sub.text} open={!collapse ? false : undefined}>
            <Link
              to={`/${sub.route}`}
              className={clsx("menu-item mb-2", {
                active: currentRoute.includes(sub.route),
                "ml-[10px] min-w-10": !collapse
              })}>
              {custom_icon ? custom_icon : <Icon className="menu-item-icon" icon={sub.icon} />}
              <div className="menu-item-name">{sub.text}</div>
            </Link>
          </Tooltip>
        ))}
    </>
  )
}

export default LinkWrapper
