import axios from "axios"
import {BehaviorSubject, Observable, of, shareReplay, switchMap} from "rxjs"
import {ApiAbstract} from "src/abstract/api.abstract"
import {IntegrationCategoryModel, IntegrationModel} from "src/models/integration"
import authService from "../auth.service"
import {getAPI} from "src/interceptor"
import {WazzupTriggerModel} from "src/models/integration/wazzup-trigger"
import {RingostatCall} from "../../models/integration/ringostat"
import {ListModel} from "../../models/common"

class IntegrationService extends ApiAbstract {
  constructor() {
    super("v1/crm/integrations")
  }

  listIntegrations$ = new BehaviorSubject<void>(null)
  listIntegrations = () => this.listIntegrations$.next()

  integrations$: Observable<IntegrationModel[]> = authService.authorized$.pipe(
    switchMap((logged) =>
      logged
        ? this.listIntegrations$.pipe(
            switchMap(() => getAPI<IntegrationModel[]>(axios.get(this.getUrl(), {params: {skip_error_handling: true}})))
          )
        : of([])
    ),
    shareReplay(1)
  )

  public async listCategories(): Promise<IntegrationCategoryModel[]> {
    const response = await axios.get(this.getUrl("categories"))
    return response.data
  }
  public async get(code: string): Promise<IntegrationModel> {
    const response = await axios.get(this.getUrl(code))
    return response.data
  }

  public async connect(code: string, payload = {}): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${code}/connect`), payload)
    this.listIntegrations()
    return response.data
  }

  public async disconnect(code: string): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${code}/disconnect`), {})
    this.listIntegrations()
    return response.data
  }

  public async wazzupIFrame(payload = {}): Promise<{url: string}> {
    const response = await axios.post(this.getUrl("wazzup/iframe"), payload)
    return response.data
  }
  public async wazzupTriggers(): Promise<WazzupTriggerModel> {
    const response = await axios.get(this.getUrl("wazzup/triggers"))
    return response.data
  }
  public async updateWazzupTriggers(payload: WazzupTriggerModel): Promise<WazzupTriggerModel> {
    const response = await axios.post(this.getUrl("wazzup/triggers"), payload)
    return response.data
  }
  public async ringostatList(params: any): Promise<RingostatCall[]> {
    const response = await axios.get(this.getUrl("ringostat/calls"), {params})
    return response.data
  }
  public async ringostatOnlineUsers(): Promise<string[]> {
    const response = await axios.get(this.getUrl("ringostat/accounts/online"))
    return response.data
  }

  public async ringostatCall(payload = {}): Promise<{url: string}> {
    const response = await axios.post(this.getUrl("ringostat/outward_call"), payload)
    return response.data
  }

  public async ringostatCreateContact(payload = {}): Promise<{[key: string]: string}> {
    const response = await axios.post(this.getUrl("ringostat/clients"), payload)
    return response.data
  }
}

const integrationService = new IntegrationService()
export default integrationService
