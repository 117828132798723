import moment from "moment"
import {useContext, useEffect, useState} from "react"
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker
} from "react-calendar-timeline"
import {FormProvider, useForm} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import {ORDER_STATUS_NAME} from "src/components/shared/constants"
import {FormControl} from "src/components/shared/inputs/form-control"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {useDebounce} from "src/hooks/useDebounce"
import useQueryParams from "src/hooks/useQuertParams"
import {EMPTY_LIST, ListModel, ListParams} from "src/models/common"
import {InventoryScheduleModel, ScheduleModel} from "src/models/manager/inventory/inventory.model"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import inventoryScheduleService from "src/services/inventory/inventory-schedule.service"
import {isNil} from "src/utils/isNil"
import objectFilter from "src/utils/object.filter"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import HeaderContent from "src/components/header/header-content"
import {useTranslation} from "react-i18next"
import {SharedInventoryGroupSelect} from "src/components/shared/components/select/inventory-group"
import {ConfigContext} from "src/app"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"
import "./styles.scss"

const defaultStart = moment().add(-3, "days")
const defaultEnd = moment().add(3, "days")
const timeSteps = {minute: 60, hour: 1, day: 1, month: 1, year: 1}
const maxZoom = 150 * 24 * 60 * 60 * 1000
const minZoom = 2 * 24 * 60 * 60 * 1000

export interface InventoryScheduleFilter extends ListParams {
  id: number
  search: string
  category: number
  unique_id: string
  orders__request__id: string
  orders__request__client__id: number
  start_at: number
  end_at: number
}

export default function SchedulesListComponent() {
  const {t} = useTranslation()
  const {searchParams, setSearchParams} = useQueryParams()
  const {settings, pointId} = useContext(ConfigContext)

  const form = useForm<InventoryScheduleFilter>({
    defaultValues: {
      page: 1,
      pageSize: 10,
      rental_point: pointId,
      start_at: defaultStart.valueOf(),
      end_at: defaultEnd.valueOf(),
      ...searchParams
    }
  })

  const {watch, formState, reset} = form

  const [start, setStart] = useState(defaultStart.toDate())
  const [end, setEnd] = useState(defaultEnd.toDate())
  const [time, setTime] = useState<[number, number]>([defaultStart.valueOf(), defaultEnd.valueOf()])
  const [minTime, setMinTime] = useState<number>(moment().add(-6, "months").valueOf())
  const [maxTime, setMaxTime] = useState<number>(moment().add(6, "months").valueOf())
  const [list, setList] = useState<ListModel<InventoryScheduleModel>>(EMPTY_LIST)
  const [groups, setGroups] = useState<any[]>([])
  const [rents, setRents] = useState<any[]>([])
  const [load, setLoad] = useState(false)

  const values = watch()

  const timeDebounce = useDebounce(time, 500)
  const searchDebounce = useDebounce(watch("search", ""), 500)

  const navigate = useNavigate()

  const getColor = (rent: ScheduleModel) => {
    const color = settings[rent.request_status_color]
    return isNil(color) ? "var(--color-black)" : color
  }

  const colorView = (color: string, label: string) => (
    <div className="color-hint">
      <div className="color" style={{backgroundColor: color}}></div>
      <div className="name" style={{color: color}}>
        {label}
      </div>
    </div>
  )

  const updateSchedule = async (list: InventoryScheduleModel[]) => {
    setGroups(list.map((inv) => ({id: inv.id, title: `${inv.name} (${inv.unique_id})`})))

    const prev_ids = rents.map((rent) => rent.id)

    const new_rents = list
      .reduce(
        (prev, curr) => [
          ...prev,
          ...curr.schedules.map((rent: ScheduleModel) => ({
            id: rent.id,
            group: curr.id,
            order_id: rent.request_id,
            title: t("calendar.timerange_title", {id: rent.request_id}),
            start_time: moment(rent.rent_start),
            end_time: moment(rent.rent_end),
            rent
          }))
        ],
        []
      )
      .filter((schedule) => !prev_ids.includes(schedule.id))

    setRents(new_rents)
    setLoad(true)
  }

  const itemRenderer = ({item, _, itemContext, getItemProps, getResizeProps}) => {
    const {left: leftResizeProps, right: rightResizeProps} = getResizeProps()
    const backgroundColor = getColor(item.rent)
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            overflow: "hidden",
            padding: "4px 8px",
            fontSize: 15,
            borderRadius: 8,
            border: "1px solid #ffffff00",
            color: "var(--color-white)",
            display: "flex",
            alignItems: "center"
          },
          onMouseDown: (e) => {
            e.stopPropagation()
            if (item.order_id) navigate(`/orders/${item.order_id}/all`)
          }
        })}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "text-nowrap"
          }}>
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    )
  }

  const handleScroll = (start: number, end: number) => setTime([start, end])

  const onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime)
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
    }
  }

  const listSchedule = async (params: any & InventoryScheduleFilter) => {
    const schedule = await inventoryScheduleService.list({
      ...params,
      start_at: moment(+params.start_at).toISOString(),
      end_at: moment(+params.end_at).toISOString(),
      skip_loader: true
    })
    setList(schedule)
    updateSchedule(schedule.results)
  }

  const getBoundaris = async () => {
    const {min_date, max_date} = await inventoryScheduleService.getBoundaries()
    setMinTime(moment(min_date).add(-6, "month").valueOf())
    setMaxTime(moment(max_date).add(6, "month").valueOf())
  }

  useEffect(() => {
    if (formState.isDirty) {
      reset({...values, search: searchDebounce, page: 1}, {keepDirty: true, keepTouched: true})
    }
  }, [searchDebounce])

  useEffect(() => {
    reset({...values, start_at: time[0], end_at: time[1]}, {keepDirty: true, keepTouched: true})
  }, [timeDebounce])

  useEffect(() => {
    listSchedule(values)
    getBoundaris()

    const startTime = +searchParams.start_at || defaultStart.valueOf()
    const endTime = +searchParams.end_at || defaultEnd.valueOf()

    setStart(searchParams.start_at ? new Date(startTime) : defaultStart.toDate())
    setEnd(searchParams.end_at ? new Date(endTime) : defaultEnd.toDate())

    const sub = watch((params, {name}) => {
      if (!["search", "start_at", "end_at"].includes(name)) listSchedule(params)
      const query = objectFilter(params)
      setSearchParams(query)
    })

    return () => {
      sub.unsubscribe()
      setGroups([])
      setRents([])
    }
  }, [])

  return (
    settings && (
      <FormProvider {...form}>
        <HeaderContent>
          <div className="text-2xl font-semibold header-content">{t("calendar.header")}</div>
        </HeaderContent>

        <div className="flex gap-2">
          <FormControlDaterange
            allowClear={false}
            className="col form-control"
            name_start="start_at"
            name_end="end_at"
            onChange={(values) => {
              if (!values) return
              setStart(values[0].toDate())
              setEnd(values[1].toDate())
              setTime([values[0].valueOf(), values[1].valueOf()])
            }}
          />
          <FormControl
            icon="search"
            rootclassname="col"
            name="search"
            className="form-control"
            placeholder={t("common.input.search")}
          />
          <div className="col-2"></div>
          <AsyncRentalPoint name="rental_point" />
          <SharedCategorySelect
            className="col"
            placeholder={t("common.select.category")}
            name="category"
            params={{required: true}}
            required={true}
            isClearable={true}
            isSearchable={false}
          />
          <SharedInventoryGroupSelect
            className="col"
            name="group"
            placeholder={t("common.select.group")}
            listOptions={(params) => inventoryGroupService.list({...params, type: 0})}
            isSearchable={true}
            isClearable={true}
          />
        </div>

        <PaginationFormComponent
          className="mb-3"
          count={list.count}
          component={
            <div className="flex items-center gap-3 flex-wrap">
              {colorView(settings["request"], t(ORDER_STATUS_NAME["request"]))}
              {colorView(settings["reserve"], t(ORDER_STATUS_NAME["reserve"]))}
              {colorView(settings["inrent"], t(ORDER_STATUS_NAME["inrent"]))}
              {colorView(settings["completed"], t(ORDER_STATUS_NAME["completed"]))}
              {colorView(settings["cancel"], t(ORDER_STATUS_NAME["cancel"]))}
              {colorView(settings["debtor"], t(ORDER_STATUS_NAME["debtor"]))}
              {colorView(settings["exceed"], t(ORDER_STATUS_NAME["exceed"]))}
            </div>
          }
        />

        {load && (
          <Timeline
            sidebarWidth={250}
            groups={groups}
            items={rents}
            defaultTimeStart={start}
            defaultTimeEnd={end}
            maxZoom={maxZoom}
            minZoom={minZoom}
            canMove={false}
            timeSteps={timeSteps}
            lineHeight={60}
            onBoundsChange={handleScroll}
            onTimeChange={onTimeChange}
            itemRenderer={itemRenderer}>
            <TimelineHeaders>
              <DateHeader unit="primaryHeader" />
              <DateHeader />
              <SidebarHeader>
                {({getRootProps}) => {
                  const props = {
                    ...getRootProps(),
                    style: {
                      width: 250,
                      // boxShadowRight: '10px 0px 12px var(--color-black-8)',
                      backgroundColor: "var(--color-white)",
                      borderBottom: "1px solid var(--color-grey-100)",
                      padding: "8px 12px",
                      zIndex: 81
                    }
                  }
                  return <div {...props}></div>
                }}
              </SidebarHeader>
            </TimelineHeaders>
            <TimelineMarkers>
              <TodayMarker>
                {({styles}) => {
                  const style = {
                    ...styles,
                    width: 3,
                    backgroundColor: "var(--color-primary)"
                  }
                  return <div style={style} />
                }}
              </TodayMarker>
            </TimelineMarkers>
          </Timeline>
        )}
      </FormProvider>
    )
  )
}
