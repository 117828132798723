import {useContext, useMemo, useRef} from "react"
import {useTranslation} from "react-i18next"
import Icon from "../shared/components/material-icon"
import {Link, useLocation} from "react-router-dom"
import clsx from "clsx"
import {ConfigContext} from "src/app"
import {GroupType} from "src/services/auth.service"
import {TenantType} from "src/services/tenant.service"

type MenuKey = "order" | "inventory" | "delivery" | "report" | "profile" | "rides" | "drivers" | "cities" | "expenses"
interface MenuProps {
  path: string
  icon: string
  label: string
  active?: boolean
}

const transferDriver: MenuKey[] = ["rides", "expenses", "profile"]
const transferManger: MenuKey[] = ["rides", "cities", "profile"]
const transferAdmin: MenuKey[] = ["rides", "drivers", "cities", "profile"]
const transferSuperuser: MenuKey[] = ["rides", "drivers", "report", "profile"]

const defaultMenuList: MenuKey[] = ["order", "delivery", "inventory", "report", "profile"]

export function MobileMenu() {
  const ref = useRef(null)
  const location = useLocation()
  const {t} = useTranslation()
  const {isMobile, profile, tenant, mobileMenu, settings} = useContext(ConfigContext)
  const has_perms = (key: string) => profile && (profile.perms.some((perm) => perm.includes(key)) || profile.is_superuser)

  const menuMap: Record<MenuKey, MenuProps> = {
    order: {
      path: "/orders",
      icon: "list_alt",
      label: t("aside.menu.orders"),
      active: has_perms("orderrequest")
    },
    inventory: {
      path: "/groups",
      icon: "inventory_2",
      label: t("aside.menu.groups"),
      active: has_perms("inventorygroup")
    },
    delivery: {
      path: "/deliveries",
      icon: "local_shipping",
      label: t("aside.menu.delivery"),
      active: has_perms("orderrequestdelivery")
    },
    report: {
      path: "/reports",
      icon: "insert_chart",
      label: t("aside.menu.metrics"),
      active: has_perms("metrics")
    },
    profile: {
      path: "/profile",
      icon: "account_circle",
      label: t("profile.profile"),
      active: true
    },
    rides: {
      path: "/rides",
      icon: "home",
      label: t("orders.transfer.all_order"),
      active: true
    },
    drivers: {
      path: "/drivers",
      icon: "insert_chart",
      label: t("orders.transfer.expenses"),
      active: true
    },
    cities: {
      path: "/cities",
      icon: "location_on",
      label: t("orders.transfer.destinations"),
      active: true
    },
    expenses: {
      path: "/expenses",
      icon: "insert_chart",
      label: t("orders.transfer.expenses"),
      active: true
    }
  }

  const currentMenus = useMemo(() => {
    if (!profile) return []
    if (!tenant) return []
    if (!settings) return []

    if (tenant.type_code === TenantType.TRANSFER) {
      if (profile.group_type === GroupType.DRIVER) return transferDriver.map(k => menuMap[k])
      if (profile.group_type === GroupType.MANAGER) return transferManger.map(k => menuMap[k])
      if (profile.group_type === GroupType.ADMIN) return transferAdmin.map(k => menuMap[k])
      if (profile.is_superuser) return transferSuperuser.map(k => menuMap[k])
    }

    let menus: MenuKey[] = defaultMenuList
    if (!settings.order_delivery) menus = menus.filter(k => k !== "delivery")

    return menus.map(k => menuMap[k]).filter(m => m.active)
  }, [tenant, profile, menuMap])

  if (!isMobile) return <></>
  if (!mobileMenu) return <></>
  if (currentMenus.length === 0) return <></>

  return (
    <div
      ref={ref}
      className="fixed flex items-center w-full px-2 shadow-menu bg-white max-h-[72px] bottom-0 left-0 right-0 z-[1001] gap-1">
      {currentMenus.map((menu) => (
        <Link
          key={menu.path}
          to={menu.path}
          className={clsx(
            "flex flex-col gap-[6px] justify-center items-center w-full pt-3 pb-2 text-center",
            location.pathname.startsWith(menu.path) && "text-primary"
          )}>
          <Icon icon={menu.icon} />
          <span className="text-[13px] text-ellipsis line-clamp-1">{menu.label}</span>
        </Link>
      ))}
    </div>
  )
}
