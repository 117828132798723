import moment from "moment"
import {useMemo} from "react"
import {useTranslation} from "react-i18next"

export default function Duration({time, period = "seconds"}: {time: any; period?: "seconds" | "milliseconds"}) {
  const {t} = useTranslation()

  const value = useMemo(() => {
    if (!time) return ""
    if (!period) return ""

    const seconds = moment.duration(time, period).asSeconds()
    const days = [Math.floor(seconds / (3600 * 24)), t("common.times_abb.day")]
    const hours = [Math.floor((seconds % (3600 * 24)) / 3600), t("common.times_abb.hour")]
    const minutes = [Math.floor((seconds % 3600) / 60), t("common.times_abb.minute")]
    const secs = [Math.round(seconds % 60), t("common.times_abb.second")]
    return [days, hours, minutes, secs]
      .filter(([time, _]) => time != 0)
      .map(([time, symbol]) => `${time} ${symbol}`)
      .slice(0, 3)
      .join(" ")
  }, [time, period])

  return <>{value}</>
}
