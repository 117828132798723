import IconDark from "src/assets/icons/icon-dark.svg"
import {use100vh} from "react-div-100vh"
import clsx from "clsx"

export function SplashScreen() {
    const height = use100vh()

    return (
        <div
            className={clsx("flex flex-col gap-8 items-center justify-center bg-dark-light w-screen p-3")}
            style={{minHeight: height, maxHeight: height}}>
            <img src={IconDark} width={240} alt="Yume Cloud"></img>
            <div className="spinner-border text-gray-300"></div>
        </div>
    )
}