import React, {useContext, useEffect} from "react"
import {FormProvider, useForm} from "react-hook-form"
import logoDark from "../../assets/icons/icon-dark.svg"
import {FormControlPassword} from "../../components/shared/inputs/form-control-password"
import {useTranslation} from "react-i18next"
import {useNavigate, useSearchParams} from "react-router-dom"
import authService from "../../services/auth.service"
import {ConfigContext} from "src/app"

const AcceptComponent = () => {
  const form = useForm({
    defaultValues: {
      username: "",
      password: ""
    }
  })
  const {formState, handleSubmit, setError} = form
  const {t} = useTranslation()
  const {logged} = useContext(ConfigContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const invitationToken = searchParams.get("invitation_token")

  const onSubmit = handleSubmit(async ({password}) => {
    try {
      const result = await authService.onInviteAccept(invitationToken, password)

      if (result?.email) {
        await authService.login(result.email, password)
      }
    } catch (error) {
      if (error.response?.data?.detail) {
        setError("username", {message: error.response.data.detail})
        setError("password", {message: error.response.data.detail})
      }
    }
  })

  useEffect(() => {
    if (logged) navigate("/orders/all")
  }, [logged])

  return (
    <FormProvider {...form}>
      <div className="w-full flex flex-col items-center gap-16 md:gap-[120px] bg-dark-light h-screen">
        <div className="max-w-[480px] w-full bg-white p-8 rounded-2xl flex flex-col items-center gap-3 md:gap-4 mt-10 md:mt-20">
          <img src={logoDark} draggable={false} alt="yume cloud" className="auth-icon" />
          <div className="tracking-normal text-base md:text-3xl font-medium">Придумайте пароль</div>
          <div className="auth">
            <FormControlPassword
              label={t("auth.password")}
              placeholder={t("auth.placeholder.password")}
              className="form-control w-full"
              name="password"
              params={{required: true, minLength: 8}}
            />
          </div>

          <button
            onClick={onSubmit}
            disabled={!formState.isValid}
            className="btn btn-color-white btn-primary w-full auth__submit-btn">
            {t("auth.login")}
          </button>
        </div>
      </div>
    </FormProvider>
  )
}

export default AcceptComponent
