import {ColumnsType} from "antd/es/table"
import {useContext} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useParams} from "react-router-dom"
import {InventoryModel} from "src/models/manager/inventory/inventory.model"
import {InventoryFilterForm} from "src/pages/inventories/list"
import InventoryListFilter from "src/pages/inventories/list/filter"
import InventoryListComponent from "src/pages/inventories/list/list"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"

interface InventoryListParams {
  filter?: any
  onSelect?: (inventory: InventoryModel) => Promise<number[]>
}

export default function OrderDetailInventoryListComponent({filter = {}, onSelect}: InventoryListParams) {
  const {settings, pointId} = useContext(ConfigContext)
  const form = useForm<InventoryFilterForm>({
    defaultValues: {
      page: 1,
      pageSize: 10,
      rental_point: pointId,
      type: 0,
      search: "",
      ...filter
    }
  })
  const {watch, setValue, reset} = form
  const {id} = useParams()
  const {t} = useTranslation()
  const values = watch()

  const columns: ColumnsType<InventoryModel> = settings
    ? settings.custom_fields.inventory
      .filter((field) => field.table)
      .map((field) => ({
        width: 48,
        title: field.label,
        key: field.name,
        render: (_, inventory) => inventory.extra[field.name]
      }))
    : []

  return (
    <FormProvider {...form}>
      <InventoryListFilter customFieldFilter={!onSelect && {group_id: id}} />

      <InventoryListComponent
        additionalColumns={columns}
        onRow={(inventory) => ({
          onClick: async () => {
            await onSelect(inventory)
              .then((numbers) => reset({
                ...values,
                ...filter,
                exclude_ids: [filter?.exclude_ids?.split(","), ...numbers].join(",")
              }))
          }
        })}
      />
    </FormProvider>
  )
}
