import {SyntheticEvent} from "react"
import {useTranslation} from "react-i18next"
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom"
import HeaderContent from "src/components/header/header-content"
import {Segmented} from "src/components/shared/antd-custom"
import Icon from "src/components/shared/components/material-icon"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"

export default function SaleWrapperComponent() {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const onSale = (e: SyntheticEvent, group: InventoryGroupModel) => {
    e.stopPropagation()
    navigate("/sales/add", {state: {group}})
  }

  return (
    <>
      <HeaderContent>
        <div className="flex items-center justify-between gap-2 header-content">
          <div className="text-2xl font-semibold flex-1">{t("sale.header")}</div>
          <div className="flex gap-2">
            <button className="btn btn-card gap-2 w-full" onClick={(e) => onSale(e, null)}>
              <Icon icon="shopping_cart" />
              <span>{t("sale.btn")}</span>
            </button>
            <Link
              to="/groups/add"
              state={{from: "sales"}}
              className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap">
              <Icon icon="add" />
              <span className="hidden md:block">{t("groups.add")}</span>
            </Link>
          </div>
        </div>
      </HeaderContent>

      <Segmented
        value={location.pathname}
        onChange={(option) => navigate(option)}
        className="bg-gray-200 shadow self-center rounded-md mb-3 w-max"
        style={{
          background: "var(--color-gray-70)",
          padding: 4,
          borderRadius: 6
        }}
        options={[
          {
            label: t("sale.sections.all"),
            value: "/sales",
            className: "p-1 font-medium text-black"
          },
          {
            label: t("sale.sections.history"),
            value: "/sales/history",
            className: "p-1 font-medium text-black"
          }
        ]}
      />

      <Outlet
        context={{
          action: (group: InventoryGroupModel) => (
            <button className="btn btn-card gap-2 w-full" onClick={(e) => onSale(e, group)}>
              <Icon icon="shopping_cart" />
              <span>{t("sale.btn")}</span>
            </button>
          )
        }}
      />
    </>
  )
}
