import {KanbanContext} from "./kanban-context"
import KanbanFilter from "./filter"
import {Drawer, Space} from "antd"
import React from "react"
import Icon from "../../../components/shared/components/material-icon"
import LeadCreateComponent from "../edit/lead-create"

const KanbanBoardComponent = ({closeDrawer, open}) => {
  return (
    <>
      <KanbanFilter />
      <KanbanContext />
      <Drawer
        title="Новая заявка"
        placement="right"
        closable={false}
        onClose={closeDrawer}
        open={open}
        zIndex={1002}
        getContainer={document.body}
        extra={
          <Space>
            <div
              className="cursor-pointer p-1 flex items-center justify-center rounded-full border border-gray-300 hover:bg-gray-100 transition"
              onClick={closeDrawer}>
              <Icon icon="close" className="text-black text-[12px]" />
            </div>
          </Space>
        }>
        <LeadCreateComponent onSuccess={closeDrawer} />
      </Drawer>
    </>
  )
}

export default KanbanBoardComponent
