import {useTranslation} from "react-i18next"
import {Outlet, useNavigate} from "react-router-dom"
import {Segmented} from "src/components/shared/antd-custom"
import {FilterWrapper} from "src/components/shared/components/filter-wrapper"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"

export default function ReportPayback() {
  const navigate = useNavigate()
  const page = useCurrentRoute(4)
  const {t} = useTranslation()

  return (
    <FilterWrapper
      header={
        <Segmented
          value={page}
          className="bg-gray-200 self-center rounded-lg mr-auto shadow border border-gray-200 w-max"
          onChange={(option) => navigate(option)}
          options={[
            {
              label: t("metrics.inventory.pages.efficency"),
              value: "efficency",
              className: "md:p-1 md:py-[7px] font-medium text-black md:h-auto text-center min-w-24"
            },
            {
              label: t("metrics.inventory.pages.payback"),
              value: "payback",
              className: "md:p-1 md:py-[7px] font-medium text-black md:h-auto text-center min-w-24"
            }
          ]}
        />
      }>
      <Outlet />
    </FilterWrapper>
  )
}
