import {ApiAbstract} from "../../abstract/api.abstract"
import axios from "axios"
import {ProfileModel} from "../auth.service"
import {ListModel} from "../../models/common"

export interface LeadList {
  id: number
  manager: ProfileModel
  created_at: string
  updated_at: string
  client_name: string
  client_phone: string
  price: string
  ordering: number
  cancel_reason?: string | null
  extra?: Record<string, any>
  stage: number
  funnel: number
  source?: number | null
  request?: number | null
  sale?: number | null
}

export class LeadsService extends ApiAbstract {
  constructor() {
    super("v1/sales/leads")
  }

  public async list(params?: any): Promise<ListModel<LeadList>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async post(payload: any): Promise<LeadList> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async getLead(id?: number): Promise<LeadList> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async putLead(id?: number): Promise<LeadList> {
    const response = await axios.put(this.getUrl(id))
    return response.data
  }
  public async patchLead(id: number, payload?: any): Promise<LeadList> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async deleteLead(id?: number): Promise<LeadList> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
}

const leadsService = new LeadsService()
export default leadsService
