import {useContext, useEffect, useMemo, useState} from "react"
import {firstValueFrom, of} from "rxjs"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {LabelModel} from "src/models/label"
import {CustomField, CustomFieldModel, CustomFieldType, optionlessFieldTypes} from "src/models/setting-config"
import fields, {FieldParams} from "./utils"
import {chunkArray} from "src/utils/chunk-array"
import Icon from "src/components/shared/components/material-icon"
import modalService from "src/components/modal/global/modal.service"
import {CustomFieldModal} from "./field-modal"
import {ConfigContext} from "src/app"
import {useTranslation} from "react-i18next"
import clsx from "clsx"
import {useFormContext} from "react-hook-form"
import {Popover} from "antd"
import {PopoverOptions} from "src/components/shared/antd-custom"
import {title} from "process"
import {f} from "react-router/dist/development/fog-of-war-DLtn2OLr"
import {isNil} from "src/utils/isNil"

interface CustomFieldChoiceInterface {
  type: keyof CustomFieldModel
  className?: string
  prefix?: string
  keys?: string[]
  newField?: boolean
}

export function CustomFieldChoice(config: {type: CustomFieldType; params: FieldParams}) {
  if (config.params.options && config.params.options.length > 0 && !optionlessFieldTypes.includes(config.type)) {
    return (
      <FormSelectAsync<LabelModel>
        className="col"
        name={config.params.name}
        label={config.params.label}
        placeholder={config.params.placeholder}
        required={config.params.required}
        params={{required: config.params.required}}
        listOptions={() =>
          of(
            config.params.options.map((item: string) => ({
              id: item,
              label: item
            }))
          )
        }
        getValue={(val: string) => firstValueFrom(of({id: val, label: val}))}
        getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
        getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
        isClearable={true}
        isSearchable={false}
      />
    )
  }
  return fields(config.params)[config.type]
}

export function CustomFieldChoiceList({
  type,
  keys,
  className = "",
  prefix = "",
  newField = true
}: CustomFieldChoiceInterface) {
  const form = useFormContext()
  const value = form.watch(`${prefix}extra`)
  const [fieldMap, setFieldMap] = useState<Record<string, boolean>>({})
  const {settings, tenant, profile} = useContext(ConfigContext)
  const limited = !useMemo(() => tenant && tenant.custom_fields_enabled, [tenant])
  const {t} = useTranslation()

  const customFields = settings && type && settings.custom_fields[type]
  // const customFields: CustomField[] = useMemo(() => {
  //   if (!settings) return []
  //   if (!value) return []
  //   if (keys) return settings.custom_fields[type].filter((field) => keys.includes(field.name))
  //   // return settings.custom_fields[type].filter((field) => fieldMap[field.name])
  //   return settings.custom_fields[type]
  // }, [type, settings, fieldMap])

  const fields = chunkArray(customFields, 4)

  const onCreate = (key: string) => setFieldMap((p) => ({...p, [key]: true}))

  const onCustomField = () => {
    modalService.open({
      size: "lg",
      component: <CustomFieldModal type={type} onCreate={onCreate} />
    })
  }

  if (limited) return <></>

  useEffect(() => {
    if (!settings) return
    if (!value) return
    // if (Object.keys(fieldMap).length > 0) return
    setFieldMap(settings.custom_fields[type].reduce((p, c) => ({...p, [c.name]: true}), {}))
    // setFieldMap(settings.custom_fields[type].reduce((p, c) => ({...p, [c.name]: !isNil(value[c.name])}), {}))
  }, [type, settings, value])

  return (
    <div className={clsx(className, "w-full")}>
      {customFields.length > 0 && (
        <div className="flex flex-col gap-3 mb-3">
          {fields.map((chunk: CustomField[], index: number) => (
            <div className="flex flex-wrap gap-3" key={index}>
              {chunk.map((field: CustomField) => (
                <CustomFieldChoice
                  key={field.name}
                  type={field.type}
                  params={{
                    name: `${prefix}extra.${field.name}`,
                    label: field.label,
                    placeholder: field.label,
                    required: field.required,
                    params: {required: field.required},
                    className: "form-control",
                    rootclassname: "flex-1",
                    options: field.options
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      )}

      {settings && profile && profile.is_superuser && (
        <div className="btn btn-gray-70 gap-2 w-full p-4 font-medium text-nowrap" onClick={onCustomField}>
          <Icon icon="add" />
          <span>{t("settings.custom-fields.add")}</span>
        </div>
      )}

      {/* {settings && newField && (
        <PopoverOptions
          overlayInnerStyle={{padding: 0}}
          trigger="click"
          className="cursor-pointer"
          options={[
            ...settings.custom_fields[type]
              .filter((f) => !fieldMap[f.name])
              .map((f) => ({
                key: f.name,
                title: f.label,
                onClick: () => onCreate(f.name),
                className: "px-4 py-3 md:px-4 md:py-3"
              })),
            profile && !profile.is_superuser && {
              key: "new",
              title: t("settings.custom-fields.add"),
              icon: "add",
              onClick: onCustomField,
              className: "px-4 py-3 md:px-4 md:py-3"
            }
          ]}>
          <div className="btn btn-gray-70 gap-2 w-full p-4 font-medium text-nowrap">
            <Icon icon="add" />
            <span>{t("settings.custom-fields.add")}</span>
          </div>
        </PopoverOptions>
      )} */}
    </div>
  )
}
