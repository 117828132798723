import {useContext, useEffect, useState} from "react"
import {useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import {FormControl} from "src/components/shared/inputs/form-control"
import {useDebounce} from "src/hooks/useDebounce"
import {ListParams} from "src/models/common"
import {ConfigContext} from "src/app"
import Icon from "src/components/shared/components/material-icon"
import {CustomModal} from "src/components/modal/global/customModal"
import clsx from "clsx"

export interface ProductSetFilterModel extends ListParams {
  rental_point: number
  archived: boolean
  search: string
}

export default function ProductSetFilterComponent() {
  const {t} = useTranslation()
  const form = useFormContext<any>()
  const [showFilters, setShowFilters] = useState(false)
  const {watch, reset} = form
  const values = watch()
  const {isMobile} = useContext(ConfigContext)
  const searchDebounce = useDebounce(watch("search"), 500)

  useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

  return (
    <div className={clsx("flex gap-2 bg-white md:bg-transparent py-2 px-4 md:px-0 md:py-0 mb-3 md:mb-3")}>
      <FormControl
        icon="search"
        name="search"
        rootclassname="w-full md:w-80"
        className="form-control"
        placeholder={t("common.input.search")}
      />
      {!isMobile && (
        <>
          <SharedCategorySelect
            placeholder={t("common.select.category")}
            name="category"
            params={{required: true}}
            className="w-[162px]"
            required={true}
            isClearable={true}
            isSearchable={false}
          />
          <AsyncRentalPoint name="rental_point" className="w-full md:w-60" />
        </>
      )}

      {isMobile && (
        <div
          className={"min-h-12 min-w-12 rounded-lg border border-gray-200 flex justify-center items-center"}
          onClick={() => setShowFilters(true)}>
          <Icon icon="filter_list" className={"h-5 w-6 text-2xl"} />
        </div>
      )}
      <CustomModal backdropClick={true} show={showFilters} onHide={() => setShowFilters(false)}>
        <div className="flex flex-col gap-3">
          <SharedCategorySelect
            className="col"
            label={t("common.select.category")}
            placeholder={t("common.select.category")}
            name="category"
            isClearable={true}
            isSearchable={false}
          />
          <AsyncRentalPoint name="rental_point" label={t("common.select.rental_point")} />
        </div>
      </CustomModal>
    </div>
  )
}
