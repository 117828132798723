import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import {InventoryGroupInventoriesBulkCreate} from "src/pages/group/edit/inventories-bulk-create"
import SaleFormComponent from "src/pages/sale/edit/form"
import {SaleInventoryList} from "src/pages/sale/edit/inventories/inventories"
import SaleWrapperComponent from "src/pages/sale/wrapper"
import {ProtectedRoute} from "./guard"
import componentLoader from "src/components/custom-suspense/loader"
import ProductSetPartsComponent from "../pages/set/info/sets"
import ProductSetPeriodComponent from "../pages/set/info/period"
import {ProductSetProvider} from "../pages/set/info/context"

const InventoryDetailComponent = lazy(() =>
  componentLoader(() => import("src/pages/inventories/detail/inventory-detail"))
)
const InventoryGroupInventorizationComponent = lazy(() =>
  componentLoader(() => import("src/pages/group/detail/inventory-group-inventorization"))
)
const InventoryInventorizationListComponent = lazy(() =>
  componentLoader(() => import("src/pages/inventories/detail/inventory-inventorization"))
)
const InventoryRentListComponent = lazy(() =>
  componentLoader(() => import("src/pages/inventories/detail/inventory-rents"))
)
const InventoryTarifListComponent = lazy(() =>
  componentLoader(() => import("src/pages/inventories/detail/inventory-tarifs"))
)
const InventoryEditComponent = lazy(() => componentLoader(() => import("src/pages/inventories/edit/inventory-edit")))
const InventoryGroupDetailComponent = lazy(() =>
  componentLoader(() => import("src/pages/group/detail/inventory-group"))
)
const InventoryGroupImagesComponent = lazy(() =>
  componentLoader(() => import("src/pages/group/detail/inventory-group-images"))
)
const InventoryGroupInventoriesComponent = lazy(() =>
  componentLoader(() => import("src/pages/group/detail/inventory-group-inventories"))
)
const InventoryGroupRentsComponent = lazy(() =>
  componentLoader(() => import("src/pages/group/detail/inventory-group-rents"))
)
const InventoryGroupSalesComponent = lazy(() =>
  componentLoader(() => import("src/pages/group/detail/inventory-group-sales"))
)
const InventoryGroupEditComponent = lazy(() => componentLoader(() => import("src/pages/group/edit")))
const InventoryGroupListComponent = lazy(() => componentLoader(() => import("src/pages/group/list")))
const InventoryGroupTarifsComponent = lazy(() =>
  componentLoader(() => import("src/pages/group/detail/inventory-group-tarifs"))
)
const SaleListComponent = lazy(() => componentLoader(() => import("src/pages/sale/list")))
const SaleHistoryComponent = lazy(() => componentLoader(() => import("src/pages/sale/history")))
const SaleEditComponent = lazy(() => componentLoader(() => import("src/pages/sale/edit")))
const ProductSetListComponent = lazy(() => componentLoader(() => import("src/pages/set/list")))
const ProductSetInfoComponent = lazy(() => componentLoader(() => import("src/pages/set/info")))
const ProductSetEditComponent = lazy(() => componentLoader(() => import("src/pages/set/edit")))

export const inventoryRoutes: RouteObject[] = [
  {
    path: "groups",
    element: (
      <ProtectedRoute accessKey="groups">
        <CustomSuspense>
          <InventoryGroupListComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "groups/add",
    element: (
      <ProtectedRoute accessKey="groups">
        <CustomSuspense>
          <InventoryGroupEditComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "groups/:id/edit",
    element: (
      <ProtectedRoute accessKey="groups">
        <CustomSuspense>
          <InventoryGroupEditComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "groups/:id",
    element: (
      <ProtectedRoute accessKey="groups">
        <CustomSuspense>
          <InventoryGroupDetailComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="inventories" replace={true} />
      },
      {
        path: "inventories",
        element: (
          <CustomSuspense>
            <InventoryGroupInventoriesComponent />
          </CustomSuspense>
        )
      },
      {
        path: "images",
        element: (
          <CustomSuspense>
            <InventoryGroupImagesComponent />
          </CustomSuspense>
        )
      },
      {
        path: "inventorization",
        element: (
          <CustomSuspense>
            <InventoryGroupInventorizationComponent />
          </CustomSuspense>
        )
      },
      {
        path: "rents",
        element: (
          <CustomSuspense>
            <InventoryGroupRentsComponent />
          </CustomSuspense>
        )
      },
      {
        path: "sales",
        element: (
          <CustomSuspense>
            <InventoryGroupSalesComponent />
          </CustomSuspense>
        )
      },
      {
        path: "tarifs",
        element: (
          <CustomSuspense>
            <InventoryGroupTarifsComponent />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "sales",
    element: (
      <ProtectedRoute accessKey="groups.sales">
        <SaleWrapperComponent />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: (
          <CustomSuspense>
            <SaleListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "history",
        element: (
          <CustomSuspense>
            <SaleHistoryComponent />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "sales/add",
    element: (
      <ProtectedRoute accessKey="groups.sales">
        <CustomSuspense>
          <SaleEditComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {path: "", element: <SaleInventoryList />},
      {path: "form", element: <SaleFormComponent />},
      {path: "*", element: <Navigate to="" replace={true} />}
    ]
  },
  {
    path: "sales/:id",
    element: (
      <ProtectedRoute accessKey="groups.sales">
        <CustomSuspense>
          <SaleEditComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {path: "", element: <SaleInventoryList />},
      {path: "form", element: <SaleFormComponent />},
      {path: "*", element: <Navigate to="" replace={true} />}
    ]
  },
  {
    path: "groups/:id/inventories/add",
    element: (
      <ProtectedRoute accessKey="groups">
        <CustomSuspense>
          <InventoryGroupInventoriesBulkCreate />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "groups/:id/inventories/:inventoryId/edit",
    element: (
      <ProtectedRoute accessKey="groups">
        <CustomSuspense>
          <InventoryEditComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "groups/:id/inventories/:inventoryId",
    element: (
      <ProtectedRoute accessKey="groups">
        <CustomSuspense>
          <InventoryDetailComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {path: "", element: <Navigate to="rents" replace={true} />},
      {
        path: "rents",
        element: (
          <CustomSuspense>
            <InventoryRentListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "tarifs",
        element: (
          <CustomSuspense>
            <InventoryTarifListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "inventorization",
        element: (
          <CustomSuspense>
            <InventoryInventorizationListComponent />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "inventory-sets",
    element: (
      <ProtectedRoute accessKey="groups.sets">
        <ProductSetListComponent needActions={true} />
      </ProtectedRoute>
    )
  },
  {
    path: "inventory-sets/add",
    element: (
      <ProtectedRoute accessKey="groups.sets">
        <ProductSetEditComponent mode="create" />
      </ProtectedRoute>
    )
  },
  {
    path: "inventory-sets/:id",
    element: (
      <ProtectedRoute accessKey="groups.sets">
        <ProductSetProvider>
          <ProductSetInfoComponent />
        </ProductSetProvider>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="sets" relative="route" replace={true} />
      },
      {
        path: "sets",
        element: (
          <ProtectedRoute accessKey="groups.sets">
            <ProductSetProvider>
              <ProductSetPartsComponent />
            </ProductSetProvider>
          </ProtectedRoute>
        )
      },
      {
        path: "period",
        element: (
          <ProtectedRoute accessKey="groups.sets">
            <ProductSetProvider>
              <ProductSetPeriodComponent />{" "}
            </ProductSetProvider>
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: "inventory-sets/:id/edit",
    element: (
      <ProtectedRoute accessKey="groups.sets">
        <ProductSetEditComponent mode="edit" />
      </ProtectedRoute>
    )
  }
]
