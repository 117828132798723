import axios from "axios"
import {BehaviorSubject, map, Observable, of, shareReplay, switchMap} from "rxjs"
import {ApiAbstract} from "src/abstract/api.abstract"
import {CustomFieldModel, CustomFieldOption, SettingConfig} from "src/models/setting-config"
import {getAPI} from "../interceptor"
import authService from "./auth.service"
import {defaultConstant, ManagerConstantModel} from "src/models/manager/constants"

export let currency = "T"
export let currencyPrefix = true

export class SettingService extends ApiAbstract {
  constructor() {
    super("v1/crm")
  }

  private loadSettings$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined)
  public refreshSettings = () => this.loadSettings$.next()

  private loadConstants$: BehaviorSubject<void> = new BehaviorSubject<void>(null)
  loadConstants = () => this.loadConstants$.next()

  public config$: Observable<SettingConfig> = authService.authorized$.pipe(
    switchMap((logged) =>
      logged
        ? this.loadSettings$.pipe(
            switchMap(() =>
              getAPI<SettingConfig>(axios.get(this.getUrl("settings"), {params: {skip_error_handling: true}}))
            ),
            map((conf) => {
              currency = conf.currency
              currencyPrefix = conf.currency_prefix
              return conf
            })
          )
        : of(undefined)
    ),
    shareReplay(1)
  )

  public constants$: Observable<ManagerConstantModel> = authService.authorized$.pipe(
    switchMap((logged) =>
      logged
        ? this.loadConstants$.pipe(
            switchMap(() =>
              getAPI<ManagerConstantModel>(axios.get(this.getUrl("constants"), {params: {skip_error_handling: true}}))
            )
          )
        : of(defaultConstant)
    ),
    shareReplay(1)
  )

  public async save(payload: any): Promise<SettingConfig> {
    const response = await axios.patch(this.getUrl("settings"), payload)
    this.refreshSettings()
    return response.data
  }

  public async getInventoryOptions(params: any = {}): Promise<CustomFieldOption> {
    const response = await axios.get(this.getUrl("settings/custom_fields/inventory"), {params})
    return response.data
  }

  public async saveCustomFields(payload: any): Promise<CustomFieldModel> {
    const response = await axios.patch(this.getUrl("settings/custom_fields"), payload)
    this.refreshSettings()
    return response.data
  }
}

const settingService = new SettingService()
export default settingService
