import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "../components/custom-suspense"

import TransportationCities from "../pages/transportation/pages/cities/transportation-cities"
import TransferAddresses from "../pages/transportation/pages/address/transportation-addresses"
import AddressForm from "../pages/transportation/pages/address/detail/address-form"
import TransportationDrivers from "../pages/transportation/pages/drivers/transportation-drivers"
import ExpenseForm from "../pages/transportation/pages/expenses/detail/expense-form"

import RideThanks from "../pages/transportation/details/ui/ride-thanks"
import {lazy} from "react"
import {ProtectedRoute} from "./guard"
import DriverDetailComponent from "../pages/drivers"
import DriverProvider from "../pages/drivers/detail/driver-context"
import DriverInfoComponent from "../pages/drivers/detail/driver-info"
import DriverRidesComponent from "../pages/drivers/detail/driver-rides"
import TransportationDatabase from "../pages/transportation/pages/database/transportation-database"
import CarProvider from "../pages/cars/car-context"
import CarsDetailComponent from "../pages/cars"
import TransportationFilter from "../pages/transportation/pages/transportation/transportation-filter"
import TransportationCreateGuest from "../pages/transportation/pages/transportation/transportation-create"
import TransferForm from "../pages/transportation/pages/transfer/detail/transfer-form"
import TransferStepper from "../pages/transportation/pages/transfer/detail/transfer-stepper"
import RidesFilter from "../pages/transportation/pages/rides/rides-filter"
import RideStepper from "../pages/transportation/pages/rides/detail/ride-stepper"
import DriverEditComponent from "../pages/drivers/detail/driver-edit"
import componentLoader from "src/components/custom-suspense/loader"

const TransportationListComponent = lazy(() =>
  componentLoader(() => import("src/pages/transportation/pages/transportation/index"))
)
const RidesListComponent = lazy(() => componentLoader(() => import("src/pages/transportation/pages/rides/index")))
const RideExpensesListComponent = lazy(() =>
  componentLoader(() => import("src/pages/transportation/pages/expenses/transportation-expense"))
)

const filterTransferRequest = "request"
const filterTransferInProcess = "inprocess"
const filterTransferConfirmed = "confirmed"
const filterTransferCompleted = "completed"

export const transferRoutes: RouteObject[] = [
  {
    path: "transportation",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransportationListComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="all" relative="route" replace={false} />
      },
      {path: "all", element: <TransportationFilter request={""} />},
      {path: "request", element: <TransportationFilter request={filterTransferRequest} />},
      {path: "inprocess", element: <TransportationFilter request={filterTransferInProcess} />},
      {path: "completed", element: <TransportationFilter request={filterTransferCompleted} />}
    ]
  },
  {
    path: "transportation/:id",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransportationCreateGuest />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "transportation/:id/ride",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransferForm />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "transportation/:id/ride/:rideId",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransferForm />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "transportation/:id/timeline/:rideId",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransferStepper />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "cities",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransportationCities />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "cities/:cityId",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransferAddresses />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "cities/:cityId/address",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <AddressForm />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "cities/:cityId/address/:addressId",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <AddressForm />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "drivers",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransportationDrivers />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },

  {
    path: "drivers/:driverId",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <DriverProvider>
            <DriverDetailComponent />
          </DriverProvider>
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="info" relative="route" replace={true} />
      },
      {
        path: "info",
        element: (
          <CustomSuspense>
            <DriverInfoComponent />
          </CustomSuspense>
        )
      },
      {
        path: "rides",
        element: (
          <CustomSuspense>
            <DriverRidesComponent />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "drivers/:driverId/edit",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <DriverProvider>
            <DriverEditComponent />
          </DriverProvider>
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "drivers/:driverId/expenses",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <RideExpensesListComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "drivers/:driverId/expenses/expense/",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <ExpenseForm />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "drivers/:driverId/expenses/expense/:expenseId",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <ExpenseForm />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "database",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <TransportationDatabase />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "/car-info/:carId",
    element: (
      <ProtectedRoute accessKey="transportation">
        <CustomSuspense>
          <CarProvider>
            <CarsDetailComponent />
          </CarProvider>
        </CustomSuspense>
      </ProtectedRoute>
    )
  },

  //rides
  {
    path: "rides",
    element: (
      <ProtectedRoute accessKey="rides">
        <CustomSuspense>
          <RidesListComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {path: "", element: <Navigate to="request" relative="route" replace={false} />},
      {path: "request", element: <RidesFilter request={filterTransferRequest} />},
      {path: "confirmed", element: <RidesFilter request={filterTransferConfirmed} />},
      {path: "inprocess", element: <RidesFilter request={filterTransferInProcess} />},
      {path: "completed", element: <RidesFilter request={filterTransferCompleted} />}
    ]
  },
  {
    path: "rides/:rideId",
    element: (
      <ProtectedRoute accessKey="rides">
        <CustomSuspense>
          <RideStepper />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "rides/:rideId/finish",
    element: (
      <ProtectedRoute accessKey="rides">
        <CustomSuspense>
          <RideThanks />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "expenses",
    element: (
      <ProtectedRoute accessKey="rides">
        <CustomSuspense>
          <RideExpensesListComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "expenses/expense",
    element: (
      <ProtectedRoute accessKey="rides">
        <CustomSuspense>
          <ExpenseForm />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "expenses/expense/:expenseId",
    element: (
      <ProtectedRoute accessKey="rides">
        <CustomSuspense>
          <ExpenseForm />
        </CustomSuspense>
      </ProtectedRoute>
    )
  }
]
