import React, {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useFormContext} from "react-hook-form"
import Icon from "src/components/shared/components/material-icon"
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import {Link} from "react-router-dom"
import ClientEditComponent from "src/pages/clients/edit/clients-edit"
import {ClientModel} from "src/models/manager/clients/client.model"
import FormSelectAsyncPagination from "src/components/shared/inputs/form-select-async.pagination"
import clientService from "src/services/clients.service"
import {ImageField} from "src/components/shared/image-loader/image"
import profileImage from "src/assets/images/profile-icon.svg"
import {Drawer} from "antd"
import {WazzupFrameComponent} from "src/pages/integration/wazzup"
import {replaceAll} from "src/utils/replaceAll"
import {GroupBase, OptionProps} from "react-select"
import {SelectOption} from "src/components/shared/inputs/select/common"
import {ConfigContext} from "src/app"
import transportationService from "src/services/transportation.service"
import {TransferDetail} from "../../../../models/manager/transfer/transfer"

function ClientSelectOption(props: OptionProps<ClientModel, boolean, GroupBase<ClientModel>>) {
  return (
    <SelectOption {...props}>
      <div className="flex justify-between gap-2 items-center">
        <span>{props.data.name}</span>
        <span
          style={{
            backgroundColor: "var(--color-primary)",
            color: "var(--color-white)",
            borderRadius: 4,
            padding: 6
          }}>
          {props.data.phone}
        </span>
      </div>
    </SelectOption>
  )
}

const ClientForm = ({rideId, transfer}: {rideId: number; transfer: TransferDetail}) => {
  const {t} = useTranslation()
  const {integrationMap} = useContext(ConfigContext)

  const transferForm = useFormContext()
  const {setValue} = transferForm
  const [client, setClient] = useState<ClientModel>()

  const clientModalProps: ModalConfig = {
    size: "lg",
    backdropClick: false,
    component: (
      <ClientEditComponent
        showHeader={false}
        showWrapper={false}
        onCreate={(client) => {
          setClient(client)
          setValue("client", +client.id, {shouldDirty: true})
          transportationService.patch(rideId, {client: client.id})
          modalService.closeModal()
        }}
      />
    )
  }

  const getClient = async (id: number): Promise<ClientModel | null> => {
    const fetchedClient = await clientService.get(id)
    if (fetchedClient) {
      setClient(fetchedClient)
    }
    return fetchedClient
  }

  const onClientRemove = () => {
    setClient(null)
    setValue("client", null)
  }

  const [open, setOpen] = useState<boolean>()
  const onToggle = () => setOpen((p) => !p)

  useEffect(() => {
    return () => setClient(null)
  }, [rideId])

  return (
    <div className="card-wrapper flex flex-col gap-3 rounded-none md:rounded-lg shadow-none md:shadow flex-1 p-4 md:p-5 border-none md:border-gray-200">
      <div className="text-xl font-bold">{t("common.select.client")}</div>
      {client ? (
        <div className="flex flex-col rounded-lg bg-gray-70 w-full gap-6 p-4 flex-1 ">
          <div className="flex justify-between items-center gap-2">
            <div className="flex gap-3 items-center">
              <ImageField
                src={client.avatar}
                fallbackimage={profileImage}
                className="w-10 h-10 rounded-full bg-gray-5 0"
              />
              <span className="text-base font-bold flex-1">{client.name}</span>
            </div>
            <span className="p-2 rounded-lg bg-white w-8 h-8 cursor-pointer shadow" onClick={onClientRemove}>
              <Icon icon="close" className="text-base font-bold" />
            </span>
          </div>
          <div className="flex flex-col gap-2 justify-center">
            <div className="flex gap-2 items-end justify-between">
              <span className="text-sm text-gray-400 font-normal">{t("transfers.form.phone_number")}</span>
              <span className="flex-1 border-b-2 border-dashed border-gray-100"></span>
              <Link to={`tel:${client.phone}`} className="text-sm text-primary font-medium underline ">
                {client.phone}
              </Link>
            </div>
          </div>
          {integrationMap["wazzup"] && integrationMap["wazzup"].connected && client.phone && (
            <div className="btn btn-green-20 btn-color-green font-medium gap-2" onClick={onToggle}>
              <Icon icon="call" />
              Wazzup
            </div>
          )}
          <Drawer
            placement="left"
            size="large"
            open={open}
            onClose={onToggle}
            zIndex={1002}
            styles={{body: {padding: 0}, header: {padding: 12}}}>
            <WazzupFrameComponent filter={[{name: client.name, phone: replaceAll(client.phone, "+", "")}]} />
          </Drawer>
        </div>
      ) : (
        <div className="flex items-center gap-3 w-full">
          <FormSelectAsyncPagination<ClientModel>
            name="client"
            listOptions={(params) => clientService.list({...params, skip_loader: true})}
            getValue={getClient}
            filterOption={null}
            isSearchable={true}
            isClearable={false}
            getOptionLabel={(val: ClientModel) => (val ? String(val.name) : null)}
            getOptionValue={(val: ClientModel) => (val ? String(val.id) : null)}
            components={{Option: ClientSelectOption}}
            placeholder={t("common.select.placeholder.client")}
            className="flex-1"
            onChange={(selectedClient: ClientModel | null) => {
              if (!selectedClient || selectedClient.id === transfer.client?.id) return
              setClient(selectedClient)
              setValue("client", +selectedClient.id, {shouldDirty: true})
              transportationService.patch(rideId, {client: selectedClient.id})
            }}
          />
          <button className="btn btn-card" onClick={() => modalService.open(clientModalProps)}>
            <Icon icon="person_add" />
          </button>
        </div>
      )}
    </div>
  )
}

export default ClientForm
