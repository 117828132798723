import {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {useParams} from "react-router"
import transportationService from "src/services/transportation.service"
import {Dropdown, MenuProps} from "antd"
import Icon from "src/components/shared/components/material-icon"
import modalService from "src/components/modal/global/modal.service"
import TransferCityModal from "../../details/modal/transfer-city-modal"
import TransferAddressCard from "./detail/transfer-address-card"
import {useDebounce} from "src/hooks/useDebounce"
import {FormProvider, useForm} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import HeaderContent from "src/components/header/header-content"
import {ConfigContext} from "src/app"
import {useTranslation} from "react-i18next"
import {ConfirmModal} from "src/components/modal/global/confirmModal"

const TransferAddresses = () => {
  const [city, setCity] = useState(null)
  const [addresses, setAddresses] = useState(null)
  const navigate = useNavigate()
  const {cityId} = useParams()
  const {t} = useTranslation()
  const {isMobile} = useContext(ConfigContext)
  const form = useForm({
    defaultValues: {
      search: ""
    }
  })
  const {watch} = form
  const search = watch("search")
  const debouncedSearch = useDebounce(search, 500)

  const fetchCities = async () => {
    const cityList = await transportationService.getCity(+cityId)
    setCity(cityList)
  }

  const fetchAddresses = async () => {
    const addressList = await transportationService
      .listAddress({
        city: cityId,
        search: debouncedSearch
      })
      .then((data) => setAddresses(data.results))
    return addressList
  }
  const onDelete = () => {
    const onConfirm = () =>
      transportationService
        .deleteCity(+cityId)
        .then(() => navigate(-1))
        .then(() => transportationService.listCity())

    modalService.open({
      component: (
        <ConfirmModal
          message={t("transfers.action.are_you_sure_delete")}
          confirm_text={t("transfers.action.yes_delete")}
          onConfirm={onConfirm}
          delete={true}
        />
      )
    })
  }

  const items: MenuProps["items"] = [
    {
      key: "edit",
      onClick: () => {
        modalService.open({
          size: "sm",
          component: <TransferCityModal currentCity={city} fetchCities={fetchCities} cityId={cityId} />
        })
      },
      label: (
        <div className="flex items-center gap-2">
          <Icon icon="edit" /> <span>{t("common.edit")}</span>
        </div>
      )
    },
    {
      type: "divider"
    },

    {
      key: "delete",
      onClick: onDelete,
      label: (
        <div className="flex items-center gap-2">
          <Icon className="text-alert" icon="delete" /> <span>{t("common.delete")}</span>
        </div>
      )
    }
  ]

  useEffect(() => {
    fetchCities()
  }, [])

  useEffect(() => {
    fetchAddresses()
  }, [debouncedSearch])

  return (
    <FormProvider {...form}>
      <HeaderContent>
        <div className="flex flex-col justify-between header-content p-0">
          <div className="flex items-center justify-between gap-2 w-full px-4 py-3">
            <div className="flex gap-2 items-center cursor-pointer">
              <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />
              <div className="text-lg font-semibold md:text-2xl">{city && city.name}</div>
            </div>
            <div className="flex gap-4 items-center">
              <button
                className="btn btn-primary btn-color-white text-base font-medium leading-5"
                onClick={() => navigate("address")}>
                <span className="flex items-center gap-2">
                  <Icon icon="add" />
                  {t("common.add")}
                </span>
              </button>
              {isMobile ? (
                <div className="border p-3 rounded-lg">
                  <Dropdown menu={{items}} placement="bottomLeft" trigger={["click", "click"]} arrow={false}>
                    <Icon icon="more_horiz" />
                  </Dropdown>
                </div>
              ) : (
                <div className="flex gap-4 flex-row w-full md:w-auto py-2">
                  <button
                    className="btn border border-gray-400 btn-color-black text-base font-medium leading-5"
                    onClick={() =>
                      modalService.open({
                        size: "sm",
                        component: <TransferCityModal currentCity={city} fetchCities={fetchCities} cityId={cityId} />
                      })
                    }>
                    <span className="flex items-center gap-2">
                      <Icon icon="edit" /> {t("common.edit")}
                    </span>
                  </button>
                  <button className="btn btn-red btn-color-white" onClick={onDelete}>
                    <span className="flex items-center gap-2 ">
                      <Icon icon="delete" />
                      {t("common.delete")}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="w-full p-3 pt-0">
            <FormControl
              icon="search"
              rootclassname="w-full"
              className="form-control"
              name="search"
              placeholder={t("common.input.search")}
            />
          </div>
        </div>
      </HeaderContent>
      <div className="-mx-4 -mt-3 md:mx-0 md:mt-0">
        <TransferAddressCard addresses={addresses} />
      </div>
    </FormProvider>
  )
}

export default TransferAddresses
