import {useMemo, useRef, useState} from "react"
import {useNavigate, useOutletContext, useParams} from "react-router"
import Icon from "src/components/shared/components/material-icon"
import {useContainerDimensions} from "src/hooks/useContainerDimensions"
import {OrderRequestDeliveryDetailModel} from "src/models/manager/order/order-request-delivery.model"
import HeaderContent from "src/components/header/header-content"
import {ImageField} from "src/components/shared/image-loader/image"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import {OrderRequestInventoryModel} from "src/models/manager/order/order-request-inventory"
import deliveryService from "src/services/delivery.service"
import clsx from "clsx"

function DeliveryInventoryListComponent() {
  const {delivery} = useOutletContext<{
    delivery: OrderRequestDeliveryDetailModel
  }>() || {delivery: undefined}
  const groups: Array<InventoryGroupModel & {inventories: OrderRequestInventoryModel[]}> = useMemo(() => {
    if (!delivery) return []
    return delivery.groups.map((group) => ({
      ...group,
      inventories: delivery.inventories.filter(
        (orderInv) => orderInv.inventory && orderInv.inventory.group === group.id
      )
    }))
  }, [delivery])

  return (
    delivery && (
      <div className="flex flex-col gap-4">
        <div className="text-primary">
          <span className="font-medium">{delivery.inventories.length} товара</span> в заказе
        </div>

        <div className="flex flex-col gap-3">
          {groups.map((group) => (
            <div
              key={group.id}
              className="flex flex-row items-start gap-3 pb-3 border-b border-gray-100 last:border-none">
              <div className="relative min-w-12 min-h-12">
                <ImageField src={group.image} className="bg-gray-100 w-12 h-12 object-contain rounded-lg" />
                <div className="absolute font-medium bg-white p-[2px] rounded -bottom-1 -right-1 shadow">
                  x{group.inventories.length}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-medium text-base">{group.name}</div>
                <div className="text-gray-400 text-base">{group.unique_id}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  )
}

export function DeliveryCheckoutComponent() {
  const ref = useRef(null)
  const btnRef = useRef(null)
  const startX = useRef(null)
  const navigate = useNavigate()
  const {orderId, id} = useParams()

  const [loading, setLoading] = useState(false)
  const [dragOffset, setDragOffset] = useState(0)
  const {delivery, getDelivery} = useOutletContext<{
    delivery: OrderRequestDeliveryDetailModel
    getDelivery: () => Promise<void>
  }>() || {delivery: undefined, getDelivery: () => Promise.reject()}

  const {width: wrapperWidth} = useContainerDimensions(ref, delivery)
  const {width: btnWidth} = useContainerDimensions(btnRef, delivery)

  const onSubmit = async () => {
    await deliveryService.start(+orderId, +id)
    await getDelivery()
    navigate(`/orders/${orderId}/deliveries/${id}`)
  }

  const handleTouchStart = (e) => {
    if (loading) return
    startX.current = e.touches[0].clientX
  }

  const handleTouchMove = (e) => {
    if (loading) return
    const currentX = e.touches[0].clientX
    const offset = currentX - startX.current
    const _dragOffset = Math.min(Math.max(0, offset), wrapperWidth - 16 - btnWidth)
    btnRef.current.style.width = _dragOffset + btnWidth
    setDragOffset(_dragOffset)
  }

  const handleTouchEnd = () => {
    if (wrapperWidth - 16 <= dragOffset + btnWidth) {
      setDragOffset(wrapperWidth - 16 - btnWidth)
      setLoading(true)
      onSubmit()
    } else {
      setDragOffset(0)
    }
  }

  const onBack = () => navigate(`/orders/${orderId}/deliveries/${id}`)

  return (
    delivery && (
      <>
        <HeaderContent>
          <div className="header-content flex gap-2 justify-between items-center w-full">
            <Icon icon="chevron_left" className="min-w-4 min-h-4 cursor-pointer text-2xl leading-4" onClick={onBack} />
            <div className="text-xl font-semibold justify-self-center text-nowrap">Проверьте и начните заказ</div>
            <div className="min-w-8 min-h-8"></div>
          </div>
        </HeaderContent>

        <DeliveryInventoryListComponent />

        <div
          ref={ref}
          className="fixed flex items-center w-[calc(100%_-_16px)] left-2 right-2 px-2 shadow-menu max-h-[72px] min-h-[72px] bottom-3 z-[100] rounded-full bg-gray-100 p-2">
          {loading ? (
            <div className="flex gap-3 items-center justify-center p-4 h-[56px] rounded-full bg-white opacity-50 font-medium text-base w-full">
              <Icon icon="circle" />
              <span>Процесс в обработке</span>
            </div>
          ) : (
            <div
              ref={btnRef}
              className={clsx(
                "flex gap-3 items-center justify-end p-4 h-[56px] rounded-full bg-primary text-white font-medium text-base"
              )}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}>
              <div style={{width: dragOffset}}></div>
              <span className="text-nowrap">Начать доставку</span>
              <Icon icon="arrow_forward" />
            </div>
          )}
        </div>
      </>
    )
  )
}
