import clsx from "clsx"
import {InputHTMLAttributes, SyntheticEvent, useState} from "react"
import {RegisterOptions, useFormContext} from "react-hook-form"
import Icon from "src/components/shared/components/material-icon"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  icon?: string
}

export function FormControlPassword(config: FormControlConfig) {
  const {register, formState: {errors}} = useFormContext()
  const error = errors[config.name]
  const [show, setShow] = useState(false)
  const className = clsx(config.className, error && "invalid")

  const togglePassword = (e: SyntheticEvent) => {
    e.stopPropagation()
    setShow((prev) => !prev)
  }

  return (
    <div className={clsx(config.rootclassname)}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] text-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}

      <div className="form-control-group">
        <input
          {...config}
          {...register(config.name, config.params)}
          type={show ? "text" : "password"}
          className={className}
          autoComplete="off"
        />
        <Icon
          className="form-control-group-icon cursor-pointer"
          onClick={togglePassword}
          icon={show ? "visibility" : "visibility_off"}
        />
      </div>
    </div>
  )
}
