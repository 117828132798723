import {BehaviorSubject} from "rxjs"
import funnelService, {Stage} from "src/services/crm/funnel.service"

class StageStore {
  private stagesSubject = new BehaviorSubject<Stage[]>([])
  public stages$ = this.stagesSubject.asObservable()

  private funnelId: number | null = null

  setFunnelId(id: number | null) {
    this.funnelId = id
    if (id !== null) this.fetchStages()
  }

  async fetchStages() {
    if (!this.funnelId) return
    const stages = await funnelService.getStages(this.funnelId)
    this.stagesSubject.next(stages)
  }

  async updateStage(stageId: number, payload: Partial<Stage>) {
    if (!this.funnelId) return
    await funnelService.putStage(this.funnelId, stageId, payload)
    this.fetchStages()
  }

  async deleteStage(stageId: number) {
    if (!this.funnelId) return
    await funnelService.deleteStage(this.funnelId, stageId)
    this.fetchStages()
  }

  async reorderStage(stageId: number, newOrdering: number) {
    if (!this.funnelId) return
    await funnelService.reOrder(this.funnelId, stageId, {new_ordering: newOrdering})
    setTimeout(() => this.fetchStages(), 300)
  }

  async createStage(payload: Partial<Stage>) {
    if (!this.funnelId) return
    await funnelService.postStages(this.funnelId, payload)
    this.fetchStages()
  }
}

export const stageStore = new StageStore()
