import {useEffect, useState} from "react"
import {Controller, useFormContext} from "react-hook-form"
import TransferDriver from "src/components/shared/components/select/transfer-driver"
import CustomSelect from "src/components/shared/inputs/select/select"
import driverService from "src/services/driver.service"
import {colourStyles} from "src/components/shared/inputs/form-select-color-style"
import {InventoryModel} from "src/models/manager/inventory/inventory.model"
import {useTranslation} from "react-i18next"
import clsx from "clsx"

const DriverSelection = ({className, disabled}: {className?: string; disabled?: boolean}) => {
  const {setValue, watch} = useFormContext()
  const [inventorySelected, setInventorySelected] = useState<InventoryModel>(undefined)
  const [inventoryOptions, setInventoryOptions] = useState<InventoryModel[]>([])
  const userId = watch("driver_user_id")
  const inventoryId = watch("driver_inventory_id")
  const {t} = useTranslation()

  useEffect(() => {
    if (userId) fetchDriverData(userId)
    return () => {
      setInventoryOptions([])
      setInventorySelected(undefined)
    }
  }, [userId])

  const fetchDriverData = async (userId: number) => {
    const driverData = await driverService.getDriver(userId)
    const drivers: Array<{id: number; user: number; inventory: number}> = driverData.drivers
    const options: InventoryModel[] = driverData.inventories.map((inv) => ({
      ...inv,
      driver_id: drivers.find((d) => +d.inventory === +inv.id && +d.user === +userId)?.id
    }))

    setInventoryOptions(options)
    if (inventoryId) setInventorySelected(options.find((inv) => +inv.id === +inventoryId))
  }

  return (
    <div
      className={clsx(
        "card-wrapper flex flex-col gap-3 rounded-none md:rounded-lg shadow-none md:shadow flex-1 p-4 md:p-5 border-none md:border-gray-200",
        className
      )}>
      <div className="text-xl font-bold">Driver</div>
      <div className="flex flex-col gap-3 w-full">
        <TransferDriver
          className="flex-1"
          addOption
          label={t("transfers.main.driver")}
          placeholder={t("transfers.form.choose_driver")}
          name="driver_user_id"
          isClearable={true}
          isSearchable={true}
          required
          params={{required: t("transfers.form.choose_driver")}}
          onChange={() => {
            setValue("driver_inventory_id", null)
            setValue("driver", null)
          }}
          disabled={disabled}
        />

        <Controller
          name="driver"
          rules={{required: true}}
          render={({field, fieldState, formState}) => (
            <CustomSelect
              name="driver"
              label={t("transfers.main.car")}
              placeholder={t("transfers.form.choose_car")}
              className={clsx("flex-1")}
              styles={colourStyles(!!fieldState.error)}
              getOptionLabel={(option: InventoryModel) => (option ? `${option.name} (${option.unique_id})` : null)}
              getOptionValue={(option: InventoryModel) => (option ? String(option.id) : null)}
              options={inventoryOptions}
              value={inventorySelected}
              onChange={(val) => {
                if (val) {
                  setValue("driver", val.driver_id, {shouldValidate: true, shouldDirty: true})
                  setValue("driver_inventory_id", val.id, {shouldValidate: true, shouldDirty: true})
                } else {
                  setValue("driver", null, {shouldValidate: true, shouldDirty: true})
                  setValue("driver_inventory_id", null, {shouldValidate: true, shouldDirty: true})
                }
                setInventorySelected(val)
              }}
              required
              isClearable={true}
              isSearchable={true}
              key={inventoryOptions.length}
              isDisabled={inventoryOptions.length === 0}
            />
          )}
        />
      </div>
    </div>
  )
}

export default DriverSelection
