import clsx from "clsx"
import Icon from "../shared/components/material-icon"
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import {TourStepItem} from "../../mock/tour-checklist"
import {useNavigate} from "react-router-dom"
import {ConfigContext} from "../../app"

export interface TourStepProps {
  step: TourStepItem
  setOpenTourChecklist: Dispatch<SetStateAction<boolean>>
}
export default function TourStep({step, setOpenTourChecklist}: TourStepProps) {
  const navigate = useNavigate()

  return (
    <div
      className={clsx(
        "flex justify-between items-center border p-4 rounded-lg"
        // open ? "border-primary" : "border-gray-200",
      )}>
      <div className="flex gap-4">
        <div
          className={clsx(
            "h-6 w-6 rounded-full flex items-center justify-center p-0.5",
            step.checked ? "bg-[#52C41A]" : "border border-gray-200"
          )}>
          {step.checked && <Icon icon={"check_small"} className={clsx("text-xl color-white")} />}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <p className={clsx("font-medium text-[16px] ", step.checked ? "text-gray-350 line-through" : "text-black")}>
              {step.title}
            </p>
          </div>
        </div>
      </div>
      <p
        onClick={() => {
          if (!step.checked) {
            navigate(step.link)
            setOpenTourChecklist(false)
          }
        }}
        className={clsx("font-medium text-xs cursor-pointer", step.checked ? "text-gray-350" : "text-primary")}>
        Перейти
      </p>
    </div>
  )
}
