import {useContext} from "react"
import {FormProvider, useForm} from "react-hook-form"
import Table from "src/components/shared/antd-custom/table"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {ConfigContext} from "src/app"
import {ProductSetContext} from "./context"
import type {ColumnsType} from "antd/es/table"
import {useTranslation} from "react-i18next"
import {ImageField} from "src/components/shared/image-loader/image"
import clsx from "clsx"
import {InventorySetItemModel} from "src/models/manager/inventory/inventory-set.model"

const ProductSetPartsComponent = () => {
  const form = useForm()
  const {t} = useTranslation()
  const {set} = useContext(ProductSetContext)
  const {constantsMap} = useContext(ConfigContext)

  const columns: ColumnsType<InventorySetItemModel> = [
    {
      width: 220,
      title: t("groups.table.name"),
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div className="flex gap-2 items-center">
          <ImageField
            className=" pointer-events-none object-contain rounded-lg min-w-12 w-12 h-12 p-2 bg-gray-70"
            src={record.group_image}
          />
          <span className="font-medium">{record.group_name}</span>
        </div>
      )
    },
    {
      width: 120,
      title: t("set.table.unique_id"),
      dataIndex: "group_unique_id",
      key: "group_unique_id",
      render: (unique_id) => unique_id
    },
    {
      width: 120,
      title: t("set.table.category"),
      dataIndex: "group_category",
      key: "group_category",
      render: (category) => constantsMap.INVENTORY_CATEGORIES[category]?.name,
      sorter: true
    },
    {
      width: 90,
      title: t("groups.table.availability.column"),
      dataIndex: "inventories_count",
      key: "inventories_count",
      className: "p-2",
      render: (_, item) => (
        <div
          className={clsx(
            "text-[13px] px-[9px] py-2 rounded-lg bg-opacity-10 font-medium min-w-32 w-max",
            item.available_cnt === 0 ? "text-alert bg-alert" : "text-accept bg-accept"
          )}>
          <span>{item.available_cnt}</span>/<span>{item.inventories_cnt}</span>
          <span> {t("groups.table.availability.free")}</span>
        </div>
      ),
      sorter: true
    }
  ]

  return (
    <FormProvider {...form}>
      <Table
        rowKey={(obj) => obj.id}
        columns={columns}
        autoScroll={false}
        rootClassName="w-full"
        dataSource={set.items}
      />

      <PaginationFormComponent count={set.items.length} />
    </FormProvider>
  )
}

export default ProductSetPartsComponent
