import {useMemo} from "react"

const colors = ["#fa541c", "#fa8c16", "#a0d911", "#13c2c2", "#1890ff", "#722ed1", "#eb2f96"]

function generatePastelColor<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)]
}

const defaultStyle = (size: number) => ({
  width: size,
  height: size,
  minWidth: size,
  maxWidth: size,
  borderRadius: "50%",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: size * 0.4,
  fontWeight: "bold"
})

const Avatar = ({name = "", size = 20}: {name: string; size?: number}) => {
  const {initials, style} = useMemo(() => {
    const randomColor = generatePastelColor(colors)
    let ret = {initials: "AB", style: {...defaultStyle(size), backgroundColor: randomColor}}

    const parts = name.split(" ").filter((p) => p.length > 0)
    if (parts.length === 0) return ret
    if (parts.length === 1) return {...ret, initials: parts[0][0]?.toUpperCase() ?? "AB"}

    return {...ret, initials: parts[0][0]?.toUpperCase() + parts[1][0]?.toUpperCase() || "AB"}
  }, [name])

  return <div style={style}>{initials}</div>
}

export default Avatar
