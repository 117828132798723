import {PopoverContentProps, StepType} from "@reactour/tour"
import TourTooltip from "../components/shared/components/tour-tooltip"
import Icon from "../components/shared/components/material-icon"
import modalService from "../components/modal/global/modal.service"
import VideoModal from "../components/shared/components/video-modal"

const tourSteps: StepType[] = [
  {
    selector: "#step1",
    content: (props: PopoverContentProps) => {
      if (props?.transition) return
      return (
        <TourTooltip
          title="Создайте аренду"
          subTitle="Нажмите на кнопку «Новая аренда», чтобы перейти к работе с арендой"
        />
      )
    }
  },
  {
    selector: "#step2",
    resizeObservables: ["#step2"],
    content: (props: PopoverContentProps) => {
      if (props?.transition) return
      return <TourTooltip title="Добавьте клиента" subTitle="Выберите клиента через поиск" />
    }
  },
  {
    selector: "#step3",
    resizeObservables: ["#step3"],
    content: (props) => {
      if (props?.transition) return
      return <TourTooltip title="Добавьте продукт" subTitle="Нажмите на кнопку «Добавить»" />
    }
  },
  {
    selector: "#step4",
    content: () => null
  },
  {
    selector: "#step5",
    content: (props: PopoverContentProps) => {
      if (props?.transition) return
      return <TourTooltip title="Забронируйте аренду" subTitle="Управляйте статусом аренды в панели справа" />
    }
  },
  {
    selector: "#step6",
    content: (props: PopoverContentProps) => {
      if (props?.transition) return
      return (
        <TourTooltip title="Получите оплату" subTitle="Введите сумму и выберите один или несколько способов оплаты" />
      )
    }
  },
  {
    selector: "#step7",
    content: (props: PopoverContentProps) => {
      if (props?.transition) return
      return <TourTooltip title="Выберите способ оплаты" subTitle="Выберите любой способ для тестовой аренды" />
    }
  },
  {
    selector: "#step8",
    content: (props: PopoverContentProps) => {
      if (props?.transition) return
      return (
        <TourTooltip
          title="Подпишите документы"
          subTitle="Узнайте, как работают интеграции подписания документов SMS и Egov"
          skip={true}
          nextStep={8}
          skipStep={9}
          currentTour="rental_control"
          custom_button={
            <button
              className="flex items-center gap-2 btn btn-white"
              onClick={() =>
                modalService.open({
                  id: "step9",
                  size: "lg",
                  component: <VideoModal nextStep={9} />,
                  removable: false,
                  disableClose: true,
                  backdropClick: false
                })
              }>
              <Icon icon="play_arrow" filled={true} className="text-[18px]" />
              <p className="font-medium text-xs text-black">Посмотреть видео</p>
            </button>
          }
        />
      )
    }
  },
  {
    selector: "#step9",
    content: () => null
  },
  {
    selector: "#step10",
    content: (props) => {
      if (props?.transition) return
      return <TourTooltip title="Отметьте продукты" subTitle="Выберите продукты, которые хотите выдать в аренду" />
    }
  },
  {
    selector: "#step11",
    content: (props) => {
      if (props?.transition) return
      return <TourTooltip title="Выдайте в аренду" subTitle="Управляйте статусом аренды в панели справа" />
    }
  },
  {
    selector: "#step12",
    content: (props) => {
      if (props?.transition) return
      return <TourTooltip title="Отметьте продукты" subTitle="Выберите продукты, которые хотите принять с аренды" />
    }
  },
  {
    selector: "#step13",
    content: (props) => {
      if (props?.transition) return
      return <TourTooltip title="Получите товары" subTitle="Управляйте статусом аренды в панели справа" />
    }
  },
  {
    selector: "#step14",
    content: () => null
  },
  {
    selector: "#step15",
    content: (props) => {
      if (props?.transition) return
      return (
        <TourTooltip title="Перейти к общему списку" subTitle="Чтобы увидеть все аренды, нажмите на кнопку «Аренды»" />
      )
    }
  },
  {
    selector: "#step16",
    content: (props) => {
      if (props?.transition) return
      return (
        <TourTooltip
          title="Список аренд"
          subTitle="Здесь отображается последняя созданная вами аренда"
          currentTour="rental_control"
          nextButton={true}
        />
      )
    }
  },
  {
    selector: "#step17",
    content: (props) => {
      if (props?.transition) return
      return (
        <TourTooltip
          title="Фильтр по статусам"
          subTitle="Подробную информацию о статусах аренд можно найти в Базе знаний"
          currentTour="rental_control"
          finish={true}
        />
      )
    }
  },
  {
    selector: "#step18",
    content: (props) => {
      if (props?.transition) return
      return (
        <TourTooltip
          title="Вы готовы к работе!"
          subTitle="Удалите демо данные и переходите к настройке своего аккаунта"
        />
      )
    },
    position: "bottom"
  }
]

export default tourSteps
