import {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react"
import {OrderContext} from "../../context/order-context"
import OrderDetailInventoryListComponent from "./inventory-add"
import {useTranslation} from "react-i18next"
import SharedInventoryGroupListComponent from "src/components/shared/components/group"
import {Segmented} from "src/components/shared/antd-custom"
import {ConfigContext} from "src/app"
import Icon from "../../../../../components/shared/components/material-icon"
import {useNavigate} from "react-router-dom"
import HeaderContent from "../../../../../components/header/header-content"
import {ProductType} from "src/models/setting-config"
import clsx from "clsx"

const defaultTypes: ProductType[] = [ProductType.GROUP, ProductType.SALE, ProductType.INVENTORTY]

export default function OrderInventoryAddList({hideModal}: {hideModal?: Dispatch<SetStateAction<boolean>>}) {
  const [page, setPage] = useState<ProductType>((localStorage.getItem("main_section") as ProductType) || ProductType.GROUP)
  const {order, orderForm, inventoriesIds, onSelect, onGroup} = useContext(OrderContext)
  const {t} = useTranslation()
  const {settings, tenant} = useContext(ConfigContext)
  const {isMobile, hideMobileMenu, showMobileMenu} = useContext(ConfigContext)
  const navigate = useNavigate()

  const allowedPages: ProductType[] = useMemo(() => {
    if (!settings) return defaultTypes
    if (!settings.rent_product_types) return defaultTypes.filter(key => {
      if (key === ProductType.SALE) return tenant.sale_enabled
      return true
    })
    return settings.rent_product_types.filter(key => {
      if (key === ProductType.SALE) return tenant.sale_enabled
      return true
    })
  }, [settings])

  const pages: Record<ProductType, string> = {
    [ProductType.GROUP]: t("orders.sections.group"),
    [ProductType.SALE]: t("orders.sections.sales"),
    [ProductType.INVENTORTY]: t("orders.sections.inventory")
  }

  const params = useMemo(
    () => ({
      rental_point: order.rental_point,
      start_at: orderForm.watch("rent_start"),
      end_at: orderForm.watch("rent_end"),
      exclude_ids: inventoriesIds.join(",")
    }),
    [inventoriesIds, order.rental_point, orderForm]
  )

  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  return (
    <div className="-m-4 md:m-0">
      {isMobile && (
        <HeaderContent>
          <div className="gap-1 header-content">
            <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />
            <p className="font-bold text-xl text-black">Продукты</p>
          </div>
        </HeaderContent>
      )}

      <Segmented
        value={page}
        onChange={(option: ProductType) => {
          setPage(option)
          localStorage.setItem("main_section", option)
        }}
        className={clsx(
          "w-full md:w-max self-center rounded-md bg-white md:bg-gray-100 h-full",
          "md:h-[inherit] px-4 py-3 md:px-1 md:py-1 overflow-x-visible mb-0 md:mb-3",
          "rounded-none md:rounded-md"
        )}
        options={(allowedPages.length === 0 ? defaultTypes : allowedPages).map((id: ProductType) => ({
          value: id,
          label: pages[id],
          className: isMobile
            ? `${id === page ? "bg-black text-white" : "bg-gray-70 text-black"} flex justify-center items-center first-of-type:mr-2  last-of-type:ml-2  min-h-10 text-[13px] font-medium rounded-[32px]`
            : "p-1 font-medium text-black"
        }))}
      />

      {page === ProductType.GROUP && (
        <SharedInventoryGroupListComponent
          params={{...params, type: 0}}
          onSelect={onGroup}
          fields={["name", "unique_id", "category", "inventories_count-2", "group_action"]}
          hideModal={hideModal}
        />
      )}
      {page === ProductType.SALE && (
        <SharedInventoryGroupListComponent
          params={{...params, type: 1}}
          hideModal={hideModal}
          onSelect={onGroup}
        />
      )}
      {page === ProductType.INVENTORTY && (
        <OrderDetailInventoryListComponent
          filter={params}
          onSelect={onSelect}
        />
      )}
    </div>
  )
}
