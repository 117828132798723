import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"

import {IntegrationComponent} from "src/pages/integration"
import {SmsIntegrationComponent} from "src/pages/integration/sms"
import {WazzupFrameComponent, WazzupLandingComponent} from "src/pages/integration/wazzup"
import {EgovIntegrationLanding} from "src/pages/integration/egov"
import {GPSIntegrationLanding} from "src/pages/integration/gps"
import {ProtectedRoute} from "./guard"
import {RingostatHistory} from "../pages/integration/ringostat/history"
import componentLoader from "src/components/custom-suspense/loader"

const WidgetWrapperComponent = lazy(() => componentLoader(() => import("src/pages/widget/products/wrapper")))
const WidgetCategoryList = lazy(() => componentLoader(() => import("src/pages/widget/categories")))
const ProductPublishedList = lazy(() => componentLoader(() => import("src/pages/widget/products/product-list")))
const WebsiteArticles = lazy(() => componentLoader(() => import("src/pages/widget/artices/list")))
const WebsiteEvents = lazy(() => componentLoader(() => import("src/pages/widget/events/list")))

const integrationRoutes: RouteObject[] = [
  {
    path: "integrations",
    element: <ProtectedRoute accessKey="integrations"><IntegrationComponent /></ProtectedRoute>
  },
  {
    path: "integrations/ringostat",
    element: <ProtectedRoute accessKey="integrations.ringostat"><RingostatHistory /></ProtectedRoute>
  },
  {
    path: "integrations/sms",
    element: <ProtectedRoute accessKey="integrations.sms"><SmsIntegrationComponent /></ProtectedRoute>
  },
  {
    path: "integrations/gps",
    element: <ProtectedRoute accessKey="integrations.gps"><GPSIntegrationLanding /></ProtectedRoute>
  },
  {
    path: "integrations/wazzup",
    element: <ProtectedRoute accessKey="integrations.wazzup"><WazzupLandingComponent /></ProtectedRoute>
  },
  {
    path: "integrations/egov",
    element: <ProtectedRoute accessKey="integrations.egov"><EgovIntegrationLanding /></ProtectedRoute>
  },
  {
    path: "integrations/widget",
    element: (
      <ProtectedRoute accessKey="integrations.widget">
        <CustomSuspense>
          <WidgetWrapperComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {path: "", element: <Navigate to="products" replace={false} />},
      {
        path: "products",
        element: (
          <CustomSuspense>
            <ProductPublishedList />
          </CustomSuspense>
        )
      },
      {
        path: "categories",
        element: (
          <CustomSuspense>
            <WidgetCategoryList />
          </CustomSuspense>
        )
      },
      {
        path: "articles",
        element: (
          <CustomSuspense>
            <WebsiteArticles />
          </CustomSuspense>
        )
      },
      {
        path: "events",
        element: (
          <CustomSuspense>
            <WebsiteEvents />
          </CustomSuspense>
        )
      }
    ]
  },
  {path: "integrations/*", element: <>not found</>},
  {path: "wazzup", element: <WazzupFrameComponent />}
]

export default integrationRoutes
