import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {BehaviorSubject, Observable, of, shareReplay, switchMap} from "rxjs"
import authService from "../auth.service"
import {getAPI} from "../../interceptor"

export interface Funnel {
  id: number
  name: string
  state: []
  created_at: string
  extra: {}
  created_by: number
}
export interface FunnelMethods {
  id: number
  name: string
  state: []
  created_at: string
  extra: {}
  created_by: number
}

export interface Stage {
  id: number
  name: string
  funnel: number
  ordering: number
  created_at: string
  type: "DEFAULT" | "SUCCESS" | "CANCEL"
  color: string
  leads: any[]
  extra: Record<string, any>
}

export class FunnelService extends ApiAbstract {
  constructor() {
    super("v1/sales/funnels")
  }

  private loadFunnels$: BehaviorSubject<void> = new BehaviorSubject<void>(null)
  loadFunnels = () => this.loadFunnels$.next()

  public funnels$: Observable<Funnel[]> = authService.authorized$.pipe(
    switchMap((logged) =>
      logged
        ? this.loadFunnels$.pipe(
            switchMap(() =>
              getAPI<Funnel[]>(axios.get(this.getUrl(), {params: {skip_error_handling: true, skip_loader: true}}))
            )
          )
        : of([])
    ),
    shareReplay(1)
  )

  public async list(): Promise<Funnel[]> {
    const response = await axios.get(this.getUrl())
    return response.data
  }
  public async post(payload: any): Promise<Funnel> {
    const response = await axios.post(this.getUrl(), payload)
    this.loadFunnels()
    return response.data
  }
  public async getMethods(id?: number): Promise<FunnelMethods> {
    const response = await axios.get(this.getUrl(`${id}/methods`))
    return response.data
  }
  public async postMethods(id?: number, payload?: any): Promise<FunnelMethods> {
    const response = await axios.post(this.getUrl(`${id}/methods`), payload)
    return response.data
  }
  public async getStages(id?: number): Promise<Stage[]> {
    const response = await axios.get(this.getUrl(`${id}/stages`))
    return response.data
  }
  public async postStages(id?: number, payload?: any): Promise<FunnelMethods> {
    const response = await axios.post(this.getUrl(`${id}/stages`), payload)
    return response.data
  }

  public async getStage(id?: number, stageId?: number): Promise<Stage> {
    const response = await axios.get(this.getUrl(`${id}/stages/${stageId}`))
    return response.data
  }
  public async postStage(id?: number, stageId?: number, payload?: any): Promise<Stage> {
    const response = await axios.post(this.getUrl(`${id}/stages/${stageId}`), payload)
    return response.data
  }
  public async putStage(id?: number, stageId?: number, payload?: any): Promise<Stage> {
    const response = await axios.patch(this.getUrl(`${id}/stages/${stageId}`), payload)
    return response.data
  }

  public async deleteStage(id?: number, stageId?: number): Promise<Stage> {
    const response = await axios.delete(this.getUrl(`${id}/stages/${stageId}`))
    return response.data
  }
  public async reOrder(id?: number, stageId?: number, payload?: any): Promise<Stage> {
    const response = await axios.post(this.getUrl(`${id}/stages/${stageId}/reorder`), payload)
    return response.data
  }
}

const funnelService = new FunnelService()
export default funnelService
