import {FormControl} from "src/components/shared/inputs/form-control"
import Icon from "src/components/shared/components/material-icon"
import TrasfersRideCard from "../../pages/transfer/detail/trasfers-ride-card"
import {FormProvider, useForm} from "react-hook-form"
import React, {useContext, useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import modalService from "src/components/modal/global/modal.service"
import {createPortal} from "react-dom"
import {useNavigate} from "react-router-dom"
import {EMPTY_LIST, ListModel} from "src/models/common"
import transportationService from "src/services/transportation.service"
import {Transfer} from "src/models/manager/transfer/transfer"
import {useDebounce} from "src/hooks/useDebounce"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {isNil} from "src/utils/isNil"
import useOnScreen from "src/hooks/useOnScreen"
import TransferMainFilterModal from "../../details/modal/transfer-main-filter"
import TransportationListTableComponent from "./detail/transportation-table-component"
import MultimodeDatePicker from "src/components/shared/antd-custom/multimode-range-picker"
import TransferAgent from "src/components/shared/components/select/transfer-agent"
import SmoothOperator from "src/components/shared/components/button/action/smooth-operator"

export default function TransportationFilter(props: {request: string}) {
  const {request} = props
  const currentRoute = useCurrentRoute()
  const form = useForm({
    defaultValues: {
      search: "",
      page: 1,
      pageSize: 10,
      status: request
    }
  })
  const elementRef = useRef<HTMLDivElement>()
  const isOnScreen = useOnScreen(elementRef)
  const {reset, watch} = form
  const {t} = useTranslation()
  const values = watch()
  const {isMobile} = useContext(ConfigContext)
  const navigate = useNavigate()
  const ignoreNextWatchRef = useRef(false)

  const [list, setList] = useState<ListModel<Transfer>>(EMPTY_LIST)
  const searchDebounce = useDebounce(watch("search"), 1000)

  const onLoad = async (params: any): Promise<void> => {
    const response = await transportationService.list(params)
    setList((prev) => ({
      ...response,
      results: params?.page === 1 ? response.results : [...prev.results, ...response.results]
    }))
  }

  const createTransfer = async () => {
    const response = await transportationService.post({})
    if (response?.id) {
      navigate(`/transportation/${response.id}`)
    }
  }

  useEffect(() => {
    reset({...values, page: 1, search: searchDebounce})
  }, [searchDebounce])

  useEffect(() => {
    reset({...values, page: 1, status: isNil(request) ? null : request})
  }, [currentRoute])

  useEffect(() => {
    onLoad({...values, status: isNil(request) ? null : request})
    const sub = watch((params, {name}) => {
      if (ignoreNextWatchRef.current) {
        ignoreNextWatchRef.current = false
        return
      }
      if (["search", "transfer__agent", "start_at__gt", "start_at__lte"].includes(name)) {
        const updatedParams = {...params, page: 1}
        ignoreNextWatchRef.current = true
        reset(updatedParams)
        onLoad(updatedParams)
      } else {
        onLoad(params)
      }
    })
    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [])

  useEffect(() => {
    if (isOnScreen && list.next !== false && !!list.next) {
      reset({
        ...values,
        page: values.page + 1
      })
    }
  }, [isOnScreen])

  const onShowModal = () => {
    modalService.open({
      size: "lg",
      component: <TransferMainFilterModal form={form} />
    })
  }

  return (
    <FormProvider {...form}>
      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0">
        <div className="flex gap-2 justify-between">
          <div
            className={`w-full p-4 md:p-0 border-t md:border-t-0 md:w-auto bg-white md:bg-transparent md:flex md:gap-2 mb-3`}>
            {!isMobile && (
              <>
                <FormControl
                  icon="search"
                  rootclassname="col"
                  className="form-control lg:flex-1 lg:min-w-[320px]"
                  name="search"
                  placeholder={t("transfers.input.search")}
                />
                <TransferAgent
                  className="col"
                  placeholder={t("transfers.form.customer")}
                  name="agent"
                  isClearable={true}
                  isSearchable={false}
                  required
                />
              </>
            )}
            <MultimodeDatePicker name_start="start_at__gt" name_end="start_at__lte" />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {isMobile && list.results.map((transfer, index) => <TrasfersRideCard key={index} transfer={transfer} />)}
        <div ref={elementRef}></div>
      </div>

      {!isMobile && (
        <div>
          <TransportationListTableComponent list={list} />
          <div ref={elementRef}></div>
        </div>
      )}

      {isMobile &&
        createPortal(
          <button
            className="fixed z-[100]  bottom-20 md:bottom-10 right-4 h-14 w-14 rounded-full bg-primary border border-white border-opacity-20 shadow-[0_0_16px_0_rgba(0, 0, 0, 0.16)]"
            style={{padding: 10, overflow: "hidden", width: "100%"}}
            onClick={() => createTransfer()}>
            <Icon icon="add" className={"text-white text-2xl"} />
          </button>,
          document.body
        )}

      <SmoothOperator />
    </FormProvider>
  )
}
