import React, {InputHTMLAttributes, useId} from "react"
import {FieldError, RegisterOptions, useFormContext} from "react-hook-form"
import {getNestedObject} from "src/utils/get-nested-object"
import {isNil} from "src/utils/isNil"
import Icon from "src/components/shared/components/material-icon"
import clsx from "clsx"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  icon?: string
  number_max?: number
  number_only?: number
  number_decimal?: number
  disabled?: boolean
  suffix?: string | React.ReactNode
  iconStyles?: string
}

export function FormControl(config: FormControlConfig) {
  const {
    register,
    formState: {errors}
  } = useFormContext()
  const error = getNestedObject(errors, config.name)
  const className = clsx("rounded-lg", config.className, Object.keys(error).length !== 0 && "invalid")
  const number_decimal: number = isNil(config.number_decimal) ? 0 : config.number_decimal
  const uniqueId = useId()
  const onInput = (event) => {
    if (config.number_only === 1) {
      let num = event.target.value.replace(/[^0-9.,]/, "").replace(",", ".")
      if (number_decimal === 1 && config.number_max) num = String(Math.min(+num, config.number_max))
      if (config.number_only === 1 && config.number_max) num = String(Math.min(+num, config.number_max))
      if (event.target.value === "") return
      const split = num.split(".")
      if (num === "." && number_decimal === 1) {
        event.target.value = "0."
        return
      }
      if (split.length > 2) {
        event.target.value = num.slice(0, -1)
        return
      }
      if (split.length > 1 && split[1].length > 2) {
        event.target.value = num.slice(0, -1)
        return
      }
      if (Number.isInteger(+num) || num % 1 !== 0) {
        event.target.value = num
        return
      }
    }
    // setValue(config.name, event.target.value, {shouldDirty: true, shouldValidate: true, shouldTouch: true})
  }

  return (
    <div className={config.rootclassname} key={uniqueId}>
      {config.label && (
        <div className="flex items-center mb-2 gap-0.5">
          {config.label && (
            <div className="flex gap-0.5">
              <label className="overflow line-clamp-1 text-[13px] text-gray-400">{config.label}</label>
              {config.required && <div className="form-required " />}
            </div>
          )}
        </div>
      )}

      <div className="input-group relative">
        {config.icon && (
          <Icon className={clsx("input-group-icon text-gray-400", config.iconStyles)} icon={config.icon} />
        )}
        <input
          {...config}
          {...register(config.name, config.params)}
          disabled={config.disabled ?? false}
          onInput={onInput}
          className={className}
          autoComplete="off"
        />
        {config.suffix && (
          <div className="z-50 h-full absolute bottom-0 right-0 flex items-center pr-4 bg-transparent !text-[13px] !text-gray-400 !font-normal ">
            {config.suffix}
          </div>
        )}
      </div>

      {errors[config.name] && "message" in errors[config.name] && errors[config.name].message && (
        <p className="text-alert mt-1 text-sm">{(errors[config.name] as FieldError)?.message}</p>
      )}
    </div>
  )
}
