import axios from "axios"
import {BehaviorSubject, Observable, of, shareReplay, switchMap} from "rxjs"
import {ApiAbstract} from "../abstract/api.abstract"
import authService from "./auth.service"
import {getAPI} from "src/interceptor"

export interface OnboardingModel {
  id: number
  state: {
    rental_control: {
      [key: number]: boolean
    }
    control_inventory: {
      [key: string]: boolean
    }
    client_base_and_docs: {
      [key: string]: boolean
    }
    final: {
      [key: string]: boolean
    }
  }
  user_id: number
  is_demo: boolean
  tenant: number
  is_finished: boolean
}

export class NewOnboardingService extends ApiAbstract {
  constructor() {
    super("v1/tenant/onboarding")
  }

  private loadOnboarding$: BehaviorSubject<void> = new BehaviorSubject<void>(null)
  loadOnboarding = () => this.loadOnboarding$.next()

  public onboarding$: Observable<OnboardingModel | null> = authService.authorized$.pipe(
    switchMap((logged) =>
      logged
        ? this.loadOnboarding$.pipe(
          switchMap(() => getAPI<OnboardingModel>(axios.get(this.getUrl(), {params: {skip_error_handling: true}})))
        )
        : of(null)
    ),
    shareReplay(1)
  )

  public async get(): Promise<OnboardingModel> {
    const response = await axios.get(this.getUrl(), {params: {skip_error_handling: true}})
    this.loadOnboarding()
    return response.data
  }

  public async put(payload?: any): Promise<OnboardingModel> {
    const response = await axios.put(this.getUrl(), payload, {params: {skip_error_handling: true}})
    this.loadOnboarding()
    return response.data
  }

  public async patch(payload?: any): Promise<OnboardingModel> {
    const response = await axios.patch(this.getUrl(), payload, {params: {skip_error_handling: true}})
    this.loadOnboarding()
    return response.data
  }
}

const onboardingService = new NewOnboardingService()
export default onboardingService
