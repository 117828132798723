import authService, {ProfileModel} from "src/services/auth.service"
import {useContext, useEffect, useState} from "react"
import {Outlet} from "react-router"
import HeaderContent from "src/components/header/header-content"
import {SidebarWrapper} from "src/components/shared/components/side-wrapper"
import {useTranslation} from "react-i18next"
import {setChildStyle, setDefaultChildStyle} from "src/main"
import {ConfigContext} from "src/app"

export default function ProfileComponent() {
  const [profile, setProfile] = useState<ProfileModel>()
  const {isMobile, headerHeight} = useContext(ConfigContext)
  const {t} = useTranslation()

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    setChildStyle({paddingLeft: 0, paddingRight: 0, paddingTop: isMobile ? headerHeight : 0, paddingBottom: 0})
    return setDefaultChildStyle
  }, [isMobile, headerHeight])

  return (
    <>
      <HeaderContent>
        <div className="text-2xl font-semibold header-content">{t("profile.account")}</div>
      </HeaderContent>

      <SidebarWrapper menus={[
        {
          link: "/profile/about",
          icon: "person",
          label: "Профиль"
        },
        {
          link: "/profile/telegram",
          icon: "near_me",
          label: "Telegram"
        },
        {
          link: "/profile/activity",
          icon: "bar_chart_4_bars",
          label: "Моя статистика"
        },
        {
          link: "/profile/subscription",
          icon: "arrow_circle_up",
          label: "Мой тариф"
        }
      ]}>
        {profile && (
          <div className="bg-transparent border-none shadow-none card flex flex-col p-0 gap-0">
            <Outlet context={{profile}} />
          </div>
        )}
      </SidebarWrapper>
    </>
  )
}
