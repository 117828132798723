import {StringMap, TOptions} from "i18next"
import moment from "moment"
export const startOfMonth = moment().startOf("month")
export const endOfMonth = moment().endOf("month").add(1, "days")

// inventory statuses
export const INVENTORY_FREE = 0
export const INVENTORY_RESERVED = 1
export const INVENTORY_INREND = 2
export const INVENTORY_BROKEN = 3
export const INVENTORY_OVERDUE = 4
export const INVENTORY_DISABLED = 5
export const INVENTORY_SOLD = 6

export type INVENTORY_STATUS =
  | typeof INVENTORY_FREE
  | typeof INVENTORY_RESERVED
  | typeof INVENTORY_INREND
  | typeof INVENTORY_BROKEN
  | typeof INVENTORY_OVERDUE
  | typeof INVENTORY_DISABLED
  | typeof INVENTORY_SOLD

export enum OrderStatusColor {
  REQUEST = "request",
  RESERVED = "reserve",
  INRENT = "inrent",
  CANCELLED = "cancel",
  COMPLETED = "completed",
  DEBTOR = "debtor",
  EXCEED = "exceed",
  DELETED = "deleted"
}

export const ORDER_STATUS_NAME: Record<OrderStatusColor, TOptions<StringMap> | string> = {
  [OrderStatusColor.REQUEST]: "common.order_status.request",
  [OrderStatusColor.RESERVED]: "common.order_status.reserve",
  [OrderStatusColor.INRENT]: "common.order_status.inrent",
  [OrderStatusColor.CANCELLED]: "common.order_status.cancel",
  [OrderStatusColor.COMPLETED]: "common.order_status.completed",
  [OrderStatusColor.DEBTOR]: "common.order_status.debtor",
  [OrderStatusColor.EXCEED]: "common.order_status.exceed",
  [OrderStatusColor.DELETED]: "common.order_status.deleted"
}

export const ORDER_STATUS_NUMBER_NAME: Record<number, TOptions<StringMap> | string> = {
  0: "common.order_status.request",
  1: "common.order_status.reserve",
  2: "common.order_status.inrent",
  3: "common.order_status.cancel",
  4: "common.order_status.completed",
  5: "common.order_status.debtor"
}

export const ORDER_STATUS_NUMBER_CODE: Record<number, OrderStatusColor> = {
  0: OrderStatusColor.REQUEST,
  1: OrderStatusColor.RESERVED,
  2: OrderStatusColor.INRENT,
  3: OrderStatusColor.CANCELLED,
  4: OrderStatusColor.COMPLETED,
  5: OrderStatusColor.DEBTOR
}

export const PAYMENT_STATUS_NAME: Record<number, TOptions<StringMap> | string> = {
  0: "common.payment_status.not_paid",
  1: "common.payment_status.paid",
  2: "common.payment_status.partly_paid"
}

export const Validators = {
  required: {required: true},
  percent: {valueAsNumber: true, validate: (value) => value > 0 && value <= 100}
}

export const colorPaletes: Record<INVENTORY_STATUS, string> = {
  [INVENTORY_FREE]: "#52C41A",
  [INVENTORY_RESERVED]: "#B77EFF",
  [INVENTORY_INREND]: "#EC1D1D",
  [INVENTORY_BROKEN]: "#EC1D1D",
  [INVENTORY_OVERDUE]: "#B77EFF",
  [INVENTORY_DISABLED]: "#ECECECE",
  [INVENTORY_SOLD]: "#EC1D1D"
}
