import {Link, useLocation} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"
import {useContext, useEffect, useRef, useState} from "react"
import {ConfigContext} from "src/app"
import {use100vh} from "react-div-100vh"
import clsx from "clsx"

export interface SidebarMenu {
  link: string
  label: string
  icon?: string
  className?: string
  disabled?: boolean
}

export function SidebarWrapper({
  menus,
  header,
  children
}: {
  menus: SidebarMenu[]
  header?: JSX.Element
  children: JSX.Element | JSX.Element[] | string
}) {
  const ref = useRef()
  const [isLeft, setIsLeft] = useState(false)
  const [isRight, setIsRight] = useState(false)

  const location = useLocation()
  const {headerHeight, isMobile} = useContext(ConfigContext)
  const height = use100vh() - headerHeight

  const handleScroll = () => {
    const el = ref.current as HTMLDivElement
    if (!el) return
    setIsLeft(el.scrollLeft > 0)
    setIsRight(el.scrollWidth > el.clientWidth + el.scrollLeft)
  }

  useEffect(() => {
    const el = ref.current as HTMLDivElement
    if (!el) return

    handleScroll()
    el.addEventListener('scroll', handleScroll)
    return () => el.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className="flex flex-col md:flex-row items-stretch justify-between w-full" style={{maxHeight: height, minHeight: height}}>
      <div className="relative h-full" style={!isMobile ? {height} : {}}>
        <div className={clsx(
          "absolute left-0 top-0 bottom-0 w-6 bg-gradient-to-r from-black/15 to-transparent pointer-events-none z-10",
          "transition-opacity duration-300",
          {"opacity-100": isLeft, "opacity-0": !isLeft}
        )} />
        <div className={clsx(
          "absolute right-0 top-0 bottom-0 w-6 bg-gradient-to-l from-black/15 to-transparent pointer-events-none z-10",
          "transition-opacity duration-300",
          {"opacity-100": isRight, "opacity-0": !isRight}
        )} />
        {isMobile && header}
        <div
          ref={ref}
          style={!isMobile ? {height} : {}}
          className={clsx(
            "flex flex-row md:flex-col md:gap-2 p-0 md:py-8 md:px-4 shadow bg-white overflow-y-scroll",
            "border-r border-gray-200 w-full md:w-52 min-h-12 scrollbar-hide"
          )}
        >
          {!isMobile && header}
          {menus.map((menu, index) => (
            <Link
              key={`${menu}/${index}`}
              to={menu.link}
              className={clsx(
                "w-full flex text-black items-center md:rounded-lg px-[10px] py-3 gap-2 text-nowrap md:text-wrap border-b md:border-transparent",
                "first:pl-4 md:first:pl-[10px] last:pr-4 md:last:pr-[10px] min-h-8",
                location.pathname.startsWith(menu.link) && "text-primary outline-none bg-primary bg-opacity-20 border-b border-primary",
                menu.className,
              )}
            >
              {menu.icon && <Icon className="" icon={menu.icon} />}
              <div className="text-[13px] font-medium text-ellipsis line-clamp-2">{menu.label}</div>
            </Link>
          ))}
        </div>
      </div>
      <div className="flex-1 overflow-y-scroll p-3 md:p-4 pb-24">{children}</div>
    </div>
  )
}
