import {Link, useNavigate} from "react-router-dom"
import {forwardRef, useCallback, useContext, useEffect, useMemo, useState} from "react"
import authService, {GroupType} from "src/services/auth.service"
import Icon from "src/components/shared/components/material-icon"
import orderService from "src/services/order-requests/order.service"
import workshiftService, {WorkshiftModel} from "src/services/workshift.service"
import WorkshiftTimer from "../workshift/workshift-timer"
import {firstValueFrom, switchMap} from "rxjs"
import {CurrentRentalPoint} from "./rental-point"
import {useTranslation} from "react-i18next"
import {isNil} from "src/utils/isNil"
import {ConfigContext} from "src/app"
import clsx from "clsx"
import {trackAmplitudeData} from "src/services/amplitude"
import LinkWrapper from "./linkWrapper/linkWrapper"
import moment from "moment-timezone"
import subscriptionModalService from "src/services/subscription.service"
import notificationService from "src/services/notification.service"
import {isProd} from "src/utils/isProd"
import iconMini from "src/assets/icons/icon-mini.svg"
import iconLight from "src/assets/icons/icon-light.svg"
import hasAccess from "../../utils/hasAccess"
import transportationService from "../../services/transportation.service"
import {TenantType} from "../../services/tenant.service"
import {useTour} from "@reactour/tour"
import onboardingService from "src/services/onboarding"
import {Popover} from "antd"
import NotificationPopUp from "./notification/list"
import "./aside.scss"

const getClassName = (percent: number) => {
  if (percent < 20) {
    return {
      bar: "bg-alert",
      text: "text-alert"
    }
  } else if (percent > 20 && percent < 80) {
    return {
      bar: "bg-warn",
      text: "color-warn"
    }
  } else {
    return {
      bar: "bg-accept",
      text: "text-accept"
    }
  }
}

function SubscriptionAside({collapse}: {collapse: boolean}) {
  const {t} = useTranslation()
  const {tenant} = useContext(ConfigContext)

  const {_totalDays, _daysLeft, _percent, bar, text} = useMemo(() => {
    if (!tenant)
      return {
        _totalDays: 0,
        _daysLeft: 0,
        _percent: 0,
        bar: "bg-gray-300",
        text: "bg-accept"
      }

    const duration = moment(tenant.end_at).diff(tenant.start_at, "days")
    const left = moment(tenant.end_at).diff(moment(), "days")
    const percent = Math.max((100 * left) / duration, 5)

    return {
      _totalDays: duration,
      _daysLeft: left,
      _percent: percent,
      ...getClassName(percent)
    }
  }, [tenant])

  return (
    _totalDays === 14 && (
      <div
        className={
          collapse
            ? "flex bg-gray-800 rounded-lg p-3 gap-4 items-center border border-dark-light"
            : "flex flex-col bg-gray-800 rounded-lg p-3 gap-4 border border-dark-light"
        }>
        {!collapse && (
          <div className="text-sm font-semibold">
            {t("aside.limit")}
            <span className={clsx("ml-1", text)}>{`${_daysLeft}/${_totalDays}`}</span>
          </div>
        )}
        <div className="w-full h-[7px] relative overflow-hidden rounded-lg bg-gray-500">
          <div className={clsx("h-full", bar)} style={{width: `${_percent}%`}}></div>
        </div>
        {!collapse && (
          <button
            className="btn btn-primary btn-color-white justify-start gap-1 font-semibold text-nowrap, text-xs"
            style={{padding: 10, overflow: "hidden", width: "100%"}}
            onClick={() => subscriptionModalService.show$.next({show: true, type: null})}>
            {t("aside.update_plan")} <Icon icon="east" className="text-lg" />
          </button>
        )}
      </div>
    )
  )
}

interface AsideProps {
  collapse: boolean
  onCollapse: () => void
}

enum Page {
  ORDER = 'ORDER',
  CALENDAR = 'CALENDAR',
  YUME_PLUS = 'YUME_PLUS',
  TRANSFER_RIDES = 'TRANSFER_RIDES',
  TRANSFER_TRANSPORTATION = 'TRANSFER_TRANSPORTATION',
  NOTIFICATION = 'NOTIFICATION',
  CLIENT = 'CLIENT',
  PRODUCT = 'PRODUCT',
  PRODUCT_GROUP = 'PRODUCT_GROUP',
  PRODUCT_SALE = 'PRODUCT_SALE',
  PRODUCT_SET = 'PRODUCT_SET',
  PRODUCT_SERVICE = 'PRODUCT_SERVICE',
  REPORT = 'REPORT',
  MAINTENANCE = 'MAINTENANCE',
  INVENTORIZATION = 'INVENTORIZATION',
  USERS = "USERS",
  CONFIGS = "CONFIGS",
  SETTINGS = "SETTINGS",
  INTEGRATIONS = 'INTEGRATIONS',
  INTEGRATIONS_GRS = 'INTEGRATIONS_GRS',
  INTEGRATIONS_WAZZUP = 'INTEGRATIONS_WAZZUP',
  INTEGRATIONS_WIDGET = "INTEGRATIONS_WIDGET",
  INTEGRATIONS_RINGOSTAT = "INTEGRATIONS_RINGOSTAT",
  TRANSFER_EXPENSES = 'TRANSFER_EXPENSES',
  TRANSFER_CITIES = 'TRANSFER_CITIES'
}

const defaultPerms: Record<Page, boolean> = {
  [Page.ORDER]: false,
  [Page.CALENDAR]: false,
  [Page.YUME_PLUS]: false,
  [Page.TRANSFER_RIDES]: false,
  [Page.TRANSFER_TRANSPORTATION]: false,
  [Page.NOTIFICATION]: false,
  [Page.PRODUCT]: false,
  [Page.PRODUCT_GROUP]: false,
  [Page.PRODUCT_SALE]: false,
  [Page.PRODUCT_SET]: false,
  [Page.PRODUCT_SERVICE]: false,
  [Page.CLIENT]: false,
  [Page.TRANSFER_EXPENSES]: false,
  [Page.TRANSFER_CITIES]: false,
  [Page.REPORT]: false,
  [Page.MAINTENANCE]: false,
  [Page.INVENTORIZATION]: false,
  [Page.INTEGRATIONS]: false,
  [Page.INTEGRATIONS_GRS]: false,
  [Page.INTEGRATIONS_WAZZUP]: false,
  [Page.INTEGRATIONS_WIDGET]: false,
  [Page.INTEGRATIONS_RINGOSTAT]: false,
  [Page.USERS]: false,
  [Page.CONFIGS]: false,
  [Page.SETTINGS]: false,
}

export const Aside = forwardRef<HTMLDivElement, AsideProps>(({collapse, onCollapse}, ref) => {
  const {setCurrentStep, currentStep} = useTour()
  const {profile, settings, integrationMap, tenant, tenantConfigs: {accesses}, currentTour} = useContext(ConfigContext)
  const [workshift, setWorkshift] = useState<WorkshiftModel>()
  const [opacity, setOpacity] = useState(0.3)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [open, setOpen] = useState(false)
  const [count, setCount] = useState<number>(0)
  const navigate = useNavigate()
  const {t} = useTranslation()

  const has_perms = useCallback(
    (key: string) => {
      if (!profile) return false
      if (profile.is_superuser) return true
      return profile.perms.some((perm) => perm.includes(key))
    },
    [profile]
  )

  const createOrder = () => {
    firstValueFrom(
      authService.profile$.pipe(
        switchMap((profile) => {
          if (isProd) trackAmplitudeData("Create Order")
          return orderService.post({rental_point: profile.points.length > 0 ? profile.points[0] : null}).then((res) => {
            if (currentTour === "rental_control" && currentStep === 0) {
              onboardingService
                .patch({
                  block_name: currentTour,
                  step_name: currentStep,
                  passed: true
                })
                .then(() => setCurrentStep(1))
            }
            navigate(`/orders/${res.id}/all`)
          })
        })
      )
    )
  }

  const createTransfer = async () => {
    const response = await transportationService.post({})
    if (response?.id) navigate(`/transportation/${response.id}`)
  }

  const useWorkshift = settings && settings.workshift
  const workshiftStarted = workshift && !isNil(workshift.start_at)
  const is_superuser = profile && profile.is_superuser

  const contentAccess = useWorkshift ? workshiftStarted || is_superuser : true

  const handleLinkClick = () => setPopoverVisible(!popoverVisible)
  const handleOpenChange = (newOpen: boolean) => setOpen(newOpen)
  const onHide = () => setOpen(false)

  const countView = count > 0 && (
    <div className={clsx(
      "bg-alert text-white rounded-full flex items-center justify-center text-[8px] px-1",
      collapse && "absolute top-0 right-0 min-w-4 h-4",
      !collapse && "min-w-5 h-5"
    )}>
      {count > 99 ? "99+" : count}
    </div>
  )

  const permissions: Record<Page, boolean> = useMemo(() => {
    const perms = defaultPerms
    if (!tenant) return perms
    if (!profile) return perms
    if (!accesses) return perms
    if (!settings) return perms

    perms[Page.ORDER] = accesses.orders && has_perms("orderrequest")
    perms[Page.YUME_PLUS] = accesses.orders && ["Tobe", "JaiGoi", "yama", "procateam"].includes(tenant.name) && has_perms("orderrequest")
    perms[Page.CALENDAR] = accesses.calendar && has_perms("inventoryschedule") && settings.feature_schedule
    perms[Page.CLIENT] = accesses.clients && has_perms("view_client")
    perms[Page.REPORT] = accesses.reports && has_perms("metrics")
    perms[Page.MAINTENANCE] = settings.feature_maintenance
    perms[Page.INVENTORIZATION] = accesses.inventorization && has_perms("inventoryinventorization")
    perms[Page.USERS] = accesses.users && profile && profile.is_superuser
    perms[Page.CONFIGS] = !!accesses.config
    perms[Page.SETTINGS] = profile && profile.is_superuser
    perms[Page.NOTIFICATION] = false
    // perms[Page.NOTIFICATION] = tenant.type_code !== TenantType.TRANSFER

    // TRANSFER
    perms[Page.TRANSFER_EXPENSES] = hasAccess(accesses, "transportation.expenses") && profile.group_type !== GroupType.MANAGER
    perms[Page.TRANSFER_CITIES] = hasAccess(accesses, "transportation.cities") && profile.group_type !== GroupType.DRIVER

    if (accesses.transportation) {
      perms[Page.TRANSFER_RIDES] = true
      perms[Page.TRANSFER_TRANSPORTATION] = (GroupType.MANAGER === profile.group_type || profile.is_superuser)
    }

    if (accesses.groups) {
      perms[Page.PRODUCT] = (has_perms("view_client") || has_perms("inventorygroup") || has_perms("inventoryset") || has_perms("view_service"))
      perms[Page.PRODUCT_GROUP] = has_perms("inventorygroup") && tenant.type_code !== TenantType.TRANSFER
      perms[Page.PRODUCT_SALE] = accesses.groups.sales && has_perms("inventorygroup") && tenant.sale_enabled
      perms[Page.PRODUCT_SET] = accesses.groups.sets && has_perms("inventoryset") && settings.feature_set
      perms[Page.PRODUCT_SERVICE] = accesses.groups.services && has_perms("view_service") && settings.feature_service
    }

    if (settings.feature_integration) {
      perms[Page.INTEGRATIONS] = true
      perms[Page.INTEGRATIONS_GRS] = integrationMap["gps"] && integrationMap["gps"].connected
      perms[Page.INTEGRATIONS_WAZZUP] = integrationMap["wazzup"] && integrationMap["wazzup"].connected
      perms[Page.INTEGRATIONS_WIDGET] = integrationMap["widget"] && integrationMap["widget"].connected
      perms[Page.INTEGRATIONS_RINGOSTAT] = integrationMap["ringostat"] && integrationMap["ringostat"].connected
    }

    return perms
  }, [tenant, profile, accesses, integrationMap, settings, has_perms, workshift])

  const integrationSubroutes = useMemo(() => {
    return [
      permissions.INTEGRATIONS_GRS && {
        route: "geolocation",
        icon: "map",
        text: t("aside.menu.gps")
      },
      permissions.INTEGRATIONS_WAZZUP && {
        route: "wazzup",
        icon: "forum",
        text: "Wazzup"
      },
      permissions.INTEGRATIONS_WIDGET && {
        route: "integrations/widget",
        icon: "widgets",
        text: t("aside.menu.widget")
      },
      permissions.INTEGRATIONS_RINGOSTAT && {
        route: "integrations/ringostat",
        icon: "call",
        text: t("aside.menu.ringostat")
      }
    ].filter(Boolean)
  }, [permissions])

  const productSubroutes = useMemo(() => {
    return [
      permissions.PRODUCT_SALE && {
        route: "sales",
        icon: "storefront",
        text: t("aside.menu.shop")
      },
      permissions.PRODUCT_SET && {
        route: "inventory-sets",
        icon: "dataset",
        text: t("aside.menu.sets")
      },
      permissions.PRODUCT_SERVICE && {
        route: "services",
        icon: "design_services",
        text: t("aside.menu.services")
      }
    ].filter(Boolean)
  }, [permissions])

  const productBlock = [permissions.PRODUCT, permissions.CLIENT].filter(Boolean).length > 0

  const reportBlock = [
    permissions.TRANSFER_EXPENSES,
    permissions.TRANSFER_CITIES,
    permissions.REPORT,
    permissions.MAINTENANCE,
    permissions.INVENTORIZATION
  ].filter(Boolean).length > 0

  useEffect(() => {
    if (currentTour !== "rental_control") return
    if (currentStep > 0 && currentStep <= 15) {
      createOrder()
    } else if (currentStep > 15) {
      navigate("/orders/all")
    }
  }, [])

  useEffect(() => {
    if (tenant && tenant.type_code !== TenantType.TRANSFER) {
      const subscription = notificationService.count$.subscribe(setCount)
      return () => subscription.unsubscribe()
    }
  }, [tenant])

  useEffect(() => {
    const sub = workshiftService.workshift$.subscribe(setWorkshift)
    return () => sub.unsubscribe()
  }, [])

  return (
    <aside className="scrollbar-hide h-screen" ref={ref}>
      <div className="company-icon">
        <div className="py-7">
          {collapse ? (
            <img src={iconMini} draggable={false} />
          ) : (
            <img src={iconLight} width="145" height="24" draggable={false} />
          )}
        </div>

        {accesses.orders && (
          <div className="py-8 px-3 w-full">
            <button
              id="step1"
              className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap w-full h-10 p-[10px]"
              onClick={createOrder}>
              <Icon icon="add" />
              {!collapse && t("orders.new_order")}
            </button>
          </div>
        )}

        {accesses.transportation && profile && (profile.group_type === GroupType.MANAGER || profile.is_superuser) && (
          <div className="py-8 px-3 w-full">
            <button
              className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap w-full h-10 p-[10px]"
              onClick={createTransfer}>
              <Icon icon="add" />
              {!collapse && t("orders.transfer.new")}
            </button>
          </div>
        )}
      </div>

      <div
        className={clsx("collapse-btn shadow z-[1000]", !collapse && "active")}
        style={{opacity}}
        onMouseEnter={() => setOpacity(1)}
        onMouseLeave={() => setTimeout(() => setOpacity(0.3), 1500)}
        onClick={onCollapse}>
        <Icon className="cursor-pointer text-2xl text-gray-400" icon="chevron_right" />
      </div>

      {profile && (
        <div className="menu overflow-scroll scrollbar-hide">
          <div className="flex flex-col gap-4 items-stretch w-full">
            <div className={clsx(clsx(!collapse && "menu-block"))}>
              {permissions.ORDER && (
                <LinkWrapper
                  collapse={collapse}
                  route="orders"
                  icon="list_alt"
                  id={currentTour === "rental_control" ? "step15" : undefined}
                  text={t("aside.menu.orders")}
                  onClick={() => {
                    if (currentTour === "rental_control" && currentStep === 14) {
                      onboardingService
                        .patch({
                          block_name: currentTour,
                          step_name: currentStep,
                          passed: true
                        })
                        .then(() => {
                          setCurrentStep(15)
                        })
                    }
                  }}
                />
              )}
              {permissions.YUME_PLUS && (
                <LinkWrapper
                  collapse={collapse}
                  route="plus_orders"
                  icon="list_alt"
                  text="Yume Plus"
                />
              )}
              {permissions.CALENDAR && (
                <LinkWrapper
                  collapse={collapse}
                  route="calendar"
                  icon="calendar_month"
                  text={t("aside.menu.schedules")}
                />
              )}
              {permissions.TRANSFER_RIDES && (
                <LinkWrapper
                  collapse={collapse}
                  icon="list"
                  route="rides"
                  text={t("orders.transfer.all_order")}
                />
              )}
              {permissions.TRANSFER_TRANSPORTATION && (
                <LinkWrapper
                  collapse={collapse}
                  icon="directions_car"
                  route="transportation"
                  text={t("transfers.main.transportation")}
                />
              )}
              {permissions.NOTIFICATION && (
                <Popover
                  content={<NotificationPopUp onHide={onHide} />}
                  trigger="click"
                  open={open}
                  arrow={false}
                  onOpenChange={handleOpenChange}
                  placement="right"
                  overlayInnerStyle={{padding: 0, marginLeft: "20px", marginTop: "10px"}}
                >
                  <div onClick={handleLinkClick} className={clsx("relative menu-item mb-2")}>
                    <Icon className="menu-item-icon" icon="notifications" />
                    {!collapse && <div className="menu-item-name">{t("settings.sections.notifications")}</div>}
                    {countView}
                  </div>
                </Popover>
              )}
            </div>

            {productBlock && (
              <div className={clsx(!collapse && "menu-block")}>
                {permissions.PRODUCT_GROUP && (
                  <LinkWrapper
                    route="groups"
                    icon="inventory_2"
                    text={t("aside.menu.groups")}
                    isSection={productSubroutes.length > 0}
                    collapse={collapse}
                    subRoutes={productSubroutes}
                  />
                )}
                {permissions.CLIENT && (
                  <LinkWrapper
                    collapse={collapse}
                    route="clients"
                    icon="group"
                    text={t("aside.menu.clients")}
                  />
                )}
              </div>
            )}

            {reportBlock && (
              <div className={clsx(!collapse && "menu-block")}>
                {permissions.TRANSFER_EXPENSES && (
                  <LinkWrapper
                    collapse={collapse}
                    icon="insert_chart"
                    text={t("transfers.main.expenses")}
                    route="drivers"
                  />
                )}
                {permissions.TRANSFER_CITIES && (
                  <LinkWrapper
                    collapse={collapse}
                    icon="location_on"
                    text={t("orders.transfer.destinations")}
                    route="cities"
                  />
                )}
                {permissions.REPORT && (
                  <LinkWrapper
                    collapse={collapse}
                    route="reports"
                    icon="analytics"
                    text={t("aside.menu.metrics")}
                  />
                )}
                {permissions.MAINTENANCE && (
                  <LinkWrapper
                    collapse={collapse}
                    route="maintenance"
                    icon="inventory"
                    text={t("maintenance.maintenance_abr")}
                  />
                )}
                {permissions.INVENTORIZATION && (
                  <LinkWrapper
                    collapse={collapse}
                    route="inventorization"
                    icon="inventory"
                    text={t("aside.menu.inventorization")}
                  />
                )}
              </div>
            )}

            <div className={clsx(!collapse && "menu-block menu-block--last")}>
              {permissions.INTEGRATIONS && (
                <LinkWrapper
                  route="integrations"
                  icon="toys_and_games"
                  text={t("aside.menu.integrations")}
                  isSection={integrationSubroutes.length > 0}
                  collapse={collapse}
                  subRoutes={integrationSubroutes}
                />
              )}
              {permissions.USERS && (
                <LinkWrapper
                  collapse={collapse}
                  route="users"
                  icon="manage_accounts"
                  text={t("aside.menu.users")}
                />
              )}
              {permissions.CONFIGS && (
                <LinkWrapper
                  collapse={collapse}
                  route="config"
                  icon="page_info"
                  text={t("aside.menu.configurations")}
                />
              )}
              {permissions.SETTINGS && (
                <LinkWrapper
                  collapse={collapse}
                  route="settings"
                  icon="settings"
                  text={t("aside.menu.settings")}
                />
              )}
            </div>

            <a
              href="https://wa.me/+7779479990"
              target="_blank"
              rel="noreferrer"
              className={clsx(
                "flex gap-2 p-3 items-center cursor-pointer border border-gray-700 rounded-lg",
                collapse ? "justify-center" : "justify-content-between"
              )}
            >
              <div className="flex gap-2 items-center">
                <Icon icon="help" />
                {!collapse && <div>Support</div>}
              </div>
              {!collapse && <Icon icon="open_in_new" />}
            </a>

            <SubscriptionAside collapse={collapse} />

            <div>
              <CurrentRentalPoint collapse={collapse} />

              {workshift && contentAccess && <WorkshiftTimer collapse={collapse} workshift={workshift} />}

              {profile && (
                <Link to="/profile" className="menu-profile">
                  <div className="menu-profile-icon">
                    <Icon icon="person" className="text-base text-white" />
                  </div>
                  <div className="menu-profile-name">
                    {[profile.first_name, profile.last_name].filter(n => !!n).join(" ")}
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </aside>
  )
})
