import {Popover, PopoverProps, Tooltip} from "antd"
import React, {SyntheticEvent, useContext, useMemo, useState} from "react"
import {Link} from "react-router-dom"
import Icon from "../components/material-icon"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import modalService from "src/components/modal/global/modal.service"
import clsx from "clsx"

interface OptionProps {
  key: string
  title: string | JSX.Element
  icon?: string
  onClick?: (e?: SyntheticEvent) => void
  hidden?: boolean
  disabled?: boolean
  connected?: boolean
  is_dev?: boolean
  className?: string
}

function PopoverButton({
  title,
  icon,
  onClick = (e: SyntheticEvent) => {},
  disabled = false,
  is_dev = false,
  connected = true,
  className = "px-4 py-3 md:px-2 md:py-2 "
}: OptionProps) {
  const {t} = useTranslation()
  const {currentTour} = useContext(ConfigContext)
  return (
    <div className="flex items-center gap-1 border-b border-gray-100 last:border-none first:mt-12 md:first:mt-0 -mx-4 md:mx-0 w-[calc(100%_+_32px)] md:w-full">
      <button
        disabled={disabled || !connected}
        className={clsx("btn btn-white justify-start gap-2 flex-1", className)}
        onClick={onClick}>
        {icon && <Icon className="text-lg" icon={icon} />}
        {title}
      </button>
      {currentTour !== "rental_control" && is_dev && (
        <>
          <div className="vr my-2 text-gray-400" />

          <Tooltip
            title={
              <div className="flex gap-2 flex-col">
                <span className="text-center">Для подключения данного функционала свяжитесь с менеджером</span>
                <a
                  href="https://wa.me/+7779479990"
                  target="_blank"
                  className="btn btn-white px-3 py-2"
                  rel="noreferrer">
                  {t("banner.link")}
                </a>
              </div>
            }
            zIndex={10001}>
            <Icon icon="info" className="btn py-0" />
          </Tooltip>
        </>
      )}
      {currentTour !== "rental_control" && !connected && (
        <>
          <div className="vr my-2 text-gray-400" />

          <Tooltip
            title={
              <div className="flex gap-2 flex-col">
                <span className="text-center">Для подключения данного функционала подключите интеграцию</span>
                <Link to="/integrations" className="btn btn-white px-3 py-2">
                  Перейти
                </Link>
              </div>
            }
            zIndex={10001}>
            <Icon icon="info" className="btn py-0" />
          </Tooltip>
        </>
      )}
    </div>
  )
}

export interface PopoverOptionsProps extends PopoverProps {
  options: OptionProps[]
}

const _PopoverOptions = React.forwardRef<any, PopoverOptionsProps>((props, ref) => {
  const {isMobile} = useContext(ConfigContext)
  const [open, setOpen] = useState(false)

  const filteredOptions = useMemo(() => props.options.filter((option) => !option.hidden), [props.options])

  if (isMobile) {
    const onOpen = () =>
      modalService.open({
        component: filteredOptions.map((option) => <PopoverButton key={option.key} {...option} />)
      })
    return React.isValidElement(props.children)
      ? React.cloneElement(props.children as React.ReactElement, {onClick: onOpen})
      : null
  }

  return (
    <Popover
      ref={ref}
      {...props}
      {...(props.trigger === "click" ? {open, onOpenChange: (o) => setOpen(o)} : {})}
      overlayInnerStyle={{overflow: "scroll", padding: 0}}
      content={
        <div className="flex flex-col items-stretch max-h-80">
          {filteredOptions.map((option) => (
            <PopoverButton
              key={option.key}
              {...option}
              {...(props.trigger === "click"
                ? {
                    onClick: (e) => {
                      option.onClick(e)
                      setOpen(false)
                    }
                  }
                : {})}
            />
          ))}
        </div>
      }
    />
  )
})

export default _PopoverOptions
