import React, {CSSProperties, useId, useState} from "react"
import {useFormContext} from "react-hook-form"
import Icon from "./material-icon"

export function MultiImageInputField({
  name,
  onEdit,
  style = {width: "100%", height: "100%", borderRadius: 8},
  containerStyle = {width: "max-content", borderRadius: 8},
  label,
  required = false
}: {
  name: string
  style?: CSSProperties
  containerStyle?: CSSProperties
  onEdit?: boolean
  label?: string
  required?: boolean
}) {
  const imageId = useId()
  const {setValue, watch, formState, clearErrors} = useFormContext()
  const images = watch(name) || []
  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || [])
    setValue(name, [...images, ...files])
    clearErrors(name)
  }

  const handleRemoveImage = (index: number) => {
    const updatedImages = images.filter((_: any, i: number) => i !== index)
    setValue(name, updatedImages)
  }
  const handleImageClick = (image: string | File) => {
    setSelectedImage(image instanceof File ? URL.createObjectURL(image) : image)
  }
  const closeModal = () => setSelectedImage(null)

  return (
    <div>
      {label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] text-gray-400">{label}</label>
          {required && <div className="form-required"></div>}
        </div>
      )}
      {images.length === 0 ? (
        <div>
          <input id={imageId} hidden type="file" accept="image/*" multiple onChange={handleImageChange} />
          <label
            htmlFor={imageId}
            className={`p-[54px] rounded-lg cursor-pointer border border-dashed ${
              formState.errors.media?.message ? "border-red-500" : "border-gray-200"
            } flex items-center justify-center`}
            style={{
              ...containerStyle,
              padding: "20px",
              display: "flex",
              flexDirection: "column"
            }}>
            <div className="flex flex-col text-center gap-2">
              <div className="flex gap-3">
                <Icon className="text-primary" icon={"add_photo_alternate"} />
                <p className="text-primary">Upload photo</p>
              </div>
              <small className="text-gray-400">File size limit 50MB</small>
            </div>
          </label>
          {typeof formState.errors.media?.message === "string" && (
            <p className="mt-3 text-red-500">{formState.errors.media.message}</p>
          )}
        </div>
      ) : (
        <div className="mt-3 flex flex-wrap gap-3">
          {images.map((image: File | string, index: number) => (
            <div
              key={index}
              className="relative group flex justify-center items-center bg-gray-100 w-20 h-20 rounded-lg overflow-hidden"
              style={containerStyle}>
              <img
                src={image instanceof File ? URL.createObjectURL(image) : image}
                alt={`Preview ${index}`}
                style={style}
                className="absolute inset-0 w-full h-full"
                onClick={() => handleImageClick(image)}
              />
              {!onEdit && (
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  className="absolute top-1 right-1 bg-white text-black rounded-full w-5 h-5 opacity-0 group-hover:opacity-100 transition-opacity">
                  <Icon className="text-[12px]" icon={"close_small"} />
                </button>
              )}
            </div>
          ))}
          {!onEdit && (
            <div
              className="flex justify-center items-center w-20 h-20 border border-gray-200 rounded-lg"
              onChange={handleImageChange}>
              <input id={imageId} hidden type="file" accept="image/*" multiple onChange={handleImageChange} />
              <label htmlFor={imageId}>
                <Icon icon={"add_photo_alternate"} />
              </label>
            </div>
          )}
        </div>
      )}
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-white z-50 md:z-[10000] "
          onClick={closeModal}>
          <div className="relative">
            <img src={selectedImage} alt="Enlarged view" className="max-w-80" />
            <button
              type="button"
              onClick={closeModal}
              className="absolute top-2 right-2 bg-white text-black rounded-full w-8 h-8">
              <Icon icon={"close"} />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
