import axios from "axios"
import {BehaviorSubject, Observable} from "rxjs"
import {CrudInterface} from "src/interfaces/crud.interface"
import {ListModel} from "src/models/common"
import {ApiAbstract} from "src/abstract/api.abstract"
import {OrderRequestReferral, ReferralAgent} from "src/models/manager/order/order-request-referral"

export interface ReferralPayload {
  id?: number
  agent: ReferralAgent
  percent: number
  amount: number
  paid_amount: number
  status: 0 | 1
}

export class OrderReferralService extends ApiAbstract implements CrudInterface<OrderRequestReferral> {
  constructor() {
    super("v1/crm/requests")
  }

  private referralsSubject = new BehaviorSubject<OrderRequestReferral[]>([])
  public referrals$: Observable<OrderRequestReferral[]> = this.referralsSubject.asObservable()

  public async fetch(orderId: number): Promise<OrderRequestReferral[]> {
    const response = await axios.get(this.getUrl(`${orderId}/referrals`))
    this.referralsSubject.next(response.data)
    return response.data
  }

  public async post(orderId: number, payload: ReferralPayload): Promise<OrderRequestReferral> {
    const formattedPayload = {
      agent: payload.agent.id,
      amount: payload.amount,
      percent: payload.percent
    }
    const response = await axios.post(this.getUrl(`${orderId}/referrals`), formattedPayload)
    return response.data
  }

  public async patch(
    orderId: number,
    referralId: number,
    payload: Partial<ReferralPayload>
  ): Promise<OrderRequestReferral> {
    const formattedPayload = {
      id: payload.id,
      agent: payload.agent.id,
      amount: payload.amount,
      percent: payload.percent,
      paid_amount: payload.paid_amount
    }

    const response = await axios.patch(this.getUrl(`${orderId}/referrals/${referralId}`), formattedPayload)
    return response.data
  }

  public async delete(orderId: number, referralId: number): Promise<undefined> {
    await axios.delete(this.getUrl(`${orderId}/referrals/${referralId}`))
    const current = this.referralsSubject.value
    const updatedList = current.filter((item) => item.id !== referralId)
    this.referralsSubject.next(updatedList)
    return undefined
  }

  public async pay(orderId: number, referralId: number): Promise<undefined> {
    await axios.post(this.getUrl(`${orderId}/referrals/${referralId}/pay`))
    await this.fetch(orderId)
    return undefined
  }

  list(params?: any, ...args: any[]): Promise<ListModel<any>> {
    throw new Error("Method not implemented.")
  }
  get(id: string | number, ...args: any[]): Promise<any> {
    throw new Error("Method not implemented.")
  }
}

const orderReferralService = new OrderReferralService()
export default orderReferralService
