import React, {CSSProperties, useState} from "react"
import {useSortable} from "@dnd-kit/sortable"
import {CSS} from "@dnd-kit/utilities"
import {LeadList} from "src/services/crm/leads.service"
import Avatar from "src/utils/genereteAvatar"
import toDateTime from "src/utils/date-time"
import {Drawer, Space} from "antd"
import Icon from "../../../components/shared/components/material-icon"
import LeadEdit from "../edit/lead-edit"
import {useDndContext} from "@dnd-kit/core"

interface CRMCardProps {
  lead: LeadList
  isOverlay?: boolean
}

const CRMCard: React.FC<CRMCardProps> = ({lead, isOverlay}) => {
  const {setNodeRef, attributes, listeners, transform, transition} = useSortable({
    id: lead.id,
    data: {type: "card", lead}
  })
  const [open, setOpen] = useState(false)
  const {active} = useDndContext()
  const isDragging = active?.id === lead.id

  const showDrawer = () => {
    setOpen(true)
  }
  const closeDrawer = () => {
    setOpen(false)
  }

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
    pointerEvents: isDragging && !isOverlay ? "none" : "auto"
  }

  return (
    <>
      <div ref={setNodeRef} className="p-4 rounded-lg cursor-move bg-white" onClick={showDrawer} style={style}>
        <div className="flex flex-col justify-between" {...attributes} {...listeners}>
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <div className="text-[#1890ff] font-semibold">{lead.client_name}</div>

              <div className="flex gap-2">
                <Avatar name={`${lead.manager?.first_name} ${lead.manager?.last_name}`} />
                <span>{lead.manager?.first_name}</span>
              </div>
            </div>

            <div className="text-[#1890ff] text-sm ">{lead.client_phone}</div>
          </div>
          <div className="flex items-center justify-between">
            <div className="border rounded-lg">{lead.source}</div>
            <div>{toDateTime(lead.created_at, "DD MMMM, HH:mm")}</div>
          </div>
        </div>
      </div>
      <Drawer
        title={`№${lead.id}`}
        placement="right"
        closable
        onClose={closeDrawer}
        open={open}
        zIndex={1002}
        getContainer={document.body}
        closeIcon={<Icon icon="arrow_back_ios_new" />}
        extra={
          <Space>
            <div
              className="cursor-pointer p-1 flex items-center justify-center rounded-full  transition"
              onClick={closeDrawer}>
              <Icon icon="more_horiz" className="text-black text-[12px]" />
            </div>
          </Space>
        }>
        <LeadEdit />
      </Drawer>
    </>
  )
}

export default CRMCard
