import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import UserPermissionComponent from "src/pages/accounts"
import ProfileEditComponent from "src/pages/profile/edit"
import ProfileComponent from "src/pages/profile"
import ProfileActivityComponent from "src/pages/profile/activity"
import MaintenanceList from "../pages/maintenance/list/maintenance-list"
import MaintenanceHistory from "../pages/maintenance/list/maintenance-history"
import {ProtectedRoute} from "./guard"
import ConfigTelegramComponent from "src/pages/config/telegram"
import componentLoader from "src/components/custom-suspense/loader"

const ConfigListComponent = lazy(() => componentLoader(() => import("src/pages/config/config")))
const ConfigClientTickComponent = lazy(() => componentLoader(() => import("src/pages/config/config-client-ticks")))
const ConfigDiscountComponent = lazy(() => componentLoader(() => import("src/pages/config/config-discounts")))
const ConfigInventoryCategoryComponent = lazy(() => componentLoader(() => import("src/pages/config/config-category")))
const ConfigPaymentTypeComponent = lazy(() => componentLoader(() => import("src/pages/config/config-payment-type")))
const ConfigRentalDurationComponent = lazy(() => componentLoader(() => import("src/pages/config/config-rental-duriation")))
const ConfigRentalPointsComponent = lazy(() => componentLoader(() => import("src/pages/config/config-rental-points")))
const ConfigServicesComponent = lazy(() => componentLoader(() => import("src/pages/service/list")))
// const TarifListComponent = lazy(() => componentLoader(() => import("src/pages/config/config-tarifs")))
const DocumentList = lazy(() => componentLoader(() => import("src/pages/config/documents/list")))
const DocumentTemplate = lazy(() => componentLoader(() => import("src/pages/config/documents/template")))
const UserPermissionGroupEdit = lazy(() => componentLoader(() => import("src/pages/accounts/group/group-edit")))
const UserStaffEdit = lazy(() => componentLoader(() => import("src/pages/accounts/user/user-edit")))
const InventorizationComponent = lazy(() => componentLoader(() => import("src/pages/inventorization/inventorization")))
const InventorizationHistoryComponent = lazy(() => componentLoader(() => import("src/pages/inventorization/inventorization-history")))
const InventorizationTaskListComponent = lazy(() => componentLoader(() => import("src/pages/inventorization/inventorization-tasklist")))
const MaintenanceComponent = lazy(() => componentLoader(() => import("src/pages/maintenance/maintenance")))
const ServiceComponent = lazy(() => componentLoader(() => import("src/pages/service/edit")))
const UserList = lazy(() => componentLoader(() => import("src/pages/accounts/user/list")))
const UserPermissionGroupList = lazy(() => componentLoader(() => import("src/pages/accounts/group/list")))
const ConfigClientAttactionMethodComponent = lazy(() => componentLoader(() => import("src/pages/config/config-client-attraction-methods")))
const CurrentSubscriptionTarif = lazy(() => componentLoader(() => import("src/pages/subscription/profile")))

export const configRoutes: RouteObject[] = [
  {
    path: "services",
    element: (
      <ProtectedRoute accessKey="groups.services">
        <CustomSuspense>
          <ConfigServicesComponent />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "services/add",
    element: <ProtectedRoute accessKey="groups.services"><ServiceComponent /></ProtectedRoute>
  },
  {
    path: "services/:id/edit",
    element: <ProtectedRoute accessKey="groups.services"><ServiceComponent /></ProtectedRoute>
  },
  {
    path: "config/documents/add",
    element: (
      <ProtectedRoute accessKey="config.documents">
        <CustomSuspense>
          <DocumentTemplate />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "config/documents/:id",
    element: (
      <ProtectedRoute accessKey="config.documents">
        <CustomSuspense>
          <DocumentTemplate />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "config",
    element: (
      <ProtectedRoute accessKey="config">
        <CustomSuspense>
          <ConfigListComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {path: "", element: <Navigate to="rental-points" replace={true} />},
      {
        path: "rental-points",
        element: (
          <ProtectedRoute accessKey="config.rental-points">
            <CustomSuspense>
              <ConfigRentalPointsComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "lease-terms",
        element: (
          <ProtectedRoute accessKey="config.lease-terms">
            <CustomSuspense>
              <ConfigRentalDurationComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "discounts",
        element: (
          <ProtectedRoute accessKey="config.discounts">
            <CustomSuspense>
              <ConfigDiscountComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "client-ticks",
        element: (
          <ProtectedRoute accessKey="config.client-ticks">
            <CustomSuspense>
              <ConfigClientTickComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "inventory-category",
        element: (
          <ProtectedRoute accessKey="config.inventory-category">
            <CustomSuspense>
              <ConfigInventoryCategoryComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "attraction-methods",
        element: (
          <ProtectedRoute accessKey="config.attraction-methods">
            <CustomSuspense>
              <ConfigClientAttactionMethodComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "services",
        element: (
          <ProtectedRoute accessKey="config.services">
            <CustomSuspense>
              <ConfigServicesComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "payment-types",
        element: (
          <ProtectedRoute accessKey="config.payment-types">
            <CustomSuspense>
              <ConfigPaymentTypeComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "documents",
        element: (
          <ProtectedRoute accessKey="config.documents">
            <CustomSuspense>
              <DocumentList />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "*",
        element: <Navigate to="rental-points" replace={true} />
      }
    ]
  },
  {
    path: "inventorization",
    element: (
      <ProtectedRoute accessKey="inventorization">
        <CustomSuspense>
          <InventorizationComponent />
        </CustomSuspense>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="tasklist" relative="route" replace={true} />
      },
      {
        path: "tasklist",
        element: (
          <CustomSuspense>
            <InventorizationTaskListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "history",
        element: (
          <CustomSuspense>
            <InventorizationHistoryComponent params={{}} />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "maintenance",
    element: (
      <CustomSuspense>
        <MaintenanceComponent />
      </CustomSuspense>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="list" relative="route" replace={true} />
      },
      {
        path: "list",
        element: (
          <CustomSuspense>
            <MaintenanceList />
          </CustomSuspense>
        )
      },
      {
        path: "list/history",
        element: (
          <CustomSuspense>
            <MaintenanceHistory />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "users",
    element: <ProtectedRoute accessKey="users"><UserPermissionComponent /></ProtectedRoute>,
    children: [
      {
        path: "",
        element: <Navigate to="main" replace={true} />
      },
      {
        path: "main",
        element: (
          <ProtectedRoute accessKey="users.main">
            <CustomSuspense>
              <UserList params={{is_staff: true}} addLink="/users/add" />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "sublease",
        element: (
          <ProtectedRoute accessKey="users.sublease">
            <CustomSuspense>
              <UserList params={{is_sublease: true}} addLink="/users/sublease/add" />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "roles",
        element: (
          <ProtectedRoute accessKey="users.roles">
            <CustomSuspense>
              <UserPermissionGroupList />
            </CustomSuspense>
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: "users/add",
    element: (
      <ProtectedRoute accessKey="users">
        <CustomSuspense>
          <UserStaffEdit back="/users" params={{is_staff: true}} />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "users/:id",
    element: (
      <ProtectedRoute accessKey="users">
        <CustomSuspense>
          <UserStaffEdit back="/users" params={{is_staff: true}} />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "users/sublease/add",
    element: (
      <ProtectedRoute accessKey="users">
        <CustomSuspense>
          <UserStaffEdit back="/users/sublease" params={{is_sublease: true}} />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "users/sublease/:id",
    element: (
      <ProtectedRoute accessKey="users">
        <CustomSuspense>
          <UserStaffEdit back="/users/sublease" params={{is_sublease: true}} />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "users/roles/:id",
    element: (
      <ProtectedRoute accessKey="users">
        <CustomSuspense>
          <UserPermissionGroupEdit />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "users/roles/add",
    element: (
      <ProtectedRoute accessKey="users">
        <CustomSuspense>
          <UserPermissionGroupEdit />
        </CustomSuspense>
      </ProtectedRoute>
    )
  },
  {
    path: "profile",
    element: <ProfileComponent />,
    children: [
      {path: "", element: <Navigate to="about" replace={true} />},
      {path: "about", element: <ProfileEditComponent />},
      {path: "activity", element: <ProfileActivityComponent />},
      {path: "telegram", element: <ConfigTelegramComponent />},
      {
        path: "subscription",
        element: (
          <CustomSuspense>
            <CurrentSubscriptionTarif />
          </CustomSuspense>
        )
      }
    ]
  }
]
