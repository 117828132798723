import React, {useContext} from "react"
import {FormProvider, useForm} from "react-hook-form"
import Table from "src/components/shared/antd-custom/table"
import {ConfigContext} from "src/app"
import {ProductSetContext} from "./context"
import type {ColumnsType} from "antd/es/table"
import {useTranslation} from "react-i18next"
import {toPrice} from "src/utils/price"
import secondsToHuman from "src/utils/secondsToHuman"
import {InventorySetPriceModel} from "src/models/manager/inventory/inventory-set.model"

const ProductSetPeriodComponent = () => {
  const form = useForm()
  const {set} = useContext(ProductSetContext)
  const {t} = useTranslation()
  const {constantsMap, isMobile} = useContext(ConfigContext)

  const columns: ColumnsType<InventorySetPriceModel> = [
    {
      width: 120,
      title: t("orders.set.name"),
      dataIndex: "name",
      key: "name",
      render: (name) => name
    },
    {
      width: 120,
      title: t("orders.set.price"),
      dataIndex: "price",
      key: "price",
      render: (price) => toPrice(price),
      sorter: true
    },
    {
      width: 120,
      title: t("orders.set.period"),
      dataIndex: "period_id",
      key: "time_period",
      render: (period_id) => secondsToHuman(constantsMap.TARIF_TIME_PERIODS[period_id]?.time),
      sorter: true
    }
  ]

  return (
    <FormProvider {...form}>
      <Table
        showHeader={!isMobile}
        rowKey={(obj) => obj.id}
        columns={columns}
        autoScroll={false}
        className="rounded-none border-0"
        rootClassName="w-full"
        dataSource={set.prices}
      />
    </FormProvider>
  )
}

export default ProductSetPeriodComponent
