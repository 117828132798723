interface Configs {
  color: string
  backgroundColor: string
  text: string
}

export const Call_ANSWERED = "ANSWERED"
export const CALL_NO_ANSWER = "NO ANSWER"
export const CALL_FAILED = "FAILED"
export const CALL_BUSY = "BUSY"
export const CALL_REPEATED = "REPEATED"
export const CALL_PROPER = "PROPER"
export const CALL_NO_FORWARD = "NO-FORWARD"
export const CALL_VOICEMAIL = "VOICEMAIL"
export const CALL_WRONG_EXTENSION = "WRONG EXTENSION"
export const CALL_NO_EXTENSION = "NO EXTENSION"
export const CALL_CLIENT_NO_ANSWER = "CLIENT NO ANSWER"
export const CALL_FAILED_FORBIDDEN_DESTINATION = "FAILED FORBIDDEN DESTINATION"
export const CALL_IN_PROGRESS = "IN PROGRESS"

export type CALL_STATUSES =
  | typeof Call_ANSWERED
  | typeof CALL_NO_ANSWER
  | typeof CALL_FAILED
  | typeof CALL_BUSY
  | typeof CALL_REPEATED
  | typeof CALL_PROPER
  | typeof CALL_NO_FORWARD
  | typeof CALL_VOICEMAIL
  | typeof CALL_WRONG_EXTENSION
  | typeof CALL_NO_EXTENSION
  | typeof CALL_CLIENT_NO_ANSWER
  | typeof CALL_FAILED_FORBIDDEN_DESTINATION
  | typeof CALL_IN_PROGRESS

export const ringostatCallConfigs: Record<CALL_STATUSES, Configs> = {
  [Call_ANSWERED]: {
    color: "#52c41aFF",
    backgroundColor: "#52c41a33",
    text: "Отвечен"
  },
  [CALL_NO_ANSWER]: {
    color: "#8c8c8cFF",
    backgroundColor: "#8c8c8c33",
    text: "Нет ответа"
  },
  [CALL_FAILED]: {
    color: "#f5222dFF",
    backgroundColor: "#f5222d33",
    text: "С ошибками"
  },
  [CALL_BUSY]: {
    color: "#8c8c8cFF",
    backgroundColor: "#8c8c8c33",
    text: "Занято"
  },
  [CALL_REPEATED]: {
    color: "#52c41aFF",
    backgroundColor: "#52c41a33",
    text: "Повторный"
  },
  [CALL_PROPER]: {
    color: "#52c41aFF",
    backgroundColor: "#52c41a33",
    text: "Целевой"
  },
  [CALL_NO_FORWARD]: {
    color: "#f5222dFF",
    backgroundColor: "#f5222d33",
    text: "Не сработала схема переадресации"
  },
  [CALL_VOICEMAIL]: {
    color: "#1890ffFF",
    backgroundColor: "#1890ff33",
    text: "Голосовая почта"
  },
  [CALL_WRONG_EXTENSION]: {
    color: "#f5222dFF",
    backgroundColor: "#f5222d33",
    text: "Не правильно введён добавочный номер (IVR)"
  },
  [CALL_NO_EXTENSION]: {
    color: "#fa8c16FF",
    backgroundColor: "#fa8c1633",
    text: "Не введён добавочный номер (IVR)"
  },
  [CALL_CLIENT_NO_ANSWER]: {
    color: "#8c8c8cFF",
    backgroundColor: "#8c8c8c33",
    text: "Нет ответа клиента (Callback)"
  },
  [CALL_FAILED_FORBIDDEN_DESTINATION]: {
    color: "#f5222dFF",
    backgroundColor: "#f5222d33",
    text: "Запрещенное направление (Исходящие и Callback)"
  },
  [CALL_IN_PROGRESS]: {
    color: "#A165FDFF",
    backgroundColor: "#A165FD33",
    text: "Текущий созвон"
  }
}
