import axios from "axios"
import {ApiAbstract} from "../../abstract/api.abstract"
import {axiosResponce} from "src/interceptor/axios-response"
import {BehaviorSubject, Observable, from, shareReplay, switchMap} from "rxjs"
import {
  OrderRequestDocumentHistoryModel,
  OrderRequestDocumentModel
} from "src/models/manager/order/order-request-document"

export class OrderDocumentService extends ApiAbstract {
  constructor() {
    super("v1/crm/requests")
  }

  private listDocuments$: BehaviorSubject<number> = new BehaviorSubject(null)
  public listDocuments = (orderId: number) => this.listDocuments$.next(orderId)

  public signedDocumentId$: BehaviorSubject<number> = new BehaviorSubject(null)
  public indicateDocumentSign = (documentId: number) => this.signedDocumentId$.next(documentId)

  public documents$: Observable<OrderRequestDocumentModel[]> = this.listDocuments$.pipe(
    switchMap((orderId) =>
      from(
        axios.get<OrderRequestDocumentModel>(this.getUrl(`${orderId}/documents`), {
          params: {skip_error_handling: true, skip_loader: true}
        })
      ).pipe(axiosResponce)
    ),
    shareReplay(1)
  )

  public async post(orderId: number, payload: any): Promise<OrderRequestDocumentModel> {
    const response = await axios.post(this.getUrl(`${orderId}/documents`), payload)
    this.listDocuments(orderId)
    return response.data
  }

  public async history(orderId: number, documentId: number): Promise<OrderRequestDocumentHistoryModel[]> {
    const response = await axios.get(this.getUrl(`${orderId}/documents/${documentId}/history`))
    return response.data
  }

  public async sendSms(orderId: number, id: number, phone: string): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${orderId}/documents/${id}/send_sms`), {phone})
    this.listDocuments(orderId)
    return response.data
  }

  public async signManual(orderId: number, id: number): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${orderId}/documents/${id}/manual_sign`))
    this.listDocuments(orderId)
    return response.data
  }

  public async downloadSignedDocument(orderId: number, id: number, filename: string): Promise<undefined> {
    const response = await axios.get(this.getUrl(`${orderId}/documents/${id}/download`), {responseType: "blob"})
    const data = await response.data

    const url = window.URL.createObjectURL(new Blob([data], {type: "application/pdf"}))

    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${filename}.pdf`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

    return data
  }

  public async publish(orderId: number, id: number): Promise<{uuid: string; link: string}> {
    const response = await axios.post(this.getUrl(`${orderId}/documents/${id}/publish`))
    this.listDocuments(orderId)
    return response.data
  }
}

const orderDocumentService = new OrderDocumentService()
export default orderDocumentService
