import {useTranslation} from "react-i18next"
import {SharedProfileSelect} from "src/components/shared/components/select/profile"
import {SharedInventoryStateSelect} from "src/components/shared/components/select/inventory-state"
import {FormControl} from "src/components/shared/inputs/form-control"

export default function OneTimeMaintenanceFields({doToday, selectedType}: {doToday: boolean; selectedType: string}) {
  const {t} = useTranslation()
  return (
    <>
      <FormControl
        name="name"
        className="form-control"
        rootclassname="flex-1"
        placeholder={t("common.input.placeholder.name")}
        label={t("common.input.name")}
      />
      <div className="flex gap-3 justify-between">
        <SharedProfileSelect
          menuPortalTarget={document.body}
          className="flex-1"
          name="responsible"
          label={t("maintenance.responsible")}
          placeholder={t("common.select.user")}
          listParams={{is_staff: true}}
          isClearable={false}
          isSearchable={false}
        />

        {doToday ? (
          <SharedInventoryStateSelect
            name="state"
            placeholder={t("maintenance.condition")}
            isClearable={true}
            menuPortalTarget={document.body}
            label={t("maintenance.condition")}
            className="flex-1"
            menuPlacement="bottom"
            isSearchable={false}
          />
        ) : selectedType === "date" ? (
          <FormControl
            name="time_threshold"
            type="number"
            label={t("maintenance.notify_before")}
            rootclassname="flex-1"
            className="form-control form-control-number"
            number_only={+true}
            number_decimal={+false}
            suffix={t("common.times_count.days")}
          />
        ) : (
          <FormControl
            name="notify_distance"
            type="number"
            label={t("maintenance.notify_before")}
            rootclassname="flex-1"
            className="form-control form-control-number"
            number_only={+true}
            number_decimal={+false}
            suffix={t("maintenance.km")}
          />
        )}
      </div>
    </>
  )
}
