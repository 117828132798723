import HeaderContent from "../../../components/header/header-content"
import {Link, useNavigate} from "react-router-dom"
import Icon from "../../../components/shared/components/material-icon"
import {useContext, useEffect, useState} from "react"
import {ConfigContext} from "../../../app"
import {useTranslation} from "react-i18next"
import type {ColumnsType} from "antd/es/table"
import {FormProvider, useForm} from "react-hook-form"
import {useDebounce} from "../../../hooks/useDebounce"
import integrationService from "../../../services/integration"
import Table from "../../../components/shared/antd-custom/table"
import {RingostatCall} from "../../../models/integration/ringostat"
import moment from "moment"
import modalService from "../../../components/modal/global/modal.service"
import ClientEditComponent from "../../clients/edit/clients-edit"
import {FormControlDaterange} from "../../../components/shared/inputs/form-control-daterange"
import {ringostatCallConfigs} from "../../../models/manager/ringostat"
import AudioPlayer from "./audio"
import FormSelect from "../../../components/shared/inputs/form-select"
import {RingostatStatus, ringostatStatusOptions, ringostatStatusLabel} from "./const/statusOptions"
import {FormControlMask} from "../../../components/shared/inputs/form-control-mask"
import useQueryParams from "src/hooks/useQuertParams"
import objectFilter from "src/utils/object.filter"
import {LabelModel} from "src/models/label"
import {startOfMonth, endOfMonth} from "src/components/shared/constants"
import RingostatInfo from "./info"

interface RingostatParams {
  status: RingostatStatus
  start_at: string
  end_at: string
  phone: string
}

export function RingostatHistory({query_params}: {query_params?: Partial<RingostatParams>}) {
  const {t} = useTranslation()
  const {searchParams, setSearchParams} = useQueryParams()
  const navigate = useNavigate()
  const {integrationMap, settings} = useContext(ConfigContext)
  const integration = integrationMap["ringostat"]
  const [list, setList] = useState<RingostatCall[]>([])

  const form = useForm<RingostatParams>({
    defaultValues: {
      start_at: startOfMonth.format("YYYY-MM-DD"),
      end_at: endOfMonth.format("YYYY-MM-DD"),
      ...searchParams,
      ...query_params
    }
  })
  const {reset, watch} = form
  const values = watch()
  const searchDebounce = useDebounce(watch("phone"), 500)

  const onLoad = async (params: RingostatParams): Promise<void> => {
    if (integration && integration.connected) {
      if (params.phone && "phone" in params) params.phone = params.phone.replace(/\D/g, "").replace(/^7/, "7")
      if (params === undefined) setSearchParams(objectFilter(params))
      return integrationService.ringostatList(params).then(setList)
    }
  }

  const createClient = (dst: string) => {
    modalService.open({
      size: "lg",
      component: (
        <FormProvider {...form}>
          <ClientEditComponent
            showHeader={false}
            showWrapper={false}
            formValues={{
              phone: dst
            }}
            onCreate={(client) => {
              const params = {
                client_id: client.id
              }
              integrationService.ringostatCreateContact(params)
              modalService.closeModal()
            }}
          />
        </FormProvider>
      )
    })
  }

  const columns: ColumnsType<RingostatCall> = [
    {
      width: 240,
      title: "№",
      dataIndex: "uniqueid",
      key: "uniqueid",
      render: (id) => id
    },
    {
      width: 180,
      title: "Аккаунт системы",
      dataIndex: "employee_fio",
      key: "employee_fio",
      className: "text-nowrap",
      render: (employee_fio) => employee_fio
    },
    {
      width: 180,
      title: "Клиент",
      dataIndex: "yume_client_name",
      key: "yume_client_name",
      className: "text-nowrap",
      render: (yume_client_name, {dst}) =>
        yume_client_name ? (
          yume_client_name
        ) : (
          <div className="flex gap-2 items-center cursor-pointer" onClick={() => createClient(dst)}>
            <Icon icon="add" className="text-primary" />
            <p className="text-primary font-medium text-sm">Создать клиента</p>
          </div>
        )
    },
    {
      width: 180,
      title: "Номер телефона",
      dataIndex: "dst",
      key: "dst",
      className: "text-nowrap",
      render: (dst) => dst
    },
    {
      width: 170,
      title: "Дата звонка",
      dataIndex: "calldate",
      key: "calldate",
      className: "text-nowrap",
      render: (calldate) => moment(calldate).format("DD-MM-YYYY HH:mm")
    },
    {
      width: 170,
      title: "Длительность",
      dataIndex: "duration",
      key: "duration",
      className: "text-nowrap",
      render: (duration) => {
        const momentDuration = moment.duration(duration, "seconds")
        return `${momentDuration.minutes()} минут${momentDuration.minutes() !== 1 ? "ы" : "а"} ${momentDuration.seconds()} секунд`
      }
    },
    {
      width: 240,
      title: "Ссылка на звонок",
      dataIndex: "recording",
      key: "recording",
      className: "text-nowrap",
      render: (recording, record) => recording && <AudioPlayer src={recording} ms={record.duration} />
    },
    {
      width: 170,
      title: "Статус звонка",
      dataIndex: "disposition",
      key: "disposition",
      render: (disposition) => {
        return (
          <span
            style={{
              backgroundColor: ringostatCallConfigs[disposition]?.backgroundColor,
              color: ringostatCallConfigs[disposition]?.color
            }}
            className="flex justify-center items-center min-h-8 w-full rounded-lg p-2 text-[13px] font-medium text-center">
            {ringostatCallConfigs[disposition]?.text}
          </span>
        )
      }
    }
  ]

  useEffect(() => reset({...values, phone: searchDebounce}), [searchDebounce])

  useEffect(() => {
    onLoad(values)
    const sub = watch((params: RingostatParams, {name}) => {
      if (name !== "phone") onLoad(params)
    })
    return () => {
      sub.unsubscribe()
      setList([])
    }
  }, [integration?.connected])

  return (
    <>
      {query_params === undefined && (
        <HeaderContent>
          <div className="flex gap-2 items-center header-content">
            <Link to="/integrations" className="text-2xl font-semibold link">
              {t("integration.header")}
            </Link>
            <Icon icon="chevron_right" />
            <div className="text-2xl font-semibold">{integration?.name}</div>
          </div>
        </HeaderContent>
      )}

      {integration && integration.connected ? (
        <FormProvider {...form}>
          <div className="flex items-start gap-2 mb-8">
            <FormControlMask
              name="phone"
              placeholder="Номеру телефона"
              mask={settings && settings.phone_format}
              className="form-control w-[320px]"
            />
            <FormControlDaterange
              allowClear={true}
              className="col form-control"
              name_start="start_at"
              name_end="end_at"
            />
            <FormSelect<LabelModel>
              name="status"
              options={ringostatStatusOptions}
              value={
                watch("status")
                  ? {value: RingostatStatus[watch("status")], label: ringostatStatusLabel[watch("status")]}
                  : null
              }
              placeholder="Все статусы звонка"
              getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
              getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
            />
          </div>

          <Table
            rowKey={(obj) => obj.id}
            dataSource={list}
            columns={columns}
            locale={{
              emptyText: (
                <div style={{minHeight: 320, display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <p className="w-[400px]">
                    Совершайте звонки IP-телефонии через страницу аренды — здесь вы сможете просматривать всю историю
                  </p>
                </div>
              )
            }}
          />

          {query_params === undefined && (
            <div
              className="bg-white p-4 rounded-lg flex gap-3 mt-20 items-center cursor-pointer"
              onClick={() => {
                integrationService.disconnect("ringostat")
                navigate("/integrations")
              }}>
              <Icon icon="link_off" className="text-alert h-5 w-5" />
              <p className="font-medium text-[15px] text-alert underline">Отключить интеграцию</p>
            </div>
          )}
        </FormProvider>
      ) : (
        <RingostatInfo />
      )}
    </>
  )
}
