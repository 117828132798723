import {Link} from "react-router-dom"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import React, {useContext, useState} from "react"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import {ImageField} from "src/components/shared/image-loader/image"
import wazzup from "src/assets/images/integrations/wazzup.png"
import Step1 from "src/assets/images/integrations/wazzup-step-1.png"
import Step2 from "src/assets/images/integrations/wazzup-step-2.png"
import Step3 from "src/assets/images/integrations/wazzup-step-3.png"
import Step4 from "src/assets/images/integrations/wazzup-step-4.png"
import Step5 from "src/assets/images/integrations/wazzup-step-5.png"
import {IntegrationModel} from "src/models/integration"
import modalService from "src/components/modal/global/modal.service"

interface InnerBlockProps {
  divider?: boolean
  step: string
  description: React.ReactNode
  image?: string
  button?: React.ReactNode
  top?: boolean
}

const InnerBlockInfo: React.FC<InnerBlockProps> = ({
  divider = false,
  step,
  description,
  image,
  button,
  top = false
}) => {
  return (
    <>
      {divider && <hr className="bg-gray-100" />}

      <div className="flex flex-col gap-2">
        <span className="text-gray-370">{step}</span>
        {description}
      </div>

      {top && <div className="flex justify-start">{button && button}</div>}
      {image && <ImageField draggable={false} src={image} className="overflow-hidden  rounded-lg" />}

      {!top && <div className="flex justify-start">{button && button}</div>}
    </>
  )
}

export function WazzupLandingComponent() {
  const {integrationMap} = useContext(ConfigContext)
  const {t} = useTranslation()
  const integration = integrationMap["wazzup"]
  const [activeSection, setActiveSection] = useState<string | null>(null)

  const handleScrollTo = (id: string) => {
    setActiveSection(id)
    document.getElementById(id)?.scrollIntoView({behavior: "smooth"})
  }

  const onConnect = (integration: IntegrationModel) => {
    modalService.open({
      component: (
        <div className="flex flex-col gap-3">
          <h4 className="text-xl font-bold mb-3">{t("integration.connect_integration")}</h4>
          <div className="mb-4 flex flex-row gap-2 justify-between items-center">
            <div className="flex items-center gap-4 w-full">
              <ImageField
                draggable={false}
                src={integration.icon}
                className="w-14 h-14 border p-1 rounded-lg overflow-hidden object-contain"
              />

              <div className="flex gap-2 flex-col flex-1">
                <div className="text-lg font-bold">{integration.name}</div>
                {integration.comment && <div className="text-gray-400">{integration.comment}</div>}
              </div>
            </div>
          </div>
          <div className="flex gap-2 justify-end">
            <button
              onClick={() => {
                handleScrollTo("connection")
                modalService.closeModal()
              }}
              className="btn btn-gray-70 text-[13px] gap-3 p-4 font-medium text-nowrap">
              <Icon icon="menu_book" />
              Инструкция по подключению
            </button>
            <a
              className="btn btn-primary btn-color-white"
              href={"https://wazzup24.ru/"}
              rel="noreferrer"
              target="_blank">
              Перейти на сайт
            </a>
          </div>
        </div>
      )
    })
  }

  return (
    integration && (
      <>
        <HeaderContent>
          <div className="flex gap-2 items-center header-content">
            <Link to="/integrations" className="text-2xl font-semibold link">
              {t("integration.header")}
            </Link>
            <Icon icon="chevron_right" />
            <div className="text-2xl font-semibold">{integration.name}</div>
          </div>
        </HeaderContent>

        {integration && (
          <div className="flex flex-col md:flex-row gap-4 w-full">
            <div className="flex flex-col p-6 gap-4 bg-white rounded-lg mb-6 w-full md:w-auto md:max-w-[400px] self-start sticky top-0">
              <div className="flex flex-col gap-4 w-full">
                <div className="flex gap-5 items-center">
                  <div className="p-1 border rounded-lg">
                    <ImageField
                      draggable={false}
                      src={integration.icon}
                      className="w-7 h-7 min-w-7 min-h-7 rounded-lg overflow-hidden object-contain"
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="font-bold text-2xl">{integration.name}</div>
                    <span className="text-gray-370">{integration.comment}</span>
                  </div>
                </div>

                <button
                  className="btn btn-primary btn-color-white text-[13px] p-4"
                  onClick={() => onConnect(integration)}>
                  Подключить
                </button>
                <button
                  className="btn btn-gray-70 text-[13px] gap-3 w-full p-4 font-medium text-nowrap"
                  onClick={() => handleScrollTo("connection")}>
                  <Icon icon="menu_book" />
                  Инструкция по подключению
                </button>
                <div className="flex flex-col cursor-pointer">
                  <div
                    className={`flex justify-between p-4 border-b border-b-gray-100 ${
                      activeSection === "about" && "text-primary"
                    }`}
                    onClick={() => handleScrollTo("about")}>
                    <span>Об интеграции</span> <Icon icon="chevron_right" />
                  </div>

                  <div
                    className={`flex justify-between p-4 border-b border-b-gray-100 ${
                      activeSection === "how-it-works" && "text-primary"
                    }`}
                    onClick={() => handleScrollTo("how-it-works")}>
                    <span>Как работает интеграция</span> <Icon icon="chevron_right" />
                  </div>

                  <div
                    className={`flex justify-between p-4 border-b border-b-gray-100 ${
                      activeSection === "benefits" && "text-primary"
                    }`}
                    onClick={() => handleScrollTo("benefits")}>
                    <span>Преимущества интеграции</span> <Icon icon="chevron_right" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-8 bg-white p-6 rounded-lg mb-6 max-w-[720px]">
              <div className="flex flex-col gap-3">
                <div id="about" className="font-bold text-xl">
                  Об интеграции
                </div>
                <p>
                  Интеграция с Wazzup – удобная коммуникация с клиентами через мессенджеры! Наша CRM-система теперь
                  интегрирована с Wazzup, что позволяет вашим сотрудникам эффективно взаимодействовать с клиентами через
                  популярные мессенджеры, такие как WhatsApp, Instagram и Telegram
                </p>
              </div>

              <ImageField draggable={false} src={wazzup} className="overflow-hidden" />

              <div className="flex flex-col gap-3">
                <div id="how-it-works" className="font-bold text-xl">
                  Как работает интеграция
                </div>

                <ol className="list-decimal ml-4 space-y-2">
                  <li>
                    <span className="font-medium">Централизованное управление:</span> Все переписки с клиентами из
                    разных мессенджеров будут отображаться прямо в нашей CRM, что позволяет отслеживать коммуникации с
                    клиентами в одном месте
                  </li>
                  <li>
                    <span className="font-medium">История и архив сообщений:</span> Все сообщения сохраняются в системе,
                    что помогает вам легко возвращаться к перепискам, искать по ключевым словам и анализировать
                    взаимодействие с клиентами
                  </li>
                  <li>
                    <span className="font-medium">Массовые рассылки:</span> С помощью Wazzup можно настроить массовые
                    рассылки сообщений, чтобы информировать клиентов об акциях, новостях или важных изменениях
                  </li>
                  <li>
                    <span className="font-medium">Уведомления и автоматизация:</span>
                    Установите автоматические уведомления и ответы на часто задаваемые вопросы, чтобы экономить время
                    сотрудников и улучшать скорость отклика
                  </li>
                  <li>
                    <span className="font-medium">Поддержка многоканального общения:</span> Интеграция Wazzup с нашей
                    CRM обеспечивает возможность подключения сразу нескольких мессенджеров для поддержки клиентов, делая
                    работу более эффективной
                  </li>
                </ol>
              </div>
              <div className="flex flex-col gap-3">
                <div id="benefits" className="font-bold text-xl">
                  Преимущества интеграции
                </div>

                <ul className="list-disc ml-4 space-y-2">
                  <li>Единый интерфейс – все сообщения в CRM без переключения между мессенджерами</li>
                  <li>Простое управление – контроль коммуникаций, распределение задач внутри команды</li>
                  <li>Улучшение клиентского сервиса – быстрые ответы, удобный доступ к истории переписок</li>
                </ul>
              </div>

              <hr className="bg-gray-100" />

              <div className="flex flex-col gap-3">
                <div id="connection" className="font-bold text-xl flex items-center gap-2">
                  <Icon icon="menu_book" />
                  Инструкция по подключению
                </div>

                <InnerBlockInfo
                  divider={false}
                  top
                  step="Шаг 1"
                  image={Step1}
                  description={
                    <span>
                      Зарегистрируйтесь на платформе Wazzup. При подключении к системе у вас запросят название CRM,
                      которую Вы используйте, введите “Yume.Cloud”.
                    </span>
                  }
                  button={
                    <a
                      className="btn btn-primary btn-color-white"
                      href={"https://wazzup24.ru/"}
                      rel="noreferrer"
                      target="_blank">
                      Перейти к подключению
                    </a>
                  }
                />
                <InnerBlockInfo
                  divider
                  step={"Шаг 2"}
                  description={
                    <span>
                      Добавьте канал, который хотите подлючить в первую очередь. Нажмите по кнопке необходимого
                      мессенджера или социальной сети, на которую указывает красная стрелка на снимке экрана ниже.
                      Следуйте инструкциям на сайте для привязки канала
                    </span>
                  }
                  image={Step2}
                />

                <InnerBlockInfo
                  divider
                  step={"Шаг 3"}
                  description={
                    <span>
                      Перейдите по меню в раздел “Интеграции с СRM” и выберите yume.cloud, как указано на снимке экрана
                      ниже.
                    </span>
                  }
                  image={Step3}
                />

                <InnerBlockInfo
                  divider
                  step={"Шаг 4"}
                  description={
                    <span>
                      <span className="text-primary underline">Скопируйте ссылку</span> вашего кабинета yume.cloud и
                      нажмите кнопку “Продолжить”.
                    </span>
                  }
                  image={Step4}
                />

                <InnerBlockInfo
                  divider
                  step={"Шаг 5"}
                  description={
                    <span>
                      Последний шаг – выберите роли и укажите права сотрудникам, чтобы дать доступ к чатам Wazzup,
                      нажмите на кнопку, на которую указывает стрелка на снимке экрана ниже.
                    </span>
                  }
                  button={
                    <a
                      className="btn btn-primary btn-color-white"
                      href={"https://wazzup24.ru/"}
                      rel="noreferrer"
                      target="_blank">
                      Подключить интеграцию
                    </a>
                  }
                  image={Step5}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  )
}
