import HeaderContent from "src/components/header/header-content"
import integrationImage from "src/assets/images/integrations.png"
import BannerImage from "src/assets/images/integrations-banner-bg.png"
import {useEffect, useState} from "react"
import {IntegrationModel} from "src/models/integration"
import integrationService from "src/services/integration"
import {ImageField} from "src/components/shared/image-loader/image"
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import onboardingService from "src/services/onboarding"
import "./styles.scss"

function IntegrationBanner() {
  const {t} = useTranslation()
  return (
    <div className="integration-banner mb-6" style={{backgroundImage: `url(${BannerImage})`}}>
      <div className="flex gap-2 flex-col text-white integration-banner-text">
        <div className="text-3xl font-bold" dangerouslySetInnerHTML={{__html: t("integration.integrate_yume")}}></div>
        <div className="text-[15px]" dangerouslySetInnerHTML={{__html: t("integration.description_yume")}}></div>
      </div>
      <img src={integrationImage} className="integration-banner-image" alt="" />
    </div>
  )
}

export function IntegrationComponent() {
  const [list, setList] = useState<IntegrationModel[]>([])
  const {t} = useTranslation()

  useEffect(() => {
    const sub = integrationService.integrations$.subscribe(setList)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    onboardingService.patch({
      block_name: "final",
      step_name: "learn_integration",
      passed: true
    })
  }, [])
  return (
    <>
      <HeaderContent>
        <div className="text-2xl font-semibold header-content">{t("integration.header")}</div>
      </HeaderContent>

      <IntegrationBanner />

      <div className="integration-list ">
        {list.map((integration) => (
          <div
            key={integration.code}
            className="card-wrapper p-6 flex justify-between flex-col gap-2"
            style={{height: 320}}>
            <div
              style={{
                opacity: integration.disabled ? 0.5 : 1,
                pointerEvents: integration.disabled ? "none" : "inherit"
              }}
              className="flex gap-2 flex-col w-full">
              <div className="flex gal-2 justify-between items-center">
                <div className="flex justify-center items-center w-10 h-10 border border-gray-70 rounded">
                  <ImageField
                    draggable={false}
                    src={integration.icon}
                    className="w-5 h-5 rounded-lg overflow-hidden object-contain"
                  />
                </div>
                {integration.extra && "is_yume_cloud" in integration.extra && integration.extra["is_yume_cloud"] && (
                  <span className="color-primary font-semibold">{t("integration.from_yume")}</span>
                )}
              </div>
              <div className="text-xl font-bold">{integration.name}</div>
              <div className="text-sm" style={{whiteSpace: "pre-line"}}>
                {integration.comment}
              </div>
              <hr className="text-gray-100 w-full mt-5" />
            </div>

            {integration.disabled ? (
              <div className="btn btn-gray-70 gap-3 w-full p-4 font-medium text-nowrap text-gray-400">
                {t("common.soon")}
              </div>
            ) : integration.connected ? (
              <Link to={integration.code} className="btn btn-black text-[13px] p-4 font-semibold w-full">
                {t("common.go_to")}
              </Link>
            ) : (
              <Link to={integration.code} className="btn btn-primary btn-color-white p-4 font-semibold w-full">
                {t("common.more")}
              </Link>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
