import {Outlet, useNavigate} from "react-router-dom"
import {useContext, useEffect, useState} from "react"
import {ConfigContext} from "src/app"
import deliveryService from "src/services/delivery.service"
import {OrderRequestDeliveryCountModel} from "src/models/manager/order/order-request-delivery.model"
import {SidebarWrapper} from "src/components/shared/components/side-wrapper"
import {setChildStyle, setDefaultChildStyle} from "src/main"

export default function DeliveryWrapperComponent() {
  const [counts, setCounts] = useState<OrderRequestDeliveryCountModel>()
  const {isMobile, headerHeight, settings} = useContext(ConfigContext)

  useEffect(() => {
    deliveryService.getCount().then(setCounts)
  }, [])

  useEffect(() => {
    setChildStyle({paddingLeft: 0, paddingRight: 0, paddingTop: isMobile ? headerHeight : 0, paddingBottom: 0})
    return setDefaultChildStyle
  }, [isMobile, headerHeight])

  return counts && (
    <SidebarWrapper
      header={<h3 className="bg-white text-xl font-bold px-3 py-2 border-b border-gray-200 md:border-none md:p-0">Доставка</h3>}
      menus={[
        {link: "/deliveries/pending", label: `Новые запросы - ${counts.pending}`, className: "flex justify-center md:justify-start font-medium"},
        {link: "/deliveries/inprocess", label: `В работе - ${counts.in_progress}`, className: "flex justify-center md:justify-start font-medium"},
        {link: "/deliveries/done", label: `Завершенные - ${counts.done}`, className: "flex justify-center md:justify-start font-medium"}
      ]}>
      <Outlet />
    </SidebarWrapper>
  )
}
