import React, {useContext, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {LabelModel} from "src/models/label"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import {ConfigAddOrEdit} from "src/pages/config/config-add-or-edit"
import {FormControl} from "../../inputs/form-control"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"
import settingService from "src/services/settings.service"
import {CRMContext} from "../../../../pages/crm/list/context"
import funnelService, {Funnel} from "../../../../services/crm/funnel.service"
import {useFormContext} from "react-hook-form"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "options" | "modalProps"

interface CRMFunnelConfig extends Omit<FormSelectConfig<LabelModel>, RemovalKeys> {
  addOption?: boolean
}

const CRMFunnel: React.FC<CRMFunnelConfig> = (config) => {
  const {t} = useTranslation()
  const {watch} = useFormContext()
  const [refreshKey, setRefreshKey] = useState(0)
  const {funnels, funnelForm} = useContext(CRMContext)

  const value = watch(config.name)
  const funnelMap: Record<number, Funnel> = useMemo(() => funnels.reduce((p, c) => ({...p, [c.id]: c}), {}), [funnels])

  const onCreate = async (payload: Partial<LabelModel>) => {
    try {
      await funnelService.post(payload)
      settingService.loadConstants()
      modalService.closeModal()
      setRefreshKey((prevKey) => prevKey + 1)
    } catch (error) {
      console.error("Error creating address:", error)
    }
  }

  const modalProps: ExtendedModalConfig = {
    text: "Новая воронка",
    size: "lg",
    backdropClick: false,
    component: (
      <ConfigAddOrEdit<LabelModel>
        onSubmit={(funnel) => onCreate(funnel).then(() => modalService.closeModal())}
        buttonTitle={t("common.add")}
        title="Новая воронка"
        mode="add">
        <FormControl
          name="name"
          label={t("common.input.name")}
          rootclassname="col"
          className="form-control"
          params={{required: true}}
          required
          placeholder="Enter the funnel"
        />
      </ConfigAddOrEdit>
    )
  }

  return (
    <FormSelect<Funnel>
      key={refreshKey}
      {...config}
      modalProps={modalProps}
      options={funnels}
      value={value ? funnelMap[+value] : undefined}
      getOptionLabel={(option: Funnel) => (option ? String(option.name) : null)}
      getOptionValue={(option: Funnel) => (option ? String(option.id) : null)}
      onChange={(option: any) => {
        const selectedId = option?.id ? +option.id : null
        if (selectedId) funnelForm.setValue("funnel", selectedId)
        localStorage.setItem("selectedFunnelId", selectedId.toString())
      }}
    />
  )
}

export default CRMFunnel
