import {useTranslation} from "react-i18next"
import {FormProvider, useForm} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import modalService from "src/components/modal/global/modal.service"

export default function SaleInventoryPriceList({
  price,
  onChange
}: {
  price: number
  onChange: (payload: {price: number}) => void
}) {
  const {t} = useTranslation()
  const form = useForm({defaultValues: {price}})
  const {handleSubmit, formState} = form

  const onSubmit = handleSubmit(onChange)

  return (
    <FormProvider {...form}>
      <div className="text-2xl font-semibold mb-4">{t("sale.edit.price_title")}</div>
      <FormControl
        label={t("common.input.price")}
        placeholder={t("common.input.placeholder.price")}
        name="price"
        className="form-control"
        rootclassname="mb-3"
      />
      <div className="flex gap-2">
        <button className="btn btn-gray-70 p-3 col" onClick={() => modalService.closeModal()}>
          {t("common.cancel")}
        </button>
        <button className="btn btn-primary btn-color-white col" onClick={onSubmit} disabled={!formState.isValid}>
          {t("common.save")}
        </button>
      </div>
    </FormProvider>
  )
}
