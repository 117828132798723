import React, {useContext, useState} from "react"
import {ConfigContext} from "src/app"
import {useTranslation} from "react-i18next"
import HeaderContent from "src/components/header/header-content"
import {Link} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"
import {ImageField} from "../../../components/shared/image-loader/image"
import egov from "../../../assets/images/integrations/egov.png"
import {IntegrationModel} from "../../../models/integration"
import modalService from "../../../components/modal/global/modal.service"
import {IntegrationConnection} from "../connection.modal"
import {Tooltip} from "antd"
import integrationService from "../../../services/integration"
import {ConfirmModal} from "../../../components/modal/global/confirmModal"

export function EgovIntegrationLanding() {
  const {integrationMap} = useContext(ConfigContext)
  const {t} = useTranslation()
  const integration = integrationMap["egov"]
  const [activeSection, setActiveSection] = useState<string | null>(null)

  const handleScrollTo = (id: string) => {
    setActiveSection(id)
    document.getElementById(id)?.scrollIntoView({behavior: "smooth"})
  }

  const onDisconnect = () => {
    const onConfirm = () => integrationService.disconnect(integration.code).then(() => {})

    modalService.open({
      component: <ConfirmModal message={t("common.disconnect")} onConfirm={onConfirm} delete={true} />
    })
  }

  const onConnect = (integration: IntegrationModel) => {
    modalService.open({
      component: (
        <IntegrationConnection
          integration={integration}
          instruction={
            <div>
              <hr className="bg-gray-100 mt-6" />
              <div className="flex justify-between items-center py-6">
                <div className="flex flex-col gap">
                  <span className="text-base">Сумма заказа</span>
                  <span className="text-primary">Стоимость интеграции</span>
                </div>
                <div className="font-medium text-lg">4 900 ₸/мес</div>
              </div>

              <hr className="bg-gray-100 mb-6" />
            </div>
          }
          additional={<></>}
        />
      )
    })
  }

  function innerField(tarifName, price) {
    return (
      <div className="flex flex-col card-bio flex-1 gap-3 bg-white items-start max-w-[340px]">
        <span className="text-gray-400">{tarifName}</span>
        <div className="text-black font-semibold text-xl">{price} ₸</div>
        <button className="btn btn-primary btn-color-white font-medium w-full" onClick={() => onConnect(integration)}>
          Подключить
        </button>
        <div className="my-6 w-full h-[1px] bg-gray-100"></div>
        <div className="flex flex-col gap-2">
          <span className="flex gap-1.5 items-center">
            <Icon className="text-base text-[#52C41A]" icon="check" />
            <span className="font-medium">Безлимитное количество</span> подписаний
          </span>
          <span className="font-medium flex gap-1.5 items-center">
            <Icon className="text-base text-[#52C41A]" icon="check" />
            Первые 7 дней бесплатно!
          </span>
        </div>
      </div>
    )
  }

  return (
    integration && (
      <>
        <HeaderContent>
          <div className="flex gap-2 items-center header-content">
            <Link to="/integrations" className="text-2xl font-semibold link">
              {t("integration.header")}
            </Link>
            <Icon icon="chevron_right" />
            <div className="text-2xl font-semibold">{integration.name}</div>
          </div>
        </HeaderContent>

        <div className="flex flex-col md:flex-row gap-4 w-full">
          <div className="flex flex-col p-6 gap-4 bg-white rounded-lg mb-6 w-full md:w-auto md:max-w-[400px] self-start sticky top-0">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-row gap-5 items-center">
                <ImageField
                  draggable={false}
                  src={integration.icon}
                  className="w-10 h-10 min-w-10 min-h-10 rounded-lg overflow-hidden object-contain"
                />

                <div className="flex flex-col gap-2">
                  <div className="font-bold text-2xl">{integration.name}</div>
                  <span className="text-gray-370">{integration.comment}</span>
                </div>
              </div>

              {integration.connected ? (
                <div className="btn-group">
                  <div className="btn btn-green-8 gap-1 text-[13px] btn-color-green font-semibold w-full h-11">
                    <Icon icon="check" />
                    {t("common.connected")}
                  </div>
                  <Tooltip placement="top" title="Отключить">
                    <button className="btn btn-red-8 btn-color-red h-11" onClick={onDisconnect}>
                      <Icon icon="link_off" />
                    </button>
                  </Tooltip>
                </div>
              ) : (
                <button
                  className="btn btn-primary btn-color-white text-[13px] p-4"
                  onClick={() => onConnect(integration)}>
                  Подключить
                </button>
              )}

              <div className="flex flex-col cursor-pointer">
                <div
                  className={`flex justify-between p-4 border-b border-b-gray-100 ${
                    activeSection === "about" && "text-primary"
                  }`}
                  onClick={() => handleScrollTo("about")}>
                  <span>Об интеграции</span> <Icon icon="chevron_right" />
                </div>

                <div
                  className={`flex justify-between p-4 border-b border-b-gray-100 ${
                    activeSection === "how-it-works" && "text-primary"
                  }`}
                  onClick={() => handleScrollTo("how-it-works")}>
                  <span>Как работает интеграция</span> <Icon icon="chevron_right" />
                </div>

                <div
                  className={`flex justify-between p-4 border-b border-b-gray-100 ${
                    activeSection === "benefits" && "text-primary"
                  }`}
                  onClick={() => handleScrollTo("benefits")}>
                  <span>Преимущества интеграции</span> <Icon icon="chevron_right" />
                </div>
                <div
                  className={`flex justify-between p-4 border-b border-b-gray-100 ${
                    activeSection === "tarifs" && "text-primary"
                  }`}
                  onClick={() => handleScrollTo("tarifs")}>
                  <span>Тарифы</span> <Icon icon="chevron_right" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8 bg-white p-6 rounded-lg mb-6 max-w-[720px]">
            <div className="flex flex-col gap-3">
              <div id="about" className="font-bold text-xl">
                Об интеграции
              </div>
              <p>
                В нашей CRM-системе реализована интеграция с Egov Mobile, позволяющая подписывать сгенерированные
                документы с помощью электронной цифровой подписи (ЭЦП) через мобильное приложение Egov Mobile
              </p>
            </div>

            <ImageField draggable={false} src={egov} className="overflow-hidden" />
            <div className="flex flex-col gap-3">
              <div id="how-it-works" className="font-bold text-xl">
                Как работает интеграция
              </div>

              <ol className="list-decimal ml-4 space-y-2">
                <li>
                  <span className="font-medium">QR-код:</span> Система генерирует QR-код, который пользователь может
                  отсканировать через приложение Egov Mobile для подписи
                </li>
                <li>
                  <span className="font-medium">Ссылка на документ:</span> Также можно отправить ссылку на документ
                  любым удобным способом. Пользователь может открыть ссылку на мобильном устройстве и завершить процесс
                  подписания через Egov Mobile
                </li>
                <li>
                  <span className="font-medium">Подтверждение данных:</span>Пользователь вводит PIN-код или подтверждает
                  личность с использованием биометрических данных (отпечаток пальца, распознавание лица) для подписи
                  документа
                </li>
                <li>
                  <span className="font-medium">Использование ключа ЭЦП:</span> Процесс подписи осуществляется с
                  использованием ключа ЭЦП, который выдается Национальным удостоверяющим центром Республики Казахстан
                  при первичном входе в Egov Mobile
                </li>
              </ol>
            </div>

            <div className="flex flex-col gap-3">
              <div id="benefits" className="font-bold text-xl">
                Преимущества интеграции
              </div>

              <ul className="list-disc ml-4 space-y-2">
                <li>
                  Поддержка подписания документов как для физических, так и для юридических лиц через приложения Egov
                  Mobile и eGov Business
                </li>
                <li>Безопасная и быстрая авторизация с использованием ЭЦП или биометрии</li>
                <li>Клиенты и партнёры могут подписывать документы в любое время и в любом месте</li>
                <li>Полная интеграция с нашей CRM для автоматизированного и удобного управления документами</li>
              </ul>
            </div>

            <hr className="bg-gray-100" />

            <div className="flex flex-col gap-3">
              <div id="tarifs" className="font-bold text-xl">
                Тарифы
              </div>

              <div className="flex flex-col sm:flex-row gap-3 sm:items-start">{innerField("Standart", "4 900")}</div>
            </div>
          </div>
        </div>
      </>
    )
  )
}
