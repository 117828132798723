import axios from "axios"
import {BehaviorSubject, Observable, catchError, from, map, of, shareReplay, switchMap} from "rxjs"
import {axiosResponce} from "../interceptor/axios-response"
import authService, {TokenModel} from "./auth.service"
import tokenService from "./token.service"

export type CountLimit = number | "unlimited"
export type Limit = false | CountLimit
export enum MetricsType {
  BASE = 0,
  PRO = 1
}

export interface TenantLimitModel {
  memory_space: number | null
  staff_count: number | null
  rental_point_count: number | null
  sale_count: number | null
  request_count: number | null
  inventory_set_count: number | null
  inventory_group_count: number | null
  document_count: number | null
  metrics_type: MetricsType

  sale_enabled: boolean
  bonus_enabled: boolean
  metrics_enabled: boolean
  documents_enabled: boolean
  custom_fields_enabled: boolean
  service_enabled: boolean
  inventorization_enabled: boolean
  groups_enabled: boolean
  sublease_enabled: boolean
  discount_enabled: boolean
  export_enabled: boolean
  inventory_set_enabled: boolean
  notification_enabled: boolean
  document_template_enabled: boolean
  client_rating_enabled: boolean
  client_attraction_enabled: boolean
  widget_enabled: boolean
  delivery_enabled: boolean
  api_enabled: boolean
  kanban_enabled: boolean
}

export enum TenantType {
  AUTO = "auto",
  SPORT = "sport",
  BUGGY = "buggy",
  TRANSFER = "transfer",
  DEFAULT = "default"
}

export interface SubscriptionModel extends TenantLimitModel {
  id: number
  name: "free" | "lite" | "pro"
  extra: any
}

interface TenantPayment {
  id: number
  subscription: number
  current: boolean
  period: number
  price: number
  price_paid: number
  start_at: string
  end_at: string
  addon_users: number
  addon_users_price: number
  addon_users_price_discount: number
  addon_rental_points: number
  addon_rental_points_price: number
  addon_rental_points_price_discount: number
}

export interface InvoiceModel {
  id: number
  link: string
  status: number
  method: string
  amount: number
}

export interface InvoiceDetailModel {
  id: number
  amount: number
  created_at: string
  addon_users: number
  addon_rental_points: number
  subscription: TenantPayment & {previous_payment: TenantPayment | null}
  integration: any
  method: string
  status: number
  outer_id: string
  link: string
}

export interface TenantModel extends TenantLimitModel {
  id: number
  name: string
  address: string
  logo: string
  start_at: string
  end_at: string
  user: number
  created_at: string
  demo: boolean
  type: number
  card_phone: string | null
  card_hash: string | null
  type_code: TenantType
  payment?: TenantPayment
  subscription?: SubscriptionModel
  period?: SubscriptionPeriod
}

export interface SubscriptionPeriod {
  id: number
  subscription: number
  price: number
  days: number
  currency: string
}

export interface Subscription extends TenantLimitModel {
  id: number
  name: string
  order: number
  extra: any
  periods: SubscriptionPeriod[]
}

class TenantService {
  refreshing: boolean = false

  getTenant$ = new BehaviorSubject<void>(null)
  getTenant = () => this.getTenant$.next()

  listSubscriptions$ = new BehaviorSubject<void>(null)
  listSubscriptions = () => this.listSubscriptions$.next()

  exists$: Observable<boolean> = from(axios.get<{exists: boolean}>("/exists", {params: {custom_error_handling: true}})).pipe(
    axiosResponce,
    map(({exists}) => exists),
    catchError(e => {
      if (e.response.status === 404) return of(false)
      return e
    }),
    shareReplay(1)
  )

  tenant$: Observable<TenantModel> = authService.authorized$.pipe(
    switchMap((logged) =>
      logged
        ? this.getTenant$.pipe(
            switchMap(() => from(axios.get<TenantModel>("/v1/tenant/account/")).pipe(axiosResponce)),
            shareReplay(1)
          )
        : of(undefined)
    ),
    shareReplay(1)
  )

  subscriptions$: Observable<Subscription[]> = this.getTenant$.pipe(
    switchMap(() => from(axios.get<Subscription[]>("/v1/tenant/subscriptions/")).pipe(axiosResponce)),
    shareReplay(1)
  )

  async createInvoice(payload: any): Promise<InvoiceModel> {
    const response = await axios.post("/v1/tenant/subscriptions/payments/", payload)
    return response.data
  }

  async getInvoice(invoiceId: number): Promise<InvoiceDetailModel> {
    const response = await axios.get(`/v1/tenant/subscriptions/payments/${invoiceId}/`)
    return response.data
  }

  async moveToProduction() {
    const response = await axios.post<TokenModel>("/v1/tenant/account/production/", {})
    tokenService.setToken(response.data as TokenModel)
    window.location.reload()
  }

  async sendLogo(payload: any): Promise<string> {
    const response = await axios.post<string>("/v1/tenant/logo/", payload)
    return response.data
  }
}

const tenantService = new TenantService()
export default tenantService
