import {useContext} from "react"
import {Link, Navigate, Outlet, RouteObject} from "react-router-dom"
import {ToastContainer} from "react-toastify"
import {GlobalModal} from "src/components/modal/global/globalModal"
import AuthComponent from "src/pages/auth/auth"
import SchedulesListComponent from "src/pages/schedule"
import {clientRoutes} from "src/routers/client"
import {configRoutes} from "src/routers/configuration"
import {inventoryRoutes} from "src/routers/inventory"
import {orderRoutes} from "src/routers/order"
import {settingRoutes} from "src/routers/settings"
import {transferRoutes} from "src/routers/transfer"
import {GeolocationComponent, DeviceListComponent, DeviceTripComponent, TripListComponent} from "src/pages/geolocation"
import {ConfigContext} from "src/app"
import Main from "src/main"
import {Application} from "src/app"
import integrationRoutes from "./integration"
import {ProtectedRoute} from "./guard"
import {reportRoutes} from "./report"
import TourHandler from "../utils/tour-handler"
import AcceptComponent from "../pages/auth/accept"
import {SubscriptionInvoice} from "src/pages/subscription/invoice"
import Icon from "src/components/shared/components/material-icon"
import {crmRoutes} from "./crm"

const MainRedirect = () => {
  const {tenantConfigs, logged} = useContext(ConfigContext)
  if (logged === false) return <Navigate to="/auth" />
  if (tenantConfigs.mainRoute) return <Navigate to={tenantConfigs.mainRoute} />
}

export const routes: RouteObject[] = [
  {
    path: "",
    element: (
      <Application>
        <Outlet />
        <TourHandler />
        <GlobalModal />
        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={true}
          newestOnTop={true}
          draggable={false}
          theme="light"
        />
      </Application>
    ),
    children: [
      {
        path: "",
        element: (
          <Main>
            <Outlet />
          </Main>
        ),
        errorElement: (
          <Main>
            <div className="w-full h-full flex flex-col items-center justify-center p-4 gap-3">
              <div className="mb-0 text-2xl font-semibold">Произошла ошибка</div>
              <div className="text-[128px] font-semibold">\(o_o)/</div>
              <div className="flex gap-3">
                <button className="btn bg-primary text-white font-medium" onClick={() => location.reload()}>
                  Обновить страницу
                </button>
                <a
                  className="flex gap-2 p-3 py-2 items-center cursor-pointer text-black border border-black rounded-lg justify-content-between"
                  href="https://wa.me/+7779479990"
                  target="_blank"
                  rel="noreferrer">
                  <div className="flex gap-2 items-center">
                    <Icon icon="help" />
                    <div>Написать в поддержку</div>
                  </div>
                </a>
              </div>
            </div>
          </Main>
        ),
        children: [
          {path: "", element: <MainRedirect />},
          {
            path: "calendar",
            element: (
              <ProtectedRoute accessKey="calendar">
                <SchedulesListComponent />
              </ProtectedRoute>
            )
          },
          {
            path: "geolocation",
            element: (
              <ProtectedRoute accessKey="geolocation">
                <GeolocationComponent />
              </ProtectedRoute>
            ),
            children: [
              {
                path: "",
                element: <DeviceListComponent />
              },
              {
                path: ":deviceId/trips",
                element: <TripListComponent />,
                children: [{path: ":tripId", element: <DeviceTripComponent />}]
              }
            ]
          },

          ...settingRoutes,
          ...configRoutes,
          ...reportRoutes,
          ...inventoryRoutes,
          ...clientRoutes,
          ...orderRoutes,
          ...integrationRoutes,
          ...transferRoutes,
          ...crmRoutes
        ]
      },
      {
        path: "auth",
        element: <AuthComponent />
      },
      {
        path: "accept",
        element: <AcceptComponent />
      },
      {
        path: "payment/:id",
        element: <SubscriptionInvoice />
      }
    ]
  },
  {
    path: "*",
    element: (
      <div className="w-full h-screen flex flex-col items-center justify-center p-4 gap-6">
        <div className="text-2xl font-semibold">Страница не найдена</div>
        <Link to="/" className="btn bg-primary text-white font-medium">
          Перейти на главное
        </Link>
      </div>
    )
  }
]
