import {FormProvider, useForm} from "react-hook-form"
import React, {useContext, useEffect, useMemo, useRef, useState} from "react"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {isNil} from "src/utils/isNil"
import useOnScreen from "src/hooks/useOnScreen"
import ridesService from "src/services/rides.service"
import RideCard from "./detail/ride-card"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import {FormControl} from "src/components/shared/inputs/form-control"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import RideListTableComponent from "./detail/ride-table-component"
import {createPortal} from "react-dom"
import transportationService from "src/services/transportation.service"
import {useNavigate} from "react-router-dom"
import MultimodeDatePicker from "src/components/shared/antd-custom/multimode-range-picker"
import TransferAgent from "src/components/shared/components/select/transfer-agent"
import SmoothOperator from "src/components/shared/components/button/action/smooth-operator"
import {GroupType} from "../../../../services/auth.service"

export default function RidesFilter(props: {request: string}) {
  const {request} = props
  const currentRoute = useCurrentRoute()
  const form = useForm({
    defaultValues: {
      page: 1,
      pageSize: 10,
      status: request,
      start_at: "",
      end_at: ""
    }
  })
  const elementRef = useRef<HTMLDivElement>()
  const isOnScreen = useOnScreen(elementRef)
  const {isMobile, profile} = useContext(ConfigContext)
  const {t} = useTranslation()
  const {reset, watch} = form
  const navigate = useNavigate()
  const values = watch()
  const ignoreNextWatchRef = useRef(false)

  const [list, setList] = useState<ListModel<TransferRideModel>>(EMPTY_LIST)
  const isDriver = useMemo(() => {
    if (!profile) return false
    return profile.group_type === GroupType.DRIVER
  }, [profile])

  const onLoad = async (params: any): Promise<void> => {
    const response = await ridesService.list(params)
    setList((prev) => ({
      ...response,
      results: params?.page === 1 ? response.results : [...prev.results, ...response.results]
    }))
  }

  const createTransfer = async () => {
    const response = await transportationService.post({})
    if (response?.id) {
      navigate(`/transportation/${response.id}`)
    }
  }

  useEffect(() => {
    onLoad({...values, status: isNil(request) ? null : request})
    return () => setList(EMPTY_LIST)
  }, [currentRoute])

  useEffect(() => {
    if (isOnScreen && list.next !== false && !!list.next) {
      reset({...values, page: values.page + 1})
    }
  }, [isOnScreen])

  useEffect(() => {
    onLoad({...values, status: isNil(request) ? null : request})
    const sub = watch((params, {name}) => {
      if (ignoreNextWatchRef.current) {
        ignoreNextWatchRef.current = false
        return
      }
      if (["search", "transfer__agent", "start_at", "end_at"].includes(name)) {
        const updatedParams = {...params, page: 1}
        ignoreNextWatchRef.current = true
        reset(updatedParams)
        onLoad(updatedParams)
      } else {
        onLoad(params)
      }
    })

    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [])

  useEffect(() => {
    reset({...values, page: 1})
  }, [])

  return (
    <FormProvider {...form}>
      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0">
        <div className="flex gap-2 justify-between">
          <div
            className={`w-full p-4 md:p-0 border-t md:border-t-0 md:w-auto bg-white md:bg-transparent md:flex md:gap-2 mb-3`}>
            {!isMobile && (
              <>
                <FormControl
                  icon="search"
                  rootclassname="col"
                  className="form-control lg:flex-1 lg:min-w-[320px]"
                  name="search"
                  placeholder={t("transfers.input.search")}
                />
                <TransferAgent
                  className="col"
                  placeholder={t("transfers.form.customer")}
                  name="transfer__agent"
                  isClearable={true}
                  isSearchable={false}
                  required
                />
              </>
            )}
            <MultimodeDatePicker name_start="start_at" name_end="end_at" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {isMobile && list.results.map((ride) => <RideCard key={ride.id} ride={ride} />)}
        <div ref={elementRef}></div>
      </div>

      {!isMobile && (
        <div>
          <RideListTableComponent list={list} />
          <div ref={elementRef}></div>
        </div>
      )}

      {isMobile &&
        !isDriver &&
        createPortal(
          <button
            className="fixed z-[100]  bottom-20 md:bottom-10 right-4 h-14 w-14 rounded-full bg-primary border border-white border-opacity-20 shadow-[0_0_16px_0_rgba(0, 0, 0, 0.16)]"
            style={{padding: 10, overflow: "hidden", width: "100%"}}
            onClick={() => createTransfer()}>
            <Icon icon="add" className={"text-white text-2xl"} />
          </button>,
          document.body
        )}
      {!isMobile && <SmoothOperator />}
    </FormProvider>
  )
}
