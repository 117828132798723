import React, {useContext, useEffect} from "react"
import {Navigate, useLocation, useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import {TenantTypeConfigs} from "../mock/functionality-access"
import hasAccess from "../utils/hasAccess"
import {GroupType} from "../services/auth.service"
import {TenantType} from "src/services/tenant.service"

type Paths<T> = T extends object ? {[K in keyof T]: `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}`}[keyof T] : never

interface ProtectedRouteProps {
  children: React.ReactElement
  accessKey: Paths<TenantTypeConfigs["accesses"]>
  redirectRoute?: string
}

const restrictedForDriver = ["/transportation", "/drivers", "/cities", "/reports"]

export function ProtectedRoute({children, accessKey, redirectRoute}: ProtectedRouteProps) {
  const {profile, tenantConfigs: {mainRoute, type, accesses}} = useContext(ConfigContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!profile) return
    if (type !== TenantType.TRANSFER) return

    const pathname = location.pathname
    const restricted = restrictedForDriver.some((path) => pathname.startsWith(path))

    if (pathname.startsWith("/orders")) navigate("/rides")
    if (!profile.is_superuser && profile.group_type === GroupType.DRIVER && restricted) navigate("/rides")
  }, [profile, type, location.pathname])

  if (!hasAccess(accesses, accessKey)) return <Navigate to={redirectRoute ?? mainRoute} />
  return children
}
