import {Link} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"
import {ImageField} from "src/components/shared/image-loader/image"
import {OrderRequestInventoryError} from "src/pages/orders/details/context/order-context.model"
import {useTranslation} from "react-i18next"

export function InventoryError({inventoryError}: {inventoryError: OrderRequestInventoryError}) {
  const {t} = useTranslation()
  return (
    <div className="flex flex-col gap-2">
      <div className="text-base font-medium text-center mb-2">{inventoryError.error}</div>

      {inventoryError.inventories.map((inventory) => (
        <div className="card-bio p-2 md:p-3 flex flex-row gap-2 justify-between" key={inventory.object_id}>
          <div className="flex gap-2 items-center">
            <ImageField
              draggable={false}
              src={inventory.image}
              className="bg-gray-70 object-contain w-12 h-12 rounded-lg"
            />
            <div className="flex gap-1 flex-col items-start justify-start">
              <span className="text-[14px] font-medium">{inventory.name}</span>
              <div className="flex gap-1 items-center text-nowrap text-gray-400 rounded-lg bg-gray-70 px-1">
                <Icon icon="category" className="text-sm" />
                <span className="text-[12px]">{inventory.unique_id}</span></div>
            </div>
          </div>

          <Link
            to={`/orders/${inventory.request_id}/all`}
            className="btn btn-card p-2 md:p-3 flex gap-2 text-base font-medium">
            <span>
              {t("orders.type.rent")} {inventory.request_id}
            </span>
            <Icon icon="chevron_right" />
          </Link>
        </div>
      ))}
    </div>
  )
}
