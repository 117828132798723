import {useTour} from "@reactour/tour"
import {useContext} from "react"
import {ConfigContext} from "src/app"
import onboardingService from "src/services/onboarding"
import modalService from "src/components/modal/global/modal.service"
import Icon from "../material-icon"
import YoutubePlayer from "../video-player/youtube-player"

export default function VideoModal({nextStep}: {nextStep?: number}) {
  const {setCurrentStep, currentStep} = useTour()
  const {currentTour} = useContext(ConfigContext)

  return (
    <div className="flex flex-col gap-5">
      <p className="font-bold text-[18px] text-black">Видео-инструкция по СМС и Egov подписанию</p>
      <YoutubePlayer src="https://www.youtube.com/embed/MmbCzdGTbmg?si=NrY4JwICbB7tvyQz" className="w-full h-[400px] rounded-xl" />
      <Icon
        icon="close_small"
        className="text-2xl text-gray-400 absolute top-[10px] right-[10px] cursor-pointer"
        onClick={() => {
          if (nextStep) {
            onboardingService
              .patch({
                block_name: currentTour,
                step_name: currentStep,
                passed: true
              })
              .then(() => setCurrentStep(nextStep))
          } else {
            onboardingService
              .patch({
                block_name: currentTour,
                step_name: currentStep,
                passed: true
              })
              .then(() => setCurrentStep((prevState) => prevState + 1))
          }
          modalService.closeAllModal()
        }}
      />
    </div>
  )
}
