import React from "react"
import {useTour} from "@reactour/tour"
import onboardingService from "src/services/onboarding"
import Icon from "./material-icon"

interface TourTooltipProps {
  title: string
  subTitle?: string
  skip?: boolean
  custom_button?: JSX.Element
  nextButton?: boolean
  nextStep?: number
  skipStep?: number
  currentTour?: string
  finish?: boolean
}

const TourTooltip: React.FC<TourTooltipProps> = ({
  title,
  subTitle,
  skip,
  custom_button,
  nextButton,
  nextStep,
  skipStep,
  currentTour,
  finish
}) => {
  const {currentStep, setCurrentStep, setIsOpen} = useTour()

  return (
    <div className="relative flex flex-col gap-2 h-full w-full bg-primary rounded-lg p-4">
      <div className="absolute top-2 right-2 cursor-pointer">
        <Icon icon='close' className="h-5 w-5 text-white" onClick={() => setIsOpen(false)} />
      </div>

      <p className="font-semibold text-[15px] text-white">{title}</p>
      {subTitle && <p className=" font-normal text-sm text-white text-opacity-80">{subTitle}</p>}

      <div className="flex justify-between pt-2">
        {finish && (
          <button
            onClick={() =>
              onboardingService
                .patch({
                  block_name: currentTour,
                  step_name: currentStep,
                  passed: true
                })
                .then(() => setCurrentStep((prevState) => prevState + 1))
            }
            className="btn btn-white px-10 font-medium text-xs text-black">
            Завершить
          </button>
        )}
        {nextButton && (
          <button
            onClick={() =>
              onboardingService
                .patch({
                  block_name: currentTour,
                  step_name: currentStep,
                  passed: true
                })
                .then(() => setCurrentStep((prevState) => prevState + 1))
            }
            className="btn btn-white px-10 font-medium text-xs text-black">
            Далее
          </button>
        )}
        {custom_button && (
          <div
            onClick={() =>
              nextStep &&
              onboardingService
                .patch({
                  block_name: currentTour,
                  step_name: currentStep,
                  passed: true
                })
                .then(() => setCurrentStep(nextStep))
            }>
            {custom_button}
          </div>
        )}
        {skip && (
          <button
            className="font-medium text-xs btn btn-transparent btn-color-white text-opacity-80"
            onClick={() => {
              const skipToStep = (currentStep: number, targetStep: number) => {
                if (currentStep >= targetStep) return

                onboardingService
                  .patch({
                    block_name: currentTour,
                    step_name: currentStep,
                    passed: true
                  })
                  .then(() => {
                    setCurrentStep(currentStep + 1)
                    skipToStep(currentStep + 1, targetStep)
                  })
              }

              if (skipStep) {
                skipToStep(currentStep, skipStep)
              } else {
                onboardingService
                  .patch({
                    block_name: currentTour,
                    step_name: currentStep,
                    passed: true
                  })
                  .then(() => setCurrentStep((prev) => prev + 1))
              }
            }}>
            Пропустить
          </button>
        )}
      </div>
    </div>
  )
}

export default TourTooltip
