import axios from "src/interceptor"
import {AxiosResponse} from "axios"
import {BehaviorSubject, Observable, catchError, from, map, of, shareReplay, switchMap} from "rxjs"
import tokenService from "./token.service"

export interface TokenModel {
  access: string
  refresh?: string
}

export enum GroupType {
  ADMIN = "admin",
  MANAGER = "manager",
  DRIVER = "driver"
}

export interface ProfileModel {
  id: number
  last_login: string
  username: string
  first_name: string
  last_name: string
  email: string
  group: number
  group_type: GroupType
  is_staff: boolean
  is_sublease: boolean
  is_superuser: boolean
  point: number
  points: number[]
  user_permissions: number[]
  perms: string[]
  avatar: string

  password?: string
}

class AuthService {
  getProfile$ = new BehaviorSubject<void>(null)
  getProfile = () => this.getProfile$.next()

  profile$: Observable<ProfileModel | null> = this.getProfile$.pipe(
    switchMap(() =>
      tokenService.token$.pipe(
        switchMap((token) =>
          token
            ? from(axios.get<ProfileModel>("/v1/auth/profile/")).pipe(map((responce: AxiosResponse) => responce.data))
            : of(null)
        )
      )
    ),
    shareReplay(1)
  )

  authorized$ = this.profile$.pipe(
    map((p) => {
      if (!p) return false
      return !!p
    })
  )

  public async updateProfile(payload: any): Promise<ProfileModel> {
    const res = await axios.patch("/v1/auth/profile/", payload)
    return res.data
  }

  public async login(username: string, password: string): Promise<TokenModel> {
    const res = await axios.post("/v1/auth/login/", {username, password})
    tokenService.setToken(res.data as TokenModel)
    this.getProfile()
    return res.data
  }

  public async crossTokenLogin(token: string): Promise<TokenModel> {
    const res = await axios.post("/v1/auth/cross_login/", {token})
    tokenService.setToken(res.data as TokenModel)
    this.getProfile()
    return res.data
  }

  public async logout(): Promise<TokenModel> {
    const res = await axios.post("/v1/auth/logout/", {
      refresh: tokenService.getRefresh
    })
    tokenService.removeToken()
    this.getProfile()
    return res.data
  }
  public async onInvite(payload: any): Promise<TokenModel> {
    const res = await axios.post("/v1/tenant/invite/email/", payload)
    return res.data
  }

  public async onInviteAccept(token: string, password: string): Promise<any> {
    const res = await axios.post(`/v1/tenant/invite/accept/${token}/`, {password})
    tokenService.setToken(res.data as TokenModel)
    this.getProfile()
    return res.data
  }
}

export default new AuthService()
