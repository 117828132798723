import {HTMLAttributes, useEffect, useRef, useState} from "react"
import "./tabs.scss"
import clsx from "clsx"

export interface TabPanelConfig {
  key: string | number
  label: string | JSX.Element | ((config: TabPanelConfig) => JSX.Element)
  active?: boolean
  disabled?: boolean
  onClick?: () => void
  isMobile?: boolean
  hidden?: boolean
  tabHeaderClassNames?: string
}

export interface TabConfig extends HTMLAttributes<HTMLDivElement> {
  items: TabPanelConfig[]
  onClick?: (TabPanelConfig) => void
  activeTab?: string | number
  rightChildren?: JSX.Element
  isMobile?: boolean
  tabClassNames?: string
  tabHeaderClassNames?: string
}

export const TabHeader = (config: TabPanelConfig) => {
  const {label, active, disabled, onClick, tabHeaderClassNames} = config
  const className = clsx("tab-nav-link px-3 py-3 md:px-5 md:py-4 text-nowrap", {
    disabled,
    active,
    tabHeaderClassNames
  })

  if (typeof label === "function") {
    return <span className="self-stretch flex items-center justify-center" onClick={onClick}>{label(config)}</span>
  }
  return (
    <span className={className} onClick={onClick}>
      {label}
    </span>
  )
}

export const TabPanel = ({
  isMobile,
  className,
  style,
  items,
  activeTab,
  onClick,
  rightChildren,
  tabClassNames,
  id,
  tabHeaderClassNames,
  ...rest
}: TabConfig) => {
  const ref = useRef()
  const [isLeft, setIsLeft] = useState(false)
  const [isRight, setIsRight] = useState(false)

  const handleScroll = () => {
    const el = ref.current as HTMLDivElement
    if (!el) return
    setIsLeft(el.scrollLeft > 0)
    setIsRight(el.scrollWidth > el.clientWidth + el.scrollLeft)
  }

  useEffect(() => {
    const el = ref.current as HTMLDivElement
    if (!el) return

    handleScroll()
    el.addEventListener('scroll', handleScroll)
    return () => el.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div id={id} className={clsx("relative flex items-end overflow-auto", className)}>
      <div className={clsx(
        "absolute left-0 top-0 bottom-0 w-6 bg-gradient-to-r from-black/15 to-transparent pointer-events-none z-10",
        "transition-opacity duration-300",
        {"opacity-100": isLeft, "opacity-0": !isLeft}
      )} />
      <div className={clsx(
        "absolute right-0 top-0 bottom-0 w-6 bg-gradient-to-l from-black/15 to-transparent pointer-events-none z-10",
        "transition-opacity duration-300",
        {"opacity-100": isRight, "opacity-0": !isRight}
      )} />
      <div
        className={clsx("tab scrollbar-hide border-bottom flex-1 px-0 pt-0 max-w-screen", tabClassNames)}
        style={style}
        ref={ref}
      >
        <div className="tab-nav gap-2 md:gap-8 px-3 md:px-0">
          {items.filter((t) => !t.hidden).map((item) => (
            <TabHeader
              {...item}
              key={item.key}
              active={activeTab && item.key === activeTab}
              onClick={onClick ? () => onClick(item) : undefined}
              isMobile={isMobile}
              tabHeaderClassNames={tabHeaderClassNames}
              {...rest}
            />
          ))}
        </div>
      </div>

      {rightChildren}
    </div>
  )
}
