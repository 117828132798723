import {useContext, useEffect, useState} from "react"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {FormProvider, useForm} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import {ConfigContext} from "src/app"
import {useNavigate, useParams} from "react-router"
import {useTranslation} from "react-i18next"
import transportationService from "src/services/transportation.service"
import TransferCity from "src/components/shared/components/select/transfer-city"
import FormSelect from "src/components/shared/inputs/form-select"
import {Direction} from "src/models/manager/transfer/transfer"
import {directionType} from "../../../constants"

const AddressForm = () => {
  const {isMobile} = useContext(ConfigContext)
  const navigate = useNavigate()
  const {cityId, addressId} = useParams()
  const [selectedOption, setSelectedOption] = useState<Direction | null>(null)
  const form = useForm<any>({
    defaultValues: {
      city: cityId,
      type: null,
      name: "",
      link: null
    }
  })

  useEffect(() => {
    const loadAddress = async () => {
      if (addressId) {
        const address = await transportationService.getAddress(+addressId)
        if (address) {
          form.reset({
            ...address,
            type: directionType.find((dir) => dir.value === address.type)
          })
        }
      }
    }

    loadAddress()
  }, [addressId, form])

  const {t} = useTranslation()
  const {hideMobileMenu, showMobileMenu} = useContext(ConfigContext)

  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  const onSubmit = form.handleSubmit(async (data: any) => {
    const payload = {
      ...data,
      city: data.city ? +cityId : null
    }
    if (addressId) {
      await transportationService.putAddress(+addressId, payload)
    } else {
      await transportationService.postAddress(payload)
    }
    navigate(-1)
  })

  const onDelete = async () => {
    await transportationService.deleteAddress(+addressId)
    navigate(-1)
  }

  return (
    <>
      <HeaderContent>
        <div className="flex header-content p-0">
          <div className="flex items-center justify-normal md:justify-between gap-2 w-full px-4 py-3">
            <div className="flex items-center gap-2 cursor-pointer">
              <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />
              <div className="text-lg font-semibold md:text-2xl">
                {addressId ? t("transfers.action.edit_direction") : t("transfers.action.new_direction")}
              </div>
            </div>

            {!isMobile && (
              <div className="flex gap-1 flex-col px-4 py-2 md:px-0 md:py-0">
                <button
                  className="btn btn-primary btn-color-white text-base font-medium leading-5 py-4"
                  onClick={onSubmit}>
                  {t("common.save")}
                </button>
              </div>
            )}
          </div>
        </div>
      </HeaderContent>
      <FormProvider {...form}>
        <div className="-mx-4 -mt-4 md:mx-0 md:mt-0 flex flex-col gap-0.5">
          <div className="max-w-[768px] w-full rounded-none md:rounded-lg m-auto bg-white flex flex-col gap-5 md:shadow flex-1 p-4">
            <TransferCity
              className="col"
              label={t("transfers.main.city")}
              placeholder={t("transfers.main.city")}
              name="city"
              isClearable={true}
              isSearchable={false}
              addOption
              disabled={!!cityId}
            />
            <FormSelect<Direction>
              name="type"
              label={t("transfers.form.category_of_direction")}
              options={directionType}
              getOptionValue={(option: Direction | null) => option?.value}
              getOptionLabel={(option: Direction | null) => option?.name}
              onChange={(newValue: Direction | null) => setSelectedOption(newValue)}
              value={selectedOption}
            />
            <FormControl
              name="name"
              type="text"
              label={t("transfers.form.name_of_the_direction")}
              rootclassname="flex-1"
              className="form-control form-control-number"
              required
              params={{required: true}}
            />
            <FormControl
              name="link"
              type="text"
              label={t("transfers.action.link_to_the_map")}
              rootclassname="flex-1"
              className="form-control form-control-number"
              params={{
                pattern: {
                  value: /^(https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*)$/i,
                  message: t("transfers.action.enter_valid_url")
                }
              }}
            />
            <button
              className="btn bg-red-200 text-alert focus:text-alert hover:text-alert text-base font-medium leading-5 py-4"
              onClick={() => onDelete()}>
              <Icon icon="delete" />
              {t("common.delete")}
            </button>
          </div>
        </div>

        {isMobile && (
          <div className="fixed left-0 bottom-0 right-0 flex items-center w-full shadow-menu bg-white min-h-[72px] z-[100]">
            <div className="flex gap-1 flex-col w-full px-4 py-2 md:px-0 md:py-0">
              <button
                className="btn btn-primary btn-color-white text-base font-medium leading-5 py-4"
                disabled={!form.formState.isValid}
                onClick={onSubmit}>
                {t("common.save")}
              </button>
            </div>
          </div>
        )}
      </FormProvider>
    </>
  )
}

export default AddressForm
