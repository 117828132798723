import React, {useContext, useState} from "react"
import {ConfigContext} from "src/app"
import {ImageField} from "src/components/shared/image-loader/image"
import sms from "src/assets/images/integrations/sms.png"
import Icon from "src/components/shared/components/material-icon"
import {IntegrationModel} from "src/models/integration"
import modalService from "src/components/modal/global/modal.service"
import {IntegrationConnection} from "../connection.modal"

export function SmsLanding() {
  const {integrationMap} = useContext(ConfigContext)
  const integration = integrationMap["sms"]
  const [activeSection, setActiveSection] = useState<string | null>(null)

  const handleScrollTo = (id: string) => {
    setActiveSection(id)
    document.getElementById(id)?.scrollIntoView({behavior: "smooth"})
  }

  function innerField(tarifName, price, message) {
    return (
      <div className="flex flex-col card-bio flex-1 gap-3 bg-white items-start">
        <span className="text-gray-400">{tarifName}</span>
        <div className="text-black font-semibold text-xl">{price} ₸</div>
        <button className="btn btn-primary btn-color-white font-medium w-full" onClick={() => onConnect(integration)}>
          Подключить
        </button>
        <div className="my-6 w-full h-[1px] bg-gray-100"></div>
        <div className="flex flex-col gap-2">
          <span className="flex gap-1.5 items-center">
            <Icon className="text-base text-[#52C41A]" icon="check" />
            {message} сообщений
          </span>
          <span className="flex gap-1.5 items-center">
            <Icon className="text-base text-[#52C41A]" icon="check" />
            Безлимитный период
          </span>
        </div>
      </div>
    )
  }

  const onConnect = (integration: IntegrationModel) => {
    modalService.open({
      component: <IntegrationConnection integration={integration} />
    })
  }
  return (
    integration &&
    !integration.connected && (
      <div className="flex flex-col md:flex-row gap-4 w-full">
        <div className="flex flex-col p-6 gap-4 bg-white rounded-lg mb-6 w-full md:w-auto md:max-w-[400px] self-start sticky top-0">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-row gap-5 items-center">
              <div className="p-3 border rounded-lg">
                <ImageField
                  draggable={false}
                  src={integration.icon}
                  className="w-5 h-5 min-w-5 min-h-5 rounded-lg overflow-hidden object-contain"
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="font-bold text-2xl">{integration.name}</div>
                <span className="text-gray-370">{integration.comment}</span>
              </div>
            </div>
            <button className="btn btn-primary btn-color-white text-[13px] p-4" onClick={() => onConnect(integration)}>
              Подключить
            </button>
            <div className="flex flex-col cursor-pointer">
              <div
                className={`flex justify-between p-4 border-b border-b-gray-100 ${
                  activeSection === "about" && "text-primary"
                }`}
                onClick={() => handleScrollTo("about")}>
                <span>Об интеграции</span> <Icon icon="chevron_right" />
              </div>

              <div
                className={`flex justify-between p-4 border-b border-b-gray-100 ${
                  activeSection === "how-it-works" && "text-primary"
                }`}
                onClick={() => handleScrollTo("how-it-works")}>
                <span>Как работает интеграция</span> <Icon icon="chevron_right" />
              </div>

              <div
                className={`flex justify-between p-4 border-b border-b-gray-100 ${
                  activeSection === "benefits" && "text-primary"
                }`}
                onClick={() => handleScrollTo("benefits")}>
                <span>Преимущества интеграции</span> <Icon icon="chevron_right" />
              </div>
              <div
                className={`flex justify-between p-4 border-b border-b-gray-100 ${
                  activeSection === "tarifs" && "text-primary"
                }`}
                onClick={() => handleScrollTo("tarifs")}>
                <span>Тарифы</span> <Icon icon="chevron_right" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 bg-white p-6 rounded-lg mb-6 max-w-[720px]">
          <div className="flex flex-col gap-3">
            <div id="about" className="font-bold text-xl">
              Об интеграции
            </div>
            <p>
              Интеграция с SMS-подписанием позволяет быстро и безопасно подписывать документы, подтверждая личность
              через код из SMS. Это упрощает процесс заключения сделок.
            </p>
          </div>

          <ImageField draggable={false} src={sms} className="overflow-hidden" />
          <div className="flex flex-col gap-3">
            <div id="how-it-works" className="font-bold text-xl">
              Как работает интеграция
            </div>

            <ol className="list-decimal ml-4 space-y-2">
              <li>
                <span className="font-medium">Запрос на подпись:</span> Система отправляет пользователю SMS с уникальной
                ссылкой для подписания документа.
              </li>
              <li>
                <span className="font-medium">Подтверждение личности:</span> Пользователь вводит код из SMS или
                подтверждает действие в мобильном приложении.
              </li>
              <li>
                <span className="font-medium">Подписание документа:</span> После успешного подтверждения документ
                подписывается и сохраняется в CRM.
              </li>
            </ol>
          </div>

          <div className="flex flex-col gap-3">
            <div id="benefits" className="font-bold text-xl">
              Преимущества интеграции
            </div>

            <ul className="list-disc ml-4 space-y-2">
              <li>Быстрая отправка и получение подписанных документов</li>
              <li>Ускорение оформления договоров и сделок, повышение эффективности</li>
              <li>Клиенты и партнёры могут подписывать документы в любое время и в любом месте</li>
              <li>Полный контроль над подписанными документами через удобный интерфейс</li>
            </ul>
          </div>

          <hr className="bg-gray-100" />

          <div className="flex flex-col gap-3">
            <div id="tarifs" className="font-bold text-xl">
              Тарифы
            </div>

            <div className="flex flex-col sm:flex-row gap-3 sm:items-start">
              {innerField("Lite", "2 400", "100")}
              {innerField("Standart", "12 000", "500")}
              {innerField("PRO", "24 000", "1000")}
            </div>
          </div>
        </div>
      </div>
    )
  )
}
