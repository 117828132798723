import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {SharedProfileSelect} from "src/components/shared/components/select/profile"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormControlDatetime} from "src/components/shared/inputs/form-control-datetime"
import {FormControlTextarea} from "src/components/shared/inputs/form-control-textarea"
import {FormOption} from "src/components/shared/inputs/form-option"
import {DeliveryType} from "src/models/manager/order/order-request-delivery.model"

export function OrderRequestDeliveryAddComponent({
  defaultValues,
  onSubmit
}: {
  defaultValues: any
  onSubmit: (payload) => Promise<any>
}) {
  const {t} = useTranslation()
  const form = useForm({
    defaultValues: {
      type: DeliveryType.DELIVERY,
      issue_place: {name: "", longitude: 0, latitude: 0},
      delivery_place: {name: "", longitude: 0, latitude: 0},
      pickup_date: undefined,
      price: 0,
      comment: "",
      ...defaultValues
    }
  })
  const {setValue, watch, formState, handleSubmit} = form
  const submit = handleSubmit(onSubmit)

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-3">
        <FormOption<DeliveryType>
          value={watch("type")}
          onChange={(val: DeliveryType) => {
            setValue("type", val)
            // setValue(`${val === DeliveryType.DELIVERY ? 'issue_place' : 'delivery_place'}.name`, constants.RENTAL_POINTS[0].name)
          }}
          options={[
            {label: "Доставка", value: DeliveryType.DELIVERY},
            {label: "Доставка обратно", value: DeliveryType.PICK_UP}
          ]}
        />
        <SharedProfileSelect
          name="worker"
          label={t("common.select.user")}
          placeholder={t("common.select.placeholder.user")}
          listParams={{is_staff: true, is_sublease: false, skip_loader: true}}
          isSearchable={false}
          isClearable={false}
          params={{required: true}}
          className="flex-1"
        />

        <FormControl
          name="issue_place.name"
          className="form-control"
          label="Адрес выдачи или ссылка на точку"
          placeholder={t("orders.service.placeholder.address")}
          params={{required: true}}
          required
        />

        <FormControl
          name="delivery_place.name"
          className="form-control"
          label="Адрес доставки или ссылка на точку"
          params={{required: true}}
          placeholder={t("orders.service.placeholder.address")}
          required
        />

        <FormControl
          label={t("groups.detail.tarif.price")}
          placeholder={t("groups.detail.tarif.price_placeholder")}
          name="price"
          rootclassname="col"
          className="form-control"
          required={true}
          number_only={+true}
          params={{required: true}}
        />

        <FormControlDatetime
          name="pickup_date"
          rootclassname="col"
          label="Дата доставки"
          className="form-control"
          required={true}
          params={{required: true}}
          datetimeConfig={{
            minuteStep: 1,
            showTime: {format: "HH:mm"},
            allowClear: false
          }}
        />
        <FormControlTextarea
          name="info"
          rootclassname="col mb-2"
          className="form-control"
          label={t("orders.service.comment")}
          placeholder={t("orders.service.placeholder.comment")}
        />
        <div className="flex justify-end">
          <button className="btn btn-primary btn-color-white" onClick={submit} disabled={!formState.isValid}>
            {t("common.save")}
          </button>
        </div>
      </div>
    </FormProvider>
  )
}
