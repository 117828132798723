import {useEffect} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useNavigate, useSearchParams} from "react-router-dom"
import {FormControlPassword} from "src/components/shared/inputs/form-control-password"
import {FormControl} from "src/components/shared/inputs/form-control"
import authService from "src/services/auth.service"
import logoDark from "src/assets/icons/icon-dark.svg"
import {useTranslation} from "react-i18next"
import tokenService from "src/services/token.service"
import "./auth.scss"

type FormData = {
  username: string
  password: string
}

function AuthComponent() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const crossToken = searchParams.get("token")
  const {t} = useTranslation()
  const emailFromUrl = searchParams.get("email") || ""
  const form = useForm<FormData>({
    defaultValues: {
      username: emailFromUrl,
      password: ""
    }
  })
  const {handleSubmit, formState, setError, setValue} = form
  const onSubmit = handleSubmit(({username, password}) => {
    authService.login(username, password).catch((error) => {
      if (error.response?.data?.detail) {
        setError("username", {message: error.response.data.detail})
        setError("password", {message: error.response.data.detail})
      }
    })
  })

  const onCrossTokenLogin = async () => {
    const token = await authService.crossTokenLogin(crossToken)
    tokenService.setToken(token)
    authService.getProfile()
  }

  useEffect(() => {
    const sub = authService.authorized$.subscribe((logged) => {
      if (logged) navigate("/orders/all")
    })
    return () => sub.unsubscribe()
  }, [navigate])

  useEffect(() => {
    if (crossToken) onCrossTokenLogin()
  }, [crossToken])

  useEffect(() => {
    if (emailFromUrl) {
      setValue("username", emailFromUrl)
    }
  }, [emailFromUrl, setValue])

  return (
    <FormProvider {...form}>
      <div className="w-full flex flex-col items-center gap-16 md:gap-[120px] bg-dark-light h-screen">
        <div className="max-w-[480px] w-full bg-white p-8 rounded-2xl flex flex-col items-center gap-3 md:gap-4 mt-10 md:mt-20">
          <img src={logoDark} draggable={false} alt="yume cloud" className="auth-icon" />
          <div className="tracking-normal text-base md:text-3xl font-medium">{t("auth.label")}</div>

          <form className="auth flex flex-col gap-8" onSubmit={onSubmit}>
            <div className="flex flex-col gap-6">
              <FormControl
                label={t("auth.username")}
                placeholder={t("auth.placeholder.username")}
                name="username"
                className="form-control"
                params={{required: true}}
              />
              <FormControlPassword
                label={t("auth.password")}
                placeholder={t("auth.placeholder.password")}
                className="form-control"
                name="password"
                params={{required: true, minLength: 8}}
              />
            </div>

            <button disabled={!formState.isValid} className="btn btn-color-white btn-primary w-full auth__submit-btn">
              {t("auth.login")}
            </button>
          </form>
        </div>
      </div>
    </FormProvider>
  )
}

export default AuthComponent
