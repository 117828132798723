import {useState, useEffect, useMemo, useCallback} from "react"
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import ModalComponent from "./modal.v2"
import clsx from "clsx"
import {createPortal} from "react-dom"

export const GlobalModal = () => {
  const [modals, setModals] = useState<Array<[string, boolean, ModalConfig]>>([])

  const current = useMemo(() => {
    if (modals.length === 0) return null
    return modals[modals.length - 1]
  }, [modals])

  const onClose = useCallback((modal: [string, boolean, ModalConfig]) => {
    const index = modal[0]
    const config = modal[2]
    if (index !== current[0]) return
    if (config.backdropClick) return
    if (!config.disableClose) {
      modalService.closeModal(index)
      if (config.onHide !== undefined) config.onHide()
    }
  }, [current])

  useEffect(() => {
    if (!modalService.modals$) return
    if (!modalService.modals$.value) return
    const sub = modalService.modals$.subscribe(setModals)
    return () => sub.unsubscribe()
  }, [])

  return createPortal(
    <>
      <div
        className={clsx(
          "fixed bg-black bg-opacity-0 w-screen h-screen duration-300 ease-in-out z-[1050] left-0 top-0",
          modals.length > 0 ? "bg-opacity-50" : "pointer-events-none"
        )}></div>

      {modals.filter((_, i) => i < 3).map((modal) => (
        <div
          key={modal[0]}
          role="dialog"
          tabIndex={-1}
          onClick={() => onClose(modal)}
          style={{display: "block", paddingLeft: 0}}
          className="modal fixed inset-0 w-full h-full overflow-y-auto overflow-x-hidden outline-none z-[1056]">
          <ModalComponent index={modal[0]} current={current[0]} show={modal[1]} {...modal[2]} />
        </div>
      ))}
    </>,
    document.body
  )
}
