import moment from "moment"
import {OrderContext} from "./context/order-context"
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import discountService from "src/services/discount/discount.service"
import {firstValueFrom} from "rxjs"
import {toPrice} from "src/utils/price"
import {OrderRequestInventoryModel} from "src/models/manager/order/order-request-inventory"
import {CalculationType} from "../constants"
import {useTranslation} from "react-i18next"
import {OrderRequestServiceForm} from "./context/order-context.model"
import {ConfigContext} from "src/app"

export function inventoryPrice(form: Partial<OrderRequestInventoryModel>): number {
  if (!form) return 0
  if (form.type === 1) return +form.tarif_price

  const startAt = moment(form.start_at)
  const endAt = moment(form.end_at)
  const duration = endAt.diff(startAt, "seconds")
  const tarifDuration = moment.duration(form.tarif_duration)

  if (tarifDuration.asSeconds() === 0) return +form.tarif_price
  return +form.tarif_price * Math.ceil(duration / Math.max(tarifDuration.asSeconds(), 1))
}

export function OrderInventoryPrice({
  id,
  setMaxPriceDiscount
}: {
  id: string
  setMaxPriceDiscount: Dispatch<SetStateAction<number>>
}): JSX.Element {
  const {t} = useTranslation()
  const {orderForm} = useContext(OrderContext)
  const [price, setPrice] = useState<number>(Number(0))
  const [priceDiscount, setPriceDiscount] = useState<number>(Number(0))

  const getPrice = async () => {
    const form: Partial<OrderRequestInventoryModel> = orderForm.watch(`inventoryMap.${id}`)

    const initial = inventoryPrice(form)
    let price = +`${initial}`

    if (orderForm.watch("discount")) {
      const orderDiscount = await firstValueFrom(discountService.get(Number(orderForm.watch("discount"))))
      if (orderDiscount && orderDiscount.discount_type === CalculationType.PERCENTAGE) {
        price = (price * (100 - orderDiscount.discount)) / 100
      }
      if (orderDiscount && orderDiscount.discount_type === CalculationType.PRICE) {
        price = price - orderDiscount.discount
      }
    }

    if (form && form.discount) {
      const discount = await firstValueFrom(discountService.get(Number(form.discount)))
      if (discount && discount.discount_type === CalculationType.PERCENTAGE) {
        price = (price * (100 - discount.discount)) / 100
      }
      if (discount && discount.discount_type === CalculationType.PRICE) {
        price = price - discount.discount
      }
    }

    if (form && form.additional_discount) {
      price = price - Number(form.additional_discount)
    }

    setPrice(Math.max(initial, 0))
    setPriceDiscount(Math.max(price, 0))
    setMaxPriceDiscount(Math.max(initial, 0))
  }

  useEffect(() => {
    getPrice()
  }, [id, orderForm.watch()])

  return (
    <span className="font-semibold text-base leading-5 text-nowrap">
      <div className="flex items-end justify-center gap-1">
        {price !== priceDiscount && (
          <span className="text-xs text-gray-400 line-through">
            {toPrice(price)}
          </span>
        )}
        {toPrice(priceDiscount)}
      </div>
    </span>
  )
}

export function OrderInventoryGroupPrice({
  id,
  type = "inventoryGroupMap"
}: {
  id: string
  type?: "inventoryGroupMap" | "inventorySetMap" | "setMap"
}) {
  const {t} = useTranslation()
  const {orderForm} = useContext(OrderContext)
  const setKeys = Object.keys(orderForm.watch("inventorySetMap")).filter((key) => key.split("-")[0] === id)
  const inventorIds: string[] =
    type === "setMap"
      ? setKeys.reduce((p, key) => [...p, ...(orderForm.watch(`inventorySetMap.${key}`) || [])], [])
      : orderForm.watch(`${type}.${id}`) || []
  const inventories: Partial<OrderRequestInventoryModel>[] = inventorIds
    .map((key) => orderForm.watch(`inventoryMap.${key}`))
    .filter((f) => f !== undefined)

  const [price, setPrice] = useState<number>(Number(0))
  const [priceDiscount, setPriceDiscount] = useState<number>(Number(0))
  const penalty: number = inventorIds.reduce(
    (p, key) => p + +orderForm.watch(`inventoryMap.${key}`)?.penalty_amount || 0,
    0
  )

  const getPrice = async (key: string) => {
    const form: Partial<OrderRequestInventoryModel> = orderForm.watch(`inventoryMap.${key}`)
    const initial = inventoryPrice(form)
    let price = +`${initial}`

    if (orderForm.watch("discount")) {
      const orderDiscount = await firstValueFrom(discountService.get(Number(orderForm.watch("discount"))))
      if (orderDiscount && orderDiscount.discount_type === CalculationType.PERCENTAGE) {
        price = (price * (100 - orderDiscount.discount)) / 100
      }
      if (orderDiscount && orderDiscount.discount_type === CalculationType.PRICE) {
        price = price - orderDiscount.discount
      }
    }

    if (form && form.discount) {
      const discount = await firstValueFrom(discountService.get(Number(form.discount)))
      if (discount && discount.discount_type === CalculationType.PERCENTAGE) {
        price = (price * (100 - discount.discount)) / 100
      }
      if (discount && discount.discount_type === CalculationType.PRICE) {
        price = price - discount.discount
      }
    }

    if (form && form.additional_discount) {
      price = price - Number(form.additional_discount)
    }

    return [Math.max(initial, 0), Math.max(price, 0)]
  }

  const calculatePrice = async (ids: string[]) => {
    const [price, priceDiscount] = await ids.reduce(
      async (prev, key) => {
        const [p, pd] = await getPrice(key)
        return [(await prev)[0] + p, (await prev)[1] + pd]
      },
      Promise.resolve([0, 0])
    )

    setPrice(price)
    setPriceDiscount(priceDiscount)
  }

  useEffect(() => {
    calculatePrice(inventorIds)
  }, [id, inventorIds, inventories, orderForm.watch()])

  return (
    <div className="flex gap-1 flex-row-reverse items-start md:flex-col md:items-end">
      <span className="font-semibold text-base leading-5 text-nowrap">
        <div className="flex items-end justify-center gap-1">
          {price !== priceDiscount && (
            <span className="text-xs text-gray-400 line-through">
              {toPrice(price)}
            </span>
          )}
          {toPrice(priceDiscount)}
        </div>
      </span>
      {penalty > 0 && (
        <span className="text-[13px] text-gray-400">
          {t("orders.inventory.penalty")}: {toPrice(penalty)}
        </span>
      )}
    </div>
  )
}

export function OrderInventoryPenalty({id}: {id: string}) {
  const {orderForm} = useContext(OrderContext)
  const {t} = useTranslation()
  const penalty: number = +orderForm.watch(`inventoryMap.${id}`)?.penalty_amount || 0

  return (
    penalty > 0 && (
      <span className="text-[13px] text-gray-400">
        {t("orders.inventory.penalty")}: {toPrice(penalty)}
      </span>
    )
  )
}

export function OrderServicePrice(config: {form: OrderRequestServiceForm; setPrice: (val) => void}) {
  const {orderForm} = useContext(OrderContext)
  const {constantsMap} = useContext(ConfigContext)
  const [price, setPrice] = useState<number>(Number(config.form.price))

  const orderFormValues = orderForm.watch()

  const getPrice = async (form: OrderRequestServiceForm) => {
    const startAt = moment(form.start_at || orderFormValues.rent_start)
    const endAt = moment(form.end_at || orderFormValues.rent_end)
    const duration = endAt.diff(startAt, "seconds")
    const tarif = form.tarif && form.service.tarifs.find((tarif) => tarif.id === Number(form.tarif))

    if (!tarif) {
      setPrice(0)
      return
    }

    if (tarif.one_time_payment) {
      setPrice(form.tarif_price)
      return
    }

    const durationObject = constantsMap.TARIF_TIME_PERIODS[tarif.duriation]
    const tarifDuration = Number(durationObject?.time)

    setPrice(form.tarif_price * Math.ceil(duration / tarifDuration))
  }

  useEffect(() => {
    getPrice(config.form)
  }, [config])

  useEffect(() => {
    config.setPrice(price)
  }, [price])

  return <span>{toPrice(price)}</span>
}

export function AdditionalCustomFields({extra, type}: {extra: any; type: "inventory" | "set"}) {
  // const [fields, setFields] = useState<CustomFieldModel>()

  // useEffect(() => {
  //   const sub = settingService.config$.pipe(map(config => config.custom_fields)).subscribe(setFields)
  //   return () => sub.unsubscribe()
  // }, [])

  // const fieldName = (key: string) => fields && fields.inventory.find(obj => obj.name === key)?.label

  return <></>
  // return fields && <>
  //   {type === 'inventory' ? (
  //     fields.inventory.map((field: CustomField) =>
  //       <div className="flex gap-2 mb-1" key={field.name}>
  //         <div style={{width: 80}}>{fieldName(field.name)}:</div>
  //         <div>{extra[field.name]}</div>
  //       </div>
  //     )
  //   ) : (
  //     fields.inventory.map((field: CustomField) =>
  //       <>
  //         <div className="text-black">{fieldName(field.name)}: {extra[field.name]}</div>
  //         <div className="vr text-gray-400"></div>
  //       </>
  //     )
  //   )}
  // </>
}
