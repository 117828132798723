import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {Transfer} from "src/models/manager/transfer/transfer"
import {ColumnsType} from "antd/es/table"
import {getOrderStatus} from "../../../details/help/getOrderStatus"
import toDateTime from "src/utils/date-time"
import Table from "src/components/shared/antd-custom/table"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {toPrice} from "../../../../../utils/price"
import transportationService from "../../../../../services/transportation.service"
import {map} from "rxjs"

const TransportationListTableComponent = ({list = EMPTY_LIST}: {list: ListModel<Transfer>}) => {
  const navigate = useNavigate()
  const [agentMap, setAgentMap] = useState<Record<number, any>>({})
  const {t} = useTranslation()

  const columns: ColumnsType<Transfer> = [
    {
      width: 44,
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (_, {id}) => <span className="font-semibold text-nowrap">{id}</span>,
      sorter: true
    },
    {
      width: 120,
      title: t("orders.main.status"),
      dataIndex: "status",
      key: "rent",
      render: (_, {status}) => {
        const {text, bg} = getOrderStatus(status)
        return (
          <div
            className="text-[13px] text-nowrap text-center p-2 rounded-lg"
            style={{backgroundColor: bg + "33", color: bg, fontWeight: 600}}>
            {t(text as any)}
          </div>
        )
      },
      sorter: true
    },
    {
      width: 148,
      title: t("orders.list.rent_start"),
      dataIndex: "ride_start",
      key: "ride_start",
      render: (_, {start_at}) => <span className="font-semibold text-nowrap">{toDateTime(start_at)}</span>,
      sorter: true
    },
    {
      width: 224,
      title: t("orders.list.client"),
      dataIndex: "client",
      key: "client",
      render: (_, {client}) =>
        client ? (
          <div className="flex flex-col gap-1">
            <div className="font-semibold text-sm">{client.name}</div>
            <div className="font-normal text-sm">{client.phone}</div>
          </div>
        ) : (
          <div className="flex gap-2 items-center">
            <div className="font-semibold text-sm">{t("orders.list.no_client")}</div>
          </div>
        ),
      sorter: true
    },
    {
      width: 224,
      title: t("transfers.form.customer"),
      dataIndex: "agent",
      key: "agent",
      render: (_, {agent}) =>
        agent && (
          <div className="flex flex-col gap-1">
            <div className="font-semibold text-sm">{agentMap[agent]?.name}</div>
          </div>
        ),
      sorter: true
    },
    {
      width: 148,
      title: t("transfers.main.car"),
      dataIndex: "price",
      key: "price",
      render: (_, {price}) => (
        <div className="flex flex-col gap-1">
          <div className="font-semibold text-sm">{toPrice(+price)}</div>
        </div>
      ),
      sorter: true
    }
  ]

  useEffect(() => {
    const sub = transportationService.agents$
      .pipe(map((list) => list.reduce((p, c) => ({...p, [c.id]: c}), {})))
      .subscribe(setAgentMap)
    return () => sub.unsubscribe()
  }, [])

  return (
    <>
      <Table
        rowKey={(obj) => obj.id}
        columns={columns}
        dataSource={list.results}
        onNavigate={(ride) => navigate(`/transportation/${ride.id}`)}
      />
    </>
  )
}

export default TransportationListTableComponent
