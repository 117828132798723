import {FormProvider, useForm} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import modalService from "src/components/modal/global/modal.service"
import {Stage} from "src/services/crm/funnel.service"
import {useTranslation} from "react-i18next"
import {FormControlColor} from "src/components/shared/inputs/form-control-color"
import {useEffect} from "react"
import {stageStore} from "../../store/stage.store"

interface Props {
  refetchStages?: () => void
  funnelId: number
  initialData?: Stage
}

const CreateStageModal = ({refetchStages, funnelId, initialData}: Props) => {
  const {t} = useTranslation()
  const form = useForm({
    defaultValues: {
      color: "#ccc",
      name: ""
    }
  })

  const {reset, handleSubmit} = form

  useEffect(() => {
    if (initialData) {
      reset({
        color: initialData.color,
        name: initialData.name
      })
    }
  }, [initialData])

  const onSubmit = async (data: any) => {
    if (!funnelId) return

    const payload = {
      ...data,
      type: "DEFAULT"
    }

    try {
      if (initialData) {
        await stageStore.updateStage(initialData.id, payload)
      } else {
        await stageStore.createStage(payload)
      }

      refetchStages?.()
      modalService.closeModal()
    } catch (error) {
      console.error("Ошибка при сохранении стадии:", error)
    }
  }

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-4">
        <div className="text-lg font-semibold md:text-2xl">
          {initialData ? "Редактировать статус" : "Добавить статус"}
        </div>

        <div className="flex flex-1 gap-4">
          <FormControlColor label={t("common.input.color")} rootclassname="min-w-12" name="color" required />

          <FormControl
            name="name"
            rootclassname="col"
            className="form-control"
            label={t("common.input.name")}
            placeholder={t("common.input.placeholder.name")}
            required
            params={{required: true}}
          />
        </div>

        <div className=" flex justify-end">
          <button className="btn btn-primary btn-color-white mt-4 font-medium" onClick={() => handleSubmit(onSubmit)()}>
            {t("common.save")}
          </button>
        </div>
      </div>
    </FormProvider>
  )
}

export default CreateStageModal
