import {ReactNode} from "react"
import Icon from "src/components/shared/components/material-icon"

export function Permission({permitted, children}: {permitted: boolean; children: ReactNode}) {
  return (
    <div className="flex gap-2 items-center text-[15px]">
      {permitted ? (
        <span className="flex items-center justify-center w-4 h-4 bg-accept rounded-full">
          <Icon icon="check" className="text-white text-sm" />
        </span>
      ) : (
        <span className="flex items-center justify-center w-4 h-4 bg-gray-370 rounded-full">
          <Icon icon="remove" className="text-white text-sm" />
        </span>
      )}
      {children}
    </div>
  )
}
