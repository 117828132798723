import React, {createContext, ReactNode, useEffect, useRef, useState} from "react"
import {useForm, UseFormReturn} from "react-hook-form"
import {EMPTY_LIST, ListModel} from "src/models/common"
import funnelService, {Funnel} from "src/services/crm/funnel.service"
import leadsService, {LeadList} from "src/services/crm/leads.service"
import useOnScreen from "src/hooks/useOnScreen"

interface CRMContextParams {
  funnelForm: UseFormReturn<any>
  funnels: Funnel[]
  selectedFunnelId: number | null
  leads: ListModel<LeadList>
  setLeads: React.Dispatch<React.SetStateAction<ListModel<LeadList>>>
  elementRef: React.RefObject<HTMLDivElement>
}

export const CRMContext = createContext<CRMContextParams | undefined>(undefined)

export default function CRMProvider({children}: {children: ReactNode}) {
  const funnelForm = useForm({
    defaultValues: {
      funnel: +localStorage.getItem("selectedFunnelId"),
      page: 1,
      pageSize: 10,
      start_at: "",
      end_at: "",
      search: ""
    }
  })

  const {watch, reset, setValue} = funnelForm
  const values = watch()
  const selectedFunnelId = values.funnel
  const page = values.page

  const [funnels, setFunnels] = useState<Funnel[]>([])
  const [leads, setLeads] = useState<ListModel<LeadList>>(EMPTY_LIST)

  const elementRef = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(elementRef)
  const ignoreNextWatchRef = useRef(false)

  const onLoad = async (params: any): Promise<void> => {
    const response = await leadsService.list(params)
    setLeads((prev) => ({
      ...response,
      results: params.page === 1 ? response.results : [...prev.results, ...response.results]
    }))
  }

  useEffect(() => {
    const sub = watch((params, {name}) => {
      if (ignoreNextWatchRef.current) {
        ignoreNextWatchRef.current = false
        return
      }

      if (["search", "start_at", "end_at", "funnel"].includes(name) || !name) {
        const updatedParams = {...params, page: 1}
        ignoreNextWatchRef.current = true
        reset(updatedParams)
        onLoad(updatedParams)
      }
    })

    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    if (isOnScreen && leads.next !== false && !!leads.next) {
      setValue("page", page + 1)
    }
  }, [isOnScreen])

  useEffect(() => {
    if (!selectedFunnelId) return
    onLoad(funnelForm.getValues())
  }, [page])

  useEffect(() => {
    const sub = funnelService.funnels$.subscribe((data) => {
      setFunnels(data)

      if (!selectedFunnelId && data.length > 0) {
        const firstId = data[0].id
        funnelForm.setValue("funnel", firstId)
        localStorage.setItem("selectedFunnelId", firstId.toString())
      }
    })
    return () => sub.unsubscribe()
  }, [])

  return (
    <CRMContext.Provider
      value={{
        funnelForm,
        funnels,
        selectedFunnelId,
        leads,
        setLeads,
        elementRef
      }}>
      {children}
    </CRMContext.Provider>
  )
}
