import {CSSProperties, forwardRef, useContext, useEffect, useMemo, useRef, useState} from "react"
import {useLocation, useNavigate, useSearchParams} from "react-router-dom"
import {Aside} from "./components/aside/aside"
import Header from "./components/header"
import HeaderPeriodBanner from "./components/header-banner"
import {ConfigContext} from "src/app"
import {MobileMenu} from "./components/mobile-menu/mobile-menu"
import {use100vh} from "react-div-100vh"
import {newSubsription, unsubsribe} from "./services/cent"
import {hasNotification} from "./utils/detectNotificationAccess"
import moment from "moment"
import {BehaviorSubject} from "rxjs"
import Icon from "./components/shared/components/material-icon"
import TourSection from "./components/tour/tour-section"
import tourChecklist, {TourChecklist} from "./mock/tour-checklist"
import onboardingService from "src/services/onboarding"
import tourSteps from "./mock/tour-steps"
import {useTour} from "@reactour/tour"
import modalService from "./components/modal/global/modal.service"
import VideoModal from "./components/shared/components/video-modal"
import {ConfirmModal} from "./components/modal/global/confirmModal"
import CustomCircularProgress from "./components/circular-progress"
import clsx from "clsx"
import "./App.scss"
import OnboardingIntroModal from "./components/onboarding/modal/intro"
import {CustomModal} from "./components/modal/global/customModal"

const customChildStyle = new BehaviorSubject<CSSProperties>({})
export const setChildStyle = (style: CSSProperties) => customChildStyle.next(style)
export const setDefaultChildStyle = () => customChildStyle.next({})

const Children = forwardRef(({children}: {children: JSX.Element}, ref) => {
  const scrollToRef = (_ref: any) => _ref.current?.scroll({top: 0, behavior: "smooth"})
  const [customStyle, setCustomStyle] = useState(customChildStyle.value)
  const location = useLocation()
  const height = use100vh()
  const {isMobile, headerHeight = 0} = useContext(ConfigContext)
  const contentRef = useRef(null)
  const scroll = () => scrollToRef(contentRef)

  const style = useMemo(() => {
    const paddingLeft = customStyle.paddingLeft ?? (isMobile ? 16 : 24)
    const paddingRight = customStyle.paddingRight ?? (isMobile ? 16 : 24)
    const paddingTop = customStyle.paddingTop ?? (isMobile ? headerHeight + 16 : 24)
    const paddingBottom = customStyle.paddingBottom ?? (isMobile ? 160 : 80)

    let ret: CSSProperties = {paddingLeft, paddingRight, paddingTop, paddingBottom}
    if (isMobile) ret = {...ret, minHeight: height}
    if (!isMobile) ret = {...ret, height: height - headerHeight}
    return ret
  }, [height, location, customStyle, isMobile, headerHeight])

  useEffect(() => scroll(), [location.pathname])
  useEffect(() => {
    const sub = customChildStyle.subscribe(setCustomStyle)
    return () => sub.unsubscribe()
  }, [])

  return (
    <div
      ref={contentRef}
      className={clsx("relative w-full bg-dark-light overflow-x-scroll scrollbar-hide")}
      style={style}>
      {children}
    </div>
  )
})

export default function Main({children}: {children: JSX.Element}) {
  const navigate = useNavigate()
  const {logged, isMobile, tenant, onboardingData, currentTour, setCurrentTour, profile} = useContext(ConfigContext)
  const {setIsOpen, setCurrentStep, currentStep} = useTour()
  const [searchParams] = useSearchParams()
  const emailFromUrl = searchParams.get("email") || ""
  const [smallMenu, setSmallMenu] = useState(() => {
    if (localStorage.getItem("aside-menu")) return localStorage.getItem("aside-menu") === "true"
    return false
  })
  const onboardingState = JSON.parse(localStorage.getItem(`onboarding_${profile?.id}`)) || {}
  const [openTourChecklist, setOpenTourChecklist] = useState(false)
  const [tourChecklistChecks, setTourChecklistChecks] = useState<TourChecklist>({})
  const [openWelcome, setOpenWelcome] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const totalSteps = Object.values(tourChecklistChecks).reduce((acc, item) => {
    return acc + Object.keys(item.steps).length
  }, 0)

  const totalCheckedSteps = Object.values(tourChecklistChecks).reduce((acc, checklistItem) => {
    const checkedCount = Object.values(checklistItem.steps).filter((step) => step.checked === true).length
    return acc + checkedCount
  }, 0)

  const skipChecklist = () => {
    modalService.open({
      onHide: () => {
        setOpenTourChecklist(true)
      },
      onOpen: () => {
        setOpenTourChecklist(false)
      },
      size: "xs",
      component: (
        <ConfirmModal
          message="Вы точно хотите пропустить настройку системы?"
          confirm_text="Да, пропустить"
          onCancel={() => {
            setOpenTourChecklist(true)
          }}
          onConfirm={() => {
            onboardingService.put({
              is_finished: true
            })
          }}
          delete
        />
      )
    })
  }

  const onAside = () =>
    setSmallMenu((p) => {
      localStorage.setItem("aside-menu", String(!p))
      return !p
    })

  useEffect(() => {
    if (logged === false) navigate(emailFromUrl ? `/auth?email=${encodeURIComponent(emailFromUrl)}` : "/auth")
  }, [logged])

  useEffect(() => {
    if (!tenant) return
    const timeLeft = moment.duration(moment(tenant.end_at).valueOf() - moment.now(), "milliseconds").as("seconds")
    if (timeLeft < 0) return

    if (hasNotification() && Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Разрешение на уведомления предоставлено.")
        } else if (permission === "denied") {
          console.log("Разрешение на уведомления отклонено.")
        }
      })
    }

    newSubsription(`notification:${tenant.id}`)
    return () => unsubsribe(`notification:${tenant.id}`)
  }, [tenant])

  useEffect(() => {
    if (!onboardingData) return

    const frontPassed = (onboardingState ? onboardingState.hidden : false) === true
    const backPassed = onboardingData.is_finished === true

    if (frontPassed || backPassed) setOpenTourChecklist(false)

    if (!backPassed && frontPassed) {
      onboardingService.put({is_finished: true})
      setOpenTourChecklist(true)
    }

    if (!backPassed && !frontPassed) {
      setOpenTourChecklist(true)
      setOpenWelcome(
        !tenant.subscription && (
          Object.keys(onboardingData?.state).length === 0 ||
          (onboardingData.state.rental_control && Object.keys(onboardingData.state.rental_control).length === 0)
        )
      )
    }
  }, [tenant, onboardingData])

  useEffect(() => {
    if (currentTour) {
      const currentTourSteps = onboardingData?.state[currentTour] || {}
      const currentTourStepsKeys = Object.keys(currentTourSteps)

      if (currentTourStepsKeys.length !== 0) {
        const calculatedCurrentStep = +currentTourStepsKeys[currentTourStepsKeys.length - 1]
        setCurrentStep(calculatedCurrentStep + 1)
        if (currentTourStepsKeys[tourSteps?.length]) {
          setCurrentTour("")
          setIsOpen(false)
        } else {
          setIsOpen(true)
        }
      }
    }
  }, [])

  useEffect(() => {
    const updatedChecklist = Object.keys(tourChecklist).reduce((acc, tourKey) => {
      const checklistItem = tourChecklist[tourKey]

      const updatedSteps = Object.keys(checklistItem.steps).reduce((stepsAcc, stepKey) => {
        const isChecked = onboardingData?.state[tourKey] && onboardingData?.state[tourKey][stepKey]
        stepsAcc[stepKey] = {
          ...checklistItem.steps[stepKey],
          checked: Boolean(isChecked)
        }
        return stepsAcc
      }, {})

      acc[tourKey] = {
        ...checklistItem,
        steps: updatedSteps
      }
      return acc
    }, {})

    setTourChecklistChecks(updatedChecklist)
  }, [onboardingData])

  useEffect(() => {
    if (currentTour === "rental_control" && currentStep === 8) {
      modalService.open({
        id: "step9",
        size: "lg",
        component: <VideoModal nextStep={9} />,
        removable: false,
        disableClose: true,
        backdropClick: false
      })
    }
  }, [currentStep])

  return (
    <>
      {isMobile ? (
        <Header
          id="vhContent"
          className="relative right-side w-full overflow-y-scroll"
          topchildren={<HeaderPeriodBanner />}>
          <>
            <Children>{children}</Children>
            <MobileMenu />
          </>
        </Header>
      ) : (
        <div
          id="vhContent"
          className={clsx(
            "relative wrapper big-menu overflow-y-scroll md:overflow-y-hidden max-h-none md:max-h-screen",
            smallMenu && "small-menu"
          )}>
          {tenant?.type_code !== "transfer" && !onboardingData?.is_finished && !tenant?.demo && (
            <div
              className="flex justify-center items-center bg-white rounded-lg p-4 border border-gray-100 gap-4 absolute right-6 bottom-6 z-[1049] cursor-pointer"
              onClick={() => setOpenTourChecklist(true)}>
              <CustomCircularProgress
                size={56}
                textSize={14}
                strokeWidth={6}
                gap={totalCheckedSteps === totalSteps || totalCheckedSteps === 0 ? 0 : 3}
                percent={(totalCheckedSteps / totalSteps) * 100}
                completedColor="#a165fd"
                remainingColor="#EDEEF0"
              />
              <div className="flex flex-col gap-2">
                <p className="font-medium text-[15px] text-black">Настройте рабочую зону</p>
                <p className="font-normal text-[13px] text-primary">
                  {totalCheckedSteps} из {totalSteps} разделов завершено
                </p>
              </div>
            </div>
          )}
          <Aside ref={menuRef} collapse={smallMenu} onCollapse={onAside} />
          {tenant?.type_code !== "transfer" && !onboardingState?.hidden && !tenant?.demo && openTourChecklist && (
            <div className="z-[1049] absolute flex h-full bg-black w-full bg-opacity-20 right-0">
              <div className="bg-white h-full ml-auto min-w-[640px] border border-gray-100 transition-all duration-500 delay-150 z-[1048] overflow-scroll">
                <div className="flex justify-between p-6">
                  <p className="text-2xl font-bold text-black">Добро пожаловать</p>
                  <div
                    onClick={() => setOpenTourChecklist(false)}
                    className="flex justify-center items-center p-[6px] rounded-full border border-gray-200 cursor-pointer">
                    <Icon icon="close_small" className="text-xl text-black" />
                  </div>
                </div>
                <div className="border-b-gray-100 border-b" />
                <div>
                  {Object.keys(tourChecklistChecks).map((key) => (
                    <TourSection
                      key={key}
                      setOpenTourChecklist={setOpenTourChecklist}
                      section={tourChecklistChecks[key]}
                    />
                  ))}
                </div>
                <div className="flex items-center p-5 pb-9">
                  {totalCheckedSteps === totalSteps ? (
                    <button
                      className="btn btn-primary btn-color-white rounded-lg font-medium text-[13px]"
                      onClick={() => {
                        onboardingService.put({
                          is_finished: true
                        })

                        setOpenTourChecklist(false)
                      }}>
                      Приступить к работе
                    </button>
                  ) : (
                    <button className="btn btn-black rounded-lg font-medium text-[13px]" onClick={skipChecklist}>
                      Пропустить инструкцию
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="relative wrapper-content md:overflow-y-scroll h-screen">
            <Header className="relative right-side" topchildren={<HeaderPeriodBanner />}>
              <Children>{children}</Children>
            </Header>
          </div>
        </div>
      )}
      <CustomModal backdropClick={false} show={openWelcome}>
        <OnboardingIntroModal onClick={() => setOpenWelcome(false)} />
      </CustomModal>
    </>
  )
}
