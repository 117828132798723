import React, {useContext, useRef, useState} from "react"
import {ConfigContext} from "src/app"
import {ImageField} from "src/components/shared/image-loader/image"
import {Image} from "antd"
import Step2 from "src/assets/images/integrations/ringostat-step-2.jpg"
import Step3 from "src/assets/images/integrations/ringostat-step-3.jpg"
import Step4 from "src/assets/images/integrations/ringostat-step-4.jpg"
import Step5 from "src/assets/images/integrations/ringostat-step-5.jpg"
import Banner1 from "src/assets/images/integrations/ringostat_banner_1.png"
import Banner2 from "src/assets/images/integrations/ringostat_banner_2.png"
import Icon from "src/components/shared/components/material-icon"
import {Link} from "react-router-dom"
import {IntegrationModel} from "../../../models/integration"
import modalService from "../../../components/modal/global/modal.service"
import {IntegrationConnection} from "../connection.modal"

interface InnerBlockProps {
  divider?: boolean
  step: string
  description: React.ReactNode
  image?: string
  button?: React.ReactNode
}

const InnerBlockInfo: React.FC<InnerBlockProps> = ({divider = false, step, description, image, button}) => {
  return (
    <>
      {divider && <hr className="bg-gray-100" />}

      <div className="flex flex-col gap-2">
        <span className="text-gray-370">{step}</span>
        {description}
      </div>

      {image && (
        <div className="flex justify-center items-center bg-gray-70 rounded">
          <ImageField draggable={false} src={image} className="overflow-hidden" />
        </div>
      )}

      <div className="flex justify-start">{button && button}</div>
    </>
  )
}

const RingostatInfo = () => {
  const {integrationMap} = useContext(ConfigContext)
  const integration = integrationMap["ringostat"]
  const [activeSection, setActiveSection] = useState<string | null>(null)
  const [copied, setCopied] = useState(false)

  const copyDomainToClipboard = () => {
    const domain = window.location.origin
    navigator.clipboard.writeText(domain).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 1000)
    })
  }

  const handleScrollTo = (id: string) => {
    setActiveSection(id)
    document.getElementById(id)?.scrollIntoView({behavior: "smooth"})
  }

  const onConnect = (integration: IntegrationModel) => {
    modalService.open({
      component: (
        <IntegrationConnection
          additional={
            <button
              onClick={() => {
                handleScrollTo("connection")
                modalService.closeModal()
              }}
              className="btn btn-gray-70 text-[13px] gap-3 p-4 font-medium text-nowrap">
              <Icon icon="menu_book" />
              Инструкция по подключению
            </button>
          }
          instruction={
            "* Запрашиваемые данные для подключения можно получить выполнив шаги описанные в “Инструкции по подключению” ниже"
          }
          integration={integration}
        />
      )
    })
  }
  return (
    <>
      {integration && (
        <div className="flex flex-col md:flex-row gap-4 w-full">
          <div className="flex flex-col p-6 gap-4 bg-white rounded-lg mb-6 w-full md:w-auto md:max-w-[400px] self-start sticky top-0">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-row gap-5 items-center">
                <ImageField
                  draggable={false}
                  src={integration.icon}
                  className="w-10 h-10 min-w-10 min-h-10 rounded-lg overflow-hidden object-contain"
                />

                <div className="flex flex-col gap-2">
                  <div className="font-bold text-2xl">{integration.name}</div>
                  <span className="text-gray-370">{integration.comment}</span>
                </div>
              </div>

              <button
                className="btn btn-primary btn-color-white text-[13px] p-4"
                onClick={() => onConnect(integration)}>
                Подключить
              </button>
              <button
                className="btn btn-gray-70 text-[13px] gap-3 w-full p-4 font-medium text-nowrap"
                onClick={() => handleScrollTo("connection")}>
                <Icon icon="menu_book" />
                Инструкция по подключению
              </button>
              <div className="flex flex-col cursor-pointer">
                <div
                  className={`flex justify-between p-4 border-b border-b-gray-100 ${
                    activeSection === "about" && "text-primary"
                  }`}
                  onClick={() => handleScrollTo("about")}>
                  <span>Об интеграции</span> <Icon icon="chevron_right" />
                </div>

                <div
                  className={`flex justify-between p-4 border-b border-b-gray-100 ${
                    activeSection === "how-it-works" && "text-primary"
                  }`}
                  onClick={() => handleScrollTo("how-it-works")}>
                  <span>Как работает интеграция</span> <Icon icon="chevron_right" />
                </div>

                <div
                  className={`flex justify-between p-4 border-b border-b-gray-100 ${
                    activeSection === "benefits" && "text-primary"
                  }`}
                  onClick={() => handleScrollTo("benefits")}>
                  <span>Преимущества интеграции</span> <Icon icon="chevron_right" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-8 bg-white p-6 rounded-lg mb-6 max-w-[720px]">
            <div className="flex flex-col gap-3">
              <div id="about" className="font-bold text-xl">
                Об интеграции
              </div>
              <p>
                Интеграция с Ringostat позволяет автоматизировать работу с входящими и исходящими звонками, фиксировать
                историю разговоров и улучшать клиентский сервис
              </p>
            </div>

            <div className="flex flex-row gap-3 mb-3 flex-nowrap overflow-x-auto" style={{scrollBehavior: "smooth"}}>
              <Image.PreviewGroup>
                <Image
                  src={Banner1}
                  style={{height: "16rem", width: "350", maxWidth: "none"}}
                  className="object-contain rounded-lg shadow border border-gray-100 bg-white"
                  draggable={false}
                />
                <Image
                  src={Banner2}
                  style={{height: "16rem", width: "350", maxWidth: "none"}}
                  className="object-contain rounded-lg shadow border border-gray-100 bg-white"
                  draggable={false}
                />
              </Image.PreviewGroup>
            </div>

            <div className="flex flex-col gap-3">
              <div id="how-it-works" className="font-bold text-xl">
                Как работает интеграция
              </div>

              <ol className="list-decimal ml-4 space-y-2">
                <li>
                  <span className="font-medium">Исходящий звонок:</span> Менеджер инициирует звонок клиенту прямо из
                  CRM, используя интеграцию с Ringostat.
                </li>
                <li>
                  <span className="font-medium">Сохранение клиента в Ringostat:</span> После звонка контакт
                  автоматически добавляется в базу Ringostat для дальнейшей работы
                </li>
                <li>
                  <span className="font-medium">Управление базами клиентов:</span> CRM и Ringostat работают параллельно:
                  в CRM остаются основные клиентские данные, а в Ringostat формируется своя база для телефонии
                </li>
              </ol>
            </div>
            <div className="flex flex-col gap-3">
              <div id="benefits" className="font-bold text-xl">
                Преимущества интеграции
              </div>

              <ul className="list-disc ml-4 space-y-2">
                <li>Возможность звонить клиентам прямо из CRM, без переключения между сервисами</li>
                <li>Автоматическое сохранение контактов в Ringostat для удобного управления звонками</li>
                <li>Ускорение обработки заявок благодаря мгновенной передаче информации</li>
                <li>Улучшение клиентского опыта за счёт удобного и быстрого взаимодействия</li>
              </ul>
            </div>

            <hr className="bg-gray-100" />

            <div className="flex flex-col gap-3">
              <div id="connection" className="font-bold text-xl flex items-center gap-2">
                <Icon icon="menu_book" />
                Инструкция по подключению
              </div>

              <InnerBlockInfo
                divider={false}
                step="Шаг 1"
                description={
                  <span>
                    При подключении к системе Ringostat у вас запросят ссылку на вебсайт компании.{" "}
                    <span className="relative inline-block">
                      <Link className="text-primary underline" to="#" onClick={copyDomainToClipboard}>
                        Скопируйте ссылку
                      </Link>
                      {copied && (
                        <div className="absolute left-1/2 transform -translate-x-1/2  px-3 py-1 bg-white text-black shadow border rounded text-sm ">
                          Скопировано
                        </div>
                      )}
                    </span>{" "}
                    вашего кабинета yume.cloud и нажмите кнопку “Перейти к подключению” ниже, по которой вас
                    перенаправит на сайт партнера интеграции.
                  </span>
                }
                button={
                  <a
                    className="btn btn-primary btn-color-white flex gap-2 "
                    href={"https://ringostat.com/ru/"}
                    rel="noreferrer"
                    target="_blank">
                    <Icon icon="open_in_new" />
                    Перейти к подключению
                  </a>
                }
              />
              <InnerBlockInfo
                divider
                step={"Шаг 2"}
                description={
                  <span>
                    Нажмите по кнопке “Раскрыть”, на которую указывает красная стрелка на снимке экрана ниже, чтобы
                    раскрыть панель управления вашего кабинета
                  </span>
                }
                image={Step2}
              />

              <InnerBlockInfo
                divider
                step={"Шаг 3"}
                description={
                  <span>
                    Нажмите по вкладке “Интеграция”, затем, в появившемся списке выберите “Ringostat API” (3-й по
                    списку)
                  </span>
                }
                image={Step3}
              />

              <InnerBlockInfo
                divider
                step={"Шаг 4"}
                description={
                  <span>
                    В открывшемся окне, нажмите на кнопку “Генерировать”, для того, чтобы получить нужные данные для
                    подключения интеграции к вашему аккаунту yume.cloud
                  </span>
                }
                image={Step4}
              />

              <InnerBlockInfo
                divider
                step={"Шаг 5"}
                description={
                  <span>
                    Скопируйте сгенерированные данные – Auth-key и Projecd id, после чего вернитесь на эту страницу в
                    yume.cloud
                  </span>
                }
                image={Step5}
              />

              <InnerBlockInfo
                divider
                step={"Шаг 6"}
                description={
                  <span>
                    Последний шаг – нажмите на кнопку “Подключить интеграцию” ниже и вставьте скопированные данные –
                    Auth-key и Project id
                  </span>
                }
                button={
                  <button className="btn btn-primary btn-color-white" onClick={() => onConnect(integration)}>
                    Подключить
                  </button>
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RingostatInfo
