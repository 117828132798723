import {useFieldArray, useFormContext} from "react-hook-form"
import {BehaviorSubject, firstValueFrom, map} from "rxjs"
import {FormControl} from "src/components/shared/inputs/form-control"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {LabelModel} from "src/models/label"
import {CustomField, CustomFieldModel, optionlessFieldTypes} from "src/models/setting-config"
import {FormControlCheckbox} from "src/components/shared/inputs/form-control-checkbox"
import {CustomFieldChoice} from "./field-choice"
import {useEffect} from "react"
import Icon from "src/components/shared/components/material-icon"
import {Popover} from "antd"
import {useTranslation} from "react-i18next"

const fieldChoices: LabelModel[] = [
  {id: "string", label: "settings.fields.string"},
  {id: "html", label: "settings.fields.html"},
  {id: "integer", label: "settings.fields.integer"},
  {id: "decimal", label: "settings.fields.decimal"},
  {id: "boolean", label: "settings.fields.boolean"},
  {id: "date", label: "settings.fields.date"},
  {id: "datetime", label: "settings.fields.datetime"},
  {id: "color", label: "settings.fields.color"},
  {id: "client", label: "settings.fields.client"},
  {id: "inventory", label: "settings.fields.inventory"},
  {id: "product", label: "settings.fields.product"},
  {id: "set", label: "settings.fields.set"}
]

export const fields$: BehaviorSubject<LabelModel[]> = new BehaviorSubject(fieldChoices)

export default function CustomFieldEdit(config: {type: keyof CustomFieldModel; index: number; onDelete: () => void}) {
  const {t} = useTranslation()
  const {watch, control, setValue} = useFormContext()
  const values: CustomField = watch(`custom_fields.${config.type}.${config.index}`)

  const optionsForm = useFieldArray({
    control,
    name: `custom_fields.${config.type}.${config.index}.options`
  })
  const onOptionAdd = () => optionsForm.append("")

  useEffect(() => {
    if (optionlessFieldTypes.includes(values.type)) setValue(`custom_fields.${config.type}.${config.index}.options`, [])
  }, [])

  return (
    values && (
      <div className="flex flex-col gap-2 mb-2">
        <div className="flex w-full gap-3 items-end">
          <FormControl
            rootclassname="col"
            className="form-control"
            label={t("settings.custom-fields.name")}
            placeholder={t("settings.custom-fields.placeholder.name")}
            required={true}
            params={{required: true}}
            name={`custom_fields.${config.type}.${config.index}.label`}
          />
          <FormSelectAsync<LabelModel>
            className="col"
            label={t("settings.custom-fields.type")}
            placeholder={t("settings.custom-fields.placeholder.type")}
            name={`custom_fields.${config.type}.${config.index}.type`}
            params={{required: true}}
            required={true}
            listOptions={() => fields$}
            getValue={(field: string) =>
              firstValueFrom(fields$.pipe(map((list) => list.find((obj) => obj.id === field))))
            }
            getOptionLabel={(option: LabelModel) => (option ? t(option.label) : null)}
            getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
            isSearchable={false}
            isClearable={false}
          />

          <Popover
            trigger="click"
            placement="leftTop"
            overlayInnerStyle={{padding: 0}}
            content={
              <div
                style={{
                  padding: 12,
                  width: 320,
                  maxHeight: 400,
                  overflowY: "scroll"
                }}>
                <FormControlCheckbox
                  name={`custom_fields.${config.type}.${config.index}.required`}
                  className="form-check-input my-0"
                  rootclassname="mb-2"
                  label={t("settings.custom-fields.required")}
                />
                <FormControlCheckbox
                  name={`custom_fields.${config.type}.${config.index}.filter`}
                  className="form-check-input my-0"
                  rootclassname="mb-2"
                  label={t("settings.custom-fields.use_in_filter")}
                />
                <FormControlCheckbox
                  name={`custom_fields.${config.type}.${config.index}.table`}
                  className="form-check-input my-0"
                  label={t("settings.custom-fields.use_in_table")}
                />
                {optionsForm.fields.length !== 0 && (
                  <>
                    <div className="text-sm text-gray-400 my-2">{t("settings.custom-fields.options")}</div>
                    {optionsForm.fields.map((field, index) => (
                      <div className="flex gap-1 mb-2" key={field.id}>
                        <CustomFieldChoice
                          type={values.type}
                          params={{
                            name: `custom_fields.${config.type}.${config.index}.options.${index}`,
                            placeholder: t("settings.custom-fields.option"),
                            params: {required: true},
                            className: "form-control",
                            rootclassname: "w-full"
                          }}
                        />
                        <div className="btn btn-red btn-color-white" onClick={() => optionsForm.remove(index)}>
                          <Icon icon="delete" />
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {!optionlessFieldTypes.includes(values.type) && (
                  <div onClick={onOptionAdd} className="btn btn-card mt-2">
                    {t("settings.custom-fields.add_option")}
                  </div>
                )}
              </div>
            }>
            <span className="btn btn-card" style={{width: 48, height: 48}}>
              <Icon icon="settings" />
            </span>
          </Popover>

          <button className="btn btn-red-8 btn-color-red" style={{width: 48, height: 48}} onClick={config.onDelete}>
            <Icon icon="delete" />
          </button>
        </div>
      </div>
    )
  )
}
