import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import SettingsComponent from "src/pages/settings/settings"
import {ProtectedRoute} from "./guard"
import componentLoader from "src/components/custom-suspense/loader"

const SettingsMainComponent = lazy(() => componentLoader(() => import("src/pages/settings/settings-main")))
const SettingsRentComponent = lazy(() => componentLoader(() => import("src/pages/settings/settings-rent")))
const SettingsPenaltyComponent = lazy(() => componentLoader(() => import("src/pages/settings/settings-penalty")))
const SettingsStateComponent = lazy(() => componentLoader(() => import("src/pages/settings/state/settings-state")))
const SettingsManagersComponent = lazy(() => componentLoader(() => import("src/pages/settings/settings-managers")))
const SettingsCustomFieldComponent = lazy(() => componentLoader(() => import("src/pages/settings/custom-field")))
const SettingsBonusComponent = lazy(() => componentLoader(() => import("src/pages/settings/settings-bonus")))
const SettingsColorsComponent = lazy(() => componentLoader(() => import("src/pages/settings/settings-colors")))
const SettingsWazzupComponent = lazy(() => componentLoader(() => import("src/pages/settings/wazzup")))
const SettingsAdvanced = lazy(() => componentLoader(() => import("src/pages/settings/settings-advanced")))

export const settingRoutes: RouteObject[] = [
  {
    path: "settings",
    element: (
      <ProtectedRoute accessKey="settings">
        <SettingsComponent />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="main" replace={true} />
      },
      {
        path: "main",
        element: (
          <ProtectedRoute accessKey="settings.main">
            <CustomSuspense>
              <SettingsMainComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "rent",
        element: (
          <ProtectedRoute accessKey="settings.rent">
            <CustomSuspense>
              <SettingsRentComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "penalty",
        element: (
          <ProtectedRoute accessKey="settings.penalty">
            <CustomSuspense>
              <SettingsPenaltyComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "inventory-state",
        element: (
          <ProtectedRoute accessKey="settings.inventory-state">
            <CustomSuspense>
              <SettingsStateComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "order-status",
        element: (
          <ProtectedRoute accessKey="settings.order-status">
            <CustomSuspense>
              <SettingsColorsComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "custom-fields",
        element: (
          <ProtectedRoute accessKey="settings.custom-fields">
            <CustomSuspense>
              <SettingsCustomFieldComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "bonus",
        element: (
          <ProtectedRoute accessKey="settings.bonus">
            <CustomSuspense>
              <SettingsBonusComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "managers",
        element: (
          <ProtectedRoute accessKey="settings.managers">
            <CustomSuspense>
              <SettingsManagersComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "wazzup",
        element: (
          <ProtectedRoute accessKey="settings.wazzup">
            <CustomSuspense>
              <SettingsWazzupComponent />
            </CustomSuspense>
          </ProtectedRoute>
        )
      },
      {
        path: "advanced",
        element: (
          <CustomSuspense>
            <SettingsAdvanced />
          </CustomSuspense>
        )
      },
      {
        path: "*",
        element: <Navigate to="/settings/main" replace={false} />
      }
    ]
  }
]
