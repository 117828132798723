import React, {useContext} from "react"
import Icon from "src/components/shared/components/material-icon"
import modalService from "src/components/modal/global/modal.service"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import {useParams} from "react-router"
import toDateTime from "src/utils/date-time"
import {useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import {GroupType} from "src/services/auth.service"
import RideLink from "../../../details/help/rideLink"
import {ConfirmModal} from "../../../../../components/modal/global/confirmModal"
import {useTranslation} from "react-i18next"
import clsx from "clsx"

export interface TransferCardProps {
  ride: TransferRideModel
  deleteRide: (id: number, rideId: number) => void
}

function TransferCard({ride, deleteRide}: TransferCardProps) {
  const {profile} = useContext(ConfigContext)
  const isAdmin = profile && profile.group_type === GroupType.ADMIN
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {id} = useParams()

  const onDelete = () => {
    const onConfirm = () => deleteRide(+id, +ride.id)

    modalService.open({
      component: (
        <ConfirmModal
          message={t("transfers.action.are_you_sure_delete")}
          confirm_text={t("transfers.action.yes_delete")}
          onConfirm={onConfirm}
          delete={true}
        />
      )
    })
  }

  return (
    <div
      className={clsx("w-full bg-gray-70 rounded-lg flex flex-col justify-start", isAdmin && "cursor-pointer")}
      onClick={() => isAdmin && navigate(`timeline/${ride.id}`)}>
      <div className="p-3 pt-4 flex flex-col gap-4 border-b border-gray-200">
        <div className="text-xl font-bold">{toDateTime(ride?.start_at)}</div>

        <div className="flex-col flex gap-3">
          <div className="text-gray-370">
            {t("transfers.form.transfer_id")}: {ride?.id}
          </div>
          {(profile && profile.group_type === GroupType.MANAGER) ||
            (profile.is_superuser && (
              <div className="text-gray-370">
                {t("transfers.form.car_type")}: {ride?.inventory_group?.name}
              </div>
            ))}
        </div>
      </div>

      <div className="p-3 flex flex-col gap-4 border-b border-gray-200">
        <div className="flex-col flex gap-3">
          <div className="text-gray-370">
            {t("transfers.form.from_city")}: {ride?.start_city?.name}
          </div>
          <div className="flex gap-2 items-center text-gray-370">
            <span>{t("transfers.form.from_address")}:</span>
            {<RideLink name={ride?.start_address?.name} link={ride?.start_address?.link} />}
          </div>

          {ride?.start_address?.extra?.flight_destination && (
            <div className="text-gray-370">
              {t("transfers.form.flight_destination")}: {ride?.start_address?.extra?.flight_destination}
            </div>
          )}
          {ride?.start_address?.extra?.flight_number && (
            <div className="text-gray-370">
              {t("transfers.form.flight_number")}: {ride?.start_address?.extra?.flight_number}
            </div>
          )}
          {ride?.start_address?.extra?.train_destination && (
            <div className="text-gray-370">
              {t("transfers.form.train_destination")}: {ride?.start_address?.extra?.train_destination}
            </div>
          )}
          {ride?.start_address?.extra?.train_line && (
            <div className="text-gray-370">
              {t("transfers.form.train_line")}: {ride?.start_address?.extra?.train_line}
            </div>
          )}
          {ride?.start_address?.extra?.train_number && (
            <div className="text-gray-370">
              {t("transfers.form.train_number")}: {ride?.start_address?.extra?.train_number}
            </div>
          )}
          {ride?.start_address?.extra?.hotel_name && (
            <div className="text-gray-370">
              {t("transfers.form.hotel_name")}: {ride?.start_address?.extra?.hotel_name}
            </div>
          )}
        </div>
      </div>

      <div className={clsx("p-3 flex flex-col gap-4 border-b border-gray-200", !ride.comment && "border-none")}>
        <div className="flex-col flex gap-3">
          <div className="text-gray-370">
            {t("transfers.form.to_city")}: {ride?.end_city?.name}
          </div>
          <div className="flex gap-2 items-center text-gray-370">
            <span>{t("transfers.form.to_address")}:</span>
            {<RideLink name={ride?.end_address?.name} link={ride?.end_address?.link} />}
          </div>
          {ride?.end_address?.extra?.flight_destination && (
            <div className="text-gray-370">
              {t("transfers.form.flight_number")}: {ride?.end_address?.extra?.flight_destination}
            </div>
          )}
          {ride?.end_address?.extra?.flight_number && (
            <div className="text-gray-370">
              {t("transfers.form.flight_number")}: {ride?.end_address?.extra?.flight_number}
            </div>
          )}
          {ride?.end_address?.extra?.train_destination && (
            <div className="text-gray-370">
              {t("transfers.form.train_destination")}: {ride?.end_address?.extra?.train_destination}
            </div>
          )}
          {ride?.end_address?.extra?.train_line && (
            <div className="text-gray-370">
              {t("transfers.form.train_line")}: {ride?.end_address?.extra?.train_line}
            </div>
          )}
          {ride?.end_address?.extra?.train_number && (
            <div className="text-gray-370">
              {t("transfers.form.train_number")}: {ride?.end_address?.extra?.train_number}
            </div>
          )}
          {ride?.end_address?.extra?.hotel_name && (
            <div className="text-gray-370">
              {t("transfers.form.hotel_name")}: {ride?.end_address?.extra?.hotel_name}
            </div>
          )}
        </div>
      </div>

      {ride && ride.comment && (
        <div className="p-3 flex-col flex gap-3">
          <div className="text-gray-370">{t("transfers.form.comments")}:</div>
          <div className="text-black text-sm font-normal">{ride?.comment}</div>
        </div>
      )}

      {!isAdmin && (
        <div className="flex w-full justify-between gap-3 p-3 pt-0">
          <button
            className="p-3 border border-gray-100 w-full gap-2 flex justify-center items-center bg-white text-black rounded-lg"
            onClick={() => navigate(`ride/${ride.id}`)}>
            <Icon icon="edit" /> <span>{t("common.edit")}</span>
          </button>
          <button className="p-3 flex items-center text-alert bg-red-200 rounded-lg" onClick={onDelete}>
            <Icon icon="delete" />
          </button>
        </div>
      )}
    </div>
  )
}

export default TransferCard
