import {useTranslation} from "react-i18next"
import {Permission} from "src/pages/subscription/permission"
import {TenantLimitModel} from "src/services/tenant.service"

export function TariffPermissionList({
  rental_point_count,
  staff_count,
  inventory_group_count,
  request_count,
  sale_enabled,
  documents_enabled,
  metrics_enabled,
  bonus_enabled,
  custom_fields_enabled
}: TenantLimitModel) {
  const {t, i18n} = useTranslation()
  return (
    <>
      <Permission permitted={true}>
        {rental_point_count !== null ? (
          <span>
            <span className="font-semibold">
              {rental_point_count} {t("profile.subscription.tarif.rental_point1")}
              {i18n.language === "ru" && rental_point_count > 1 && "а"}
            </span>{" "}
            {t("profile.subscription.tarif.rental_point2")}
            {i18n.language === "en" && rental_point_count > 1 && "s"}
          </span>
        ) : (
          <span>
            Пункты проката
            <span className="font-semibold"> без лимита</span>
          </span>
        )}
      </Permission>
      <Permission permitted={true}>
        {staff_count !== null ? (
          <span>
            <span className="font-semibold">
              {staff_count}{" "}
              {staff_count > 1 ? t("profile.subscription.tarif.accounts") : t("profile.subscription.tarif.account")}
            </span>{" "}
            {staff_count > 1 ? t("profile.subscription.tarif.for_staff") : t("profile.subscription.tarif.for_staffs")}
          </span>
        ) : (
          <span>
            Аккаунты
            <span className="font-semibold"> без лимита</span>
          </span>
        )}
      </Permission>
      <Permission permitted={true}>
        {inventory_group_count === null ? (
          <span>
            {t("profile.subscription.tarif.product_count")}{" "}
            <span className="font-semibold">{t("profile.subscription.tarif.unlimited")}</span>
          </span>
        ) : (
          <span>
            <span className="font-semibold">
              {inventory_group_count}{" "}
              {+inventory_group_count > 1
                ? t("profile.subscription.tarif.position")
                : t("profile.subscription.tarif.positions")}{" "}
            </span>{" "}
            {+inventory_group_count > 1
              ? t("profile.subscription.tarif.product")
              : t("profile.subscription.tarif.products")}
          </span>
        )}
      </Permission>
      <Permission permitted={true}>
        {request_count === null ? (
          <span>
            {t("profile.subscription.tarif.rent_count")}{" "}
            <span className="font-semibold">{t("profile.subscription.tarif.unlimited")}</span>
          </span>
        ) : (
          <span>
            <span className="font-semibold">
              {request_count}{" "}
              {+request_count > 1 ? t("profile.subscription.tarif.rent") : t("profile.subscription.tarif.rentals")}
            </span>{" "}
            {t("profile.subscription.tarif.monthly")}
          </span>
        )}
      </Permission>
      <Permission permitted={true} children={t("profile.subscription.tarif.accounting_rent_and_prod")} />
      <Permission permitted={sale_enabled} children={t("profile.subscription.tarif.accounting_sells")} />
      <Permission permitted={documents_enabled} children={t("profile.subscription.tarif.document")} />
      <Permission permitted={metrics_enabled} children={t("profile.subscription.tarif.metrics")} />
      <Permission permitted={bonus_enabled} children={t("profile.subscription.tarif.bonus")} />
      <Permission permitted={custom_fields_enabled} children={t("profile.subscription.tarif.custom_fields")} />
    </>
  )
}
