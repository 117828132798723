import {RouteObject} from "react-router-dom"
import CrmComponent from "../pages/crm"
import CRMProvider, {CRMContext} from "../pages/crm/list/context"

export const crmRoutes: RouteObject[] = [
  {
    path: "leads/:funnelId?",
    element: (
      <CRMProvider>
        <CrmComponent />
      </CRMProvider>
    )
  }
]
