import {useEffect, useState, useContext} from "react"
import {setChildStyle, setDefaultChildStyle} from "src/main"
import integrationService from "src/services/integration"
import {ConfigContext} from "src/app"

export function WazzupFrameComponent({filter = undefined}: {filter?: Array<{name: string; phone: string}>}) {
  const [url, setUrl] = useState<string>(null)
  const {isMobile, headerHeight} = useContext(ConfigContext)

  useEffect(() => {
    integrationService.wazzupIFrame({filter: filter}).then(({url}) => setUrl(url))
    return () => setUrl(undefined)
  }, [])

  useEffect(() => {
    setChildStyle({paddingLeft: 0, paddingRight: 0, paddingTop: isMobile ? headerHeight : 0, paddingBottom: 0})
    return setDefaultChildStyle
  }, [isMobile, headerHeight])

  return url && <iframe src={url} width="100%" height="100%" />
}
