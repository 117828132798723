import clsx from "clsx"
import {CSSProperties, ReactNode, Ref} from "react"
import {GroupBase, MenuProps, OptionProps, components} from "react-select"
import Icon from "src/components/shared/components/material-icon"
import {ExtendedModalConfig} from "src/components/modal/global/modal.service"

const btnStyle: CSSProperties = {
  padding: "16px 16px",
  backgroundColor: "var(--color-white)",
  borderTop: "1px solid var(--color-gray-100)",
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8
}

interface CustomOptionProps extends OptionProps<any, boolean, GroupBase<any>> {
  icon?: ReactNode
}

export function SelectOption(props: CustomOptionProps) {
  return (
    <components.Option {...props}>
      <span className="flex items-center justify-between gap-2" style={{minHeight: 20}}>
        {props.icon || <></>}
        {props.children}
        {props.isSelected && <Icon icon="radio_button_checked" className="text-white" />}
      </span>
    </components.Option>
  )
}

export function SelectMenu(
  props: MenuProps<any, boolean, GroupBase<any>> & {
    onModal: () => void
    modalProps?: ExtendedModalConfig
  }
) {
  const {text, className} = props.modalProps
  const onModal = (e) => {
    e.stopPropagation()
    props.onModal()
  }
  return (
    <components.Menu {...props}>
      {props.children}
      <span
        className={clsx("flex items-center justify-center w-full cursor-pointer", className)}
        style={btnStyle}
        onClick={onModal}
        onTouchStart={onModal}>
        <Icon icon="add" className="text-xl color-primary font-bold" />
        {text && <span className="text-sm font-medium color-primary">{text}</span>}
      </span>
    </components.Menu>
  )
}
