import React, {useEffect, useRef, useState} from "react"
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  DragEndEvent,
  DragStartEvent,
  DragOverlay
} from "@dnd-kit/core"
import {arrayMove, SortableContext, horizontalListSortingStrategy} from "@dnd-kit/sortable"
import CRMColumn from "./column"
import modalService from "src/components/modal/global/modal.service"
import CreateStageModal from "../detail/modal/create-stage-modal"
import {Stage} from "src/services/crm/funnel.service"
import {Subscription} from "rxjs"
import {stageStore} from "../store/stage.store"
import Icon from "src/components/shared/components/material-icon"
import leadsService, {LeadList} from "src/services/crm/leads.service"
import CRMCard from "./lead"
import {useFormContext} from "react-hook-form"
import KanbanFilter from "./filter"
import useOnScreen from "src/hooks/useOnScreen"
import {ListModel} from "src/models/common"

export const KanbanContext = () => {
  const {watch} = useFormContext()
  const selectedFunnelId = +watch("funnel")

  const elementRef = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(elementRef)
  const sensors = useSensors(useSensor(PointerSensor))

  const [columns, setColumns] = useState<Stage[]>([])
  const [_leads, _setLeads] = useState<Record<number, ListModel<LeadList>>>({})

  const [activeItem, setActiveItem] = useState<{
    id: string
    type: "card" | "column"
    data: any
  } | null>(null)

  const handleDragStart = (event: DragStartEvent) => {
    const {id, data} = event.active
    setActiveItem({
      id: String(id),
      type: data.current?.type,
      data: data.current
    })
  }

  const handleDragEnd = (event: DragEndEvent) => {
    setActiveItem(null)
    const {active, over} = event
    if (!over) return

    const activeType = active.data.current?.type
    const overType = over.data.current?.type

    switch (activeType) {
      case "column":
        if (overType === "column") {
          handleColumnDrop(active.id, over.id)
        }
        break
      case "card":
        handleCardDrop(active, over)
        break
      default:
        break
    }
  }

  const handleColumnDrop = (activeId: string | number, overId: string | number) => {
    if (activeId === overId) return
    const oldIndex = columns.findIndex((col) => col.id === +activeId)
    const newIndex = columns.findIndex((col) => col.id === +overId)
    const newOrder = arrayMove(columns, oldIndex, newIndex)

    setColumns(newOrder)
    const targetColumn = columns[newIndex]

    stageStore.reorderStage(+activeId, targetColumn.ordering)
  }

  const handleCardDrop = (active: any, over: any) => {
    const leadBeingDragged: LeadList = active.data.current.lead
    let newStage: number | null = null
    let overCardId: number | null = null

    switch (over.data.current?.type) {
      case "card": {
        const leadUnderCursor: LeadList = over.data.current.lead
        newStage = leadUnderCursor.stage
        overCardId = leadUnderCursor.id
        break
      }
      case "column": {
        newStage = Number(over.id)
        break
      }
      case "placeholder": {
        const splitted = String(over.id).split("-")
        newStage = Number(splitted[1])
        break
      }
      default:
        return
    }

    if (!newStage) return
    if (leadBeingDragged.stage === newStage) {
      reorderCard(leadBeingDragged, overCardId)
    } else {
      moveCard(leadBeingDragged, newStage, overCardId)
    }
  }

  const reorderCard = (draggedLead: LeadList, overCardId?: number) => {
    const leadsInColumn = _leads[draggedLead.stage]?.results || []
    const oldIndex = leadsInColumn.findIndex((l) => l.id === draggedLead.id)
    let newIndex = leadsInColumn.findIndex((l) => l.id === overCardId)

    if (newIndex < 0) newIndex = leadsInColumn.length
    if (oldIndex < 0 || newIndex < 0 || oldIndex === newIndex) return

    let reordered = arrayMove(leadsInColumn, oldIndex, newIndex)

    reordered = reordered.map((lead, idx) => ({
      ...lead,
      ordering: idx + 1
    }))

    _setLeads((prev) => ({
      ...prev,
      [draggedLead.stage]: {
        ...prev[draggedLead.stage],
        results: reordered
      }
    }))

    const updatedDraggedLead = reordered.find((l) => l.id === draggedLead.id)
    if (updatedDraggedLead) {
      leadsService.patchLead(updatedDraggedLead.id, {
        ordering: updatedDraggedLead.ordering
      })
    }
  }

  const moveCard = async (draggedLead: LeadList, newStage: number, overCardId?: number) => {
    const oldLeads = _leads[draggedLead.stage]?.results || []
    const newLeads = _leads[newStage]?.results || []
    const updatedOld = oldLeads.filter((l) => l.id !== draggedLead.id)
    const newLead = {...draggedLead, stage: newStage}

    if (overCardId) {
      const idx = newLeads.findIndex((l) => l.id === overCardId)
      if (idx >= 0) newLeads.length = idx
    }

    const updatedNew = [...newLeads]
    updatedNew.splice(newLeads.length, 0, newLead)

    _setLeads((prev) => ({
      ...prev,
      [draggedLead.stage]: {
        ...prev[draggedLead.stage],
        results: updatedOld
      },
      [newStage]: {
        ...prev[newStage],
        results: updatedNew
      }
    }))

    await leadsService.patchLead(draggedLead.id, {stage: newStage})
  }

  const openCreateModal = () => {
    modalService.open({
      size: "sm",
      component: <CreateStageModal funnelId={selectedFunnelId} />
    })
  }

  const onLoadNext = async (stageId: number) => {
    const current = _leads[stageId]
    if (!current?.next) return

    const response = await leadsService.list({
      stage: stageId,
      page: current.page + 1,
      pageSize: 10
    })

    _setLeads((prev) => ({
      ...prev,
      [stageId]: {
        ...prev[stageId],
        results: [...(prev[stageId]?.results || []), ...response.results],
        next: response.next ?? false,
        page: current.page + 1,
        count: response.count
      }
    }))
  }

  useEffect(() => {
    if (!isOnScreen) return

    for (const col of columns) {
      const columnData = _leads[col.id]
      if (columnData?.next) {
        onLoadNext(col.id)
      }
    }
  }, [isOnScreen])

  useEffect(() => {
    const onLoad = async () => {
      for (const col of columns) {
        const response = await leadsService.list({
          stage: col.id,
          page: 1,
          pageSize: 10
        })

        _setLeads((prev) => ({
          ...prev,
          [col.id]: {
            results: response.results,
            next: response.next ?? false,
            page: 1,
            count: response.count
          }
        }))
      }
    }

    if (columns.length) {
      onLoad()
    }
  }, [columns])

  useEffect(() => {
    const subscription: Subscription = stageStore.stages$.subscribe(setColumns)
    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (!selectedFunnelId) return
    stageStore.setFunnelId(selectedFunnelId)
  }, [selectedFunnelId])

  return (
    <>
      <KanbanFilter />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}>
        <SortableContext items={columns.map((col) => col.id)} strategy={horizontalListSortingStrategy}>
          <div className="flex gap-4 items-start">
            <div className="flex gap-4">
              {columns.map((column) => {
                const leadsForColumn = _leads[column.id]?.results || []
                return <CRMColumn key={column.id} column={column} leads={leadsForColumn} />
              })}
            </div>
            <div
              className="py-3 flex items-center gap-4 justify-center min-w-[200px] rounded-md cursor-pointer font-semibold"
              onClick={openCreateModal}>
              <Icon icon="add" />
              <span className="text-gray-500 text-base">Добавить статус</span>
            </div>
            <div className="p-2"></div>
          </div>
          <div className="h-6" ref={elementRef} />
        </SortableContext>
        <DragOverlay>
          {activeItem?.type === "column" &&
            (() => {
              const draggedColumn = columns.find((c) => c.id === +activeItem.id)
              if (!draggedColumn) return null
              const leadsData = _leads[draggedColumn.id]?.results || []
              return <CRMColumn column={draggedColumn} leads={leadsData} isOverlay />
            })()}

          {activeItem?.type === "card" &&
            (() => {
              let draggedLead: LeadList | undefined
              for (const col of Object.values(_leads)) {
                const found = col.results.find((l) => l.id === +activeItem.id)
                if (found) {
                  draggedLead = found
                  break
                }
              }
              if (!draggedLead) return null
              return <CRMCard lead={draggedLead} isOverlay />
            })()}
        </DragOverlay>
      </DndContext>
    </>
  )
}
