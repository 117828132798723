import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import FormSelectAsync from "../../inputs/form-select-async.list"
import {LabelModel} from "src/models/label"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import {ConfigAddOrEdit} from "src/pages/config/config-add-or-edit"
import {FormControl} from "../../inputs/form-control"
import GenericEntityService from "src/services/generic-entity.service"
import {from} from "rxjs"
import {FormSelectConfig} from "../../inputs/form-select"
import settingService from "src/services/settings.service"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "options" | "modalProps"

interface TransferCityConfig extends Omit<FormSelectConfig<LabelModel>, RemovalKeys> {
  addOption?: boolean
}

const TransferCity: React.FC<TransferCityConfig> = (config) => {
  const {t} = useTranslation()
  const service = new GenericEntityService<LabelModel>("v1/crm/transfers/cities")
  const [refreshKey, setRefreshKey] = useState(0)

  const onCreate = async (payload: Partial<LabelModel>) => {
    try {
      await service.post(payload)
      settingService.loadConstants()
      modalService.closeModal()
      setRefreshKey((prevKey) => prevKey + 1)
    } catch (error) {
      console.error("Error creating address:", error)
    }
  }

  const modalProps: ExtendedModalConfig = {
    text: "Новый город",
    size: "lg",
    backdropClick: false,
    component: (
      <ConfigAddOrEdit<LabelModel>
        onSubmit={(city) => onCreate(city).then(() => modalService.closeModal())}
        buttonTitle={t("common.add")}
        title="Add city"
        mode="add">
        <FormControl
          name="name"
          label={t("common.input.name")}
          rootclassname="col"
          className="form-control"
          params={{required: true}}
          required
          placeholder="Enter the city"
        />
      </ConfigAddOrEdit>
    )
  }

  return (
    <FormSelectAsync<any>
      key={refreshKey}
      {...config}
      modalProps={modalProps}
      listOptions={() => from(service.fetch())}
      getValue={(id) => service.get(+id)}
      getOptionLabel={(option: any) => (option ? String(option.name) : null)}
      getOptionValue={(option: any) => (option ? String(option.id) : null)}
    />
  )
}

export default TransferCity
