import React, {useEffect, useState} from "react"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import modalService from "src/components/modal/global/modal.service"
import transportationService from "src/services/transportation.service"
import TransferCities from "./detail/transfer-cities"
import TransferCityModal from "../../details/modal/transfer-city-modal"
import {TransferCity} from "src/models/manager/transfer/transfer"

const TransportationCities = () => {
  const {t} = useTranslation()
  const [cities, setCities] = useState<TransferCity[]>([])

  useEffect(() => {
    fetchCities()
  }, [])

  const fetchCities = async () => {
    const cityList = await transportationService.listCity()
    setCities(cityList)
  }

  const openCityModal = () => {
    modalService.open({
      size: "sm",
      component: <TransferCityModal fetchCities={fetchCities} />
    })
  }

  return (
    <>
      <HeaderContent>
        <div className="flex items-center justify-between gap-2 header-content">
          <div className="text-lg font-semibold md:text-2xl">{t("transfers.form.all_cities")}</div>
          <button className="btn btn-primary btn-color-white text-base font-medium leading-5" onClick={openCityModal}>
            <span className="flex items-center gap-2">
              <Icon icon="add" /> {t("common.add")}
            </span>
          </button>
        </div>
      </HeaderContent>

      <div className="-mx-4 -mt-3 md:mx-0 md:mt-0">
        <TransferCities cities={cities} />
      </div>
    </>
  )
}

export default TransportationCities
