import clsx from "clsx"
import {FieldError, RegisterOptions, useFormContext} from "react-hook-form"
import InputMask, {Props} from "react-input-mask"

interface FormControlConfig extends Props {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

export function FormControlMask(config: FormControlConfig) {
  const {
    register,
    watch,
    formState: {errors}
  } = useFormContext()
  const error = errors[config.name]

  const className = clsx(config.className, error && "invalid")

  return (
    <div className={clsx(config.rootclassname)}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] text-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <InputMask
        {...config}
        {...register(config.name, {...config.params, setValueAs: (val) => (val ? val.replace(/-/g, "") : "")})}
        value={watch(config.name) || ""}
        className={className}
        autoComplete="off"
      />
      {errors[config.name] && "message" in errors[config.name] && errors[config.name].message && (
        <p className="text-alert mt-1 text-sm">{(errors[config.name] as FieldError)?.message}</p>
      )}
    </div>
  )
}
