import {useContext, useEffect, useMemo, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useDebounce} from "src/hooks/useDebounce"
import useQueryParams from "src/hooks/useQuertParams"
import {ListParams} from "src/models/common"
import {Outlet, useNavigate} from "react-router"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import metricsService from "src/services/metrics/products-parts.service"
import {InventoryGroupEarningAggregateModel} from "src/models/manager/metrics/payback-product"
import {toPrice} from "src/utils/price"
import {toPercent} from "src/utils/percent"
import CardWrapper from "src/components/shared/components/card"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import {FormControl} from "src/components/shared/inputs/form-control"
import {useTranslation} from "react-i18next"
import Duration from "src/utils/duriation"
import {Segmented} from "src/components/shared/antd-custom"
import {CardSection} from "src/components/shared/components/card/card-content"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"
import {FormControlSwitch} from "src/components/shared/inputs/form-control-switch"
import {SharedInventoryGroupSelect} from "src/components/shared/components/select/inventory-group"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import {FilterContext} from "src/components/shared/components/filter-wrapper"
import {CustomModal} from "src/components/modal/global/customModal"
import FormSelect from "src/components/shared/inputs/form-select"
import {LabelModel} from "src/models/label"

export default function InventoryPaybackWrapper() {
  const navigate = useNavigate()
  const page = useCurrentRoute()
  const {t} = useTranslation()
  const [general, setGeneral] = useState<InventoryGroupEarningAggregateModel>()
  const {setFilter, modal, setModal} = useContext(FilterContext)
  const {searchParams} = useQueryParams()
  const form = useForm<ListParams>({
    defaultValues: {
      page: 1,
      pageSize: 10,
      search: "",
      ...searchParams
    }
  })
  const {watch, reset} = form
  const values = watch()

  const searchDebounce = useDebounce(watch("search"), 500)
  useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

  const listPaybackGeneral = (params: any) => metricsService.listInventoryGroupPaybackGeneral(params).then(setGeneral)

  const sectionDataConfig: CardSection[] = general && [
    {
      title: t("metrics.inventory.payback.general.title_common"),
      values: [
        {
          label: t("metrics.inventory.payback.general.group_count"),
          value: <div className="flex gap-2 flex-col">{general.group_cnt}</div>
        },
        {
          label: t("metrics.inventory.payback.general.inventory_count"),
          value: <div className="flex gap-2 flex-col">{general.inventory_cnt}</div>
        }
      ]
    },
    {
      title: t("metrics.inventory.payback.general.title_finance"),
      values: [
        {
          label: t("metrics.inventory.payback.general.buy_price_sum"),
          value: <div className="flex gap-2 flex-col">{toPrice(general.buy_price_sum)}</div>
        },
        {
          label: t("metrics.inventory.payback.general.earning_sum"),
          value: <div className="flex gap-2 flex-col">{toPrice(general.earning_sum)}</div>
        },
        {
          label: t("metrics.inventory.payback.general.overdue_sum"),
          value: <div className="flex gap-2 flex-col">{toPrice(general.overdue_sum)}</div>
        }
      ]
    },
    {
      title: t("metrics.inventory.payback.general.title_payback"),
      values: [
        {
          label: t("metrics.inventory.payback.general.efficency_sum"),
          value: <div className="flex gap-2 flex-col">{toPercent(general.efficency_sum)}</div>
        },
        {
          label: t("metrics.inventory.payback.general.payback_duration_avg"),
          value: (
            <div className="flex gap-2 flex-col">
              <Duration time={general.payback_duration_avg} />
            </div>
          )
        }
      ]
    }
  ]

  const filter = useMemo(
    () => (
      <FormProvider {...form}>
        <FormControlDaterange
          className="form-control"
          name_start="start_at"
          name_end="end_at"
          allowClear={true}
        />
        <SharedCategorySelect
          className="flex-1 min-w-48"
          placeholder={t("common.select.category")}
          name="category"
          isClearable={true}
          isSearchable={false}
        />
        <SharedInventoryGroupSelect
          name="group"
          className="flex-1 min-w-48"
          placeholder={t("common.select.group")}
          listOptions={(params) => inventoryGroupService.list(params)}
          isSearchable={false}
          isClearable={true}
        />
        <FormSelect
          name="sublease"
          placeholder={t("metrics.inventory.payback.filter.sublease")}
          getOptionValue={(option: LabelModel) => String(option.id)}
          getOptionLabel={(option: LabelModel) => String(option.label)}
          options={[{id: true, label: "Только субаренда"}, {id: false, label: "Только свои товары"}]}
        />
        <FormControlSwitch
          name="deleted"
          label={t("metrics.inventory.payback.filter.archived")}
        />
        <FormControl
          rootclassname="flex-1 min-w-48"
          icon="search"
          name="search"
          className="form-control"
          placeholder={t("common.input.search")}
        />
      </FormProvider>
    ),
    []
  )

  useEffect(() => {
    listPaybackGeneral(values)

    const sub = watch((params, {name}) => {
      if (name !== "search") listPaybackGeneral(params)
    })
    return () => {
      sub.unsubscribe()
      setGeneral(undefined)
    }
  }, [])

  useEffect(() => setFilter(filter), [filter])

  return (
    <FormProvider {...form}>
      {general && <CardWrapper cardSectionData={sectionDataConfig} />}

      <Outlet
        context={{
          title: (
            <Segmented
              value={page}
              className="bg-gray-100 self-center rounded-md mr-auto"
              onChange={(option) => {
                navigate(option)
                reset({...values, group: null})
              }}
              style={{
                background: "var(--color-gray-70)",
                padding: 4,
                borderRadius: 6
              }}
              options={[
                {
                  label: t("metrics.inventory.payback.pages.group"),
                  value: "group",
                  className: "p-1 font-medium text-black"
                },
                {
                  label: t("metrics.inventory.payback.pages.inventory"),
                  value: "inventory",
                  className: "p-1 font-medium text-black"
                }
              ]}
            />
          )
        }}
      />
      <CustomModal show={modal} onHide={() => setModal(false)}>
        <div className="flex flex-col gap-3">{filter}</div>
      </CustomModal>
    </FormProvider>
  )
}
