import {LabelModel} from "../label"
import {Weekday} from "./inventory/inventory-tarif.model"

export interface CategoryModel {
  id: number
  name: string
  comment: string
  prefix: string
  parent: number
  slug: string
  icon: string
  children?: CategoryModel[]
  indentSize?: number
  order: number
}

export interface ClientTickModel {
  id: number
  name: string
  comment: string
  code: string
}

export interface RentalPointModel {
  id: number
  name: string
  address: string
  deleted: boolean
}

export interface InventoryTarifTimePeriod {
  name: string
  id: number
  time: number
  weekdays: Weekday[]

  inventory_tarif_count?: number
  service_tarif_count?: number
}

export interface InventoryStateStatus {
  id: number
  name: string
  disable: boolean
  comment: string
  color: string
}

export interface PassportIssuerModel {
  id: number
  issue_ru: string
  issue_kk: string
  type: string
}

export interface PaymentTypeModel {
  id: number
  label: any
  deleted: boolean
}

export interface ManagerConstantModel {
  APPLICATION_READY: boolean
  CLIENT_TICKS: ClientTickModel[]
  CLIENT_ATTRACTION_METHOD: LabelModel[]
  CLIENT_TYPE: LabelModel[]
  SERVICE_TYPE: LabelModel[]
  INVENTORY_GROUP_TYPE: LabelModel[]
  INVENTORY_CATEGORIES: CategoryModel[]
  INVENTORY_STATUS: LabelModel[]
  INVENTORY_STATE_STATUS: InventoryStateStatus[]
  TARIF_TIME_PERIODS: InventoryTarifTimePeriod[]
  ORDER_REQUEST_STATUS: LabelModel[]
  ORDER_PAYMENT_STATUS: LabelModel[]
  PAYMENT_TYPES: PaymentTypeModel[]
  RENTAL_POINTS: RentalPointModel[]
  PASSPORT_ISSUERS: PassportIssuerModel[]
  DISCOUNT_TYPE: LabelModel[]
  DISCOUNT_CALCULATION_TYPE: LabelModel[]
  EXPENSE_CATEGORY: LabelModel[]
  EXPENSE_COUNTERPARTY: LabelModel[]
}

export interface ManagerConstantMapModel {
  CLIENT_TICKS: Record<number, ClientTickModel>
  CLIENT_ATTRACTION_METHOD: Record<number | string, LabelModel>
  CLIENT_TYPE: Record<number | string, LabelModel>
  SERVICE_TYPE: Record<number | string, LabelModel>
  INVENTORY_GROUP_TYPE: Record<number | string, LabelModel>
  INVENTORY_CATEGORIES: Record<number, CategoryModel>
  INVENTORY_STATUS: Record<number | string, LabelModel>
  INVENTORY_STATE_STATUS: Record<number, InventoryStateStatus>
  TARIF_TIME_PERIODS: Record<number, InventoryTarifTimePeriod>
  ORDER_REQUEST_STATUS: Record<number | string, LabelModel>
  ORDER_PAYMENT_STATUS: Record<number | string, LabelModel>
  PAYMENT_TYPES: Record<number | string, PaymentTypeModel>
  RENTAL_POINTS: Record<number, RentalPointModel>
  PASSPORT_ISSUERS: Record<number, PassportIssuerModel>
  DISCOUNT_TYPE: Record<number | string, LabelModel>
  DISCOUNT_CALCULATION_TYPE: Record<number | string, LabelModel>
  EXPENSE_CATEGORY: Record<number | string, LabelModel>
  EXPENSE_COUNTERPARTY: Record<number | string, LabelModel>
}

export const defaultConstant: ManagerConstantModel = {
  APPLICATION_READY: false,
  CLIENT_TICKS: [],
  CLIENT_ATTRACTION_METHOD: [],
  CLIENT_TYPE: [],
  SERVICE_TYPE: [],
  INVENTORY_GROUP_TYPE: [],
  INVENTORY_CATEGORIES: [],
  INVENTORY_STATUS: [],
  INVENTORY_STATE_STATUS: [],
  TARIF_TIME_PERIODS: [],
  ORDER_REQUEST_STATUS: [],
  ORDER_PAYMENT_STATUS: [],
  PAYMENT_TYPES: [],
  RENTAL_POINTS: [],
  PASSPORT_ISSUERS: [],
  DISCOUNT_TYPE: [],
  DISCOUNT_CALCULATION_TYPE: [],
  EXPENSE_CATEGORY: [],
  EXPENSE_COUNTERPARTY: []
}
