import {useContext} from "react"
import {Transfer} from "src/models/manager/transfer/transfer"
import {Link} from "react-router-dom"
import {toPrice} from "src/utils/price"
import toDateTime from "src/utils/date-time"
import {GroupType} from "src/services/auth.service"
import {ConfigContext} from "src/app"
import {getOrderStatus} from "../../../details/help/getOrderStatus"
import {useTranslation} from "react-i18next"

const TransferOrderCard = ({transfer}: {transfer: Transfer}) => {
  const {
    id,
    start_at,
    price,
    client,
    driver_user_name,
    driver_user_id,
    driver_inventory_name,
    driver_inventory_unique_id,
    status
  } = transfer
  const {profile} = useContext(ConfigContext)
  const isAdmin = profile && profile.group_type === GroupType.ADMIN
  const {text, bg} = getOrderStatus(status)
  const {t} = useTranslation()

  return (
    <Link to={`/transportation/${id}`} className="w-full bg-white rounded-lg flex flex-col justify-start">
      <div className="p-3 flex flex-col gap-4 border-b border-gray-100">
        <div className="flex justify-between items-center">
          <div className="text-base font-bold text-black">№{id}</div>
          <div className="text-[13px] px-4 py-2 text-white rounded-lg" style={{backgroundColor: bg}}>
            {t(text as any)}
          </div>
        </div>
      </div>

      <div className="p-3 flex flex-col gap-3 border-b border-gray-100 last:border-none">
        <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-370">{t("transfers.form.date")}</span>
            <span className="block font-medium text-[15px] text-gray-900">{toDateTime(start_at)}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-370">{t("transfers.form.order_amount")}</span>
            <span className="block font-medium text-[15px] text-gray-900">{toPrice(Number(price))}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-370">{t("transfers.main.client")}</span>
            <span className="block font-medium text-[15px] text-gray-900">{client ? client.name : "Not selected"}</span>
          </div>
        </div>
      </div>

      {driver_user_id && (
        <div className="p-3 flex flex-col gap-3">
          <div className="flex bg-gray-70 p-3 rounded-lg flex-col gap-2">
            <div className="grid grid-cols-2 gap-2">
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-370">{t("transfers.main.driver")}</span>
                <span className="block font-medium text-[15px] text-gray-900">{driver_user_name}</span>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-370">{t("transfers.main.client")}</span>
                <span className="block font-medium text-[15px] text-gray-900">
                  {driver_inventory_name} ({driver_inventory_unique_id})
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Link>
  )
}

export default TransferOrderCard
