import axios from "axios"
import {ListModel} from "../models/common"
import {ApiAbstract} from "../abstract/api.abstract"
import {ClientModel} from "../models/manager/clients/client.model"
import {ClientGeneralModel} from "src/models/manager/clients/client-general.model"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import {ClientFileModel} from "src/models/manager/clients/client-file.model"
import {ClientBonusModel} from "src/models/manager/clients/client-bonus.model"
import exportExcel from "src/utils/export/excel"

class ManageClientService extends ApiAbstract {
  constructor() {
    super("v1/crm/clients")
  }

  public async general(params?: any): Promise<ClientGeneralModel> {
    const response = await axios.get(this.getUrl("general"), {params})
    return response.data
  }
  public async list(params?: any): Promise<ListModel<ClientModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async importFile(payload, clientType: "individual" | "legal"): Promise<any> {
    const response = await axios.post(this.getUrl(`import/${clientType}`), payload)
    return response.data
  }
  public async exportList(params?: any): Promise<void> {
    const response = await axios.get(this.getUrl("export"), {
      params,
      responseType: "blob"
    })
    exportExcel("clients", response)
  }
  public async get(id: number): Promise<ClientModel> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async getPopularProduct(id: number): Promise<InventoryGroupModel> {
    const response = await axios.get(this.getUrl(`${id}/popular_product`))
    return response.data
  }
  public async listFiles(id: number): Promise<ClientFileModel[]> {
    const response = await axios.get(this.getUrl(`${id}/files`))
    return response.data
  }
  public async createFiles(id: number, payload: any): Promise<ClientFileModel[]> {
    const response = await axios.post(this.getUrl(`${id}/files`), payload)
    return response.data
  }
  public async deleteFiles(id: number, fileId: number): Promise<ClientFileModel[]> {
    const response = await axios.delete(this.getUrl(`${id}/files/${fileId}`))
    return response.data
  }
  public async post(payload: any): Promise<ClientModel> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: any): Promise<ClientModel> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async delete(id: number): Promise<ClientModel> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }

  public async listBonuses(id: number, params): Promise<ListModel<ClientBonusModel>> {
    const response = await axios.get(this.getUrl(`${id}/bonuses`), {params})
    return response.data
  }
  public async createBonusTransaction(id: number, payload: any): Promise<ClientBonusModel> {
    const response = await axios.post(this.getUrl(`${id}/bonuses`), payload)
    return response.data
  }
  public async deleteBonusTransaction(id: number, bonus_id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${id}/bonuses/${bonus_id}`))
    return response.data
  }
}

const clientService = new ManageClientService()
export default clientService
