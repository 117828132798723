import {RentFilter} from "src/pages/settings/settings-rent"
import {OrderStatusColor} from "src/components/shared/constants"

export enum LANG {
  RU = "ru",
  EN = "en"
}

export enum CustomFieldType {
  STRING = "string",
  HTML = "html",
  INTEGER = "integer",
  DECIMAL = "decimal",
  BOOLEAN = "boolean",
  DATE = "date",
  DATETIME = "datetime",
  COLOR = "color",
  CLIENT = "client",
  INVENTORY = "inventory",
  PRODUCT = "product",
  SET = "set"
}

export enum PriceRounding {
  NONE = "none",
  DEFAULT = "default",
  UP = "up",
  DOWN = "down"
}

export enum OrderSplit {
  CREATED_AT = "created_at",
  RENT_START = "rent_start",
  RENT_FACT_START = "rent_fact_start",
  RENT_END = "rent_end",
  RENT_FACT_END = "rent_fact_end"
}

export enum OrderInterface {
  DEFAULT = "default",
  SIMPLE = "simple"
}

export enum InventoryPriority {
  COMPANY = "COMPANY",
  SUBLEASE = "SUBLEASE",
  ALL = "ALL"
}

export enum ProductType {
  INVENTORTY = "inventory",
  GROUP = "group",
  SALE = "sale"
}

export type CustomFieldOption = Record<string, Array<{[key: string]: string | number; cnt: number}>>

export const optionlessFieldTypes: CustomFieldType[] = [
  CustomFieldType.BOOLEAN,
  CustomFieldType.COLOR,
  CustomFieldType.CLIENT,
  CustomFieldType.INVENTORY,
  CustomFieldType.PRODUCT,
  CustomFieldType.SET,
  CustomFieldType.HTML
]

export const defaultCustomField: CustomFieldModel = {
  inventory_group: [],
  inventory_set: [],
  inventory: [],
  service: [],
  category: [],
  client: [],
  client_document: [],
  order: [],
  order_inventory: [],
  order_service: []
}

export interface CustomField {
  name: string
  label: string
  required: boolean
  table: boolean
  filter: boolean
  type: CustomFieldType
  options: any[]
  defaultValue: string
}

export interface CustomFieldModel {
  inventory_group: CustomField[]
  inventory_set: CustomField[]
  inventory: CustomField[]
  service: CustomField[]
  category: CustomField[]
  client: CustomField[]
  client_document: CustomField[]
  order: CustomField[]
  order_inventory: CustomField[]
  order_service: CustomField[]
}

export interface PenaltyOption {
  name: string
  duration: string
  // value: number
}

export type DiscountType = "manual" | "discount" | "promocode"

export interface SettingConfig extends Record<OrderStatusColor, string> {
  name: string
  address: string
  currency: string
  currency_prefix: boolean
  language: LANG
  type: string
  reservation: boolean
  min_order_duration: string
  max_order_duration: string
  penalty: boolean
  penalty_type: "timestamp" | "duration"
  penalty_after: boolean
  penalty_manager_access: boolean
  penalty_default: string
  creation_percent: number
  reservation_percent: number
  issue_percent: number
  recievement_percent: number
  single_issue: boolean
  single_recievement: boolean
  order_client: boolean
  order_service: boolean
  order_penalty: boolean
  order_document: boolean
  order_discount: boolean
  order_discount_types: DiscountType[]
  order_inventory_occupation: boolean
  order_inventory_discount: boolean
  order_agent: boolean
  order_agent_percent: number
  order_interface: OrderInterface
  order_duration: string
  order_deposit: boolean
  order_pause: boolean
  order_delivery: boolean
  order_default_filter: RentFilter
  order_table_expanded: boolean
  order_inventory_detail: boolean
  order_inventory_availability: "strict" | "free"
  workshift: boolean
  price_rounding: PriceRounding
  custom_fields: CustomFieldModel
  penalty_options: PenaltyOption[]
  bonus_default_amount: number
  bonus_default_percent: number
  bonus_default_usage: number
  rent_inventory_priority: InventoryPriority
  metrics_order_split: OrderSplit
  metrics_order_statuses: number[]
  metrics_order_payment_statuses: number[]
  rent_product_types: ProductType[]
  tax: boolean
  tax_included: boolean
  tax_percent: number
  phone_format: string
  feature_set: boolean
  feature_service: boolean
  feature_schedule: boolean
  feature_integration: boolean
  feature_maintenance: boolean
  feature_history: boolean

  // Order Layout
  order_layout_left: OrderLayoutLeft
  order_layout_right: OrderLayoutRight
}

export interface OrderLayoutLeft {
  width: number | null | ""
  ordering: orderingLeft[]
  ordering_flex_style: ordering_flex_style[]
  ordering_info: ordering_info[]
  ordering_products: ordering_products[]
}
export interface OrderLayoutRight {
  ordering: orderingRight[]
}

export type orderingLeft = "info" | "products"
export type orderingRight = "rent_info" | "payment" | "docs" | "comments" | "deposit" | "penalty" | "agent"
export type ordering_flex_style = "column" | "row"
export type ordering_info = "client" | "agent" | "datetime"
export type ordering_products = "inventories" | "sets" | "services" | "deliveries"
