import {FormControl} from "../shared/inputs/form-control"
import {useTranslation} from "react-i18next"
import {FormProvider, useForm} from "react-hook-form"
import usersService from "../../services/users"
import {FormControlPassword} from "../shared/inputs/form-control-password"

export default function NewSubleaseModal({onUserAdded}: {onUserAdded?: (sublease) => void}) {
  const {t} = useTranslation()
  const form = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      is_sublease: true
    }
  })
  const {watch, handleSubmit} = form
  const values = watch()
  const onSubmit = async () => {
    await usersService.post(values).then((res) => onUserAdded(res))
  }
  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-8">
        <p className="font-bold text-2xl text-black">Новый партнер субаренды</p>
        <div className="flex flex-col gap-4">
          <FormControl
            required
            label={t("users.form.first_name")}
            placeholder={t("users.form.placeholder.first_name")}
            rootclassname="col"
            className="form-control"
            name="first_name"
            params={{required: true}}
          />
          <FormControl
            required
            label={t("users.form.last_name")}
            placeholder={t("users.form.placeholder.last_name")}
            rootclassname="col"
            className="form-control"
            name="last_name"
            params={{required: true}}
          />
          <FormControl
            required
            label={t("users.form.username")}
            placeholder={t("users.form.placeholder.username")}
            rootclassname="col"
            className="form-control"
            name="username"
            params={{required: true}}
          />
          <FormControl
            label={t("users.form.email")}
            placeholder={t("users.form.placeholder.email")}
            rootclassname="col"
            className="form-control"
            name="email"
            params={{
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t("users.form.error.email")
              }
            }}
            required
          />
          <FormControlPassword
            label={t("users.form.password")}
            placeholder={t("users.form.placeholder.password")}
            rootclassname="col"
            params={{required: true, minLength: 8}}
            className="form-control"
            name="password"
          />
        </div>
        <button
          onClick={handleSubmit(onSubmit)}
          className="self-end btn btn-primary btn-color-white font-medium text-sm">
          Создать и добавить
        </button>
      </div>
    </FormProvider>
  )
}
