import axios, {AxiosResponse} from "axios"
import {Observable, from} from "rxjs"
import loadingService from "src/services/loading.service"
import {extractHttpErrorMessage} from "src/utils/url.utils"
import modalService from "../components/modal/global/modal.service"
import tokenService from "../services/token.service"
import {axiosResponce} from "./axios-response"
import {isNil} from "src/utils/isNil"
import subscriptionModalService, {SubscriptionModalType} from "src/services/subscription.service"
import authService from "src/services/auth.service"
import i18n from "i18next"

const protocol = process.env.REACT_APP_API_PROTOCOL
const endpointApi = process.env.REACT_APP_API_BACKEND
const enviroment = window.location.hostname.includes(".dev.") ? "dev" : "prod"

axios.defaults.baseURL = `${protocol}://api.${enviroment === "dev" ? "dev." : ""}${endpointApi}`

export function getAPI<T>(promise: Promise<AxiosResponse<T, any>>): Observable<T> {
  return from(promise).pipe(axiosResponce)
}

let refreshing: boolean = false
let failedQueue: {resolve: (token: string) => void; reject: (error: any) => void}[] = []

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((promise) => {
    if (token) {
      promise.resolve(token)
    } else {
      promise.reject(error)
    }
  })
  failedQueue = []
}

axios.interceptors.request.use(
  (config) => {
    const skip_loading = config && config.params && config.params.skip_loader === true
    if (!skip_loading) loadingService.setLoading(config.url)
    if (config.params) {
      config.params = Object.fromEntries(
        Object.keys(config.params).map((key) => [
          key,
          Array.isArray(config.params[key]) ? config.params[key].join(",") : config.params[key]
        ])
      )
    }
    const token = tokenService.getAccess
    if (isNil(token) && config.url === "/v1/crm/constants/") return Promise.reject(config)
    config.headers["Accept-Language"] = "ru"
    if (token) config.headers["Authorization"] = `Bearer ${token}`
    return config
  },
  (e) => Promise.reject(e)
)

axios.interceptors.response.use(
  (res) => {
    loadingService.removeLoading(res.config.url)
    return res
  },
  (err) => {
    loadingService.removeLoading(err?.config?.url)
    const originalConfig = err.config
    const urlRefresh = originalConfig?.url === "/v1/auth/refresh/"
    const urlLogin = originalConfig?.url === "/v1/auth/login/"
    const notAuth = err.response && tokenService.getRefresh && err.response.status === 401

    if (!urlLogin) {
      if (err.response?.status === 401 && urlRefresh) {
        tokenService.removeToken()
        processQueue(err, null)
        return Promise.reject(err)
      }

      if (err.response?.status === 401 && !originalConfig._retry) {
        if (refreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({
              resolve: (token) => {
                originalConfig.headers["Authorization"] = `Bearer ${token}`
                resolve(axios(originalConfig))
              },
              reject: (err) => reject(err)
            })
          })
        }
        originalConfig._retry = true
        refreshing = true

        if (!tokenService.getRefresh) {
          tokenService.removeToken()
          authService.getProfile()
          return Promise.reject()
        }

        return axios
          .post("/v1/auth/refresh/", {refresh: tokenService.getRefresh})
          .then((res) => {
            const {access} = res.data
            processQueue(null, access)
            tokenService.updateAccess(access)
            axios.defaults.headers.common["Authorization"] = `Bearer ${access}`
            return axios(originalConfig)
          })
          .catch((_error) => {
            tokenService.removeToken()
            authService.getProfile()
            return Promise.reject(_error)
          })
          .finally(() => (refreshing = false))
      }
    }

    let message: JSX.Element | string = i18n.t("common.error")

    if (err && err.code === "ERR_NETWORK") {
      modalService.open({
        component: (
          <div className="flex items-center justify-center text-center">
            Ваш браузер не подключен к интернету или такой компании не сушествует
          </div>
        )
      })
      return Promise.reject()
    }

    if (err.response === undefined) return Promise.reject()

    const data = err.response?.data || {}

    if (Object.keys(data).includes("code") && data.code === "token_not_valid") {
      tokenService.removeToken()
      authService.getProfile()
      modalService.closeAllModal()
      return Promise.reject(err)
    }

    if (
      Object.keys(data).includes("detail") &&
      Object.keys(data).includes("type") &&
      data.detail === "subscription_limit"
    ) {
      message = null
      const type: SubscriptionModalType | null = data.type as SubscriptionModalType
      subscriptionModalService.setShow({show: true, type})
      return Promise.reject()
    }

    if (originalConfig && originalConfig.params && originalConfig.params["skip_error_handling"] === true) {
      return Promise.reject()
    }

    if (err.response.status === 0) {
      message = <div className="py-2">{i18n.t("interceptors.cors_block")}</div>
    } else if (err.response.status >= 500) {
      message = <div className="py-2">{i18n.t("interceptors.server_error")}</div>
    } else if (err.response.status === 404) {
      message = <div className="py-2">{i18n.t("interceptors.not_found")}</div>
    } else if (err.response.status === 409) {
      message = <div className="py-2">{i18n.t("interceptors.unable_delete")}</div>
    } else {
      message = extractHttpErrorMessage(err.response.data)
    }

    if (originalConfig.params && originalConfig.params["custom_error_handling"] === true) {
      return Promise.reject(err)
    }

    if (message) {
      modalService.open({
        component: <div className="flex items-center justify-center text-center">{message}</div>
      })
    }

    return Promise.reject(err)
  }
)

export default axios
