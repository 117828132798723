import {Link} from "react-router-dom"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {SmsListComponent} from "./list"
import {useContext} from "react"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import {SmsLanding} from "./landing"
import {IntegrationModel} from "src/models/integration"
import modalService from "src/components/modal/global/modal.service"
import {IntegrationConnection} from "../connection.modal"

export function SmsIntegrationComponent() {
  const {integrationMap} = useContext(ConfigContext)
  const integration = integrationMap["sms"]
  const {t} = useTranslation()

  const onConnect = (integration: IntegrationModel) => {
    modalService.open({
      component: <IntegrationConnection integration={integration} />
    })
  }

  return (
    <>
      <HeaderContent>
        <div className="flex gap-2 items-center header-content">
          <Link to="/integrations" className="text-2xl font-semibold link">
            {t("integration.header")}
          </Link>
          <Icon icon="chevron_right" />
          <div className="text-2xl font-semibold">{integrationMap["sms"]?.name}</div>
        </div>
      </HeaderContent>

      {integration && (
        <>
          <SmsLanding />
          {integration.connected && <SmsListComponent />}
        </>
      )}
    </>
  )
}
