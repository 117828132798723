import {createPortal} from "react-dom"
import Icon from "../../material-icon"
import {useContext} from "react"
import {ConfigContext} from "src/app"

const SmoothOperator = () => {
  const {isMobile} = useContext(ConfigContext)

  const scrollToBottom = () => {
    const divToScrollTo = document.getElementById(`scroll`)
    divToScrollTo.scrollIntoView({behavior: "smooth"})
  }

  if (isMobile) return <></>

  return (
    <>
      <div id="scroll"></div>
      {createPortal(
        <button
          className="opacity-30 fixed z-[1000] bottom-20 md:bottom-10 right-4 h-14 w-14 rounded-full bg-primary border border-white border-opacity-20 shadow-[0_0_16px_0_rgba(0, 0, 0, 0.16)]"
          style={{padding: 10, overflow: "hidden", width: "100%"}}
          onClick={() => scrollToBottom()}>
          <Icon icon="keyboard_arrow_down" className="text-white text-2xl" />
        </button>,
        document.body
      )}
    </>
  )
}

export default SmoothOperator
