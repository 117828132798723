import ModalComponent from "./modal.v2"
import {createPortal} from "react-dom"
import modalService, {ModalConfig} from "./modal.service"
import {useCallback, useEffect, useId} from "react"
import clsx from "clsx"

interface ModalConfigCustom extends Omit<ModalConfig, "component"> {
  children: JSX.Element | JSX.Element[]
  id?: string
}

export const CustomModal = ({id = useId(), removeIcon = true, removable = true, children, ...config}: ModalConfigCustom) => {
  useEffect(() => {
    modalService.shadow$.next(config.show)
  }, [config.show])

  const onClose = useCallback(() => {
    if (config.backdropClick) return
    if (!config.disableClose && config.onHide !== undefined) config.onHide()
  }, [])

  return (
    <>
      {config.show && createPortal(
        <div
          className={clsx(
            "fixed bg-black bg-opacity-0 w-screen h-screen duration-300 ease-in-out z-[1050] left-0 top-0",
            config.show ? "bg-opacity-50" : "pointer-events-none"
          )}
        />,
        document.body
      )}

      {config.show && createPortal(
        <div
          id={id}
          role="dialog"
          tabIndex={-1}
          onClick={onClose}
          style={{display: "block", paddingLeft: 0}}
          className="modal fixed inset-0 w-full h-full overflow-y-auto overflow-x-hidden outline-none z-[1056]">
          <ModalComponent
            {...config}
            show={config.show}
            removable={removable}
            removeIcon={removeIcon}
            component={children}
          />
        </div>,
        document.body
      )}
    </>
  )
}
