import {FormControlMask} from "src/components/shared/inputs/form-control-mask"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormControlDate} from "src/components/shared/inputs/form-control-date"
import {useTranslation} from "react-i18next"
import {FieldError, useFormContext} from "react-hook-form"

type PassportErrors = {
  birth_date?: FieldError
  issue_date?: FieldError
  issue_date_end?: FieldError
}

type FormErrors = {
  passport__individual?: PassportErrors
}
export default function ClientIndividualPassportComponent() {
  const {t} = useTranslation()
  const {formState} = useFormContext()

  const errors = formState.errors as FormErrors
  return (
    <>
      <div className="grid sm:grid-cols-2 gap-4">
        <FormControlMask
          label={t("clients.form.document.individual.iin")}
          placeholder="000000000000"
          name="passport__individual.iin"
          mask="999999999999"
          rootclassname="col"
          className="form-control"
        />
        <FormControlDate
          label={t("clients.form.document.individual.birth_date")}
          name="passport__individual.birth_date"
          rootclassname="col"
          className={`form-control ${errors.passport__individual?.birth_date ? "border-red-500" : ""}`}
        />
        <FormControl
          label={t("clients.form.document.individual.document_number")}
          placeholder={t("clients.form.document.individual.placeholder.document_number")}
          name="passport__individual.document_number"
          rootclassname="col"
          className="form-control"
        />
        <FormControl
          label={t("clients.form.document.individual.issuer_manual")}
          placeholder={t("clients.form.document.individual.placeholder.issuer_manual")}
          name="passport__individual.issuer_manual"
          rootclassname="col"
          className="form-control"
        />

        <FormControlDate
          label={t("clients.form.document.individual.issue_date")}
          name="passport__individual.issue_date"
          rootclassname="col"
          className={`form-control ${errors.passport__individual?.issue_date ? "border-red-500" : ""}`}
        />

        <FormControlDate
          label={t("clients.form.document.individual.issue_date_end")}
          name="passport__individual.issue_date_end"
          rootclassname="col"
          className={`form-control ${errors.passport__individual?.issue_date_end ? "border-red-500" : ""}`}
        />
      </div>
    </>
  )
}
