import {currency, currencyPrefix} from "src/services/settings.service"
import {isNil} from "./isNil"

export function priceBeautify(price: number, fixed = 2): string {
  return !isNil(price) && !isNaN(price)
    ? Number(price)
      .toFixed(fixed)
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    : ""
}

export function priceModifier(price: number, suffix: string = "₸"): string {
  return `${(Math.round(Number(price) * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${suffix}`
}

export function toPrice(
  price: number,
  prefix: string = "",
  suffix: string = ""
): string {
  let text = prefix
  if (prefix !== "") text += " "
  if (isNil(price)) return text += `${currency} 0${suffix}`
  if (currencyPrefix) text += currency

  text = `${text} ${(Math.round(Number(price) * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`

  if (!currencyPrefix) text = text += ` ${currency}`
  if (suffix !== "") text = `${text} ${suffix}`
  return text
}
