import {lazy} from "react"
import {Navigate, Outlet, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import DeliveryWrapperComponent from "src/pages/deliveries/list"
import DeliveryListComponent from "src/pages/deliveries/list/list"
import {OrderDetailProvider} from "src/pages/orders/details/context/order-context"
import OrderInventoryAddList from "../pages/orders/details/inventories/inventory-list/add"
import ProductSetList from "../pages/set/list"
import OrderServicesAddModal from "../pages/orders/details/services/add"
import StateCheckComponent from "../pages/orders/details/order-media/state-check"
import {OrderInventorizationRouteWrapper} from "../pages/orders/details/side-menu/inventorization-modal"
import {DeliveryDetailComponent} from "src/pages/deliveries/detail"
import {DeliveryDetailMainComponent} from "src/pages/deliveries/detail/main"
import {DeliveryCheckoutComponent} from "src/pages/deliveries/detail/checkout"
import OrderListProviderComponent from "src/pages/orders/list/orders.context"
import componentLoader from "src/components/custom-suspense/loader"
import {ProtectedRoute} from "./guard"
import {AllWrapper} from "../pages/orders/details/all-product-tabs"
import {OrderDeliveryListComponent} from "src/pages/orders/details/delivery"

const DocumentTemplateEntity = lazy(() => componentLoader(() => import("src/pages/config/documents/entity")))
const DocumentTemplateGeneration = lazy(() => componentLoader(() => import("src/pages/config/documents/generation")))
const OrderDetailGroupList = lazy(() => componentLoader(() => import("src/pages/orders/details/inventories/inventory-list/group-list")))
const OrderDetailSetList = lazy(() => componentLoader(() => import("src/pages/orders/details/inventories/inventory-set-list/list")))
const OrderDetailServiceList = lazy(() => componentLoader(() => import("src/pages/orders/details/services/services")))
const OrderDetailComponent = lazy(() => componentLoader(() => import("src/pages/orders/details/order")))
const OrderListComponent = lazy(() => componentLoader(() => import("src/pages/orders/list/orders")))
const OrderListTabFilterComponent = lazy(() => componentLoader(() => import("src/pages/orders/list/order-tab.filter")))

const orderPages = [
  {path: "all", filter: {}},
  {path: "requests", filter: {status: 0, grant: false}},
  {path: "reserved", filter: {status: 1}},
  {path: "inrent", filter: {status: 2}},
  {path: "completed", filter: {status: 4, time_exceed: false}},
  {path: "expired", filter: {time_exceed: true}},
  {path: "debtor", filter: {status: 5, payment_status__in: "0,2", is_debtor: true}},
  {path: "canceled", filter: {status: 3}},
  {path: "deleted", filter: {deleted: true}}
]

const orders: RouteObject = {
  path: "orders",
  element: (
    <ProtectedRoute accessKey="orders">
      <OrderListProviderComponent>
        <CustomSuspense>
          <OrderListComponent />
        </CustomSuspense>
      </OrderListProviderComponent>
    </ProtectedRoute>
  ),
  children: [
    {
      path: "",
      element: <Navigate to="all" relative="route" replace={false} />
    },
    ...orderPages.map(({path, filter}) => ({
      path,
      element: (
        <CustomSuspense>
          <OrderListTabFilterComponent filter={filter} childStyle={true} />
        </CustomSuspense>
      )
    }))
  ]
}

const plus_orders: RouteObject = {
  path: "plus_orders",
  element: (
    <ProtectedRoute accessKey="orders">
      <OrderListProviderComponent>
        <CustomSuspense>
          <OrderListComponent type="yume plus" />
        </CustomSuspense>
      </OrderListProviderComponent>
    </ProtectedRoute>
  ),
  children: orders.children
}

export const orderRoutes: RouteObject[] = [
  orders,
  plus_orders,
  {
    path: "orders/:id",
    element: (
      <ProtectedRoute accessKey="orders">
        <OrderDetailProvider>
          <Outlet />
        </OrderDetailProvider>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: (
          <CustomSuspense>
            <OrderDetailComponent />
          </CustomSuspense>
        ),
        children: [
          {path: "", element: <Navigate to="all" replace={false} />},
          {
            path: "all",
            element: <AllWrapper />
          },
          {
            path: "inventories",
            element: (
              <CustomSuspense>
                <OrderDetailGroupList />
              </CustomSuspense>
            )
          },
          {
            path: "sets",
            element: (
              <CustomSuspense>
                <OrderDetailSetList />
              </CustomSuspense>
            )
          },
          {
            path: "services",
            element: (
              <CustomSuspense>
                <OrderDetailServiceList />
              </CustomSuspense>
            )
          },
          {
            path: "deliveries",
            element: (
              <CustomSuspense>
                <OrderDeliveryListComponent />
              </CustomSuspense>
            )
          },
        ]
      },
      {
        path: "add_product",
        element: <OrderInventoryAddList />
      },
      {
        path: "add_set",
        element: <ProductSetList />
      },
      {
        path: "add_service",
        element: <OrderServicesAddModal />
      },
      {
        path: "media",
        element: <StateCheckComponent />
      },
      {
        path: "media/:mediaId",
        element: <StateCheckComponent />
      },
      {
        path: "inventorization",
        element: <OrderInventorizationRouteWrapper />
      }
    ]
  },
  {
    path: "deliveries",
    element: <DeliveryWrapperComponent />,
    children: [
      {path: "", element: <Navigate to="pending" replace={false} />},
      {path: "pending", element: <DeliveryListComponent />},
      {path: "inprocess", element: <DeliveryListComponent />},
      {path: "done", element: <DeliveryListComponent />}
    ]
  },
  {
    path: "orders/:orderId/deliveries/:id",
    element: <DeliveryDetailComponent />,
    children: [
      {path: "", element: <DeliveryDetailMainComponent />},
      {path: "checkout", element: <DeliveryCheckoutComponent />}
    ]
  },
  {
    path: "documents/:id",
    element: (
      <CustomSuspense>
        <DocumentTemplateGeneration />
      </CustomSuspense>
    )
  },
  {
    path: "documents/:id/entity/:documentId",
    element: (
      <CustomSuspense>
        <DocumentTemplateEntity />
      </CustomSuspense>
    )
  }
]
