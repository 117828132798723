import Icon from "../shared/components/material-icon"
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import {TourSection as TourSectionType, TourStepItem} from "../../mock/tour-checklist"
import TourStep from "./tour-step"
import clsx from "clsx"
import {ConfigContext} from "../../app"

export interface TourSectionProps {
  section: TourSectionType
  setOpenTourChecklist: Dispatch<SetStateAction<boolean>>
}

export default function TourSection({section, setOpenTourChecklist}: TourSectionProps): JSX.Element {
  const {tenant, constants} = useContext(ConfigContext)
  const [visibleSteps, setVisibleSteps] = useState<TourStepItem[]>([])
  const [open, setOpen] = useState<boolean>(true)

  useEffect(() => {
    if (!constants) return
    if (!tenant) return

    const fetchVisibleSteps = async () => {
      const stepsArray = Object.values(section.steps)
      const filtered = await Promise.all(
        stepsArray.map(async (step) => {
          let shouldShow = true
          if (step?.checkShowing?.getCount) {
            const count = await step.checkShowing.getCount()
            shouldShow = count < tenant[step.checkShowing.limitProperty] || step.checked
          } else if (step?.checkShowing?.constantProperty) {
            const constantLength = constants[step.checkShowing.constantProperty].length
            shouldShow = constantLength < tenant[step.checkShowing.limitProperty] || step.checked
          }
          return shouldShow ? step : null
        })
      )
      setVisibleSteps(filtered.filter((s): s is TourStepItem => s !== null))
    }

    fetchVisibleSteps()
  }, [section, tenant, constants])

  const finishedCount = visibleSteps.filter((step) => step.checked).length
  const totalCount = visibleSteps.length

  useEffect(() => {
    if (finishedCount === totalCount) {
      setOpen(false)
    }
  }, [finishedCount, totalCount])

  return (
    <div className="flex flex-col p-5 gap-5">
      <div className="flex justify-between items-center cursor-pointer" onClick={() => setOpen(!open)}>
        <div className="flex gap-4 items-center">
          <div
            className="p-1.5 flex justify-center items-center bg-opacity-20 rounded-lg"
            style={{backgroundColor: section.icon.color + "33"}}>
            <Icon icon={section.icon.name} className="text-xl" style={{color: section.icon.color}} />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-[16px] text-black">{section.title}</p>
            <p
              className={clsx(
                "text-[13px] font-normal",
                finishedCount === totalCount ? "text-accept" : "text-gray-370"
              )}>
              Выполнено {finishedCount} из {totalCount}
            </p>
          </div>
        </div>
        <Icon icon={open ? "keyboard_arrow_up" : "keyboard_arrow_down"} className={clsx("text-xl select-none")} />
      </div>
      {open && visibleSteps.length > 0 && (
        <div className="flex flex-col gap-3">
          {visibleSteps.map((step, index) => (
            <TourStep key={index} setOpenTourChecklist={setOpenTourChecklist} step={step} />
          ))}
        </div>
      )}
    </div>
  )
}
