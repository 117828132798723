import {LabelModel} from "src/models/label"

export enum RingostatStatus {
  ANSWERED = "ANSWERED",
  NO_ANSWER = "NO ANSWER",
  FAILED = "FAILED",
  BUSY = "BUSY",
  REPEATED = "REPEATED",
  PROPER = "PROPER",
  NO_FORWARD = "NO-FORWARD",
  VOICEMAIL = "VOICEMAIL",
  WRONG_EXTENSION = "WRONG+EXTENSION",
  NO_EXTENSION = "NO+EXTENSION",
  CLIENT_NO_ANSWER = "CLIENT+NO+ANSWER",
  FAILED_FORBIDDEN_DESTINATION = "FAILED+FORBIDDEN+DESTINATION"
}

export const ringostatStatusLabel: Record<RingostatStatus, string> = {
  [RingostatStatus.ANSWERED]: "Отвечен",
  [RingostatStatus.NO_ANSWER]: "Нет ответа",
  [RingostatStatus.FAILED]: "С ошибками",
  [RingostatStatus.BUSY]: "Занято",
  [RingostatStatus.REPEATED]: "Повторный",
  [RingostatStatus.PROPER]: "Целевой",
  [RingostatStatus.NO_FORWARD]: "Не сработала схема переадресации",
  [RingostatStatus.VOICEMAIL]: "Голосовая почта",
  [RingostatStatus.WRONG_EXTENSION]: "Не правильно введён добавочный номер (IVR)",
  [RingostatStatus.NO_EXTENSION]: "Не введён добавочный номер (IVR)",
  [RingostatStatus.CLIENT_NO_ANSWER]: "Нет ответа клиента (Callback)",
  [RingostatStatus.FAILED_FORBIDDEN_DESTINATION]: "Запрещенное направление (Исходящие и Callback)"
}

export const ringostatStatusOptions: LabelModel[] = [
  {id: RingostatStatus.ANSWERED, label: ringostatStatusLabel[RingostatStatus.ANSWERED]},
  {id: RingostatStatus.NO_ANSWER, label: ringostatStatusLabel[RingostatStatus.NO_ANSWER]},
  {id: RingostatStatus.FAILED, label: ringostatStatusLabel[RingostatStatus.FAILED]},
  {id: RingostatStatus.BUSY, label: ringostatStatusLabel[RingostatStatus.BUSY]},
  {id: RingostatStatus.REPEATED, label: ringostatStatusLabel[RingostatStatus.REPEATED]},
  {id: RingostatStatus.PROPER, label: ringostatStatusLabel[RingostatStatus.PROPER]},
  {id: RingostatStatus.NO_FORWARD, label: ringostatStatusLabel[RingostatStatus.NO_FORWARD]},
  {id: RingostatStatus.VOICEMAIL, label: ringostatStatusLabel[RingostatStatus.VOICEMAIL]},
  {id: RingostatStatus.WRONG_EXTENSION, label: ringostatStatusLabel[RingostatStatus.WRONG_EXTENSION]},
  {id: RingostatStatus.NO_EXTENSION, label: ringostatStatusLabel[RingostatStatus.NO_EXTENSION]},
  {id: RingostatStatus.CLIENT_NO_ANSWER, label: ringostatStatusLabel[RingostatStatus.CLIENT_NO_ANSWER]},
  {
    id: RingostatStatus.FAILED_FORBIDDEN_DESTINATION,
    label: ringostatStatusLabel[RingostatStatus.FAILED_FORBIDDEN_DESTINATION]
  }
]
