import {useContext, useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useNavigate, useParams} from "react-router-dom"
import {MessageModal} from "src/components/modal/global/messageModal"
import modalService from "src/components/modal/global/modal.service"
import {FormControlMask} from "src/components/shared/inputs/form-control-mask"
import {FormControl} from "src/components/shared/inputs/form-control"
import {ClientIndividualPassportModel} from "src/models/manager/clients/client-individual-passport.model"
import {ClientLegalPassportModel} from "src/models/manager/clients/client-legal-passport.model"
import {ClientModel} from "src/models/manager/clients/client.model"
import clientIndividualPassportService from "src/services/clients-individual-passport.service"
import clientLegalPassportService from "src/services/clients-legal-passport.service"
import clientService from "src/services/clients.service"
import {isNil} from "src/utils/isNil"
import payloadFilter from "src/utils/payload.filter"
import {replaceAll} from "src/utils/replaceAll"
import ClientIndividualPassportComponent from "./passport/clients-individual-passport"
import ClientLegalPassportComponent from "./passport/clients-legal-passport"
import {CustomFieldChoiceList} from "../../settings/custom-field/field-choice"
import {SharedClientTypeSelect} from "src/components/shared/components/select/client-type"
import {SharedClientAttractionSelect} from "src/components/shared/components/select/client-attraction"
import {SharedClientTickSelect} from "src/components/shared/components/select/client-tick"
import {FormControlCheckbox} from "src/components/shared/inputs/form-control-checkbox"
import {FormControlDate} from "src/components/shared/inputs/form-control-date"
import Icon from "src/components/shared/components/material-icon"
import HeaderContent from "src/components/header/header-content"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import {TenantType} from "src/services/tenant.service"
import onboardingService from "src/services/onboarding"

interface ClientFormData extends ClientModel {
  passport__individual: ClientIndividualPassportModel
  passport__legal: ClientLegalPassportModel
}

export default function ClientEditComponent({
  onCreate,
  showHeader = true,
  showWrapper = true,
  formValues
}: {
  onCreate?: (obj: ClientModel) => void
  showHeader?: boolean
  showWrapper?: boolean
  formValues?: Partial<ClientModel>
}) {
  const form = useForm<ClientFormData>({defaultValues: {type: 0}, mode: "onBlur"})
  const {settings, tenant, onboardingData} = useContext(ConfigContext)
  const {handleSubmit, reset, watch, formState} = form
  const id = useParams<{clientId: string}>().clientId
  const isEditing = !isNil(id)
  const [client, setClient] = useState<ClientModel>()
  const navigate = useNavigate()
  const values = watch()
  const signed = watch("signed")
  const {t} = useTranslation()
  const accessExceptTransfer = tenant && tenant.type_code !== TenantType.TRANSFER

  const onSubmit = handleSubmit(async (payload) => {
    payload.phone = replaceAll(payload.phone, "-", "")
    const data = payloadFilter(payload, client)
    const type: "IND" | "LEG" = Number(payload.type) === 0 ? "IND" : "LEG"
    const passport = type === "IND" ? {...payload.passport__individual} : {...payload.passport__legal}

    delete data.passport__individual
    delete data.passport__legal

    if (isEditing) {
      const client = await onPatch(+id, data)
      await onPassportSave(+client.id, type, passport)
      if (!isNil(onCreate)) onCreate(client)
    }
    if (!isEditing) {
      const client = await onPost(data)
      await onPassportSave(+client.id, type, passport)
      if (!onboardingData?.state?.client_base_and_docs?.add_client)
        onboardingService.patch({
          block_name: "client_base_and_docs",
          step_name: "add_client",
          passed: true
        })
      if (!isNil(onCreate)) onCreate(client)
      if (isNil(onCreate)) navigate(`/clients/${client.id}`)
    }
  })

  const onPassportSave = (clientId: number, type: "IND" | "LEG", passport: any) => {
    const created = Object.keys(passport).includes("id")
    if ("birth_date" in passport && passport.birth_date === "") delete passport.birth_date
    if ("issue_date" in passport && passport.issue_date === "") delete passport.issue_date
    if ("issue_date_end" in passport && passport.issue_date_end === "") delete passport.issue_date_end
    if (created && type === "IND") return clientIndividualPassportService.patch(clientId, passport)
    if (created && type === "LEG") return clientLegalPassportService.patch(clientId, passport)
    if (!created && type === "IND" && "iin" in passport && passport.iin !== "") return clientIndividualPassportService.post(clientId, passport)
    if (!created && type === "LEG") return clientLegalPassportService.post(clientId, passport)
  }

  const onPost = async (payload: any) => {
    const res = await clientService.post(payload)
    if (isNil(onCreate))
      modalService.open({
        component: <MessageModal message={t("clients.form.modal.created")} />
      })
    return res
  }

  const onPatch = async (id: number, payload: any) => {
    const res = await clientService.patch(id, payload)
    if (isNil(onCreate))
      modalService.open({
        component: <MessageModal message={t("clients.form.modal.saved")} />
      })
    onBack()
    return res
  }

  const onCancel = () => {
    if (isNil(onCreate)) navigate(-1)
    if (!isNil(onCreate)) modalService.closeModal()
  }

  useEffect(() => {
    if (id) {
      clientService.get(+id).then((client) => {
        setClient(client)

        reset(client)
        if (client.type === 0) {
          clientIndividualPassportService.get(+id).then((passport) => {
            reset({...client, passport__individual: passport})
          })
          return
        }
        if (client.type === 1) {
          clientLegalPassportService.get(+id).then((passport) => reset({...client, passport__legal: passport}))
          return
        }
      })
    }
  }, [id])

  const onBack = () => navigate(-1)
  useEffect(() => {
    if (!signed) {
      form.setValue("sign_date", null, {shouldValidate: true})
      form.setValue("sign_expires", null, {shouldValidate: true})
    }
  }, [signed])

  useEffect(() => {
    if (formValues) {
      reset(formValues)
    }
  }, [])

  const content = (
    <>
      <p className="font-bold text-xl text-black mb-6">{t("groups.inventories.form.title")}</p>
      <div className="grid sm:grid-cols-2 gap-4">
        <FormControl
          name="name"
          rootclassname="col"
          className="form-control"
          params={{required: true}}
          required={true}
          label={t("clients.form.name")}
          placeholder={t("clients.form.placeholder.name")}
        />
        <SharedClientTypeSelect
          className="col"
          label={t("common.select.client_type")}
          placeholder={t("common.select.placeholder.client_type")}
          name="type"
          params={{required: true}}
          required={true}
          isClearable={false}
          isSearchable={false}
        />
        <FormControl
          rootclassname="col"
          className="form-control"
          name="email"
          label={t("clients.form.email")}
          placeholder={t("clients.form.placeholder.email")}
          params={{
            required: false,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t("clients.form.error.email")
            }
          }}
        />
        <FormControlMask
          label={t("clients.form.phone")}
          placeholder={t("common.input.placeholder.phone_number")}
          rootclassname="col"
          name="phone"
          mask={tenant && tenant.type_code === TenantType.TRANSFER ? "999999999999" : settings && settings.phone_format}
          className="form-control"
          required
          params={{required: true}}
        />
        <SharedClientAttractionSelect
          className="col"
          label={t("common.select.attraction_method")}
          placeholder={t("common.select.placeholder.attraction_method")}
          name="attraction"
          isClearable={true}
          isSearchable={false}
        />
        <SharedClientTickSelect
          className="col"
          label={t("common.select.rating")}
          placeholder={t("common.select.placeholder.rating")}
          name="tick"
          isClearable={true}
          isSearchable={false}
        />
      </div>

      {accessExceptTransfer && <CustomFieldChoiceList type="client" />}

      {accessExceptTransfer && (
        <div>
          <div className="text-lg font-semibold mb-2">{t("clients.form.agreement.label")}</div>
          <div className="flex gap-3 mb-3 flex-col md:flex-row">
            <FormControlCheckbox
              label={t("clients.form.agreement.signed")}
              name="signed"
              className="form-check-input"
            />
            <FormControlDate
              placeholder={t("clients.form.agreement.sign_date")}
              name="sign_date"
              rootclassname="col"
              className="form-control"
              type="date"
              params={{required: signed}}
              disabled={!signed}
            />
            <FormControlDate
              placeholder={t("clients.form.agreement.sign_expires")}
              name="sign_expires"
              rootclassname="col"
              className="form-control"
              type="date"
              params={{required: signed}}
              disabled={!signed}
            />
          </div>
        </div>
      )}

      {accessExceptTransfer && (
        <>
          {+values.type === 0 && <ClientIndividualPassportComponent />}
          {+values.type === 1 && <ClientLegalPassportComponent />}
        </>
      )}

      <div className="flex items-center justify-end gap-2">
        <button className="btn btn-card font-medium gap-2 flex-1 md:flex-none" onClick={onCancel}>
          {t("common.cancel")}
        </button>
        <button
          className="btn btn-primary btn-color-white font-medium gap-2 flex-1 md:flex-none"
          disabled={!formState.isValid}
          onClick={onSubmit}>
          <span>{t(values.id ? "common.save" : "common.create_and_save")}</span>
        </button>
      </div>
    </>
  )

  return (
    <FormProvider {...form}>
      {showHeader && (
        <HeaderContent>
          <div className="flex items-center justify-between header-content">
            <div className="flex gap-2 items-center">
              <Icon onClick={onBack} className="cursor-pointer text-2xl" icon="chevron_left" />
              <span className="text-2xl font-semibold">
                {t(values.id ? "clients.form.header_edit" : "clients.form.header_add")}
              </span>
            </div>
          </div>
        </HeaderContent>
      )}

      {showWrapper ? (
        <div className="m-auto flex flex-col gap-3 md:gap-6 max-w-[720px] bg-white rounded-xl p-3 md:p-6 border border-gray-100 shadow">
          {content}
        </div>
      ) : (
        <div className="flex flex-col gap-4">{content}</div>
      )}
    </FormProvider>
  )
}
